import {inject} from 'mobx-react';
import {Serialize} from 'eosjs';
import React, {Component} from 'react';

import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Filter} from '../../helpers/filter/Filter';
import {Pagination} from '../../helpers/pagination/Pagination';
import {getUserActions} from '../../../utils/requester';
import {Preloader} from '../../helpers/preloader/Preloader';
import {TOKENS} from '../../../config/const';
import {DropDown} from '../../helpers/dropDown/DropDown';
import {TrxRow} from '../../helpers/trxRow/TrxRow';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

import {TrxHistoryTable} from '../../helpers/trxHistoryTable/TrxHistoryTable';

const filters = [
  {label: 'account.details.control_btn.all_act', value: 'All'},
  {label: 'account.details.control_btn.send_tokens', value: 'Transfers'},
  {
    label: 'account.details.control_btn.staking',
    value: 'Staking',
  }, // todo add actions for WCRU
  {label: 'RAM/CPU/NET', value: 'Resources'},
  {label: 'account.details.control_btn.votes', value: 'Votes'},
  // {label: "Account bids", value: "acBids"},
  {label: 'account.details.control_btn.rewards', value: 'Claim'},
];

const filtersAsset = [
  {label: 'common.all', value: ''},
  {label: 'CRU', value: TOKENS.CRU},
  {label: 'WCRU', value: TOKENS.WCRU},
  {label: 'UNTB', value: TOKENS.UNTB},
  {label: 'USDU', value: TOKENS.USDU},
];

const formatOptionLabel = data => (
  <div className='select-row'>
    <span className='label'>
      <I18n tKey={data.label} />
    </span>
  </div>
);

@inject('userStore', 'eosStore', 'loggerStore')
export class WalletHistory extends Component {
  contracts = {};

  state = {
    actionFilter: filters[0],
    currencyFilter: filtersAsset[0],
    isFetching: false,
    isError: false,
    page: 0,
    limit: 20,
    rows: [],
    totalCount: 0,
    extraAssets: [],
  };

  componentDidMount() {
    this.fetchData();
    const {userStore} = this.props;

    const extraAssets = Object.keys(userStore?.userWallet || {}).map(token => ({
      label: token,
      value: token,
    }));

    this.setState({extraAssets});
  }

  fetchData = () => {
    const {page, limit, currencyFilter, actionFilter} = this.state;
    const {userStore, loggerStore} = this.props;
    this.setState({isFetching: true});

    // action_data: [`%"username":"${this.props.accountName}"%`, `%"pay_to":"${this.props.accountName}"%`],

    const data = {
      page,
      limit,
      filter: {
        action_data: `%"username":"${userStore.user.name}"%`,
        actor: userStore.user.name,
        token_to: userStore.user.name,
        token_from: userStore.user.name,
      },
      orderBy: {column: 'id', order: 'desc'},
      matchOne: ['token_to', 'token_from', 'actor', 'action_data'],
    };

    if (currencyFilter.value) {
      data.filter.symbol = currencyFilter.value;
      // data.matchOne.push('symbol');
    }
    if (
      (currencyFilter.value === filtersAsset[0].value || currencyFilter.value === filtersAsset[3].value) &&
      actionFilter.value === filters[0].value
    ) {
      const curUser = userStore.user.name;
      data.filter.action_data = [`%"username":"${curUser}"%`, `%"pay_to":"${curUser}"%`];
    }
    let act = '';
    if (actionFilter.value) {
      const filterValue = actionFilter.value;
      // if (actionFilter.label === filters[2].label) {
      //   if (currencyFilter.value === filtersAsset[1].value || currencyFilter.value === filtersAsset[2].value) {
      //     // if cru filter add to filters only staking and unstaking op
      //     filterValue = filterValue.slice(0, 4);
      //   } // else if (currencyFilter.value === filtersAsset[3].value) {
      //   //   // if untb filter add to filters only delegatebw and undelegatebw
      //   //   filterValue = filterValue.slice(4, filterValue.length);
      //   // }
      // }
      data.filter.action_name = filterValue;
      act = filterValue;
      // data.matchOne.push('action_name');
    }

    // console.log(data);

    getUserActions({
      offset: page * limit,
      limit,
      account: userStore.user.name,
      symbol: currencyFilter.value || '',
      type: act || 'All',
    }).then(async r => {
      const newState = {isFetching: false, isError: false};
      if (!r.isError) {
        newState.rows = await Promise.all(
          r.data.map(async action => {
            const {action_name, act_data, contract: contractName} = action;

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await this.props.eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              action_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            // console.log(action_name, dataa);

            return {...action, action_data: dataa};
          })
        );

        newState.totalCount = Number(r.data.length);
      } else {
        newState.isError = true;
        loggerStore.addItem({type: LOGGER_TYPES[2], msgTxt: 'common.try_again_later'});
      }
      this.setState(newState);
    });
  };

  setPage = num => {
    this.setState({page: num}, this.fetchData);
  };

  setPrev = () => {
    this.setPage(this.state.page - 1);
  };

  setNext = () => {
    this.setPage(this.state.page + 1);
  };

  selectFilter = (propName, value) => {
    const newState = {[propName]: value, page: 0};
    if (propName === 'actionFilter') {
      if (value.label !== filters[1].label && value.label !== filters[2].label && value.label !== filters[0].label) {
        // reset cur filter
        newState.currencyFilter = filtersAsset[0];
      }
      if (value.label === filters[2].label && this.state.currencyFilter.label === filtersAsset[3].label) {
        // reset if we selected staking filter and cur was USDU
        newState.currencyFilter = filtersAsset[0];
      }
    }
    this.setState(newState, this.fetchData);
  };

  render() {
    const {actionFilter, isError, page, limit, totalCount, currencyFilter, extraAssets, rows} = this.state;
    const canRenderCurFilter =
      actionFilter.label === filters[1].label ||
      actionFilter.label === filters[2].label ||
      actionFilter.label === filters[0].label;
    const assetOptions = filtersAsset.slice(0, 3);

    console.log('rowss', this.state.rows);
    return (
      <div className='wallet-history'>
        {this.state.isFetching && <Preloader className='white-transparent-bg' />}
        <I18n tKey='wallet_history.title' className='title-page' />
        <div className='top-content'>
          <div className='filter-content'>
            <div className='desktop-filters'>
              <Filter
                title='wallet_history.filter_act'
                selectFilter={this.selectFilter.bind(this, 'actionFilter')}
                activeItem={actionFilter}
                filters={filters}
              />
              {canRenderCurFilter && (
                <>
                  <Filter
                    title='wallet_history.filter_asset'
                    selectFilter={this.selectFilter.bind(this, 'currencyFilter')}
                    activeItem={currencyFilter}
                    filters={actionFilter.label === filters[2].label ? assetOptions : [...filtersAsset, extraAssets]}
                  />
                </>
              )}
            </div>
            <div className='mobile-filters'>
              <div className='dropdown'>
                <p>
                  <I18n tKey='trx_page.table.col_act' />:
                </p>
                <DropDown
                  wrapperClass='react-select-container'
                  onChange={this.selectFilter.bind(this, 'actionFilter')}
                  value={actionFilter}
                  options={filters}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              {canRenderCurFilter && (
                <div className='dropdown asset-dropdown'>
                  <p>
                    <I18n tKey='common.asset' />:
                  </p>
                  <DropDown
                    wrapperClass='react-select-container'
                    onChange={this.selectFilter.bind(this, 'currencyFilter')}
                    value={currencyFilter}
                    options={actionFilter.label === filters[2].label ? assetOptions : filtersAsset}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>
              )}
            </div>
          </div>
          <div onClick={this.fetchData} className='refresh-block'>
            <I18n tKey='wallet_history.refresh' />
            <RefreshIcon />
          </div>
        </div>
        <div className='history-trx'>
          {rows.map((obj, index) => (
            <TrxRow obj={obj} key={index} currentUser={this.props.userStore.user.name} />
          ))}
        </div>
        <TrxHistoryTable
          accountName={this.props.userStore.user.name}
          isError={isError}
          onRetry={this.fetchData}
          rows={this.state.rows}
        />
        <Pagination
          handlePrev={this.setPrev}
          handleNext={this.setNext}
          withoutNumbers
          hasNext={totalCount == 20}
          hasPrev={page != 0}
          currentPage={page + 1}
          limit={limit}
          totalCount={totalCount}
        />
      </div>
    );
  }
}
