import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as LockIcon} from '../../../img/lock-shield.svg';
import {I18n} from '../../helpers/i18n/I18n';

export class SuccessPin extends Component {
  render() {
    return (
      <div className='success-pin-page'>
        <LockIcon />
        <I18n className='done-title' tKey='wallet_auth.done_title' />
        <I18n className='done-subtitle' tKey='wallet_auth.done_subtitle' />
        <Link to='/wallet' className='done-btn'>
          <I18n tKey='wallet_auth.cheers_btn' />
        </Link>
      </div>
    );
  }
}
