import React, {Component} from 'react';

import {I18n} from '../../helpers/i18n/I18n';

export class NoMatch extends Component {
  render() {
    return (
      <div className='no-match-page'>
        <h1>
          <I18n tKey='404' />
        </h1>
        <p>
          <I18n tKey='page_not_found.not_found' />
        </p>
        <p>
          <I18n tKey='page_not_found.problem' />
        </p>
      </div>
    );
  }
}
