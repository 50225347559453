import React, {Component} from 'react';
import classNames from 'classnames';

export class ProgressBar extends Component {
  state = {
    width: 0,
  };

  componentDidMount() {
    setTimeout(() => this.setState({width: this.props.size}));
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({width: nextProps.size});
  }

  render() {
    const {count, amount, lineClass} = this.props;
    return (
      <div className='progress-wrapper'>
        <div className={classNames('progress-line', lineClass)} style={{width: this.state.width}} />
        {(count !== undefined || amount !== undefined) && (
          <div className='lines-counts'>
            <p className='count-line'>{count}</p>
            <span className='amount-line'>{amount}</span>
          </div>
        )}
      </div>
    );
  }
}
