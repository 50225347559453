import moment from 'moment';
import Bar from 'react-chartjs-2';
import classNames from 'classnames';
import ReactSlider from 'react-slider';
import React, {Component, Fragment} from 'react';
import BigNumber from 'bignumber.js';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import {I18n} from '../../helpers/i18n/I18n';
import {graphOptions} from '../../../config/const';
import {ReactComponent as Frozen} from '../../../img/frozen.svg';

export class FrozenTab extends Component {
  componentDidMount() {
    this.props.getWithdrawedObj();
  }

  changeSelectedBar = (ev, elem) => {
    if (elem[0]) {
      this.props.onSliderChange(this.props.graphData.datasets[0].data.length - elem[0]._index - 1); // because we have inversion here
    }
  };

  render() {
    const {
      graphData,
      onSliderChange,
      openModal,
      activeValue,
      selectDateRange,
      frozenDataIsFEtching,
      withdrawedObjError,
      leftOvers,
      changeLetfOver,
    } = this.props;

    if (frozenDataIsFEtching) {
      return (
        <p className='empty-objects'>
          <I18n tKey='convert_wcru.loading_frozen_objects' />
        </p>
      );
    }
    if (withdrawedObjError) {
      return (
        <p className='empty-objects'>
          <I18n tKey='convert_wcru.err_get_withdrowed_obj' />
        </p>
      );
    }
    if (!frozenDataIsFEtching && graphData.datasets[0].data.length < 1) {
      if (leftOvers !== 'undefined' && +leftOvers !== 0) {
        return (
          <div className='notice-text-wrapper d-flex w-100'>
            <div className='wrapper-flex-item wide flex-2'>
              <InfoIcon className='circular__img' />
            </div>
            <div className='flex-item'>
              <div className='d-flex flex-column'>
                <I18n className='table-header sm' tKey='convert_wcru.table_attention' />
                <p className='sub-text'>
                  <I18n tKey='convert_wcru.table_leftovers' />
                </p>
                <p className='blue-amount'>{leftOvers} CRU</p>
              </div>
            </div>
          </div>
        );
      }
      return (
        <p className='empty-objects'>
          <I18n tKey='convert_wcru.empty_frozen_objects' />
        </p>
      );
    }
    // pass objects, to have access to detail data in tooltip render
    const options = {
      ...graphOptions(graphData.datasets[0].detailData, graphData.monthArr),
      onClick: this.changeSelectedBar,
    };
    let selectedDate;
    try {
      selectedDate = graphData.monthArr[graphData.scheduleLength - activeValue];
    } catch (e) {
      selectedDate = moment().format();
    }

    const firstDate = moment(graphData.monthArr[0], 'MMMM YYYY');
    const diff = Math.abs(moment().diff(firstDate, 'months'));
    const scheduleLenInOneYear = graphData.scheduleLength - 12 + diff;
    const len = Math.min(scheduleLenInOneYear, graphData.scheduleLength);

    return (
      <>
        <div className='action-block'>
          <div className='unfreeze-schedule' onClick={openModal}>
            <Frozen />
            <I18n tKey='convert_wcru.unfreeze_schedule' />
          </div>
          <div className='select-days'>
            <I18n
              tKey='common.select_all'
              onClick={selectDateRange.bind(this, graphData.scheduleLength)}
              className={classNames({disabled: graphData.scheduleLength === activeValue})}
            />
            <span
              role='presentation'
              onClick={selectDateRange.bind(this, len)}
              className={classNames({disabled: len === activeValue || len <= 0})}
            >
              &#62; 365 <I18n tKey='common.days' />
            </span>
          </div>
        </div>
        <div className='frozen-schedule'>
          <div className='schedule-info'>
            <div className='schedule-tips'>
              <I18n tKey='convert_wcru.select_range' className='title-block-select' />
              <I18n tKey='convert_wcru.move_slider' className='subtitle-block-select' />
            </div>
            <div className='schedule-date'>
              <span className='title-block-select'>{selectedDate}</span>
              <I18n tKey='convert_wcru.later' className='subtitle-block-select' />
            </div>
          </div>
          <Bar type='bar' data={graphData} options={options} />
          <ReactSlider
            className='horizontal-slider'
            min={0}
            value={activeValue}
            max={graphData.scheduleLength}
            invert
            thumbClassName='example-thumb'
            trackClassName='example-track'
            onChange={onSliderChange}
          />
        </div>
      </>
    );
  }
}
