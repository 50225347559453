import React, {Component, Fragment} from 'react';
import JSONTree from 'react-json-tree';
import moment from 'moment';

import {Link} from 'react-router-dom';
import {ReactComponent as ArrowIcon} from '../../../img/arrow.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {Button} from '../../helpers/button/Button';
import {Preloader} from '../../helpers/preloader/Preloader';
import {Pagination} from '../../helpers/pagination/Pagination';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';
import {TableControls} from '../../helpers/tableСontrols/TableControls';

import {translate} from '../../../utils/translater';
import {getBlock, getBlockDetails} from '../../../utils/requester';
import {JSON_TREE_THEME, TIME_FORMAT} from '../../../config/const';
import {rewriteProducer} from '../../../utils/utils';
import {EmptyResults} from '../../helpers/emptyResults/EmptyResults';

const pages = [
  {title: 'breadcrumbs.home', url: '/'},
  {title: 'breadcrumbs.blocks', url: '/blocks'},
];

const tableData = {
  columns: [
    {
      name: 'block_page.detail.table.сol_id',
      render: obj => <Link to={`/transactions/${obj.trx.id}`}>{obj.trx.id}</Link>,
    },
    {name: 'block_page.detail.table.сol_act', render: obj => <span>{(obj.trx.transaction.actions || []).length}</span>},
    {name: 'block_page.detail.table.сol_cpu', render: obj => <span>{obj.cpu_usage_us} μs</span>},
    {name: 'block_page.detail.table.сol_net', render: obj => <span>{obj.net_usage_words} B</span>},
    {
      name: 'block_page.detail.table.сol_expir',
      render: obj => <span>{moment(obj.trx.transaction.expiration).format(TIME_FORMAT.BLOCK)}</span>,
    },
  ],
};

const controlBtn = [
  {title: 'block_page.control_btn.transact', value: 'actions'},
  {title: 'block_page.control_btn.row_data', value: 'rawData'},
];

export class BlockDetails extends Component {
  state = {
    details: {},
    isFetching: false,
    activeTab: controlBtn[0].value,
    limit: 15,
    page: 0,
    previousIndex: null,
    searchValue: '',
  };

  changeBlockNumber = up => {
    const {number} = this.props.match.params;
    let nextBlock = Number(number);
    this.props.history.push(`/blocks/${up ? ++nextBlock : --nextBlock}`);
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  setPage = num => {
    this.setState({page: num});
  };

  componentDidMount() {
    const {number} = this.props.match.params;
    this.getBlock(number);

    if (parseInt(this.state.searchValue.length) < 2) {
      this.setState({searchValue: this.props.match.params.number});
      this.getBlock(this.props.match.params.number);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.match.params.number !== nextProps.match.params.number) {
      this.setState({searchValue: nextProps.match.params.number});
      this.getBlock(nextProps.match.params.number);
    }
    console.log(nextProps.match.params.number);
  }

  getBlock = number => {
    this.setState({isFetching: true});
    getBlock(number).then(r => {
      const newState = {isFetching: false, details: {}};

      if (!r.isError && r.data) {
        const details = {actions: 0, cpu: 0, net: 0};
        (r.data.transactions || []).forEach(elem => {
          details.actions += (elem.trx.transaction.actions || []).length;
          details.cpu += Number(elem.cpu_usage_us);
          details.net += Number(elem.net_usage_words);
        });
        newState.details = {
          ...r.data,
          transaction_count: r.data?.transactions.length,
          actions: details.actions,
          cpuUsage: details.cpu,
          netUsage: details.net,
        };
      }
      this.setState(newState);
    });
  };

  render() {
    const {isFetching, details, activeTab, page, limit} = this.state;
    if (isFetching) {
      return (
        <div className='block-details-page'>
          <Preloader className='white-bg' />
        </div>
      );
    }
    const transactions = details.transactions || [];
    const rows = [...transactions].slice(page > 0 ? page * limit : 0, page > 0 ? (page + 1) * limit : limit);
    return (
      <div className='block-details-page'>
        {Object.keys(details).length > 0 ? (
          <div className='page-content'>
            <div className='header-page'>
              <Breadcrumbs currentTitle='block_page.detail.title' pages={pages} />
            </div>
            <div className='info-blocks'>
              <div className='account-info'>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.height' />
                  <Button
                    btnClass='arrow-back'
                    disabled={Number(details.block_num || 1) < 2}
                    ArrowIcon={ArrowIcon}
                    onClick={this.changeBlockNumber.bind(this, false)}
                  />
                  <span className='info-txt'>{details.block_num || 1}</span>
                  <Button btnClass='arrow-forward' ArrowIcon={ArrowIcon} onClick={this.changeBlockNumber.bind(this, true)} />
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.time' />
                  <span className='info-txt'>{moment(details.timestamp).format(TIME_FORMAT.BLOCK)}</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.status' />
                  <I18n className='info-txt' tKey='home.block_list.irreversible' />
                  {/* todo */}
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.producer' />
                  <span className='info-txt'>{rewriteProducer(details.producer)}</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.blockId' />
                  <span className='info-txt'>{details.id}</span>
                </div>
              </div>
              <div className='account-info'>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.version' />
                  <span className='info-txt'>{details.schedule_version}</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.transact' />
                  <span className='info-txt'>{details.transaction_count}</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.actions' />
                  <span className='info-txt'>{details.actions}</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.cpu_data' />
                  <span className='info-txt'>{details.cpuUsage} μs</span>
                </div>
                <div className='info-content'>
                  <I18n className='title' tKey='block_page.detail.info.net_data' />
                  <span className='info-txt'>{details.netUsage} Bytes</span>
                </div>
              </div>
            </div>
            <div className='header-control-table'>
              <I18n className='main-title md' tKey='block_page.detail.table_title' />
              <div className='table-controls'>
                {controlBtn.map((elem, i) => (
                  <TableControls
                    key={i}
                    onClick={this.setActiveTab.bind(this, elem.value)}
                    active={elem.value === activeTab}
                    title={elem.checkCount ? `${translate(elem.title)} (${transactions.length})` : elem.title}
                  />
                ))}
              </div>
            </div>
            {activeTab === controlBtn[1].value ? (
              <JSONTree theme={JSON_TREE_THEME} data={details} hideRoot />
            ) : (
              <>
                {transactions.length > 0 ? (
                  <>
                    <Table data={{...tableData, rows}} tableClass='transactions-table' />
                    <Pagination changePage={this.setPage} currentPage={page + 1} limit={limit} totalCount={transactions.length} />
                  </>
                ) : (
                  <I18n className='process-msg top-pad' tKey='common.trx_not_found' />
                )}
              </>
            )}
          </div>
        ) : (
          <EmptyResults searchValue={this.state.searchValue} />
        )}
      </div>
    );
  }
}
