import {BigNumber} from 'bignumber.js';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {I18n} from '../../helpers/i18n/I18n';
import {CardStaking} from './CardStaking';
import {TOKENS} from '../../../config/const';

import cruIcon from '../../../img/cru-cru.png';
import wcruIcon from '../../../img/wcru-untb.png';
import untbIcon from '../../../img/UNTB.png';
import cruUntbIcon from '../../../img/cru-untb.png';
import {convertBytes, convertMicroSec, changeFormat, cutTokenPrefix} from '../../../utils/utils';
import {getEosTable} from '../../../utils/requester';

@inject('userStore', 'tokenStore')
@observer
export class WalletStaking extends Component {
  state = {
    cruForCruReward: null,
  };

  componentDidMount = async () => {
    const {userStore} = this.props;
    const {user} = userStore;
    const {data, isError} = await getEosTable({json: true, code: 'staker', scope: user.name, table: 'soclaim'});

    if (!isError) {
      const unclaimedCount = data.rows.reduce((count, {possible_claim}) => {
        const newCount = count + +cutTokenPrefix(possible_claim);

        return newCount;
      }, 0);

      this.setState({cruForCruReward: data.rows.length ? unclaimedCount : null});
    }
  };

  returnEmissionRate = stakedAmount => {
    const {emission} = this.props.tokenStore;

    return +stakedAmount === 0
      ? 0
      : new BigNumber(stakedAmount)
          .div(cutTokenPrefix(emission.total_stakers_balance))
          .multipliedBy(cutTokenPrefix(emission.current_emission_rate))
          .multipliedBy(2)
          .multipliedBy(60)
          .multipliedBy(60)
          .toFormat(4);
  };

  render() {
    const {cruForCruReward} = this.state;
    const {userStore} = this.props;
    const availableWCRUForStake = new BigNumber(userStore.userWallet.WCRU.available)
      .plus(userStore.userWallet.WCRU.frozen)
      .toNumber();
    const stakedCPU = userStore.accountDetails.total_resources?.cpu_weight || 0;
    const stakedNET = userStore.accountDetails.total_resources?.net_weight || 0;
    const emissionWCRU = this.returnEmissionRate(cutTokenPrefix(userStore.userStacking.staked_wcru_balance));
    const emissionCRU = this.returnEmissionRate(cutTokenPrefix(userStore.userStacking.staked_cru_balance));

    return (
      <div className='nested-route wallet-staking'>
        <div className='wallet-staking-block'>
          <I18n tKey='wallet_staking.title' className='title-page' />
          <div className='staking-content'>
            <CardStaking
              titleCard='wallet_staking.card.cru_to_cru'
              subtitleCard='wallet_staking.card.cru_subtitle'
              btnTitle='wallet_staking.card.stake_cru'
              titleReward='wallet_staking.card.monthly_reward'
              countReward='up to 8%'
              countAmount={cutTokenPrefix(userStore.cruForCruStakedActual)}
              countCurReward={(cruForCruReward !== null && `${changeFormat(cruForCruReward, 4)} CRU`) || undefined}
              countAvailable={cutTokenPrefix(userStore.userWallet.CRU.available)}
              token='cru-for-cru'
              round={4}
              cardIcon={cruIcon}
            />
            <CardStaking
              titleCard='wallet_staking.card.wrcu_to_untb'
              subtitleCard='wallet_staking.card.stake_wcru'
              btnTitle='wallet_staking.card.wrcu_to_untb'
              titleReward='wallet_staking.card.est_monthly_reward'
              countReward={`${emissionWCRU} UNTB`}
              countAmount={cutTokenPrefix(userStore.userStacking.staked_wcru_balance)}
              countCurReward={userStore.userStacking.emitted_balance}
              countAvailable={availableWCRUForStake}
              token={TOKENS.WCRU}
              round={4}
              cardIcon={wcruIcon}
            />
            <CardStaking
              titleCard='wallet_staking.card.cru_to_untb'
              subtitleCard='wallet_staking.card.stake_untb'
              btnTitle='wallet_staking.card.stake_cru_untb'
              titleReward='wallet_staking.card.est_monthly_reward'
              countReward={`${emissionCRU} UNTB`}
              countAmount={cutTokenPrefix(userStore.userStacking.staked_cru_balance)}
              countCurReward={userStore.userStacking.emitted_balance}
              countAvailable={cutTokenPrefix(userStore.userWallet.CRU.available)}
              token={TOKENS.CRU}
              round={4}
              cardIcon={cruUntbIcon}
            />
            <CardStaking
              cardClass='untb-card'
              titleCard='wallet_staking.card.untb_to_net'
              subtitleCard='wallet_staking.card.stake_your_untb'
              btnTitle='wallet_staking.card.stale_untb_net'
              countAvailable={cutTokenPrefix(userStore.userWallet.UNTB.available)} // todo add frozen
              titleAmount={`NET ${convertBytes(userStore.accountDetails.net_limit.used)} / ${convertBytes(
                userStore.accountDetails.net_limit.available
              )}`}
              countNet={stakedNET}
              titleCurReward={`CPU ${convertMicroSec(userStore.accountDetails.cpu_limit.used)} / ${convertMicroSec(
                userStore.accountDetails.cpu_limit.available
              )}`}
              countCpu={stakedCPU}
              token={TOKENS.UNTB}
              round={4}
              cardIcon={untbIcon}
            />
          </div>
        </div>
      </div>
    );
  }
}
