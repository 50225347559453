/* eslint-disable react/prefer-stateless-function */
import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export class ModalHeader extends Component {
  render() {
    const {params} = this.props;

    return (
      <div className='modal-dividend-header'>
        <h1 className={`operation ${params.className}`}>
          <I18n value={params.userName} tKey={params.headerLabelText} />
        </h1>
        {params.value && (
          <div className='amount-count'>
            <span className='value'>{params.value}</span>
          </div>
        )}
      </div>
    );
  }
}
