import React from 'react';
import {Timer} from '../../helpers/timer/Timer';

import {I18n} from '../../helpers/i18n/I18n';
import './index.scss';

export const ActionPanel = ({logo, title, subtitle, action, endTime, endTimeCallback}) => {
  return (
    <div className='action-panel'>
      {logo}
      <div className='flex-col'>
        <I18n tKey={title} className='block-title' />

        {endTime && (
          <Timer title='producer_acc.become-candidate-ends-timer' endTimeCallback={endTimeCallback} endDateTime={endTime} />
        )}

        <I18n className='sub-text' tKey={subtitle} />

        {action}
      </div>
    </div>
  );
};
