import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Bar from 'react-chartjs-2';

import {InputRow} from '../../helpers/inputRow/InputRow';
import {Input} from '../../helpers/input/Input';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {DropDown} from '../../helpers/dropDown/DropDown';
import {Table} from '../../helpers/table/Table';

import {getEosTable, getTableByScope} from '../../../utils/requester';
import {cutTokenPrefix, detectRounding, getTokenPrefix, changeFormat} from '../../../utils/utils';
import {REG_EXP, getRoundedRegExp} from '../../../config/const';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import './index.scss';

const inputFormatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          padding: 15,
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const formatOptionLabel = data => {
  return (
    <div className='select-row'>
      <I18n className='plan-name' tKey={data.title} />{' '}
    </div>
  );
};

@inject('userStore', 'modalStore', 'eosStore', 'loggerStore')
@observer
export class IssuePlan extends Component {
  state = {
    selectedToken: null,
    selectedPhase: null,
    tokens: [],
    userName: '',

    initialMetadata: null,
    initialPlan: null,
    initialPercents: [],
    depInfo: [],

    plan_name: '',
    desc: '',
    url: '',

    active_phases: '',
    frozen_phases: '',
    phase_in_days: '',

    issueAmount: '',
    issueTo: '',
  };

  tableColumns = {
    columns: [
      {
        name: 'common.table.col_name',
        key: 'username',
      },

      {name: 'common.table.at_all_objs', key: 'at_all_objs'},

      {name: 'common.table.active_objs', key: 'active_objs'},
      {name: 'common.table.total', key: 'total'},
      {name: 'common.table.withdrawed', key: 'withdrawed'},
      {
        name: 'common.table.actions',
        key: 'actions',
        render: obj => {
          const {total, withdrawed, username} = obj;

          const actionsDisabled = +cutTokenPrefix(total) <= +cutTokenPrefix(withdrawed);

          return (
            <div className='unfreeze_btn_cell'>
              <Button
                disabled={actionsDisabled}
                btnClass='with-bg green-bg'
                title='issue_plan.refresh_btn'
                onClick={() => {
                  this.openPinModal(pin => {
                    this.refreshUser({pin, user: username});
                  });
                }}
              />
              <Button
                disabled={actionsDisabled}
                btnClass='with-bg green-bg'
                title='issue_plan.force_full_unfreeze'
                onClick={() => {
                  this.openPinModal(pin => {
                    this.unfreezeUser({pin, user: username});
                  });
                }}
              />
            </div>
          );
        },
      },
    ],
  };

  getMetadata = async token => {
    const {data, isError} = await getEosTable({
      code: 'block',
      scope: 'block',
      json: true,
      limit: 2,
      table: 'metadata',
      lower_bound: token.title,
      upper_bound: token.title,
    });
    const metadata = data?.rows?.[0];

    console.log('metadata', data);

    if (!isError && metadata) {
      const {plan_name, desc, url} = metadata;
      this.setState({plan_name, desc, url, initialMetadata: metadata});
    } else {
      this.setState({plan_name: '', desc: '', url: '', initialMetadata: null});
    }
  };

  getPlan = async token => {
    const {data, isError} = await getEosTable({
      code: 'block',
      scope: 'block',
      json: true,
      limit: 2,
      table: 'plan',
      lower_bound: token.title,
      upper_bound: token.title,
    });

    const plan = data?.rows?.[0];
    console.log(data, 'plan');
    if (!isError && plan) {
      const {active_phases, frozen_phases, phase_in_days, phase_in_secs} = plan;
      this.setState({active_phases, frozen_phases, phase_in_days: phase_in_secs / (3600 * 24), initialPlan: plan});
    } else {
      this.setState({active_phases: '', frozen_phases: '', phase_in_days: '', initialPlan: null});
    }
  };

  getPercents = async token => {
    const {data, isError} = await getEosTable({
      code: 'block',
      scope: token,
      json: true,
      limit: 1000,
      table: 'percents',
    });

    const percents = data?.rows;

    if (!isError && percents) {
      const phasesState = percents.reduce((acc, {phase, percent}) => {
        acc[`phase${phase}Percent`] = percent;
        return acc;
      }, {});

      this.setState({initialPercents: percents, ...phasesState});

      console.log(percents, 'percents');
    }
  };

  getDepositorInfo = async token => {
    const {data, isError} = await getEosTable({
      code: 'block',
      scope: token,
      json: true,
      limit: 1000,
      table: 'depositor',
    });

    const depInfo = data?.rows;

    if (!isError && depInfo) {
      this.setState({depInfo});

      console.log(depInfo, 'depInfo');
    }
  };

  onChangeSelectedToken = async value => {
    this.setState({selectedToken: value});

    this.getMetadata(value);
    this.getPlan(value);
    this.getPercents(value.title);
    this.getDepositorInfo(value.title);
  };

  componentDidMount = async () => {
    this.getCreationHistory();
  };

  getAllTokensForLimiter = async () => {
    const {
      userStore: {user},
    } = this.props;

    if (user.name === 'limiter') {
      const {data: scopes} = await getTableByScope({
        code: 'eosio.token',
        json: true,
        limit: 100000,
        table: 'stat',
      });

      const rawTokensPromices = scopes.rows.map(async ({scope}) => {
        const {data: token} = await getEosTable({
          code: 'eosio.token',
          scope,
          json: true,
          limit: 1,
          table: 'stat',
        });

        return {
          currency: token.rows[0].max_supply,
          issuer: token.rows[0].isuer,
        };
      });

      const tokens = await Promise.all(rawTokensPromices);
      const userTokens = tokens.filter(value => value);

      return userTokens;
    }

    return [];
  };

  getCreationHistory = async () => {
    const {
      userStore: {user},
    } = this.props;
    const {selectedToken} = this.state;

    const {data} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: 'curcreator',
      table: 'feelog',
    });

    const {data: limits} = await getEosTable({
      code: 'limiter',
      json: true,
      limit: 100,
      scope: 'limiter',
      table: 'tokenlimit',
    });

    const allTokens = await this.getAllTokensForLimiter();

    const historyData = user.name === 'limiter' ? allTokens : data?.rows?.filter(({issuer}) => user.name === issuer) || [];

    const tokens = historyData.map(({currency}) => {
      const title = getTokenPrefix(currency);
      const limitFull = limits.rows.find(({month_limit}) => {
        return title === getTokenPrefix(month_limit);
      })?.month_limit;
      const limit = cutTokenPrefix(limitFull) || '';

      return {
        title,
        rounding: detectRounding(currency),
        maxSupply: +cutTokenPrefix(currency),
        limit,
      };
    });

    if (selectedToken) {
      const newToken = tokens.find(({title}) => {
        return title === selectedToken.title;
      });

      this.onChangeSelectedToken(newToken || null);
    }

    this.setState({tokens});
  };

  openPinModal = callback => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: callback});
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value});
  };

  setMetadata = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken, plan_name, desc, url} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.setMetadata({
      pin,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      userName,
      plan_name,
      desc,
      url,
    });

    if (!isError) {
      this.getMetadata(selectedToken);
    }
  };

  initPlan = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken, phase_in_days, frozen_phases, active_phases} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.initPlan({
      pin,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      userName,
      phase_in_days,
      frozen_phases,
      active_phases,
    });

    if (!isError) {
      this.getPlan(selectedToken);
    }
  };

  setPhasePercent = async (pin, num) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.setPhasePercent({
      pin,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      userName,
      phase: num,
      percent: this.state[`phase${num}Percent`],
    });

    if (!isError) {
      this.getPercents(selectedToken.title);
    }
  };

  activatePlan = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.activatePlan({
      pin,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      userName,
    });

    if (!isError) {
      this.getPlan(selectedToken);
      this.getPercents(selectedToken.title);
    }
  };

  deactivatePlan = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.deactivatePlan({
      pin,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      userName,
    });

    if (!isError) {
      this.getPlan(selectedToken);
      this.getPercents(selectedToken.title);
    }
  };

  issueToken = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken, issueAmount, issueTo} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.issueToken({
      pin,
      quantity: `${issueAmount} ${selectedToken.title}`,
      userName,
      issueTo,
    });

    if (!isError) {
      this.getPlan(selectedToken);
      this.getDepositorInfo(selectedToken.title);

      this.setState({issueAmount: '', issueTo: ''});
    }
  };

  unfreezeUser = async ({pin, user}) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.unfreezeUser({
      pin,
      userName,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      user,
    });

    if (!isError) {
      this.getPlan(selectedToken);
      this.getDepositorInfo(selectedToken.title);
    }
  };

  fullUnfreeze = async ({pin}) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken, depInfo} = this.state;
    const userName = userStore.user.name;

    await Promise.all(
      depInfo.map(({username}) => {
        return eosStore.unfreezeUser({
          pin,
          userName,
          currency: `${selectedToken.rounding},${selectedToken.title}`,
          user: username,
        });
      })
    );

    this.getPlan(selectedToken);
    this.getDepositorInfo(selectedToken.title);
  };

  refreshUser = async ({pin, user}) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.refreshUserPlans({
      pin,
      userName,
      currency: `${selectedToken.rounding},${selectedToken.title}`,
      user,
    });

    if (!isError) {
      this.getPlan(selectedToken);
      this.getDepositorInfo(selectedToken.title);
    }
  };

  onIssueAmountInputBlur = () => {
    const {issueAmount, selectedToken} = this.state;

    const formattedQuantity = issueAmount ? changeFormat(issueAmount, selectedToken?.rounding || 4, inputFormatSettings) : '';

    this.setState({issueAmount: formattedQuantity});
  };

  render() {
    const {
      tokens,
      selectedToken,

      plan_name,
      desc,
      url,

      initialPlan,
      active_phases,
      frozen_phases,
      phase_in_days,

      issueTo,
      issueAmount,
      depInfo,

      initialPercents,
    } = this.state;

    const labels = initialPercents.map(({phase}) => `${phase} Phase`);

    const data = {
      labels,
      datasets: [
        {
          label: 'Percentage',
          data: initialPercents.map(({percent}) => +percent),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    return (
      <div className='issue-plan-page nested-route margin'>
        <div>
          <I18n tKey='issue_plan.title' className='title-page' />
          <div className='wallet-content' style={{width: '100%'}}>
            <I18n tKey='issue_plan.title' className='block-title' />
            <div className='notice-text-wrapper d-flex'>
              <div className='wrapper-flex-item wide centered-content'>
                <InfoIcon />
              </div>
              <div className='d-flex flex-column'>
                <I18n className='sub-text' tKey='issue_plan.notice' />
              </div>
            </div>

            <div className='input-with-btn'>
              <DropDown
                wrapperClass='select-rounding'
                onChange={this.onChangeSelectedToken}
                value={selectedToken}
                isOptionSelected={option => {
                  return option === selectedToken;
                }}
                options={tokens}
                formatOptionLabel={formatOptionLabel}
                placeholder='create_token.dropDown_token'
                menuPlacement='auto'
              />
            </div>
            {selectedToken && (
              <>
                <div className='columns-wrapper'>
                  {/* <I18n tKey='issue_plan.set_logo' className='block-title' />
                  <div className='flex-column' style={{maxWidth: '70%'}}>
                    <I18n tKey='issue_plan.set_logo' className='input-label' />
                    <Input
                      containerClass='password-field'
                      placeholder='issue_plan.set_logo'
                      onChange={this.onChange}
                      name='tokenName'
                      value=''
                    />
                    <Button
                      disabled={!selectedToken || !userName}
                      onClick={this.openAddWhitelistPinModal}
                      btnClass='with-bg green-bg'
                      title='issue_plan.set_logo_btn'
                    />
                  </div> */}

                  <div className='flex-row'>
                    <div className='flex-column' style={{maxWidth: '50%'}}>
                      <I18n tKey='issue_plan.set_metadata' className='block-title' />

                      <I18n tKey='issue_plan.plan_name' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.plan_name'
                        onChange={this.onChange}
                        name='plan_name'
                        value={plan_name}
                      />

                      <I18n tKey='issue_plan.desc' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.desc'
                        onChange={this.onChange}
                        name='desc'
                        value={desc}
                      />

                      <I18n tKey='issue_plan.url' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.url'
                        onChange={this.onChange}
                        name='url'
                        value={url}
                      />
                      <Button
                        // disabled={!selectedToken || !userName}
                        onClick={() => this.openPinModal(this.setMetadata)}
                        btnClass='with-bg green-bg'
                        title='issue_plan.set_metadata_btn'
                      />
                    </div>
                    {/* </div> */}

                    {/* <div className='flex-row'> */}
                    <div className='flex-column' style={{maxWidth: '50%'}}>
                      <I18n tKey='issue_plan.initplan' className='block-title' />

                      <I18n tKey='issue_plan.phase_in_days' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.phase_in_days'
                        onChange={this.onChange}
                        name='phase_in_days'
                        value={phase_in_days}
                      />

                      <I18n tKey='issue_plan.frozen_phases' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.frozen_phases'
                        onChange={this.onChange}
                        name='frozen_phases'
                        value={frozen_phases}
                      />

                      <I18n tKey='issue_plan.active_phases' className='input-label' />
                      <Input
                        containerClass='password-field'
                        placeholder='issue_plan.active_phases'
                        onChange={this.onChange}
                        name='active_phases'
                        value={active_phases}
                      />
                      <Button
                        disabled={!!initialPlan?.active}
                        onClick={() => this.openPinModal(this.initPlan)}
                        btnClass='with-bg green-bg'
                        title='issue_plan.initplan_btn'
                      />
                    </div>
                  </div>
                </div>
                {initialPlan?.active_phases && (
                  <>
                    <div className='flex-row' style={{justifyContent: 'center'}}>
                      {initialPlan?.active ? (
                        <>
                          <Button
                            disabled={+cutTokenPrefix(initialPlan.balance) !== 0}
                            onClick={() => this.openPinModal(this.deactivatePlan)}
                            btnClass='with-bg green-bg activate-btn'
                            title='issue_plan.deactivate_btn'
                          />
                          <Button
                            onClick={() => this.openPinModal(this.fullUnfreeze)}
                            btnClass='with-bg green-bg activate-btn'
                            title='issue_plan.full_unfreeze'
                          />
                        </>
                      ) : (
                        <Button
                          disabled={!!initialPlan?.active}
                          onClick={() => this.openPinModal(this.activatePlan)}
                          btnClass='with-bg green-bg activate-btn'
                          title='issue_plan.activate_btn'
                        />
                      )}
                    </div>

                    <div className='percentages-wrapper'>
                      {initialPlan?.active ? (
                        <Bar type='bar' data={data} options={options} />
                      ) : (
                        <>
                          <I18n tKey='issue_plan.setPercentage' className='block-title' />
                          <div className='notice-text-wrapper d-flex'>
                            <div className='wrapper-flex-item wide centered-content'>
                              <InfoIcon />
                            </div>
                            <div className='d-flex flex-column'>
                              <I18n className='sub-text' tKey='issue_plan.phase_percent_notice' />
                            </div>
                          </div>

                          {[...Array(initialPlan?.active_phases || 0).keys()].map(i => {
                            const num = i + 1;
                            return (
                              <div key={i} className='input-with-btn'>
                                <div className='phase-number'>
                                  <I18n tKey='issue_plan.phase' /> {num}:
                                </div>
                                <InputRow
                                  wrapperClass='input-with-bg'
                                  name={`phase${num}Percent`}
                                  value={this.state[`phase${num}Percent`] || ''}
                                  regEx={REG_EXP.float_digits}
                                  statusBorder
                                  inputChange={this.onChange}
                                  placeholder='issue_plan.percent_for_phase'
                                  label='%'
                                  readOnly={!selectedToken}
                                />
                                <Button
                                  disabled={!selectedToken || !!initialPlan?.active}
                                  onClick={() => this.openPinModal(pin => this.setPhasePercent(pin, num))}
                                  btnClass='with-bg green-bg'
                                  title='issue_plan.set_phase_percent'
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>

                    <div className='plan-info-block'>
                      <div className='flex-column'>
                        <I18n tKey='issue_plan.balance' className='field-name' />
                        {initialPlan.balance}
                      </div>
                      <div className='flex-column'>
                        <I18n tKey='issue_plan.withdrawed' className='field-name' />
                        {initialPlan.withdrawed}
                      </div>
                      <div className='flex-column'>
                        <I18n tKey='issue_plan.users' className='field-name' />
                        {initialPlan.users}
                      </div>
                    </div>

                    <I18n tKey='issue_plan.issue_token' className='block-title' />
                    <div className='input-with-btn'>
                      <InputRow
                        wrapperClass='input-with-bg issue-to'
                        name='issueTo'
                        value={issueTo}
                        statusBorder
                        inputChange={this.onChange}
                        placeholder='issue_plan.issueTo'
                        readOnly={!selectedToken}
                      />
                      <InputRow
                        wrapperClass='input-with-bg'
                        name='issueAmount'
                        value={issueAmount}
                        regEx={getRoundedRegExp(selectedToken?.rounding)}
                        statusBorder
                        inputChange={this.onChange}
                        onBlur={this.onIssueAmountInputBlur}
                        placeholder='issue_plan.issueAmount'
                        label={selectedToken.title}
                        readOnly={!selectedToken}
                      />
                      <Button
                        disabled={!selectedToken || !initialPlan?.active || !issueTo}
                        onClick={() => this.openPinModal(this.issueToken)}
                        btnClass='with-bg green-bg'
                        title='issue_plan.issue_token_btn'
                      />
                    </div>

                    <I18n tKey='token_limit.dep_info' className='block-title' />
                    <Table data={{...this.tableColumns, rows: depInfo}} tableClass='transactions-table' />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
