import React, {Component} from 'react';
import Modal from 'react-modal';

import {ReactComponent as CloseIcon} from '../../../img/close_icon.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {Pagination} from '../../helpers/pagination/Pagination';

const tableData = {
  columns: [
    {name: '#'},
    {name: 'modal_staked.table.col_start', key: 'start_stake_date'},
    {name: 'modal_staked.table.col_cru', key: 'staked_amount'},
    {name: 'modal_staked.table.col_untb', key: 'profit'},
    {name: 'modal_staked.table.col_duration', key: 'duration'},
    {name: 'modal_staked.table.col_update', key: 'last_update_date'},
  ],
  rows: [
    {
      '#': '1',
      start_stake_date: '2020-May-19, 03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
    {
      '#': '2',
      start_stake_date: '2020-May-19,03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
    {
      '#': '3',
      start_stake_date: '2020-May-19,03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
    {
      '#': '4',
      start_stake_date: '2020-May-19,03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
    {
      '#': '5',
      start_stake_date: '2020-May-19,03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
    {
      '#': '6',
      start_stake_date: '2020-May-19,03:30:34',
      staked_amount: '123123.0000',
      profit: '10.0000',
      duration: '2 month',
      last_update_date: '1 day ago',
    },
  ],
};

export class StakedTable extends Component {
  state = {
    modalOpen: false,
  };

  // closeTable = () => {
  //   // this.props.closeModal();
  //   this.setState({modalOpen: this.state.modalOpen});
  //   console.log('close')
  //
  // };

  render() {
    const {isOpen, closeModal} = this.props;
    return (
      <Modal className='modal-table' isOpen={isOpen}>
        <div className='header-modal'>
          <I18n className='title-table' tKey='modal_staked.title' />
          <CloseIcon onClick={closeModal} />
        </div>
        <Table data={tableData} />
        <Pagination currentPage={1} limit={15} totalCount={676} />
      </Modal>
    );
  }
}
