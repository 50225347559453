import React, {Component, Fragment} from 'react';
import classNames from 'classnames';

import {inject, observer} from 'mobx-react';
import {ReactComponent as ClaimIcon} from '../../../img/positive_alert.svg';
import {ReactComponent as ChipIcon} from '../../../img/cpu_chip.svg';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import Spinner2 from '../../../img/spinner2.gif';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {changeFormat} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';

const OPERATIONS = {
  CLAIM: 'CLAIM',
  UPDATE: 'UPDATE',
};

@inject('eosStore', 'userStore', 'modalStore')
@observer
export class UpdateCru extends Component {
  state = {
    opIsFetching: false,
  };

  accountUpdate = pin => {
    const {userStore, eosStore, cruObjs} = this.props;
    this.setState({opIsFetching: true});
    if (cruObjs) {
      const requests = Object.keys(cruObjs).map(key => eosStore.updateStakedCru(userStore.user.name, cruObjs[key].id, pin));
      Promise.all(requests).then(
        r => {
          userStore.getAccountInfo().then(
            r => {
              this.setState({opIsFetching: false});
            },
            error => {
              this.setState({opIsFetching: false});
            }
          );
        },
        error => {
          this.setState({opIsFetching: false});
        }
      );
    } else {
      userStore.getAccountInfo().then(
        r => {
          this.setState({opIsFetching: false});
        },
        error => {
          this.setState({opIsFetching: false});
        }
      );
    }
  };

  claimUntb = pin => {
    const {userStore, eosStore, unclaimedCount = 0} = this.props;
    this.setState({opIsFetching: true});
    const amount = `${unclaimedCount} ${TOKENS.UNTB}`;
    eosStore.claimUntb(userStore.user.name, amount, pin).then(r => {
      userStore.getAccountInfo().then(r => {
        this.setState({opIsFetching: false});
      });
    });
  };

  claimCRU = pin => {
    const {userStore, eosStore, cruObjs} = this.props;
    this.setState({opIsFetching: true});
    console.log(cruObjs);
    const requests = Object.keys(cruObjs).map(key => {
      return eosStore.claimCru(userStore.user.name, `${cruObjs[key].quantity} ${TOKENS.CRU}`, cruObjs[key].id, pin);
    });
    Promise.all(requests).then(r => {
      userStore.getAccountInfo().then(r => {
        this.setState({opIsFetching: false});
      });
    });
  };

  openPinModal = action => {
    let cb;
    if (action === OPERATIONS.UPDATE) {
      cb = this.accountUpdate;
    } else {
      cb = this.props.cruObjs ? this.claimCRU : this.claimUntb;
    }
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: cb});
  };

  makeForceUpdate = pin => {
    const {userStore, eosStore} = this.props;
    this.setState({claimBalanceUpdating: true, opIsFetching: true});
    this.accountUpdate(pin);
    eosStore.cruForceUpdate(userStore.user.name, pin).then(r => {
      userStore.getAccountInfo().then(r => {
        this.setState({claimBalanceUpdating: false, opIsFetching: false});
      });
    });
  };

  forceUpdate = () => {
    if (this.state.claimBalanceUpdating) {
      return;
    }
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.makeForceUpdate});
  };

  render() {
    const {unclaimedCount = 0, lastUpdate, cruObjs} = this.props;
    const isClaimed = Number(unclaimedCount) > 0;
    const needsUpdate = !isClaimed;
    return (
      <div className={classNames('staking-update', {'staking-claim': !needsUpdate})}>
        <div className='update-title'>
          {!needsUpdate && isClaimed && <ClaimIcon />}
          <div className='title-count'>
            <p className='staking-count'>
              {this.props.isFetching ? (
                <I18n tKey='common.loading' />
              ) : (
                <>
                  <span>{changeFormat(unclaimedCount, 4)}</span>
                  <span> {cruObjs ? TOKENS.CRU : TOKENS.UNTB}</span>
                </>
              )}
            </p>
            <p className='staking-title'>
              <I18n tKey='wallet_cru.staking_title' />
              <I18n className='subtitle' tKey='wallet_cru.staking_subtitle' />
              {/* <InfoIcon/> */} {/* <---temporary */}
            </p>
          </div>
        </div>
        <div className='update-info'>
          <div className='update-info-text'>
            <I18n tKey='wallet_cru.update_info' />
            <span> {lastUpdate} </span>
          </div>
          <RefreshIcon className={classNames({active: this.state.claimBalanceUpdating})} onClick={this.forceUpdate} />
        </div>
        {!needsUpdate ? (
          <Button
            disabled={this.state.opIsFetching || !isClaimed || this.props.isFetching}
            onClick={this.openPinModal.bind(this, OPERATIONS.CLAIM)}
            btnClass='claim-btn'
            title={`wallet_cru.claim_btn_${cruObjs ? TOKENS.CRU.toLowerCase() : TOKENS.UNTB.toLowerCase()}`}
          />
        ) : (
          <Button
            disabled={this.state.opIsFetching || this.props.isFetching}
            onClick={this.openPinModal.bind(this, OPERATIONS.UPDATE)}
            btnClass='update-btn rel'
            ArrowIcon={ChipIcon}
            title='wallet_cru.update_btn'
          >
            {this.state.opIsFetching ? <img alt='' src={Spinner2} className='spinner-preloader' /> : undefined}
          </Button>
        )}
      </div>
    );
  }
}
