import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';

import {EnterPin} from '../../modals/enterPin/EnterPin';
import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {getEosTable} from '../../../utils/requester';
import {cutTokenPrefix} from '../../../utils/utils';
import {REG_EXP} from '../../../config/const';
import './index.scss';

const getFieldByRate = rate => {
  if (rate === 'comissrate') return 'exchange_comission_pcnt';
  if (rate === 'bafee' || rate === 'curfee') return 'price';

  return 'usdu';
};

const rates = [
  {
    amount: 1,
    exchangeUnit: 'CRU',
    priceUnit: 'USDU',
    fieldName: 'crurate',
  },
  {
    amount: 1,
    exchangeUnit: 'WCRU',
    priceUnit: 'USDU',
    fieldName: 'wcrurate',
  },
  {
    amount: 1,
    exchangeUnit: 'UNTB',
    priceUnit: 'USDU',
    fieldName: 'untbrate',
  },
  {
    exchangeUnit: 'Commission',
    priceUnit: '%',
    fieldName: 'comissrate',
    isInt: true,
  },
];

const fees = [
  {
    feeTitle: 'wallet_convert.ba_creation_fee',
    priceUnit: 'USDU',
    fieldName: 'bafee',
  },
  {
    feeTitle: 'wallet_convert.cur_creation_fee',
    priceUnit: 'USDU',
    fieldName: 'curfee',
  },
];

@inject('eosStore', 'userStore', 'modalStore')
@observer
export class WalletConvertRates extends Component {
  state = {
    comissrate: '',
    comissrateCurrent: '',
    crurate: '',
    crurateCurrent: '',
    wcrurate: '',
    wcrurateCurrent: '',
    candfee: '',
    candfeeCurrent: '',
  };

  componentDidMount = () => {
    this.getRates();
    this.getFees();
    this.getCandfee();
  };

  getCandfee = async () => {
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1,
      scope: 'prodcand',
      table: 'candfee',
    });

    const price = data?.rows?.[0]?.price ? cutTokenPrefix(data?.rows?.[0]?.price).trim() : '';

    const newState = {candfee: price, candfeeCurrent: price};
    this.setState(newState);

    return newState;
  };

  getFees = async () => {
    const feePromises = fees.map(async ({fieldName}) => {
      const {data} = await getEosTable({
        code: 'curcreator',
        json: true,
        limit: 1,
        scope: 'curcreator',
        table: fieldName,
      });

      const price = data?.rows?.[0]?.price ? cutTokenPrefix(data?.rows?.[0]?.price).trim() : '';

      return {[fieldName]: price, [`${fieldName}Current`]: price};
    });

    const currentFees = await Promise.all(feePromises);
    const newState = currentFees.reduce((acc, fee) => {
      return {...acc, ...fee};
    }, {});

    this.setState(newState);
  };

  getRate = async table => {
    const {data} = await getEosTable({
      code: 'interchange',
      scope: 'interchange',
      json: true,
      key_type: 'uint64',
      limit: 1,
      table,
    });

    return data.rows?.[0] || {};
  };

  getRates = async () => {
    let wcrurat = (await this.getRate('wcrurate')).usdu;
    let crurat = (await this.getRate('crurate')).usdu;
    let untbrat = (await this.getRate('untbrate')).usdu || '';

    [, wcrurat = 0] = wcrurat.match(/(\d+(\.?\d+))(.+)?/) || [];
    [, crurat = 0] = crurat.match(/(\d+(\.?\d+))(.+)?/) || [];
    [, untbrat = ''] = untbrat.match(/(\d+(\.?\d+))(.+)?/) || [];

    const comissrat = `${(await this.getRate('comissrate')).exchange_comission_pcnt}` || 0;

    this.setState({
      wcrurate: wcrurat,
      crurate: crurat,
      untbrate: untbrat,
      comissrate: comissrat,
      wcrurateCurrent: wcrurat,
      crurateCurrent: crurat,
      untbrateCurrent: untbrat,
      comissrateCurrent: comissrat,
    });
  };

  onChange = (name, value) => {
    this.setState({[name]: value});
  };

  onBlur = e => {
    const {name, value} = e.target;
    this.setState({[name]: Number(value).toFixed(4)});
  };

  setRate = async (pin, rate, priceUnit, contract) => {
    const {eosStore, userStore} = this.props;
    const {state} = this;

    const resp = await eosStore.setCurrencyRate({
      pin,
      userName: userStore.user.name,
      actionName: `set${rate}`,
      field: getFieldByRate(rate),
      value: priceUnit !== '%' ? `${state[rate]} ${priceUnit}` : +state[rate],
      contract,
    });

    if (!resp.isError) {
      this.getRates();
      this.getFees();
    }
  };

  openConvertModal =
    (rate, contract = 'interchange') =>
    () => {
      const {modalStore} = this.props;
      const {state} = this;
      const {priceUnit, fieldName} = rate;

      const additionalClass = 'set-rate-modal';
      const newValue = state[fieldName];
      const oldValue = state[`${fieldName}Current`];

      modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
        <EnterPin
          isOpen={isOpen}
          closeModal={closeModal}
          onOk={onOk}
          onCancel={onCancel}
          params={{
            pinContentClass: additionalClass,
            onValidPin: pin => this.setRate(pin, fieldName, priceUnit, contract),
          }}
          btnTitle={`wallet_convert_rate.btn_set_${fieldName}`}
          btnTitleBack='common.cancel'
        >
          <div className='title'>
            <I18n tKey={`wallet_convert_rate.btn_set_${fieldName}`} />
          </div>
          <div className='info'>
            <I18n tKey='wallet_convert_rate.set_rate_modal.old_value' /> = {oldValue} {priceUnit}
            <br />
            <I18n tKey='wallet_convert_rate.set_rate_modal.new_value' /> = {newValue} {priceUnit}
            <br />
          </div>
        </EnterPin>
      ));
    };

  render() {
    const {state} = this;

    return (
      <div className='nested-route wallet-convert-rates margin'>
        <div className='wallet-ram-block'>
          <I18n tKey='wallet_convert_rate.title' className='title-page' />
          <div className='wallet-content'>
            <div>Rates</div>
            {rates.map(rate => {
              const {amount, exchangeUnit, priceUnit, fieldName, isInt} = rate;
              const value = state[fieldName];

              return (
                <div key={fieldName} className='currency-rate-row'>
                  <div className='rate-label'>
                    {amount} {exchangeUnit} =
                  </div>

                  <div className='rate-input-wrapper'>
                    <Input
                      containerClass='rate-input'
                      name={fieldName}
                      onChange={this.onChange}
                      onBlur={!isInt && this.onBlur}
                      regEx={isInt ? undefined : REG_EXP.four_digits}
                      value={value}
                      onlyNumbers
                      maxAmount={100}
                    />
                    <div className='currency-label'>{priceUnit}</div>
                  </div>

                  <Button
                    btnClass='confirm-button'
                    onClick={this.openConvertModal(rate)}
                    title={`wallet_convert_rate.btn_set_${fieldName}`}
                    disabled={!value || value === state[`${fieldName}Current`]}
                  />
                </div>
              );
            })}

            <div>Fee</div>
            {fees.map(fee => {
              const {feeTitle, fieldName, priceUnit} = fee;
              const value = state[fieldName];

              return (
                <div className='currency-rate-row'>
                  <div className='rate-label'>
                    <I18n tKey={feeTitle} /> =
                  </div>

                  <div className='rate-input-wrapper'>
                    <Input
                      containerClass='rate-input'
                      name={fieldName}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      regEx={REG_EXP.four_digits}
                      maxAmount={1000000}
                      value={value}
                      onlyNumbers
                    />
                    <div className='currency-label'>{priceUnit}</div>
                  </div>

                  <Button
                    btnClass='confirm-button'
                    onClick={this.openConvertModal(fee, 'curcreator')}
                    title={`wallet_convert_rate.btn_set_${fieldName}`}
                    disabled={!value || value === state[`${fieldName}Current`]}
                  />
                </div>
              );
            })}

            <div className='currency-rate-row'>
              <div className='rate-label'>
                <I18n tKey='wallet_convert.producer_condidate_fee' /> =
              </div>

              <div className='rate-input-wrapper'>
                <Input
                  containerClass='rate-input'
                  name='candfee'
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  regEx={REG_EXP.four_digits}
                  maxAmount={1000000}
                  value={state.candfee}
                  onlyNumbers
                />
                <div className='currency-label'>USDU</div>
              </div>

              <Button
                btnClass='confirm-button'
                onClick={this.openConvertModal({priceUnit: 'USDU', fieldName: 'candfee'}, 'prodcand')}
                title='wallet_convert_rate.btn_set_candfee'
                disabled={!state.candfee || state.candfee === state.candfeeCurrent}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
