import BigNumber from 'bignumber.js';
import moment from 'moment';
import HDKey from 'hdkey';
import wif from 'wif';
import ecc from 'eosjs-ecc';
import {TIME_FORMAT, TOKEN_ICONS, unfreezeDateConst} from '../config/const';
import userCard from '../img/new_default_coin.svg';

const bip39 = require('bip39');

const formatSettings = {
  decimalSeparator: '.',
  groupSeparator: '’',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

export const getTokenIcon = token => {
  return TOKEN_ICONS[token] || userCard;
};

export const checkBalance = balance => balance; //(Number(balance) < 0 ? 0 : balance);
export const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));
export const returnNextOffset = (limit, currentPage) => limit * currentPage;
export const percentFromNum = (x, y, round = 2) => new BigNumber(x).div(y).multipliedBy(100).toFixed(round);
export const cpuToSeconds = x => new BigNumber(x).div('1e+6');
export const byteToGB = x => new BigNumber(x).div(1024).div(1024).div(1024);
export const byteToMB = x => new BigNumber(x).div(1024).div(1024);
export const byteToKB = x => new BigNumber(x).div(1024);

export const changeFormat = (x = 0, round = 4, fmt = formatSettings) => {
  if (Number.isNaN(Number(x))) {
    console.warn('wrong format', x);
    return x;
  }

  return new BigNumber(x).toFormat(round, fmt);
};

export const changeCurrencyFormat = (x = 0, ...rest) => {
  if (typeof x === 'string') {
    const [, value, , others] = x.match(/(\d+(\.?\d+))(.+)?/);

    return changeFormat(value, ...rest) + others;
  }

  return changeFormat(x, ...rest);
};

export const changeCurrencyToNumber = (x = 0) => {
  if (typeof x === 'string') {
    const [, value] = x.match(/(\d+(\.?\d+))(.+)?/);

    return +value;
  }

  return +x;
};

export const currecyFormatter = (x = '', ...rest) => {
  const [, , , others] = x.match(/(\d+(\.?\d+))(.+)?/);

  return {formatCurrency: value => changeFormat(value, ...rest) + others, parseCurrency: value => changeCurrencyToNumber(value)};
};

export const rewriteProducer = name => (name === 'eosio' ? 'crunode1' : name);
export const calcPercent = (cur, max) => {
  if (Number(max) <= 0) {
    return 0;
  }
  return new BigNumber(cur).div(max).multipliedBy(100).toFixed(2);
};

export const addQueryParams = paramsObj => {
  let paramsString = '';
  Object.keys(paramsObj).forEach(key => {
    if (String(paramsObj[key])) {
      paramsString += `${key}=${paramsObj[key]}&`;
    }
  });
  if (paramsString) {
    paramsString = paramsString.replace(/&$/, '');
    return `?${paramsString}`;
  }
  return '';
};

export const generateName = length => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz1234';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getPrivateKeyFromSeed = seedPhrase => {
  return bip39.validateMnemonic(seedPhrase)
    ? (() => {
        const seed = bip39.mnemonicToSeedSync(seedPhrase).toString('hex');
        const master = HDKey.fromMasterSeed(Buffer(seed, 'hex'));
        const node = master.derive("m/44'/194'/0'/0/0");
        return wif.encode(128, node._privateKey, false);
      })()
    : null;
};

export const privateToPublic = privateKey => {
  return ecc.isValidPrivate(privateKey) ? ecc.privateToPublic(privateKey) : null;
};

export const convertBytes = bytes => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (bytes > 0) {
    return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
  }
  return '0 KB';
};
export const timeConversion = ms => {
  const seconds = (ms / 1000).toFixed(1);
  const minutes = (ms / (1000 * 60)).toFixed(1);
  const hours = (ms / (1000 * 60 * 60)).toFixed(1);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) {
    return `${seconds} sec`;
  }
  if (minutes < 60) {
    return `${minutes} min`;
  }
  if (hours < 24) {
    return `${hours} hrs`;
  }
  return `${days} days`;
};

export const convertMicroSec = microSec => {
  return `${new BigNumber(microSec).div(1000000).toFixed(2)} s`;
};

export const cutTokenPrefix = balance => {
  const regex = new RegExp('CRU|USDU|UNTB|FLO|RAM|WCRU');
  if (typeof balance === 'string') {
    if (regex.test(balance)) {
      return balance.replace(regex, '');
    }

    return balance.split(' ')[0];
  }
  if (!balance) {
    return 0;
  }

  return balance;
};

export const getTokenPrefix = balance => {
  if (typeof balance === 'string') {
    if (balance.includes('WCRU')) {
      return 'WCRU';
    }
    if (balance.includes('CRU')) {
      return 'CRU';
    }
    if (balance.includes('USDU')) {
      return 'USDU';
    }
    if (balance.includes('UNTB')) {
      return 'UNTB';
    }
    if (balance.includes('FLO')) {
      return 'FLO';
    }
    if (balance.includes('RAM')) {
      return 'RAM';
    }

    return balance.split(' ')[1];
  }
  return '';
};

export const encode = (str, key) => str.split('').map(value => value.charCodeAt(0) ^ key);
export const decode = (arr, key) =>
  arr
    .map(value => String.fromCharCode(value ^ key))
    .join('')
    .replace(/\"/g, '');

export const shuffle = array => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const changeFigureSign = (obj, user) => {
  let isIncome = false;
  const action_name = obj.action_name || obj.name;
  if (action_name === 'transfer') {
    isIncome = obj.token_to === user || !user;
  } else {
    isIncome = /^(issue|stake|delegatebw|buyram|getreward|approvech|frstake|add|pay)/.test(action_name);
  }
  return isIncome ? 1 : -1;
};

export const getFrozenPercent = i => {
  if (i <= 6) {
    return 0.01;
  }
  if (i > 6 && i <= 12) {
    return 0.015;
  }
  if (i > 12 && i <= 18) {
    return 0.02;
  }
  if (i > 18 && i <= 24) {
    return 0.03;
  }
  if (i > 24 && i <= 35) {
    return 0.05;
  }
  console.log('Wrong argument');
  return 0;
};

export const getConvertedMonths = (withdrawed, total, id, i = 0) => {
  console.log('wwwww', withdrawed);

  const fivePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(35)).toFixed(4));
  const fourPercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(24)).toFixed(4));
  const threePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(18)).toFixed(4));
  const twoPercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(12)).toFixed(4));
  const onePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(6)).toFixed(4));

  const hidden1 = i > 6 ? 6 : i;
  const hidden2 = i > 12 ? 6 : i - 6;
  const hidden3 = i > 18 ? 6 : i - 12;
  const hidden4 = i > 24 ? 6 : i - 18;
  const hidden5 = i - 24;

  let fivePercentMonths = hidden5 > 0 ? hidden5 : 0;
  while (withdrawed >= fivePercent && fivePercentMonths <= 10) {
    withdrawed -= fivePercent;
    fivePercentMonths += 1;
  }
  let fourPercentMonths = hidden4 > 0 ? hidden4 : 0;
  while (withdrawed >= fourPercent && fourPercentMonths < 6) {
    withdrawed -= fourPercent;
    fourPercentMonths += 1;
  }
  let threePercentMonths = hidden3 > 0 ? hidden3 : 0;
  while (withdrawed >= threePercent && threePercentMonths < 6) {
    withdrawed -= threePercent;
    threePercentMonths += 1;
  }

  let twoPercentMonths = hidden2 > 0 ? hidden2 : 0;
  while (withdrawed >= twoPercent && twoPercentMonths < 6) {
    withdrawed -= twoPercent;
    twoPercentMonths += 1;
  }

  let onePercentMonths = hidden1;
  while (withdrawed >= onePercent && onePercentMonths < 6) {
    withdrawed -= onePercent;
    onePercentMonths += 1;
  }

  if (withdrawed !== 0) {
    // alert(`withdraw not empty, sum: ${withdrawed}, expected: 0`);
    console.log(`id: ${id}, amounts:`, onePercent, twoPercent, threePercent, fourPercent, fivePercent);
    console.log(`id: ${id}, withdraw not empty, sum: ${withdrawed}, expected: 0`);
    console.log('mmm:', onePercentMonths);

    switch (true) {
      case onePercentMonths < 6:
        onePercentMonths += 1;
        break;
      case twoPercentMonths < 6:
        twoPercentMonths += 1;
        break;
      case threePercentMonths < 6:
        threePercentMonths += 1;
        break;
      case fourPercentMonths < 6:
        fourPercentMonths += 1;
        break;

      default:
    }
  }

  console.log('exl', {
    '0-6': onePercentMonths - (hidden1 > 0 ? hidden1 : 0),
    '7-12': twoPercentMonths - (hidden2 > 0 ? hidden2 : 0),
    '13-18': threePercentMonths - (hidden3 > 0 ? hidden3 : 0),
    '19-24': fourPercentMonths - (hidden4 > 0 ? hidden4 : 0),
    '25-35': fivePercentMonths - (hidden5 > 0 ? hidden5 : 0),
  });

  // [totalHidden, hiddenByContract]
  return {
    '0-6': onePercentMonths - (hidden1 > 0 ? hidden1 : 0),
    '7-12': twoPercentMonths - (hidden2 > 0 ? hidden2 : 0),
    '13-18': threePercentMonths - (hidden3 > 0 ? hidden3 : 0),
    '19-24': fourPercentMonths - (hidden4 > 0 ? hidden4 : 0),
    '25-35': fivePercentMonths - (hidden5 > 0 ? hidden5 : 0),
  };
};
export const getEstMonths = (withdrawed, total) => {
  const fivePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(35)).toFixed(4));
  const fourPercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(24)).toFixed(4));
  const threePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(18)).toFixed(4));
  const twoPercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(12)).toFixed(4));
  const onePercent = Number(new BigNumber(total).multipliedBy(getFrozenPercent(6)).toFixed(4));

  let i = 0;
  let totalMonths = 0;
  while (withdrawed >= fivePercent && totalMonths <= 10) {
    withdrawed -= fivePercent;
    totalMonths += 1;
    i += 1;
  }

  i = 0;
  while (withdrawed >= fourPercent && i < 6) {
    withdrawed -= fourPercent;
    totalMonths += 1;
    i += 1;
  }
  i = 0;
  while (withdrawed >= threePercent && i < 6) {
    withdrawed -= threePercent;
    totalMonths += 1;
    i += 1;
  }
  i = 0;
  while (withdrawed >= twoPercent && i < 6) {
    withdrawed -= twoPercent;
    totalMonths += 1;
    i += 1;
  }
  i = 0;
  while (withdrawed >= onePercent && i < 6) {
    withdrawed -= onePercent;
    totalMonths += 1;
    i += 1;
  }

  return totalMonths;
};

export const detectRounding = token => {
  const defaultRound = 4;
  let round = defaultRound;

  if (typeof token === 'string') {
    const tokens = token.split(/\.|\s/);
    const [, roundNumber] = tokens;

    round = roundNumber?.length;
  }

  return round || defaultRound;
};

export const setExplorerAmount = obj => {
  if (+cutTokenPrefix(obj.data.quantity)) {
    return obj.data.quantity;
  }
};

export const setAmount = obj => {
  const parsedData = obj.action_data || {};

  switch (obj.action_name) {
    case 'issue':
    case 'transfer':
    case 'frstake':
    case 'stake':
    case 'unstake':
    case 'retire':
      return {
        amount: new BigNumber(cutTokenPrefix(parsedData.quantity)).toNumber(),
        symbol: getTokenPrefix(parsedData.quantity),
        round: 4,
      };

    case 'delegatebw':
      return {
        amount: new BigNumber(cutTokenPrefix(parsedData.stake_cpu_quantity))
          .plus(cutTokenPrefix(parsedData.stake_net_quantity))
          .toNumber(),
        symbol: 'UNTB',
        round: 4,
      };
    case 'add':
      return {
        amount: parsedData.amount,
        symbol: obj.blockchain || '',
        round: 4,
      };
    case 'undelegatebw':
      return {
        amount: new BigNumber(cutTokenPrefix(parsedData.unstake_cpu_quantity))
          .plus(cutTokenPrefix(parsedData.unstake_net_quantity))
          .toNumber(),
        symbol: 'UNTB',
        round: 4,
      };
    case 'sellram':
      return {amount: parsedData.bytes, symbol: 'Bytes', round: 4};
    case 'buyram':
      return {amount: new BigNumber(cutTokenPrefix(parsedData.quant)).toNumber(), symbol: 'UNTB', round: 4};
    case 'getreward':
      return {amount: new BigNumber(cutTokenPrefix(parsedData.to_withdraw)).toNumber(), symbol: 'UNTB', round: 4};
    case 'approvech':
      return {
        amount: new BigNumber(cutTokenPrefix(parsedData.amount)).toNumber(),
        symbol: 'WCRU',
        round: 4,
      };
    case 'pay':
    case 'distribute':
      return {
        amount: new BigNumber(cutTokenPrefix(parsedData.quantity)).toNumber(),
        symbol: 'USDU',
        round: 4,
      };
    // case 'withdraw':
    case 'frunstake':
      return {amount: obj.amount, symbol: obj.symbol, round: 4};
    default:
      return null;
  }
};

export function CreateScheduleObj(obj) {
  const startUnfreeze = moment(obj.created).add(
    obj.algorithm === 1 ? unfreezeDateConst.firstAlgorithm : unfreezeDateConst.secondAlgorithm,
    'day'
  );
  const percents = {commonProgress: 0, unfrozePercent: 0};
  const amount = cutTokenPrefix(obj.amount);
  this.startDate = moment(obj.created).format(TIME_FORMAT.CONVERT);
  this.details = [];
  this.amount = amount;
  for (let i = 1; i <= 35; i++) {
    const dateInPast = moment().isAfter(moment(startUnfreeze).add(i * 30, 'd'));
    this.details.push(new CreateConvertTableRow(startUnfreeze, percents, amount, i, dateInPast));
  }
  if (startUnfreeze.valueOf() < moment().valueOf()) {
    const fullUnfreeze = startUnfreeze.add(unfreezeDateConst.fullUnfreeze, 'day');
    this.daysLeft = fullUnfreeze.diff(moment(), 'day');
    this.unfreezePercent = percents.unfrozePercent;
    this.countUnfreeze = Number(
      new BigNumber(amount).multipliedBy(new BigNumber(100).minus(percents.unfrozePercent).toNumber()).div(100).toFixed(0)
    );
  } else {
    this.daysLeft = new BigNumber(startUnfreeze.diff(moment(), 'day')).plus(unfreezeDateConst.fullUnfreeze).toNumber();
    this.unfreezePercent = 0;
    this.countUnfreeze = Number(new BigNumber(amount).toFixed(0));
  }
}

export function CreateConvertTableRow(startUnfreeze, percents, objAmount, i, dateInPast) {
  const currentDate = moment(startUnfreeze)
    .add(i * 30, 'd')
    .format(TIME_FORMAT.CONVERT_TABLE);
  percents.commonProgress = new BigNumber(percents.commonProgress).plus(getFrozenPercent(i)).toNumber();
  dateInPast && (percents.unfrozePercent = new BigNumber(percents.unfrozePercent).plus(getFrozenPercent(i) * 100).toNumber());
  const unfrozenCRU = new BigNumber(percents.commonProgress).multipliedBy(objAmount).toFixed(4);
  this.num = Number(i) < 10 ? `0${i}` : i;
  this.date = currentDate;
  this.CRUAmount = unfrozenCRU;
  this.rate = `${new BigNumber(getFrozenPercent(i)).multipliedBy(100).toFixed(2)}%`;
  this.progress = `${new BigNumber(percents.commonProgress).multipliedBy(100).toFixed(2)}%`;
  this.additionalClass = dateInPast;
}

export class RewardRows {
  constructor(stakePlan, amountStake, currDate, dateDiff, i) {
    this.date = currDate;
    this.day = dateDiff;
    this.reward = stakePlan.value === 6 && i < 4 ? 0 : stakePlan.percent;
    this.totalReward = new BigNumber(this.reward)
      .multipliedBy(+amountStake)
      .div(100)
      .multipliedBy(stakePlan.value === 6 ? i - 3 : i)
      .toFixed(4);
  }
}

export const compareTokenAmountAsc = (amountA, amountB) => {
  if (+cutTokenPrefix(amountA) < +cutTokenPrefix(amountB)) {
    return -1;
  }

  if (+cutTokenPrefix(amountA) > +cutTokenPrefix(amountB)) {
    return 1;
  }

  return 0;
};

export const compareTimeAsc = (amountA, amountB) => {
  if (moment(amountA).isBefore(moment(amountB))) {
    return -1;
  }

  if (moment(amountA).isAfter(moment(amountB))) {
    return 1;
  }

  return 0;
};

export const defaultCompareAsc = (a, b) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};
