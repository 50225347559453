import React, {Component} from 'react';

import {I18n} from '../i18n/I18n';
import {StatusLine} from '../statusLine/StatusLine';
import {StatisticRow} from '../../mainPages/switchToken/StatisticRow';
import {changeFormat, cpuToSeconds, byteToGB, percentFromNum, byteToKB} from '../../../utils/utils';
import {getChainInfo, getEosTable} from '../../../utils/requester';

export class Blockchain extends Component {
  state = {
    cpuChain: 0,
    netChain: 0,
    cpuPerBlock: 0,
    netPerBlock: 0,
    chainId: '',
    ramSize: 0,
    ramReserved: 0,
  };

  componentDidMount = () => {
    const interval = 5000;
    this.getBlockChainStat();

    this.interval = setInterval(this.getBlockChainStat, interval);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  getBlockChainStat = () => {
    const {cpuChain, netChain, cpuPerBlock, netPerBlock, chainId, ramSize, ramStake} = this.state;
    const requests = [getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'global'}), getChainInfo()];
    Promise.all(requests).then(r => {
      const newState = {
        isFetching: false,
        cpuChain: cpuChain || 0,
        netChain: netChain || 0,
        cpuPerBlock: cpuPerBlock || 0,
        netPerBlock: netPerBlock || 0,
        chainId: chainId || 0,
        ramSize: ramSize || 0,
        ramStake: ramStake || 0,
      };
      const error = r.some(elem => elem.isError);
      if (!error) {
        newState.cpuChain = cpuToSeconds(r[0].data.rows[0].max_block_cpu_usage).toFixed(2, 1);
        newState.cpuPerBlock = cpuToSeconds(r[1].data.block_cpu_limit).toFixed(2, 1);
        newState.netChain = changeFormat(r[0].data.rows[0].max_block_net_usage, 0);
        newState.netPerBlock = changeFormat(byteToKB(r[1].data.block_net_limit), 0);
        newState.ramSize = byteToGB(r[0].data.rows[0].max_ram_size).toFixed(2, 1);
        newState.ramReserved = byteToGB(r[0].data.rows[0].total_ram_bytes_reserved).toFixed(2, 1);
        newState.chainId = r[1].data.chain_id;
      }
      this.setState(newState);
    });
  };

  render() {
    const {cpuChain, netChain, cpuPerBlock, netPerBlock, chainId, ramSize, ramReserved} = this.state;
    return (
      <div className='blockchain-block'>
        <I18n className='main-title md' tKey='card_block.block_chain.title' />
        <div className='content-block'>
          <div className='left-col'>
            <I18n className='main-title xs' tKey='card_block.block_chain.cpu_limit' />
            {/* <StatisticRow title="Chain" count={cpuChain} unit="s"/> */}
            <StatisticRow title='home.per_block' count={cpuPerBlock} unit='s' />
          </div>
          <div className='right-col'>
            <I18n className='main-title xs' tKey='card_block.block_chain.net_limit' />
            {/* <StatisticRow title="Chain" count={netChain} unit="KB"/> */}
            <StatisticRow title='home.per_block' count={netPerBlock} unit='KB' />
          </div>
        </div>
        <I18n className='main-title xs' tKey='card_block.block_chain.ram_title' />
        <StatusLine
          countLine={ramReserved}
          totalCount={`${ramSize} GB`}
          percent={`${percentFromNum(ramReserved, ramSize)}%`}
          size={`${percentFromNum(ramReserved, ramSize)}%`}
        />
        <p className='id-block'>
          <I18n className='main-title xs' tKey='card_block.block_chain.chain_id' />
          <span className='id-count'>{chainId}</span>
        </p>
      </div>
    );
  }
}
