import React, {Component} from 'react';

import {ReactComponent as ChipIcon} from '../../../img/chip.svg';

import {I18n} from '../i18n/I18n';
import {NumberConverter} from '../numberConverter/NumberConverter';

export class TokenInfoBlock extends Component {
  render() {
    const {title, count, unit, titleRow, onClick, subtitle, infoNote = 'common.info_note', converterCount, unitCount, countIncrease} = this.props;
    return (
      <div className='token-info-block'>
        <div className='title-icon'>
          <I18n tKey={title} />
          <p className='info-icon'>
            <span className='tooltip-text'>
              <I18n tKey={infoNote} />
            </span>
          </p>
        </div>
        <div className='info-block'>
          <span>{count} </span>
          {unit && <span> {unit} </span>}
          {subtitle && <I18n tKey={subtitle} className='count-subtitle' />}
          {converterCount && <NumberConverter value={converterCount} unitCount={unitCount} />}
          {countIncrease && <p className='increase-status'>{/* &#43;<span>{countIncrease}</span> */}</p>}
        </div>
        {titleRow && (
          <p className='action-link' onClick={onClick}>
            <I18n tKey={titleRow} />
            <ChipIcon />
          </p>
        )}
      </div>
    );
  }
}
