import React, {Component} from 'react';

import {I18n} from '../../helpers/i18n/I18n';
import {ProgressBar} from '../../helpers/progressBar/ProgressBar';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {calcPercent} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';

export class DataRow extends Component {
  render() {
    const {data, token, keysToShow} = this.props;
    return (keysToShow || Object.keys(data)).map((key, i) => {
      if (key !== 'total' && data.total !== '—') {
        if (token !== TOKENS.UNTB || key !== 'frozen') {
          const titleSubKey = key;
          // === 'frozen' && token === TOKENS.UNTB ? 'unstaked' : key;
          return (
            <div className='data-row-token' key={i}>
              <I18n tKey={`wallet_page.dashboard.${titleSubKey}`} className='status-title' />
              <CountWithZero dividedView round={4} count={data[key]} countClass='row-content' />
              <div className='progress-row-content'>
                <ProgressBar lineClass={`${key}-line`} size={`${calcPercent(data[key], data.total)}%`} />
                <span className='count-progress-line'>{`${calcPercent(data[key], data.total)}%`}</span>
              </div>
            </div>
          );
        }
      }
    });
  }
}
