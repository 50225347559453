import React, {Component} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';

import {Table} from '../table/Table';
import {ActionColumn} from './ActionColumn';
import {AmountColumn} from './AmountColumn';
import {MemoColumn} from './MemoColumn';

import {ReactComponent as ArrowIcon} from '../../../img/arrow.svg';
import {ReactComponent as OkIcon} from '../../../img/ok-icon.svg';
import {TIME_FORMAT} from '../../../config/const';
import './index.scss';

export class TrxHistoryTable extends Component {
  tableData = {
    columns: [
      {
        name: 'wallet_send.tx_date',
        render: obj => (
          <div>
            <NavLink to={`/transactions/${obj.trx_id}`} className='tx-title'>
              <span>Tx: </span>
              <span className='tr-id'>{obj.trx_id}</span>
              <OkIcon />
            </NavLink>
            <p className='date'>{moment(obj.timestamp).format(TIME_FORMAT.BLOCK)}</p>
          </div>
        ),
      },
      {
        name: 'wallet_send.act_data',
        render: obj => <ActionColumn obj={obj} />,
      },
      {
        name: 'wallet_send.memo_label',
        render: obj => <MemoColumn obj={obj} />,
      },
      {
        name: 'wallet_send.label_amount',
        render: obj => <AmountColumn accountName={this.props.accountName} obj={obj} />,
      },
      {
        name: 'wallet_send.raw',
        render: (row, total, action, openedRow) => (
          <div role='presentation' onClick={action} className={`arrow-btn ${openedRow === row.trx_id ? 'active' : ''}`}>
            <ArrowIcon />
            <ArrowIcon />
          </div>
        ),
      },
    ],
  };

  render = () => {
    const {isError, isFetching, onRetry, rows, tableWrapperClass} = this.props;

    return (
      <Table
        data={{...this.tableData, rows}}
        isFetching={isFetching}
        onRetry={onRetry}
        showRetryButton={isError}
        tableWrapperClass={classNames('history-table', tableWrapperClass)}
        usePreloader
        useSerializedTab
      />
    );
  };
}
