import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {Serialize} from 'eosjs';

import moment from 'moment';
import {NavLink} from 'react-router-dom';
import {ReactComponent as OkIcon} from '../../../img/ok-icon.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {Button} from '../../helpers/button/Button';
import {DragLine} from '../../helpers/dragLine/DragLine';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {Pagination} from '../../helpers/pagination/Pagination';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {TokenInfoBlock} from '../../helpers/tokenInfoBlock/TokenInfoBlock';

import {calcPercent, changeFormat, cutTokenPrefix} from '../../../utils/utils';
import {REG_EXP, TIME_FORMAT, TOKENS} from '../../../config/const';
import {UpdateCru} from './UpdateCru';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {TableControls} from '../../helpers/tableСontrols/TableControls';
import {getUserActions} from '../../../utils/requester';
import {BalanceRow} from '../../helpers/balanceRow/BalanceRow';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const tableData = {
  columns: [
    {
      name: 'wallet_cru.col_date',
      render: obj => (
        <div>
          <NavLink to={`/transactions/${obj.trx_id}`} className='tx-title'>
            <span>Tx: </span>
            <span className='tr-id'>{obj.trx_id}</span>
            <OkIcon />
          </NavLink>
          <p className='start-date'>{moment(obj.timestamp).format(TIME_FORMAT.BLOCK)}</p>
        </div>
      ),
    },
    {
      name: 'wallet_cru.col_ammount',
      render: obj => {
        const isStakeOp = obj.action_name === 'stake';
        return (
          <>
            <p className={obj.action_name}>
              {isStakeOp ? '+' : '-'} {obj.amount && obj.amount}
            </p>
          </>
        );
      },
    },
    {
      name: 'wallet_cru.col_duration',
      render: obj => (
        <>
          <p>{moment(obj.timestamp).fromNow()}</p>
        </>
      ),
    },
    {
      name: 'wallet_cru.col_profit',
      render: obj => (
        <>
          <span>{obj.action_name === 'getreward' ? obj.action_data.to_withdraw : '-'}</span>
        </>
      ),
    },
  ],
};

const tabs = [
  {title: 'tab_stake', value: 'stake'},
  {title: 'tab_unstake', value: 'unstake'},
];

@inject('userStore', 'tokenStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class WalletCru extends Component {
  state = {
    amountUnstake: '',
    amountStake: '',
    opIsActive: false,
    activeTab: tabs[0].value,
    isFetching: false,
    rows: [],
    lastIndex: 0,
    limit: 10,
    page: 0,
    firstFetch: true,
    symbolFrozen: false,
  };

  dragLineRef = React.createRef();

  componentDidMount() {
    this.getRecentTrx();
  }

  getRecentTrx = () => {
    const {page, limit, firstFetch} = this.state;

    if (firstFetch) {
      this.setState({isFetching: true, firstFetch: false});
      // The first api call might be significantly slower
    }

    getUserActions({
      offset: page * limit,
      limit,
      account: this.props.userStore.user.name,
      symbol: this.props.symbol || '',
      type: 'Staking',
    }).then(async r => {
      const newState = {isFetching: false};
      const {loggerStore, eosStore} = this.props;

      if (!r.isError) {
        newState.rows = await Promise.all(
          r.data.map(async action => {
            const {action_name, act_data, contract: contractName} = action;

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              action_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            console.log(action_name, dataa);

            return {...action, action_data: dataa};
          })
        );
        console.log(newState.rows);
        newState.lastIndex = r.data.length;
      } else {
        loggerStore.addItem({type: LOGGER_TYPES[2], msgTxt: 'common.try_again_later'});
      }

      this.setState(newState);
    });
  };

  setPage = num => {
    this.setState({page: num}, this.getRecentTrx);
  };

  onChange = (propName, value) => {
    const {userWallet} = this.props.userStore;
    const newState = {[propName]: value};
    this.setState(newState);
    const availableAmount = this.props.symbol === TOKENS.WCRU ? this.getAvailableWCRU() : userWallet.CRU.available;
    propName === 'amountStake' && this.dragLineRef.current.setDragValue(value, availableAmount);
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  makeStakingOp = (isStakingOp, pin) => {
    const {amountUnstake, amountStake} = this.state;
    this.setState({opIsActive: true});
    if (this.props.symbol === TOKENS.WCRU) {
      this.newWCRUOp(isStakingOp, isStakingOp ? amountStake : amountUnstake, pin);
    } else {
      this.CRUOPeration(isStakingOp, amountStake, amountUnstake, pin);
    }
  };

  processOkResp = objLink => {
    this.props.tokenStore.getEmission(); // todo process error and combine with getAccountInfo
    this.props.userStore.getAccountInfo(); // todo process error
    objLink.amountStake = '';
    objLink.amountUnstake = '';
  };

  newWCRUOp = (isStakingOp, amountValue, pin) => {
    const {symbolFrozen} = this.state;

    const maxAmount = isStakingOp ? this.getAvailableWCRU() : this.getStakedWCRU();
    const amount = new BigNumber(amountValue).isGreaterThan(maxAmount) ? maxAmount : amountValue;

    const quantity = `${amount} ${TOKENS.WCRU}`;

    const stakeActionName = !symbolFrozen ? 'stake' : 'frstake';
    const unstakeActionName = !symbolFrozen ? 'unstake' : 'frunstake';
    const contractName = !symbolFrozen ? 'eosio' : 'tokenlock';

    this.props.eosStore
      .makeStakingOp({
        quantity,
        sender: this.props.userStore.user.name,
        isStake: isStakingOp,
        pin,
        stakeActionName,
        unstakeActionName,
        contract: contractName,
      })
      .then(r => {
        const newState = {opIsActive: false};
        if (!r.isError) {
          this.processOkResp(newState);
        }
        this.setState(newState);
      });
  };

  WCRUOp = (isStakingOp, amountValue, pin) => {
    const {userWallet, userStacking} = this.props.userStore;
    let quantity;
    let stakeActionName;
    let unstakeActionName;
    let contractName;
    let needsAdditionalOp;
    let additionalOpQuantity;
    if (isStakingOp) {
      const amountStake = new BigNumber(amountValue).isGreaterThan(userWallet.WCRU.available)
        ? userWallet.WCRU.available
        : amountValue;
      const frozenAmount = new BigNumber(amountValue).minus(amountStake).toFixed(4);
      additionalOpQuantity = frozenAmount;
      needsAdditionalOp = amountValue > userWallet.WCRU.available && +userWallet.WCRU.available !== 0;
      quantity = `${+amountStake > 0 ? amountStake : frozenAmount} ${TOKENS[this.props.symbol]}`;
      stakeActionName = +amountStake > 0 ? 'stake' : 'frstake';
      unstakeActionName = +amountStake > 0 ? 'unstake' : 'frunstake';
      contractName = +amountStake > 0 ? 'eosio' : 'tokenlock';
    } else {
      const stakedFrozen = cutTokenPrefix(userStacking.staked_frozen_wcru_balance);
      const stakedLiquid = new BigNumber(userWallet.WCRU.staked).minus(stakedFrozen).toFixed(4);
      // 8 > 0 ? 0 : 8
      const unstakeAmount = new BigNumber(amountValue).isGreaterThan(stakedLiquid) ? stakedLiquid : amountValue;
      const unstakeFrozen = new BigNumber(amountValue).minus(unstakeAmount).toFixed(4);
      additionalOpQuantity = unstakeFrozen;
      needsAdditionalOp = +amountValue > +stakedLiquid && +stakedLiquid !== 0;
      quantity = `${+unstakeAmount > 0 ? unstakeAmount : unstakeFrozen} ${TOKENS[this.props.symbol]}`;
      stakeActionName = +unstakeAmount > 0 ? 'stake' : 'frstake';
      unstakeActionName = +unstakeAmount > 0 ? 'unstake' : 'frunstake';
      contractName = +unstakeAmount > 0 ? 'eosio' : 'tokenlock';
    }
    this.props.eosStore
      .makeStakingOp({
        quantity,
        sender: this.props.userStore.user.name,
        isStake: isStakingOp,
        pin,
        stakeActionName,
        unstakeActionName,
        contract: contractName,
      })
      .then(r => {
        const newState = {opIsActive: false};
        if (!r.isError) {
          if (needsAdditionalOp) {
            this.props.eosStore
              .makeStakingOp({
                quantity: `${additionalOpQuantity} ${TOKENS[this.props.symbol]}`,
                sender: this.props.userStore.user.name,
                isStake: isStakingOp,
                pin,
                stakeActionName: isStakingOp ? 'frstake' : 'stake',
                unstakeActionName: isStakingOp ? 'frunstake' : 'unstake',
                contract: isStakingOp ? 'tokenlock' : 'eosio',
                errorMsg: isStakingOp ? 'errors.stake.wcu' : 'errors.unstake.wcru',
              })
              .then(r => {
                if (!r.isError) {
                  this.processOkResp(newState);
                }
                this.setState(newState);
              });
          } else {
            this.processOkResp(newState);
          }
        }
        this.setState(newState);
      });
  };

  CRUOPeration = (isStakingOp, amountStake, amountUnstake, pin) => {
    this.props.eosStore
      .makeStakingOp({
        quantity: `${isStakingOp ? amountStake : amountUnstake} ${TOKENS[this.props.symbol]}`,
        sender: this.props.userStore.user.name,
        isStake: isStakingOp,
        pin,
        stakeActionName: 'stake',
        unstakeActionName: 'unstake',
        contract: 'eosio',
      })
      .then(r => {
        const newState = {opIsActive: false};
        if (!r.isError) {
          this.processOkResp(newState);
        }
        this.setState(newState);
      });
  };

  openPinModal = isStakingOp => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.makeStakingOp.bind(this, isStakingOp)});
  };

  setAmountStake = val => {
    const {userWallet} = this.props.userStore;
    const availableAmount = this.props.symbol === TOKENS.WCRU ? this.getAvailableWCRU() : userWallet.CRU.available;
    let amountStake = new BigNumber(val).multipliedBy(availableAmount).div(100).toFixed(4);
    if (+availableAmount === 0) {
      amountStake = '0';
    }
    if (+val === 0) {
      amountStake = '';
    }
    this.setState({
      amountStake,
    });
  };

  getAvailableWCRU = () => {
    const {userWallet} = this.props.userStore;

    return this.state.symbolFrozen
      ? new BigNumber(userWallet.WCRU.frozen).toNumber()
      : new BigNumber(userWallet.WCRU.available).toNumber();
  };

  getStakedWCRU = () => {
    const {userWallet} = this.props.userStore;

    return this.state.symbolFrozen
      ? new BigNumber(userWallet.WCRU.frozenStaked).toNumber()
      : new BigNumber(userWallet.WCRU.staked).toNumber();
  };

  onInputBlur = checkStakeInput => {
    const {amountUnstake, amountStake} = this.state;
    const checkingValue = checkStakeInput ? amountStake : amountUnstake;
    let defaultAmount = '';
    const splitedAmount = checkingValue.split('.');
    if (!checkingValue) {
      return;
    }
    if (!splitedAmount[0]) {
      defaultAmount = '1.0000';
    }
    if (!splitedAmount[1]) {
      defaultAmount = `${splitedAmount[0]}.0000`;
    } else if (splitedAmount[1].length < 4) {
      const emptyFigures = 4 - splitedAmount[1].length;
      defaultAmount = checkingValue;
      Array.from(new Array(emptyFigures)).forEach(i => (defaultAmount += '0'));
    }
    defaultAmount && this.setState(checkStakeInput ? {amountStake: defaultAmount} : {amountUnstake: defaultAmount});
  };

  toggleSymbolType = () => {
    this.setState(prevState => ({
      symbolFrozen: !prevState.symbolFrozen,
      amountUnstake: '',
      amountStake: '',
    }));

    const availableAmount = this.getAvailableWCRU();
    this.dragLineRef.current.setDragValue(0, availableAmount);
  };

  render() {
    const {symbol} = this.props;
    const {amountUnstake, amountStake, opIsActive, activeTab, symbolFrozen, rows, isFetching} = this.state;
    const {userWallet, userStacking} = this.props.userStore;
    const {emission} = this.props.tokenStore;

    // values
    const emissionPercent =
      +userWallet[symbol].staked === 0
        ? 0
        : new BigNumber(userWallet[symbol].staked)
            .div(cutTokenPrefix(emission.total_stakers_balance))
            .multipliedBy(100)
            .toFixed(2);
    const income =
      +userWallet[symbol].staked === 0
        ? 0
        : new BigNumber(userWallet[symbol].staked)
            .div(cutTokenPrefix(emission.total_stakers_balance))
            .multipliedBy(cutTokenPrefix(emission.current_emission_rate))
            .multipliedBy(2)
            .multipliedBy(60)
            .multipliedBy(60)
            .toFormat(4);

    const availableAmount = userWallet[symbol].available;
    const stakedAmount = symbol === TOKENS.WCRU ? this.getStakedWCRU() : cutTokenPrefix(userStacking.staked_cru_balance);

    const availableForStake = symbol === TOKENS.WCRU ? this.getAvailableWCRU() : availableAmount;
    const selectedStakePercent = calcPercent(amountStake || 0, availableForStake);
    // end values

    const lastUpdateWas = moment.utc(userStacking.last_update_at).local().fromNow();
    const daysBefore = moment().startOf('day').diff(moment(userStacking.last_update_at).startOf('day'), 'days');
    const daysStep = Number(
      typeof GLOBAL_ENV !== 'undefined' ? GLOBAL_ENV.REACT_APP_REFRESH_DAYS : process.env.REACT_APP_REFRESH_DAYS
    );
    const needsUpdate = daysBefore >= daysStep;
    const symbLCase = symbol.toLowerCase();
    const zeroToStake = +amountStake === 0;
    const zeroToUnstake = +amountUnstake === 0;
    return (
      <div className='nested-route wallet-cru'>
        <div className='wallet-cru-block'>
          <I18n tKey={`wallet_${symbLCase}.title`} className='title-page' />
          <UpdateCru
            daysStep={daysStep}
            daysBefore={daysBefore}
            needsUpdate={needsUpdate}
            lastUpdate={lastUpdateWas}
            unclaimedCount={cutTokenPrefix(userStacking.emitted_balance)}
          />
          <div className='wallet-content relative'>
            {symbol === TOKENS.WCRU && (
              <div className='choose-type'>
                <div className='choose-tip'>
                  <I18n tKey='convert_wcru.wcru_convertion_tip_header' />
                  <I18n tKey='convert_wcru.wcru_convertion_tip' />
                </div>
                <div className='choose-btns'>
                  <Button
                    btnClass={classNames('with-bg', {blue: symbolFrozen})}
                    title='common.frozen_wcru'
                    onClick={this.toggleSymbolType}
                  />
                  <Button
                    btnClass={classNames('with-bg', {blue: !symbolFrozen})}
                    title='common.available_wcru'
                    onClick={this.toggleSymbolType}
                  />
                </div>
              </div>
            )}
            {needsUpdate && (
              <div className='updating-required'>
                <I18n tKey='common.update_balance' />
              </div>
            )}
            <div className='top-block'>
              <div className='amount-block'>
                <div className='cru-amount'>
                  <I18n className='amount-title' tKey={`wallet_${symbLCase}.amount`} />
                  <CountWithZero count={stakedAmount} />
                </div>
                <div className='cru-amount'>
                  <I18n className='amount-title' tKey={`wallet_${symbLCase}.available`} />
                  <CountWithZero count={availableForStake} />
                </div>
              </div>
              <DragLine
                ref={this.dragLineRef}
                value={selectedStakePercent > 100 ? 100 : selectedStakePercent}
                setAmountStake={this.setAmountStake}
              />
              <div className='cru-info-blocks'>
                <div className='content-block'>
                  <TokenInfoBlock title='wallet_cru.title_emission' count={emissionPercent} unit='%' countIncrease='0.162' />
                  <TokenInfoBlock title='wallet_cru.title_income' count={income} unit={TOKENS.UNTB} countIncrease='0.162' />
                </div>
              </div>
            </div>
            <div className='toggle-tabs'>
              {tabs.map((elem, i) => (
                <TableControls
                  key={i}
                  onClick={this.setActiveTab.bind(this, elem.value)}
                  active={elem.value === activeTab}
                  title={`wallet_${symbLCase}.${elem.title}`}
                  tabClass='tab-item'
                />
              ))}
            </div>
            <div className='bottom-block'>
              <div className={classNames('stake-block', {'hide-mobile-form': activeTab === tabs[1].value})}>
                <I18n tKey={`wallet_${symbLCase}.stake_input`} className='title-page' />
                <InputRow
                  wrapperClass='input-with-bg'
                  name='amountStake'
                  value={amountStake}
                  onlyNumbers
                  placeholder={`wallet_${symbLCase}.stake_placeholder`}
                  inputChange={this.onChange}
                  regEx={REG_EXP.four_digits}
                  onBlur={this.onInputBlur.bind(this, true)}
                  statusBorder
                  label={amountStake && `wallet_${symbLCase}.stake_placeholder`}
                  countBalance={availableAmount}
                  token={`wallet_${symbLCase}.token_title`}
                  titleRow='wallet_send.balance'
                  maxAmount={availableForStake}
                />
                {symbol === TOKENS.WCRU && (
                  <div className='frozen-balance'>
                    <BalanceRow
                      countBalance={userWallet[symbol].frozen}
                      token={`wallet_${symbLCase}.token_title`}
                      titleRow='common.frozen_balance'
                    />
                  </div>
                )}
                <Button
                  onClick={this.openPinModal.bind(this, true)}
                  title={`wallet_${symbLCase}.btn_stake`}
                  disabled={opIsActive || !amountStake || zeroToStake}
                  btnClass='with-bg blue'
                />
              </div>
              <div className={classNames('stake-block unstake', {'hide-mobile-form': activeTab === tabs[0].value})}>
                <div className='d-flex space-between title-container'>
                  {' '}
                  <I18n tKey={`wallet_${symbLCase}.unstake_input`} className='title-page' />
                  {/* <I18n tKey={`wallet_cru.delay`} className='delay-span' /> */}
                </div>

                <InputRow
                  wrapperClass='input-with-bg'
                  name='amountUnstake'
                  value={amountUnstake}
                  onlyNumbers
                  placeholder={`wallet_${symbLCase}.unstake_placeholder`}
                  inputChange={this.onChange}
                  regEx={REG_EXP.four_digits}
                  onBlur={this.onInputBlur.bind(this, false)}
                  statusBorder
                  label={amountUnstake && `wallet_${symbLCase}.unstake_placeholder`}
                  countBalance={stakedAmount}
                  token={`wallet_${symbLCase}.token_title`}
                  titleRow='wallet_send.balance'
                  maxAmount={stakedAmount}
                />
                <Button
                  onClick={this.openPinModal.bind(this, false)}
                  title={`wallet_${symbLCase}.btn_unstake`}
                  disabled={opIsActive || !amountUnstake || zeroToUnstake}
                  btnClass='with-bg blue'
                />
              </div>
            </div>
          </div>
          <div className='staking-history'>
            <div className='header-block'>
              <I18n tKey={`wallet_${symbLCase}.history`} className='title-block' />{' '}
              <RefreshIcon onClick={this.getRecentTrx} className='update-ic' />
            </div>
            <Table data={{...tableData, rows}} tableWrapperClass='staking-history-table' usePreloader isFetching={isFetching} />
            {/* <Pagination changePage={this.setPage} currentPage={page + 1} limit={limit} totalCount={lastIndex} /> */}
            {rows.length === 0 && (
              <p className='no-transactions'>
                <I18n tKey='common.no_trx' />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
