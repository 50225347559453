import React, {Component} from 'react';
import {inject} from 'mobx-react';

import {StakedTable} from '../../modals/stakedTable/StakedTable';
import {I18n} from '../../helpers/i18n/I18n';
import {changeFormat} from '../../../utils/utils';
import {Preloader} from '../../helpers/preloader/Preloader';

@inject('modalStore')
export class TableBalance extends Component {
  openModal = () => {
    this.props.modalStore.open(StakedTable, null, null, null);
  };

  checkHandlers = key => {
    // TODO refactor
    // if (key === 'staked') {
    //   return this.openModal();
    // }
    return undefined;
  };

  render() {
    const {data = {}, isFetching, isError, accName = ''} = this.props;
    const {columns = [], rows = []} = data;

    return (
      <div className='table-balance'>
        {isFetching && <Preloader />}
        <table className='table-content'>
          <thead className='header-table'>
            <tr>
              {columns.map((column, i) => (
                <th key={i}>
                  <I18n tKey={column.name} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='body-table'>
            {rows.map((row, i) => (
              <tr className='body-row' key={i}>
                {columns.map((column, index) => (
                  <td key={index} onClick={this.checkHandlers.bind(this, column.key)}>
                    {!column.type ? (
                      changeFormat(row[column.key || column.name], row.rounding || 4)
                    ) : (
                      <div>
                        <span className='card-icon'>
                          <img src={row.icon} alt='' />
                        </span>
                        <p>
                          <span>{row.shortName} </span>{' '}
                          {row.shortName === 'CRU' && accName.toLowerCase() === 'interchange' ? (
                            <I18n tKey='interchange.balance.cru' />
                          ) : (
                            <I18n tKey={row.title} />
                          )}
                        </p>
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isError && (
          <p>
            <I18n tKey='app_error.table_error' />
          </p>
        )}
      </div>
    );
  }
}
