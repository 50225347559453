import React, {Component} from 'react';
import {ReactComponent as BuyIcon} from '../../../img/buy_ram.svg';
import {ReactComponent as CreateIcon} from '../../../img/create_icon.svg';
import {ReactComponent as SellIcon} from '../../../img/sell_ram.svg';
import {ReactComponent as SendIcon} from '../../../img/send_icon.svg';
import {ReactComponent as StakeIc} from '../../../img/stake_icon.svg';
import {ReactComponent as UnstakeIc} from '../../../img/unstake_icon.svg';
import {ReactComponent as ClaimIc} from '../../../img/rec_trx.svg';
import {ReactComponent as DistrIc} from '../../../img/distribute.svg';
import {ReactComponent as VoteIc} from '../../../img/vote_icon.svg';
import {ReactComponent as DellIc} from '../../../img/delete_icon.svg';
import {ReactComponent as UpdIc} from '../../../img/refresh_icon.svg';
import {ReactComponent as BurnIc} from '../../../img/burn_icon.svg';
import {ReactComponent as Approvech} from '../../../img/success.svg';

export const OperationIcon = ({obj, currentUser, isExplorer = false}) => {
  const actionName = isExplorer ? obj.name : obj.action_name;
  const tokenTo = obj.token_to;
  if (actionName === 'transfer') {
    return tokenTo === currentUser ? <ClaimIc className='sent-icon' /> : <SendIcon className='sent-icon' />;
  } else if (actionName === 'stake' || actionName === 'delegatebw' || actionName === 'frstake') {
    return <StakeIc className='sent-icon' />;
  } else if (actionName === 'unstake' || actionName === 'undelegatebw' || actionName === 'frunstake') {
    return <UnstakeIc className='sent-icon' />;
  } else if (actionName === 'buyram') {
    return <BuyIcon className='sent-icon' />;
  } else if (actionName === 'sellram') {
    return <SellIcon className='sent-icon' />;
  } else if (actionName === 'getreward') {
    return <ClaimIc className='sent-icon' />;
  } else if (actionName === 'issue') {
    return <CreateIcon className='sent-icon' />;
  } else if (actionName === 'distribute') {
    return <DistrIc className='sent-icon' />;
  } else if (actionName === 'cancel') {
    return <DellIc className='sent-icon' />;
  } else if (actionName === 'refresh' || actionName === 'updateauth') {
    return <UpdIc className='sent-icon' />;
  } else if (actionName === 'retire') {
    return <BurnIc className='sent-icon' />;
  } else if (actionName === 'approvech') {
    return <Approvech className='sent-icon' />;
  } else {
    return <VoteIc className='sent-icon' />;
  }
};
