import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {TIME_FORMAT} from '../../../config/const';
import {ProgressScale} from './ProgressScale';
import moment from 'moment';

export class Emission extends Component {
  render() {
    const scale = Array.from(
      new Array(Math.round((20 * moment(this.props.emission.next_emission_step_start_at).diff(moment(new Date()), 'days')) / 30)),
      (x, i) => i + 1
    );
    return (
      <div className='emission-block'>
        <I18n className='main-title md' tKey='home.tokens.untb_emission.title' />
        <div className='emission-block-content'>
          <div className='current-block'>
            <I18n className='main-title' tKey='home.tokens.untb_emission.current' />
            <p className='count-emission-block'>
              <span>{this.props.emission?.current_emission_rate?.split(' ')[0]} </span>
              <I18n tKey='home.tokens.untb_block.title' />
            </p>
            <div className='info-content'>
              <I18n className='title' tKey='home.tokens.untb_emission.row_time' />
              <span className='info-txt'>
                {moment(this.props.emission.next_emission_step_start_at)
                  .subtract(30, 'day')
                  .endOf('day')
                  .format(TIME_FORMAT.BLOCK)}
              </span>
            </div>
          </div>
          <div className='coming-block'>
            <I18n className='main-title' tKey='home.tokens.untb_emission.upcoming' />
            <p className='count-emission-block'>
              <span>{this.props.emission.next_emission_rate?.split(' ')[0]} </span>
              <I18n tKey='home.tokens.untb_block.title' />
            </p>
            <div className='info-content'>
              <I18n className='title' tKey='home.tokens.untb_emission.row_time' />
              <span className='info-txt'>
                {' '}
                {moment(this.props.emission.next_emission_step_start_at).endOf('day').format(TIME_FORMAT.BLOCK)}
              </span>
            </div>
          </div>
        </div>
        <div className='progress-row'>
          <ProgressScale scale={scale} />
          <p>
            <span>{moment(this.props.emission.next_emission_step_start_at).diff(moment(new Date()), 'days')} </span>
            <I18n className='main-title' tKey='home.tokens.untb_emission.progress_day' />
          </p>
        </div>
      </div>
    );
  }
}
