import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

export class Carousel extends Component {
  render() {
    const {settings = {}, wrapperClass, renderSlides} = this.props;
    return (
      <div className={`${wrapperClass || ''} custom-slider`}>
        <Swiper {...settings}>{renderSlides && renderSlides()}</Swiper>
      </div>
    );
  }
}
