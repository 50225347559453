import React, {Component, Fragment} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {percentFromNum} from '../../../utils/utils';
import {translate} from '../../../utils/translater';

export class LineGraph extends Component {
  render() {
    const {totalAmount, holders} = this.props;
    if (holders === null) {
      return null;
    }
    const classes = ['first-line', 'second-line', 'third-line', 'fourth-line', 'fifth-line'];
    return (
      <>
        <div className='header-bottom-content'>
          <div className='title-block'>
            <I18n className='main-title md' tKey='home.tokens.cru_block.top_list' />
            <div className='subtitle'>
              <span>
                <I18n tKey='home.top' /> 500{' '}
              </span>
              <span>{this.props.activeToken} </span>
              <span>
                <I18n tKey='home.holders' />
              </span>
            </div>
          </div>
          {/* {(activeToken === TOKENS.CRU || activeToken === TOKENS.UNTB) && */}
          {/* <div className="table-controls"> */}
          {/* {controlBtn.map((elem, i) => <TableControls active={i === 0} key={i} title={elem.title}/>)} */}
          {/* </div>} */}
        </div>
        <div className='status-line-block'>
          <div className='line'>
            {Object.keys(holders || {}).map((key, i) => (
              <div key={i} style={{width: `${percentFromNum(holders[key], totalAmount)}%`}} className={classes[i]} />
            ))}
          </div>
          <div className='data-line-block'>
            {Object.keys(holders || {}).map((key, i) => {
              const percent = percentFromNum(holders[key], totalAmount);
              return (
                <div className='line-info' key={i}>
                  <i className='info-dot' />
                  <p className='line-count'>
                    <span>{key}</span>
                    <span>{`${isNaN(Number(percent)) ? 0 : percent} %`}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
