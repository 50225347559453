import React from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export const AccountDetail = ({title, value, account, permissionName}) => {
  const txt = value;
  let keys = null;

  if (permissionName && account.permissions) {
    const permission = account.permissions.find(({perm_name}) => perm_name === permissionName);
    const accounts = permission?.required_auth?.accounts;
    keys = permission?.required_auth?.keys || [];

    accounts.forEach(({permission: {actor, permission: actPermission}}) => {
      keys.push({key: `${actor}@${actPermission}`});
    });
  }

  return (
    <div className='info-content'>
      <I18n className='title' tKey={title} />

      <div className='info-column'>
        {keys?.length ? (
          keys.map(({key}) => <I18n className='info-txt' tKey={key} />)
        ) : (
          <I18n className='info-txt' tKey={permissionName ? 'account.details.no_key' : txt} />
        )}
      </div>
    </div>
  );
};
