import React, {Component} from 'react';
import classNames from 'classnames';

import {I18n} from '../i18n/I18n';

export class CountTxtRow extends Component {
  render() {
    const {title, count, percent, wrapperClass, dot, dotTitle, noWrapTitle} = this.props;
    return (
      <div className={classNames('row-container', wrapperClass)}>
        <p className='count-block'>
          {this.props.children || <span className='count-row'>{count}</span>}
          {percent && <span className='percent-row'> ({percent})</span>}
          {dot && <span className='dot' />}
        </p>
        <p className={classNames('title-block', {'no-wrap': noWrapTitle})}>
          {dotTitle && <span className='dot-title' />}
          <I18n className='title-row' tKey={title} />
        </p>
      </div>
    );
  }
}
