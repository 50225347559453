import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {privateToPublic} from 'eosjs-ecc';
import classNames from 'classnames';
import HDKey from 'hdkey';
import * as bip39 from 'bip39';
import wif from 'wif';

import ecc from 'eosjs-ecc/lib/api_common';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {Input} from '../../helpers/input/Input';
import {DropDown} from '../../helpers/dropDown/DropDown';

import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {accountInfo} from '../../../utils/requester';
import {translate} from '../../../utils/translater';

import {ConfirmModal} from '../../modals/confirmModal/ConfirmModal';

import {ReactComponent as KeyIcon} from '../../../img/key-circle.svg';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import {ReactComponent as ArrowDown} from '../../../img/arrow_down.svg';
import './index.scss';

const uniKey =
  (typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_UNI_KEY : process.env.REACT_APP_UNI_KEY) || 'none';

const ownerPermission = {
  permission: 'owner',
  parent: '',
};
const activePermission = {
  permission: 'active',
  parent: 'owner',
};

const dropdownOptions = [
  // {
  //   title: 'change_key.permission.active_and_owner',
  //   permissionToChange: [activePermission, ownerPermission],
  // },
  {
    title: 'change_key.permission.active',
    permissionToChange: [activePermission],
  },
  {
    title: 'change_key.permission.owner',
    permissionToChange: [ownerPermission],
  },
];

const formatOptionLabel = data => {
  return (
    <div className='select-row'>
      <I18n className='plan-name' tKey={data.title} />{' '}
    </div>
  );
};

const getPrivKey = seedOrKey => {
  const isSeed = bip39.validateMnemonic(seedOrKey);
  if (isSeed) {
    const seed = bip39.mnemonicToSeedSync(seedOrKey).toString('hex');
    const master = HDKey.fromMasterSeed(Buffer(seed, 'hex'));
    const node = master.derive("m/44'/194'/0'/0/0");

    return wif.encode(128, node._privateKey, false);
  }

  return seedOrKey;
};

@inject('modalStore', 'eosStore', 'loggerStore')
@observer
export class ChangeKey extends Component {
  state = {
    oldKey: '',
    newKey: '',
    userName: '',
    selectedOption: '',
    oldPublicKey: '',
    step: 0,
    isPrivKeyValid: true,
    isPublicKeyValid: true,
    pass: '',
  };

  onChange = (propName, value) => {
    const newState = {[propName]: value};

    if (propName === 'newKey' && !value) {
      newState.isPublicKeyValid = true;
    }

    if (propName === 'oldKey') {
      if (!value) {
        newState.isPrivKeyValid = true;
      }

      try {
        const oldPrivKey = getPrivKey(value);
        const oldPublicKey = privateToPublic(oldPrivKey);
        newState.oldPublicKey = oldPublicKey;
      } catch (e) {
        newState.oldPublicKey = '';
      }
    }

    this.setState(newState);
  };

  getAccInfo = async () => {
    const {loggerStore} = this.props;
    const {userName} = this.state;

    const {
      data: {permissions},
      isError,
    } = await accountInfo(userName);

    if (isError) {
      loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'errors.acc_not_found'});
      return false;
    }

    this.setState({permissions});
    return true;
  };

  changeKey = async () => {
    const {loggerStore} = this.props;
    const {selectedOption, permissions} = this.state;
    let {oldKey} = this.state;

    oldKey = getPrivKey(oldKey);

    try {
      const oldPublicKey = privateToPublic(oldKey);

      selectedOption.permissionToChange.forEach(async permission => {
        const {isError} = await this.updatePermission({permission, permissions, oldPublicKey});

        if (isError) {
          const {isError: secondError, errMsg} = await this.updatePermission({permission, permissions, oldPublicKey});

          if (secondError) {
            loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: errMsg || 'errors.change_key'});

            // return;
          }
        }

        // loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.change_key_success'});
      });
    } catch (e) {
      loggerStore.addItem({
        type: LOGGER_TYPES[1],
        msgTxt: e.message.includes('checksum') ? 'errors.invalid_private_key' : 'errors.change_key',
      });
    }
  };

  updatePermission = async ({permission, permissions, oldPublicKey}) => {
    const {eosStore} = this.props;
    const {userName, newKey, oldKey} = this.state;

    const oldPermission = permissions.find(({perm_name, parent}) => {
      return perm_name === permission.permission && parent === permission.parent;
    });

    if (oldPermission) {
      const {
        required_auth: {accounts, keys},
      } = oldPermission;

      const newKeys = keys.map(key => {
        if (key.key === oldPublicKey) {
          return {...key, key: newKey};
        }

        return key;
      });

      const authorization_object = {
        threshold: 1,
        accounts,
        keys: newKeys,
        waits: [],
      };

      const response = await eosStore.updateAccountAuth(
        {account_name: userName},
        {
          account: userName,
          auth: authorization_object,
          ...permission,
        },
        oldKey
      );

      return response;
    }

    return {isError: true, errMsg: 'errors.change_key.premission_not_found'};
  };

  openConfirmModal = () => {
    const {modalStore} = this.props;

    modalStore.open(({isOpen, closeModal, onCancel}) => (
      <ConfirmModal
        isOpen={isOpen}
        closeModal={closeModal}
        onConfirm={async () => {
          await this.changeKey();
          closeModal();
        }}
        onCancel={onCancel}
        btnTitle='change_key.btn_confirm_change_key'
        btnTitleBack='common.cancel'
        message='change_key.confirm_change_key_msg'
      />
    ));
  };

  onChangeDropDown = value => {
    this.setState({selectedOption: value});
  };

  render() {
    const {oldKey, newKey, selectedOption, step, userName, isPrivKeyValid, isPublicKeyValid, oldPublicKey, pass} = this.state;
    const {loggerStore} = this.props;

    return (
      <div className='change-keys-page'>
        <div className={classNames('change-keys-content', {'first-step': step === 1, 'last-step': step === 3})}>
          <KeyIcon className='auth-icon' />
          <p className='auth-title'>
            {step === 3 ? <I18n tKey='wallet_auth.change-key-confirm' /> : <I18n tKey='wallet_auth.change-key' />}
          </p>

          {step === 0 && (
            <>
              <div className='notice-text-wrapper d-flex'>
                <I18n className='sub-text' tKey='change_key.password' />
              </div>

              <Input
                containerClass='password-field'
                placeholder='change_key.password-placeholder'
                statusBorder
                onChange={this.onChange}
                name='pass'
                value={pass}
              />

              <Button
                btnClass='with-bg blue'
                title='change_key.next-btn'
                disabled={pass !== uniKey}
                onClick={async () => {
                  this.setState({step: 1});
                }}
              />
            </>
          )}

          {step === 1 && (
            <>
              <div className='notice-text-wrapper d-flex'>
                <div className='wrapper-flex-item wide centered-content'>
                  <InfoIcon />
                </div>
                <div className='d-flex flex-column'>
                  <I18n className='sub-text' tKey='change_key.attension' />
                </div>
              </div>

              <Input
                containerClass='password-field'
                placeholder='change_key.user-name-placeholder'
                statusBorder
                onChange={this.onChange}
                name='userName'
                value={userName}
              />
              <DropDown
                wrapperClass='select-permission'
                onChange={this.onChangeDropDown}
                value={selectedOption}
                isOptionSelected={value => value === selectedOption}
                options={dropdownOptions}
                formatOptionLabel={formatOptionLabel}
                placeholder='change_key.dropDown_placeholder'
              />
              <Button
                btnClass='with-bg blue'
                title='change_key.next-btn'
                disabled={!selectedOption || !userName}
                onClick={async () => {
                  const isAccValid = await this.getAccInfo();
                  isAccValid && this.setState({step: 2});
                }}
              />
            </>
          )}

          {step === 2 && (
            <>
              <div className='flex-row step'>
                <div className='flex-column'>
                  <div style={{paddingBottom: '20px'}} className='flex-row'>
                    <div className='first-column'>
                      <I18n tKey='change_key.user-name-label' />
                    </div>

                    <div className='flex-row'>
                      <Input
                        containerClass='password-field'
                        placeholder='change_key.user-name-placeholder'
                        name='userName'
                        readOnly
                        value={userName}
                      />
                      <div style={{width: '100%', position: 'relative'}}>
                        <div className='relative-notice'>
                          <div className='notice-text-wrapper second-step d-flex'>
                            <div className='wrapper-flex-item wide centered-content'>
                              <InfoIcon />
                            </div>
                            <div className='d-flex flex-column'>
                              <I18n className='sub-text' tKey='change_key.attension' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex-row'>
                    <div className='first-column'>
                      <I18n tKey='change_key.permission-label' />
                    </div>
                    <div className='flex-row'>
                      <Input containerClass='password-field' readOnly value={translate(selectedOption.title)} />

                      <div style={{width: '100%'}} />
                    </div>{' '}
                  </div>
                </div>
              </div>

              <div className='flex-row step'>
                <div className='first-column'>
                  <I18n tKey='change_key.old-key-title' />:
                </div>

                <div className='flex-row'>
                  <div className='flex-column'>
                    <div className='key-input-label'>
                      {oldPublicKey ? (
                        <I18n tKey='change_key.old-public-key-label' />
                      ) : (
                        <I18n tKey='change_key.old-public-key-info-label' />
                      )}
                    </div>
                    <Input
                      containerClass='password-field'
                      placeholder='change_key.old-public-key-placeholder'
                      name='oldPublicKey'
                      value={oldPublicKey}
                      readOnly
                    />
                  </div>
                  <div className='flex-column'>
                    <div className='key-input-label'>
                      <I18n tKey='change_key.old-private-key-label' />
                    </div>
                    <Input
                      containerClass='password-field'
                      placeholder='change_key.old-private-key-placeholder'
                      statusBorder
                      error={!isPrivKeyValid}
                      onBlur={e => {
                        const isValid = ecc.isValidPrivate(e.target.value);
                        this.setState({isPrivKeyValid: isValid});

                        !isValid && loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'errors.invalid-private-or-seed'});
                      }}
                      onChange={this.onChange}
                      name='oldKey'
                      value={oldKey}
                    />
                  </div>
                </div>
              </div>

              <div className='flex-row step'>
                <div className='first-column'>
                  <I18n tKey='change_key.new-key-title' />:
                </div>

                <div className='flex-column'>
                  <ArrowDown className='arrow-down-icon' />
                  <div className='flex-row'>
                    <div className='flex-column'>
                      <div className='key-input-label'>
                        <I18n tKey='change_key.new-public-key-label' />
                      </div>
                      <Input
                        containerClass='password-field'
                        placeholder='change_key.new-public-key-placeholder'
                        statusBorder
                        onChange={this.onChange}
                        error={!isPublicKeyValid}
                        onBlur={e => {
                          const isValid = ecc.isValidPublic(e.target.value);
                          this.setState({isPublicKeyValid: isValid});

                          !isValid && loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'errors.invalid-public-key'});
                        }}
                        name='newKey'
                        value={newKey}
                      />
                    </div>
                    <div className='flex-column'>
                      <div className='private-key-notice'>
                        <I18n tKey='change_key.new-private-key-notice' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row'>
                <Button
                  btnClass='with-bg'
                  title='change_key.back'
                  onClick={() => {
                    this.setState({step: 1});
                  }}
                />
                <Button
                  btnClass='with-bg blue'
                  title='change_key.next-btn'
                  disabled={!newKey || !oldKey || !isPublicKeyValid || !isPrivKeyValid}
                  onClick={() => {
                    this.setState({step: 3});
                  }}
                />
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <div className='flex-row step'>
                <div className='first-column'>
                  <I18n tKey='change_key.user-name-label' />
                </div>
                <div>{userName}</div>
              </div>
              <div className='flex-row step'>
                <div className='first-column'>
                  <I18n tKey='change_key.permission-label' />
                </div>
                <div>
                  <I18n tKey={selectedOption.title} />
                </div>
              </div>
              <div className='flex-row step'>
                <div className='first-column'>
                  <I18n tKey='change_key.new-public-key' />
                </div>
                <div className='row-value'>{newKey}</div>
              </div>

              <div className='notice-text-wrapper last-step-notice d-flex'>
                <div className='wrapper-flex-item wide centered-content'>
                  <InfoIcon />
                </div>
                <div className='d-flex flex-column'>
                  <I18n tKey='change_key.new-private-key-notice' />
                </div>
              </div>

              <div className='flex-row'>
                <Button
                  btnClass='with-bg'
                  title='change_key.back'
                  onClick={() => {
                    this.setState({step: 2});
                  }}
                />
                <Button
                  btnClass='with-bg blue'
                  title='change_key.confirm-btn'
                  disabled={!selectedOption || !userName}
                  onClick={this.openConfirmModal}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
