import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {ReactComponent as LeftArrow} from '../../../img/arrow.svg';
import {ReactComponent as PauseIcon} from '../../../img/pause.svg';
import {ReactComponent as PlayIcon} from '../../../img/play_icon.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {BlocksChart} from '../../helpers/blockChart/BlocksChart';
import {BlockHeader} from '../../helpers/blockHeader/BlockHeader';
import {VerticalGraph} from '../../helpers/verticalGraph/VerticalGraph';

import {TIME_FORMAT} from '../../../config/const';
import {rewriteProducer} from '../../../utils/utils';

@inject('blockListStore')
@observer
export class BlockList extends Component {
  componentDidMount() {
    // this.props.blockListStore.getBlocksSetInterval(20, true);
    this.props.blockListStore.getBlocksSetInterval(20);
  }

  componentWillUnmount() {
    this.props.blockListStore.isPaused && this.props.blockListStore.togglePause();
    this.props.blockListStore.blockIndex = 0;
    this.props.blockListStore.removeTimer();
  }

  changeBlockIndex = increase => {
    const lastElem = 24;
    if (!increase || this.props.blockListStore.blockIndex < lastElem) {
      this.props.blockListStore.changeBlockIndex(increase);
    }
  };

  render() {
    const {blockListStore} = this.props;
    const {isPaused, blockIndex, isFetching} = blockListStore;
    const listLength = blockListStore.blocks.length;
    const blocks = this.props.blockListStore.blocks.slice().reverse().slice(0, 25);
    const lastBlock = listLength ? blocks[blockIndex] : {};
    return (
      <div className='right-block'>
        {isFetching ? (
          ''
        ) : (
          <div>
            <BlockHeader title='home.block_list.title' linkTitle='home.block_list.link_view' linkUrl='/blocks' />
            <BlocksChart data={[...blocks].reverse()} width='100%' height={110} />
            <div className='block-number'>
              <div className='title-block'>
                <I18n tKey={blockIndex > 0 ? 'home.block_list.title_block' : 'home.block_list.title_head_block'} className='head-block-title' />
                <span className='number-block'>#{lastBlock.block_num}</span>
              </div>
              <div className='btn-list'>
                <Button onClick={this.props.blockListStore.togglePause} ArrowIcon={!isPaused ? PauseIcon : PlayIcon} disabled={isFetching} btnClass={!isPaused ? 'pause-btn' : 'play-btn'} />
                <Button
                  onClick={this.changeBlockIndex.bind(this, true)}
                  title='home.block_list.pagin_btn.btn_prev'
                  ArrowIcon={LeftArrow}
                  disabled={blockIndex + 1 >= this.props.blockListStore.blocks.length}
                  btnClass='prev-btn'
                />
                <Button onClick={this.changeBlockIndex.bind(this, false)} title='home.block_list.pagin_btn.btn_next' ArrowIcon={LeftArrow} disabled={blockIndex < 1} btnClass='next-btn' />
              </div>
            </div>
            <div className='direction-wrapper'>
              <div className='block-info-wrapper'>
                <div className='block-info'>
                  <p className='status-block'>
                    <I18n tKey='home.block_list.irreversible' />
                  </p>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_time' />
                    <span className='data'> {moment(lastBlock.timestamp).format(TIME_FORMAT.BLOCK)}</span>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_id' />
                    <span className='data'> {lastBlock.id}</span>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_transact' />
                    <Link className='data data-trx' to={`/blocks/${lastBlock.block_num}`}>
                      {lastBlock.transaction_count}
                    </Link>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_act' />
                    <span className='data'>{lastBlock.actions}</span>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_producer' />
                    <span className='data'>{rewriteProducer(lastBlock.producer)}</span>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_cpu' />
                    <span className='data'>{lastBlock.cpuUsage} μs</span>
                  </div>
                  <div className='info-content'>
                    <I18n className='title' tKey='home.block_list.info.row_net' />
                    <span className='data'> {lastBlock.netUsage} Bytes</span>
                  </div>
                </div>
              </div>
              <VerticalGraph blocks={blocks} activeIndex={this.props.blockListStore.blockIndex} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
