import {inject} from 'mobx-react';
import React, {Component} from 'react';
import {CountWithZero} from '../countWithZero/CountWithZero';
import {changeFigureSign, setAmount} from '../../../utils/utils';

@inject('userStore')
export class AmountColumn extends Component {
  render() {
    const {obj, accountName} = this.props;
    const {action_data} = obj;
    const data = setAmount(obj);
    let amount = '-';
    if (obj.action_name === 'setcrurate' || obj.action_name === 'setwcrurate') {
      amount = action_data.usdu;
    }
    if (obj.action_name === 'setcomissrate') {
      amount = `${action_data.exchange_comission_pcnt} %`;
    }

    return (
      <div>
        {data ? (
          <CountWithZero
            round={data.round}
            dividedView
            plusBeforeSumm={obj.action_name === 'transfer' && obj.token_to === accountName}
            count={data.amount * changeFigureSign(obj, accountName)}
            countClass='count-row'
          >
            {` ${data.symbol}`}
          </CountWithZero>
        ) : (
          <span> {amount} </span>
        )}
      </div>
    );
  }
}
