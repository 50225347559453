import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';

export class CustomTooltip extends Component {
  render() {
    const {active} = this.props;
    if (active) {
      const {payload, label} = this.props;
      return (
        <div className='custom-tooltip'>
          <p className='row-tooltip'>
            <I18n tKey='block_chart.tooltip_label' className='label' />
            <span className='data-label'>{`${label}`}</span>
          </p>
          <p className='row-tooltip'>
            <I18n tKey='block_chart.tooltip_cpu' className='label' />
            <span className='data-label'>{`${payload && payload[1] !== undefined ? payload[1].value : ''}`} μs</span>
          </p>
          <p className='row-tooltip'>
            <I18n tKey='block_chart.tooltip_act' className='label' />
            <span className='data-label'>{`${payload && payload[0] !== undefined ? payload[0].value : ''}`}</span>
          </p>
        </div>
      );
    }
    return null;
  }
}
