import React, {Component} from 'react';
import classNames from 'classnames';

import {I18n} from '../i18n/I18n';

export class CheckBox extends Component {
  onChange = () => {
    this.props.onChange && this.props.onChange({label: this.props.label, value: this.props.value});
  };

  render() {
    const {isChecked, name, value, label, checkBoxClass} = this.props;
    return (
      <div className={classNames('custom-checkbox', checkBoxClass)}>
        <label>
          <I18n tKey={label} />
          <input type='checkbox' onChange={this.onChange} checked={isChecked} name={name} value={value} />
          <div className={`fake-checkbox ${isChecked ? 'box-checked' : ''}`}></div>
        </label>
      </div>
    );
  }
}
