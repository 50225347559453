import {observable} from 'mobx';
import moment from 'moment';

import {getDictionary, getLanguagesList} from '../utils/requester';

const DICTIONARY_LOCAL_KEY = 'CRYPTO_EXPLORER_DICTIONARY';
export const LNG_LOCAL_KEY = 'CRYPTO_EXPLORER_SELECTED_LNG';
const LANGUAGE_LIST = 'CRYPTO_EXPLORER_LANGUAGE_LIST';

const store_saved_lng = JSON.parse(localStorage.getItem(LNG_LOCAL_KEY)) || {iso: 'en'};

export class LocalizeStore {
  @observable languagesList = JSON.parse(localStorage.getItem(LANGUAGE_LIST)) || [];

  @observable dictionaryList = JSON.parse(localStorage.getItem(DICTIONARY_LOCAL_KEY)) || {};

  @observable selectedLng = store_saved_lng;

  constructor(cb) {
    Promise.all([getLanguagesList(), getDictionary(store_saved_lng.iso)]).then(r => {
      if (!r[0].isError) {
        const parsedLang = r[0].data.map(o => ({id: o.lang_id, iso: o.lang_iso, name: o.lang_name}));
        this.languagesList = parsedLang;
        localStorage.setItem(LANGUAGE_LIST, JSON.stringify(parsedLang));
      }
      if (!r[1].isError) {
        const keys = {};
        for (let i = 0; i < r[1].data.length; i++) {
          const obj = r[1].data[i];
          const key = obj.key_name.web;

          keys[key] = obj.translations[obj.translations.length - 1].translation;
        }
        this.dictionaryList = keys;
        localStorage.setItem(DICTIONARY_LOCAL_KEY, JSON.stringify(keys));
      }

      cb(true); // todo if you dont wont to start app on error resp of dictionary
    });
  }

  changeLng = async ({iso}) => {
    this.selectedLng = {iso};
    localStorage.setItem(LNG_LOCAL_KEY, JSON.stringify({iso}));
    moment.locale(iso);
    getDictionary(iso).then(r => {
      if (!r.isError) {
        const keys = {};
        for (let i = 0; i < r.data.length; i++) {
          const obj = r.data[i];
          const key = obj.key_name.web;

          keys[key] = obj.translations[obj.translations.length - 1].translation;
        }
        this.dictionaryList = keys;
        localStorage.setItem(DICTIONARY_LOCAL_KEY, JSON.stringify(keys));
      }

      document.location.reload();
    });
  };
}
