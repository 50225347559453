import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Table} from '../../helpers/table/Table';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';
import {Pagination} from '../../helpers/pagination/Pagination';

import {BlocksChart} from '../../helpers/blockChart/BlocksChart';
import {TIME_FORMAT} from '../../../config/const';
import {rewriteProducer} from '../../../utils/utils';
import {Preloader} from '../../helpers/preloader/Preloader';
import {I18n} from '../../helpers/i18n/I18n';

const tableData = {
  columns: [
    {
      name: 'block_page.table.col_id',
      render: obj => (
        <>
          <p>
            <Link to={`/blocks/${obj.block_num}`}>{obj.block_num}</Link>
          </p>
          <p>{obj.block_id || obj.id}</p>
        </>
      ),
    },
    {name: 'block_page.table.col_transact', key: 'transaction_count'},
    {name: 'block_page.table.col_act', key: 'actions'},
    {name: 'block_page.table.col_region', render: row => rewriteProducer(row.producer), key: 'producer'},
    {name: 'block_page.table.col_time', key: 'timestamp', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
  ],
};
const pages = [{title: 'breadcrumbs.home', url: '/'}];

@inject('blockListStore')
@observer
export class Blocks extends Component {
  state = {
    page: 0,
    limit: 20,
  };

  setPage = num => {
    this.setState({page: num});
  };

  openBlockPage = block => {
    this.props.history.push(`/blocks/${block.block_num}`);
  };

  componentDidMount() {
    //  this.props.blockListStore.getBlocksSetInterval(20, true);
    this.props.blockListStore.getBlocksSetInterval(20);
  }

  componentWillUnmount() {
    this.props.blockListStore.removeTimer();
    this.props.blockListStore.showEmpty = true;
  }

  render() {
    const {page, limit} = this.state;
    const rows = [...this.props.blockListStore.blocks]
      .reverse()
      .slice(page > 0 ? page * limit : 0, page > 0 ? (page + 1) * limit : limit);
    return (
      <div className='blocks-page'>
        {this.props.blockListStore.isFetching ? (
          <Preloader className='white-bg' />
        ) : (
          <div className='page-content'>
            <div className='header-page'>
              <Breadcrumbs currentTitle='block_page.title' pages={pages} />
            </div>
            {this.props.blockListStore.blocks.length > 0 ? (
              <div>
                <BlocksChart width='100%' height={115} data={rows} />
                <Pagination
                  onBoxChange={this.props.blockListStore.toggleEmptyFlag}
                  totalCount={this.props.blockListStore.blocks.length}
                  isChecked={!this.props.blockListStore.showEmpty}
                  changePage={this.setPage}
                  currentPage={page + 1}
                  limit={limit}
                  withCheckBox
                  wrapperClass='top-pagination'
                  labelTxt='block_page.checkbox'
                />
                <Table data={{...tableData, rows: rows.reverse()}} rowClick={this.openBlockPage} tableClass='blocks-table' />
                <Pagination
                  changePage={this.setPage}
                  currentPage={page + 1}
                  limit={limit}
                  totalCount={this.props.blockListStore.blocks.length}
                />
              </div>
            ) : (
              <h1>
                <span className='main-title md title-top-block not-found'>
                  <I18n tKey='common.no_data' />
                </span>
              </h1>
            )}
          </div>
        )}
      </div>
    );
  }
}
