import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

import {InputRow} from '../../helpers/inputRow/InputRow';
import {Table} from '../../helpers/table/Table';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {Input} from '../../helpers/input/Input';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {DropDown} from '../../helpers/dropDown/DropDown';

import {getEosTable} from '../../../utils/requester';
import {changeFormat, cutTokenPrefix, detectRounding, getTokenPrefix} from '../../../utils/utils';
import {translate} from '../../../utils/translater';
import {REG_EXP, TIME_FORMAT, getRoundedRegExp} from '../../../config/const';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import {ModalHeader} from './ModalHeader';
import {TokenFullNameInput} from './TokenFullNameInput';
import './index.scss';

const formatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

const maxDigitsLengthOfMaxSupply = 18;
const maxSupplyMaxAmount = 10 ** maxDigitsLengthOfMaxSupply;

const formatOptionLabel = data => {
  return (
    <div className='select-row'>
      <I18n className='plan-name' tKey={data.title} />{' '}
    </div>
  );
};

const generateRoundingsOptions = maxRounding => {
  const options = [];
  const startStr = '.';
  for (let i = 1; i < maxRounding; i += 1) {
    const value = {
      title: startStr.padEnd(i + 1, '0'),
      value: i,
      maxSupplyDigitsLimit: maxDigitsLengthOfMaxSupply - i,
    };

    options.push(value);
  }

  return options;
};

const dropdownOptions = [
  {
    title: 'crate_token.without-rounding',
    value: 0,
    maxSupplyDigitsLimit: maxDigitsLengthOfMaxSupply + 1,
  },
  ...generateRoundingsOptions(10),
];

const inputFormatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

@inject('userStore', 'modalStore', 'eosStore', 'loggerStore')
@observer
export class WalletCreateToken extends Component {
  state = {
    tokenName: '',
    rounding: dropdownOptions[4],
    isFetching: false,
    maxSupply: '',
    fee: '',
    tokens: [],
    tokenToIssue: null,
    issueQuantity: '',
    historyData: [],
    curFullName: '',
  };

  tableColumns = {
    columns: [
      {name: 'common.table.col_token', key: 'currency', parseFunc: value => getTokenPrefix(value)},
      {
        name: 'common.table.col_full-title',
        key: 'fullName',
        render: obj => {
          const {fullName, currency} = obj;

          return (
            <div>
              {fullName && <span className='token-full-name'>{fullName}</span>}
              <button
                type='button'
                className='edit-full-name_btn'
                onClick={() => {
                  this.setState({curFullName: fullName || ''}, () => {
                    this.openSetTokenFullName(getTokenPrefix(currency));
                  });
                }}
              >
                Edit
              </button>
            </div>
          );
        },
      },
      {name: 'common.table.col_max-supply', key: 'currency'},
      {name: 'common.table.col_create', key: 'created', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
      {name: 'common.table.col_amount_fee', key: 'payment'},
    ],
  };

  pinForm = React.createRef();

  getRate = async () => {
    const {data} = await getEosTable({
      code: 'interchange',
      scope: 'interchange',
      json: true,
      key_type: 'uint64',
      limit: 1,
      table: 'untbrate',
    });

    return data.rows?.[0] || {};
  };

  componentDidMount = async () => {
    const newState = {};

    this.getCreationHistory();

    const {usdu} = await this.getRate();

    const {isError, data} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1,
      scope: 'curcreator',
      table: 'curfee',
      memo: '',
    });

    if (!isError) {
      newState.fee = data?.rows?.[0]?.price;
      const cruToPay = cutTokenPrefix(newState.fee) / cutTokenPrefix(usdu);
      newState.feeToPay = `${changeFormat(cruToPay, 4, formatSettings)} UNTB`;
    }

    this.setState(newState);
  };

  componentDidUpdate = (props, state) => {
    const {maxSupply, rounding, tokenName} = this.state;
    const memo = `${changeFormat(maxSupply || 0, rounding.value, inputFormatSettings)} ${tokenName}`;

    if (state.memo !== memo) {
      this.setState({memo});
    }
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value.toUpperCase()});
  };

  onChangeDropDown = value => {
    this.setState({
      rounding: value,
      maxSupply: '',
    });
  };

  onChangeTokenToIssue = value => {
    const {issueQuantity} = this.state;
    const canIssueCurrentQuantity = value.maxSupply >= issueQuantity || 0;

    const formattedQuantity = issueQuantity
      ? changeFormat(canIssueCurrentQuantity ? issueQuantity : value.maxSupply, value?.rounding || 4, inputFormatSettings)
      : '';

    this.setState({issueQuantity: formattedQuantity, tokenToIssue: value});
  };

  getUserTokens = async () => {
    const {historyData} = this.state;

    const tokens = historyData.map(({currency}) => {
      return {
        title: getTokenPrefix(currency),
        rounding: detectRounding(currency),
        maxSupply: +cutTokenPrefix(currency),
      };
    });

    this.setState({tokens});
  };

  getCreationHistory = async () => {
    const {
      userStore: {user},
    } = this.props;

    const {data} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: 'curcreator',
      table: 'feelog',
    });

    const {data: names} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: user.name,
      table: 'curconfig',
    });

    const historyData = data?.rows?.filter(({issuer}) => user.name === issuer) || [];
    const historyDataWithFullName = historyData.map(row => {
      return {...row, fullName: names?.rows.find(({cursymbol}) => cursymbol === getTokenPrefix(row.currency))?.name};
    });

    this.setState({historyData: historyDataWithFullName}, this.getUserTokens);
  };

  createToken = async pin => {
    const {eosStore, userStore, loggerStore} = this.props;
    const {feeToPay, memo} = this.state;

    this.setState({isFetching: true});

    const {isError} = await eosStore.createToken({
      pin,
      fee: feeToPay,
      userName: userStore.user.name,
      memo: `create ${memo}`,
      errorMsg: 'common.create_token_error',
    });
    if (!isError) {
      loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.create_token_success'});
      userStore.getAccountInfo();
    }

    this.getCreationHistory();
    this.setState({isFetching: false});
  };

  openCreateModal = () => {
    const {modalStore} = this.props;
    const {fee, feeToPay} = this.state;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.createToken,
        }}
        btnTitle='create_token.create'
        btnTitleBack='common.cancel'
      >
        <div className='create-token-modal-info'>
          <I18n value={`${feeToPay} (≈${fee})`} tKey='create_token.fee-info' />
        </div>
      </EnterPin>
    ));
  };

  makeUsduOp = async pin => {
    const {issueQuantity, tokenToIssue} = this.state;
    const {eosStore, userStore, loggerStore} = this.props;
    const user = userStore.user.name;

    this.setState({isFetching: true});
    const {isError} = await eosStore.makeUsduOp(`${issueQuantity} ${tokenToIssue?.title}`, user, '', true, pin);
    if (isError) {
      loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'common.token_issuing_error'});
    } else {
      loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.token_issuing_success'});
      userStore.updateUsdu(); // todo update balances
    }

    this.setState({isFetching: false});
  };

  openPinModal = () => () => {
    const {modalStore, userStore} = this.props;
    const {issueQuantity, tokenToIssue} = this.state;
    const params = {
      className: 'issuing',
      headerLabelText: 'common.you_issuing_user_token',
      value: `${issueQuantity} ${tokenToIssue?.title}`,
      userName: userStore.user.name,
    };

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{onValidPin: this.makeUsduOp}}
        operation='ISSUING'
      >
        <ModalHeader operation='ISSUING' params={params} />
      </EnterPin>
    ));
  };

  onInputBlur = () => {
    const {maxSupply, rounding} = this.state;

    const formattedQuantity = changeFormat(maxSupply, rounding, inputFormatSettings);

    this.setState({maxSupply: formattedQuantity});
  };

  onIssueInputBlur = () => {
    const {issueQuantity, tokenToIssue} = this.state;

    const formattedQuantity = issueQuantity ? changeFormat(issueQuantity, tokenToIssue?.rounding || 4, inputFormatSettings) : '';

    this.setState({issueQuantity: formattedQuantity});
  };

  setCurFullName = async (pin, cursymbol) => {
    const {eosStore, userStore, loggerStore} = this.props;
    const {curFullName} = this.state;
    const {name} = userStore.user;

    const {isError} = await eosStore.setTokenName({
      name,
      pin,
      cursymbol,
      fullName: curFullName,
      errorMsg: 'common.set_token_full_name_error',
    });

    !isError && loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.set_token_full_name_success'});

    this.getCreationHistory();
    userStore.getAccountInfo();
  };

  openSetTokenFullName = cursymbol => {
    const {modalStore} = this.props;
    const {curFullName} = this.state;

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{onValidPin: pin => this.setCurFullName(pin, cursymbol)}}
      >
        <TokenFullNameInput
          onChange={value => {
            this.setState({curFullName: value});
          }}
          value={curFullName}
        />
      </EnterPin>
    ));
  };

  onBlurInputName = e => {
    const {userStore, loggerStore} = this.props;
    const isTokenNameUsed = Object.keys(userStore.userWallet).includes(e.target.value);

    if (isTokenNameUsed) loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'common.token-name-used'});
    this.setState({isTokenNameUsed});
  };

  render() {
    const {
      tokenName,
      tokenToIssue,
      tokens,
      isTokenNameUsed,
      maxSupply,
      issueQuantity,
      isFetching,
      rounding,
      fee,
      feeToPay,
      historyData,
      memo,
    } = this.state;
    const {userStore} = this.props;
    const canPayFee = +cutTokenPrefix(feeToPay) <= +userStore.userWallet[getTokenPrefix(feeToPay)]?.available || 0;

    return (
      <div className='create-token-page nested-route margin'>
        <div>
          <I18n tKey='business_acc.title' className='title-page' />
          <div className='wallet-content'>
            <I18n tKey='create_token.title' className='block-title' />
            <div className='notice-text-wrapper d-flex'>
              <div className='wrapper-flex-item wide centered-content'>
                <InfoIcon />
              </div>
              <div className='d-flex flex-column'>
                <I18n className='sub-text' tKey='create_token.fee_notice' />
                <br />
                <I18n tKey='create_token.fee_cost' value={`${feeToPay} (≈${fee})`} className='fee-cost' />
              </div>
            </div>
            <div className='flex-row'>
              <div className='flex-column'>
                <I18n tKey='create_token.token-name-placeholder' className='input-label' />
                <Input
                  containerClass='password-field'
                  placeholder='create_token.token-name-placeholder'
                  statusBorder
                  error={isTokenNameUsed}
                  regEx={REG_EXP.four_letters}
                  onChange={this.onChange}
                  onBlur={this.onBlurInputName}
                  name='tokenName'
                  value={tokenName}
                />
              </div>

              <div className='flex-column'>
                <I18n tKey='create_token.max-supply' className='input-label' />
                <Input
                  containerClass='password-field'
                  placeholder={`${translate('create_token.max-supply-placeholder')} ${changeFormat(
                    rounding.value ? 10 ** rounding?.maxSupplyDigitsLimit : maxSupplyMaxAmount,
                    0
                  )}`}
                  statusBorder
                  onlyNumbers
                  onChange={this.onChange}
                  maxAmount={rounding?.value ? 10 ** rounding?.maxSupplyDigitsLimit : maxSupplyMaxAmount}
                  maxLength={rounding?.maxSupplyDigitsLimit + 1}
                  name='maxSupply'
                  value={maxSupply}
                />
              </div>
            </div>

            <div className='flex-row'>
              <div className='flex-column'>
                <I18n tKey='create_token.rounding' className='input-label' />
                <DropDown
                  wrapperClass='select-rounding'
                  onChange={this.onChangeDropDown}
                  value={rounding}
                  isOptionSelected={option => option === rounding}
                  options={dropdownOptions}
                  formatOptionLabel={formatOptionLabel}
                  placeholder='change_key.dropDown_placeholder'
                />
              </div>

              <div className='flex-column rounding-demo-wrapper'>
                <I18n tKey='create_token.token-demo' className='input-label' />
                <div className='rounding-demo'>{memo}</div>
              </div>
            </div>

            <Button
              btnClass='with-bg blue'
              title='create_token.create'
              disabled={isFetching || isTokenNameUsed || !tokenName || !fee || !maxSupply || !canPayFee}
              onClick={this.openCreateModal}
            />

            {tokens.length ? (
              <>
                <div className='history-table-wrapper'>
                  <I18n tKey='create_token.creation-history' className='block-title' />
                  <Table data={{...this.tableColumns, rows: historyData}} tableClass='transactions-table' />
                </div>

                <I18n tKey='create_token.issue-token' className='block-title' />
                <div className='notice-text-wrapper d-flex'>
                  <div className='wrapper-flex-item wide centered-content'>
                    <InfoIcon />
                  </div>
                  <div className='d-flex flex-column'>
                    <I18n className='sub-text' tKey='create_token.issue_notice' />
                  </div>
                </div>
                <div className='input-with-btn'>
                  <DropDown
                    wrapperClass='select-rounding'
                    onChange={this.onChangeTokenToIssue}
                    value={tokenToIssue}
                    isOptionSelected={option => {
                      return option === tokenToIssue;
                    }}
                    options={tokens}
                    formatOptionLabel={formatOptionLabel}
                    placeholder='create_token.dropDown_token'
                    menuPlacement='auto'
                  />
                  <InputRow
                    wrapperClass='input-with-bg'
                    // subtitle='wallet_usdu.mint_subtitle'
                    name='issueQuantity'
                    value={issueQuantity}
                    onlyNumbers
                    maxAmount={tokenToIssue?.maxSupply || 0}
                    regEx={getRoundedRegExp(tokenToIssue?.rounding)}
                    statusBorder
                    inputChange={this.onChange}
                    onBlur={this.onIssueInputBlur}
                    placeholder={changeFormat(0, tokenToIssue?.rounding || 4, inputFormatSettings)}
                    label={tokenToIssue?.title}
                    readOnly={!tokenToIssue}
                  />
                  <Button
                    disabled={isFetching || !+issueQuantity || !tokenToIssue}
                    onClick={this.openPinModal(true, false, 'ISSUING')}
                    btnClass='with-bg green-bg'
                    title='wallet_usdu.btn_issue'
                  />
                </div>
              </>
            ) : (
              <div className='history-table-wrapper'>
                <I18n tKey='create_token.creation-history' className='block-title' />
                <I18n tKey='create_token.no-tokens-yet' className='no-token-notice' />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
