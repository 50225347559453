import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';

import {ReactComponent as SearchIcon} from '../../../img/search_icon.svg';
import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {TIME_FORMAT} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {changeCurrencyFormat} from '../../../utils/utils';
import {translate} from '../../../utils/translater';
import {Input} from '../../helpers/input/Input';

const validName = typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_ADMIN_NAME : process.env.REACT_APP_ADMIN_NAME;

const tableColumns = {
  columns: [
    {name: 'common.table.col_id', key: 'id'},
    {name: 'common.table.col_create', key: 'date_created', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
    {name: 'common.table.col_amount_wcru', key: 'wcru_user', parseFunc: value => changeCurrencyFormat(value)},
    {name: 'common.table.col_amount_dividend_usdu', key: 'usdu', parseFunc: value => changeCurrencyFormat(value)},
    {name: 'common.table.col_amount_dist_usdu', key: 'dist_usdu', parseFunc: value => changeCurrencyFormat(value)},
    {name: 'common.table.col_amount_total_wcru', key: 'dist_wcru', parseFunc: value => changeCurrencyFormat(value)},
  ],
};

@inject('userStore')
@observer
export class WalletDividendsTable extends Component {
  state = {
    dividendsListExtended: [],
    userName: '',
  };

  componentDidMount = () => {
    this.generateTableData();
  };

  generateTableData = async () => {
    const dividends = await this.getDividends();
    const dividendsDistributions = await this.getDividendsDistributions();

    const dividendsListExtended = dividends.map(dividend => {
      const dividendsDistribution = dividendsDistributions.find(({id}) => id === dividend.dist_id);

      return {...dividend, dist_usdu: dividendsDistribution?.usdu, dist_wcru: dividendsDistribution?.wcru_total};
    });

    this.setState({dividendsListExtended});
  };

  getDividends = async () => {
    const {
      userStore: {
        user: {name},
      },
    } = this.props;
    const {userName} = this.state;

    const {isError, data} = await getEosTable({
      code: 'dividend',
      index_position: 2,
      json: true,
      key_type: 'name',
      limit: 1000,
      lower_bound: userName || name,
      scope: 'dividend',
      table: 'results',
      upper_bound: userName || name,
    });

    if (isError) return [];

    if (data.more) {
      const nextData = await this.getDividends();
      return nextData;
    }

    return data.rows;
  };

  getDividendsDistributions = async () => {
    const {isError, data} = await getEosTable({
      code: 'dividend',
      json: true,
      limit: 1000,
      scope: 'dividend',
      table: 'distrib',
    });

    return isError ? [] : data.rows;
  };

  onChange = (name, value) => {
    this.setState({[name]: value});
  };

  onKeyDown = ev => {
    if (ev.which === 13 || ev.keyCode === 13) {
      this.generateTableData();
    }
  };

  render() {
    const {
      userStore: {
        user: {name},
      },
    } = this.props;
    const {dividendsListExtended, userName} = this.state;

    return (
      <div className='nested-route wallet-dividends margin'>
        <div className='wallet-ram-block'>
          <I18n tKey='wallet_dividends.title' className='title-page' />
          <div className='wallet-content'>
            {validName === name && (
              <div className='username-panel'>
                <div className={classNames('search-block', {'active-search': userName})}>
                  <Input
                    allowClear
                    value={userName}
                    name='userName'
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    placeholder={translate('wallet_dividends.userName')}
                  />
                  <SearchIcon onClick={this.generateTableData} />
                </div>
              </div>
            )}
            {dividendsListExtended.length ? (
              <div className='wallet-dividends-table'>
                <Table data={{...tableColumns, rows: dividendsListExtended}} tableClass='transactions-table' />
              </div>
            ) : (
              <I18n tKey='wallet_dividends.no_dividents' className='process-msg' />
            )}
          </div>
        </div>
      </div>
    );
  }
}
