import React from 'react';
import {I18n} from '../i18n/I18n';
import {Link} from 'react-router-dom';

export const BlockHeader = ({title, linkTitle, linkUrl}) => (
  <div className='block-header'>
    <I18n className='main-title md' tKey={title} />
    <Link to={linkUrl} className='link-title'>
      <I18n tKey={linkTitle} />
    </Link>
  </div>
);
