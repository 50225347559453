import React, {Component, Fragment} from 'react';
import {inject} from 'mobx-react';
import {Serialize} from 'eosjs';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {Pagination} from '../../helpers/pagination/Pagination';
import {TableControls} from '../../helpers/tableСontrols/TableControls';

import {getUserActions} from '../../../utils/requester';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {LockHistory} from './LockHistory';
import {TrxHistoryTable} from '../../helpers/trxHistoryTable/TrxHistoryTable';

const controlBtn = [
  {title: 'account.details.control_btn.all_act', value: 'all'},
  {title: 'account.details.control_btn.contract', value: 'contract'},
  // {title: "account.details.control_btn.ram"},
  // {title: "account.details.control_btn.votes"},
  // {title: "account.details.control_btn.bids"},
  // {title: "account.details.control_btn.rewards"},
  // {title: "account.details.control_btn.refund"},
  // {title: "account.details.control_btn.transfers"}
];

@inject('eosStore', 'loggerStore')
export class AccountTransactions extends Component {
  contracts = {};

  state = {
    isError: false,
    history: [],
    limit: 20,
    lastIndex: 0,
    page: 0,
    activeTab: controlBtn[0].value,
  };

  componentDidMount() {
    this.props.eosStore.getContract({name: 'eosio.token'});
  }

  componentWillMount() {
    this.getHistory();
  }

  setPage = num => {
    !this.state.isFetching && this.setState({page: num}, this.getHistory);
  };

  setPrev = () => {
    this.setPage(this.state.page - 1);
  };

  setNext = () => {
    this.setPage(this.state.page + 1);
  };

  getHistory = () => {
    this.setState({isFetching: true});
    getUserActions({
      type: 'All',
      account: this.props.accountName,
      offset: this.state.page * this.state.limit,
      limit: this.state.limit,
      symbol: '',
    }).then(async r => {
      const st = {isFetching: false, isError: false};
      const {loggerStore, eosStore} = this.props;

      if (!r.isError) {
        st.history = await Promise.all(
          r.data.map(async action => {
            const {action_name, act_data, contract: contractName} = action;

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              action_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            console.log(action_name, dataa);

            return {...action, action_data: dataa};
          })
        );

        st.lastIndex = r.data.length || 0;
      } else {
        st.isError = true;
        loggerStore.addItem({type: LOGGER_TYPES[2], msgTxt: 'common.try_again_later'});
      }
      this.setState(st);
    });
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  render() {
    const {page, limit, isError, history, lastIndex, activeTab, isFetching} = this.state;
    console.log('rowsData', history);
    return (
      <>
        <div className='header-block'>
          <I18n className='main-title md' tKey='account.details.transact.title' />
          <div className='table-controls'>
            {controlBtn.map((elem, i) => (
              <TableControls
                key={i}
                active={elem.value === activeTab}
                title={elem.title}
                onClick={this.setActiveTab.bind(this, elem.value)}
              />
            ))}
          </div>
        </div>
        {activeTab === controlBtn[0].value ? (
          <>
            <TrxHistoryTable
              accountName={this.props.accountName}
              isError={isError}
              onRetry={this.getHistory}
              isFetching={isFetching}
              rows={history}
              tableWrapperClass='relative min-height'
            />
            <Pagination
              handlePrev={this.setPrev}
              handleNext={this.setNext}
              hasNext={lastIndex != 0}
              hasPrev={page != 0}
              currentPage={page + 1}
              limit={limit}
              totalCount={lastIndex}
              withoutNumbers
            />
          </>
        ) : (
          <LockHistory accountName={this.props.accountName} />
        )}
      </>
    );
  }
}
