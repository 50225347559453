import React, {Component} from 'react';
import {Shimmer} from 'react-shimmer';
import {BlockList} from './BlockList';
export default class ShimmerLayout extends Component {
  render() {
    const {blockListLayout} = this.props;
    return (
      <div>
        {!blockListLayout ? (
          <div>
            <div className='shimmer-container'>
              {/* <Shimmer  width={550} height={140} /> */}
              <div className='d-flex space-between'>
                <Shimmer width={250} height={30} />
                <Shimmer width={120} height={30} />
              </div>
              <div className='d-flex flex-direction-row justify-content-start'>
                <div className='d-flex flex-direction-column justify-content-start'>
                  <Shimmer width={25} height={25} />
                  <Shimmer width={85} height={25} />
                </div>
                <div className='d-flex flex-direction-column justify-content-start'>
                  <Shimmer width={25} height={25} />
                  <Shimmer width={85} height={25} />
                </div>
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={68} height={13} />
                <Shimmer width={55} height={13} />
              </div>
            </div>
            <div className='shimmer-container'>
              <div className='d-flex space-between'>
                <Shimmer width={68} height={13} />
                <Shimmer width={55} height={13} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={180} height={13} />
                <Shimmer width={55} height={13} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={240} height={13} />
                <Shimmer width={55} height={13} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={288} height={13} />
                <Shimmer width={40} height={13} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={327} height={13} />
                <Shimmer width={30} height={13} />
              </div>
            </div>

            <div className='shimmer-container'>
              <div className='d-flex flex-direction-column justify-content-start'>
                <Shimmer width={80} height={13} />
                <Shimmer width={140} height={13} />
              </div>
              <Shimmer width={600} height={13} />
              <div className='d-flex space-between'>
                <Shimmer width={38} height={30} />
                <Shimmer width={38} height={30} />
                <Shimmer width={38} height={30} />
                <Shimmer width={38} height={30} />
                <Shimmer width={38} height={30} />
              </div>
              <Shimmer className='d-flex justify-content-center' width={500} height={30} />
            </div>
            <div className='shimmer-container'>
              <Shimmer className='d-flex justify-content-center' width={650} height={30} />
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
              <div className='d-flex space-between'>
                <Shimmer width={35} height={38} />
                <Shimmer width={120} height={38} />
                <Shimmer width={38} height={38} />
                <Shimmer width={38} height={38} />
              </div>
            </div>
          </div>
        ) : (
          <BlockList />
        )}
      </div>
    );
  }
}
