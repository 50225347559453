import React, {Component} from 'react';
import classNames from 'classnames';
import {changeFormat} from '../../../utils/utils';

export class CountWithZero extends Component {
  render() {
    const {count, countClass, round = 4, plusBeforeSumm, dividedView, separator = '.'} = this.props;
    const value = changeFormat(count, round);
    if (dividedView && (value || '0').split(separator).length > 1) {
      const separatedView = value.split(separator);
      return (
        <div className={classNames('count-token', countClass)}>
          <span className='count-row'>
            {plusBeforeSumm && '+'}
            {separatedView[0]}
          </span>
          <span className='count-row'>
            {separator}
            {separatedView[1]}
          </span>
          {this.props.children && this.props.children}
        </div>
      );
    }
    return (
      <div className={classNames('count-token', countClass)}>
        {plusBeforeSumm && <span className='count-row'>+</span>}
        <span className='count-row'>{value}</span>
        {this.props.children && <span>{this.props.children}</span>}
      </div>
    );
  }
}
