import React, {Component} from 'react';
import classNames from 'classnames';

import {I18n} from '../../helpers/i18n/I18n';

export class DotRow extends Component {
  render() {
    const {rows} = this.props;
    return rows.map((elem, i) => (
      <div className={classNames('dot-row', elem.rowClass)} key={i}>
        <span className='dots' />
        <I18n tKey={elem.title} />
        {elem.totalCount && <span>&#58; {elem.totalCount}</span>}
      </div>
    ));
  }
}
