import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import moment from 'moment';
import {Tabs, Tab, Tooltip} from '@material-ui/core';

import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {Table} from '../../helpers/table/Table';
import {UrlPopupCell} from '../../helpers/urlPopupCell/UrlPopupCell';
import {Timer} from '../../helpers/timer/Timer';

import {TIME_FORMAT} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {compareTokenAmountAsc, cutTokenPrefix} from '../../../utils/utils';

import {ReactComponent as ClaimLogo} from '../../../img/prod_claim.svg';
import {ReactComponent as ProdCandLogo} from '../../../img/prod_cand.svg';
import {ReactComponent as ProdCandsLogo} from '../../../img/prod_cands.svg';
import {ReactComponent as ProducerLogo} from '../../../img/producer.svg';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';

import {InvestSumInput} from './InvestSumInput';
import {ActionPanel} from './actionPanel';
import './index.scss';

const assistancesColumns = [
  {name: 'account.table.col_name', key: 'assistant'},
  {name: 'producers.table.col_total_cru', key: 'sum'},
  {
    name: 'producers.table.col_last_invest',
    key: 'supptime',
    render: obj => moment(obj.supptime).format(TIME_FORMAT.CONVERT_TABLE),
  },
];

@inject('userStore', 'modalStore', 'eosStore', 'loggerStore')
@observer
export class WalletProducer extends Component {
  state = {
    isFetching: false,
    candfee: '',
    url: '',
    candidates: [],
    assistances: [],
    selectedTab: 0,
    // isProducer: false,
    investSum: '',
    investments: {},
  };

  candidatesColumnsExtended = [
    {name: 'account.table.col_name', key: 'candidate'},
    {
      name: 'producers.table.col_url',
      render: ({url}) => <UrlPopupCell url={url} />,
    },
    {name: 'producers.table.col_total_cru', withSort: true, key: 'totalsum'},
    {name: 'producers.table.col_total_assistance', withSort: true, key: 'assistcount'},
    {
      name: 'producers.table.col_my_invest_sum',
      withSort: true,
      sortKey: 'myInvestSum',
      getValueForSort: obj => {
        const {investments} = this.state;
        const {candidate} = obj;

        return (!investments[candidate]?.cancelled && cutTokenPrefix(investments[candidate]?.sum)) || 0;
      },
      render: obj => {
        const {investments} = this.state;
        const {candidate} = obj;

        return (!investments[candidate]?.cancelled && investments[candidate]?.sum) || '-';
      },
    },
    {
      name: 'account.table.col_actions',
      render: obj => {
        const {investments, params} = this.state;
        const {candidate, cancelled} = obj;

        const isBeforeStartComp = moment().isBefore(params?.startcomp); // && moment().isBefore(params?.endcomp);
        const isAfterEndComp = moment().isAfter(params?.endcomp);
        const isBetweenComp = moment().isBetween(params?.startcomp, params?.endcomp);

        const isRefundFrozen =
          investments[candidate] &&
          !investments[candidate]?.cancelled &&
          !isAfterEndComp &&
          moment().isBefore(moment(`${investments[candidate].supptime}Z`).add(params?.freezesecs || 0, 's'));

        return (
          <div className='actions-column'>
            {(isBeforeStartComp || (isBetweenComp && !cancelled)) && (
              <Button
                onClick={() => {
                  this.setState({investSum: ''}, () => {
                    this.openInvestModal(candidate);
                  });
                }}
                disabled={cancelled}
                btnClass='with-bg green'
                title='producer_acc.invest-btn'
              />
            )}
            {(isBeforeStartComp || isAfterEndComp || !!cancelled) && (
              <Tooltip
                title={
                  isRefundFrozen ? (
                    <I18n
                      tKey='producer_acc.refund-btn-tooltip'
                      value={moment(`${investments[candidate].supptime}Z`)
                        .add(params?.freezesecs || 0, 's')
                        .format(TIME_FORMAT.BLOCK)}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <span style={{width: '100%'}}>
                  <Button
                    disabled={!investments[candidate] || investments[candidate]?.cancelled || isRefundFrozen} // || (isAfterEndComp && isprod)
                    onClick={() => {
                      this.openRefundModal(investments[candidate]?.id);
                    }}
                    btnClass='with-bg gray'
                    title='producer_acc.refund-btn'
                  />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  getAssistances = async (userName, isIncoming) => {
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1000,
      scope: 'prodcand',
      table: 'candsupp',
      index_position: isIncoming ? 2 : 3,
      key_type: 'name',
      lower_bound: userName,
      upper_bound: userName,
    });

    return data?.rows || [];
  };

  getCandidates = async () => {
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1000,
      scope: 'prodcand',
      table: 'candprod',
    });

    return data?.rows || [];
  };

  getParams = async () => {
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1000,
      scope: 'prodcand',
      table: 'param',
    });
    // eslint-disable-next-line prefer-const
    let params = data?.rows?.[0];
    console.log(params);

    // params = {
    //   freezesecs: 604800,
    //   startcomp: '2021-11-03T16:17:00',
    //   endcomp: '2021-11-03T16:40:00',
    //   endprod: '2024-02-09T22:00:00',
    // };

    if (params) {
      return {
        ...params,
        endcomp: `${params.endcomp}Z`,
        endprod: `${params.endprod}Z`,
        startcomp: `${params.startcomp}Z`,
      };
    }

    return {};
  };

  getTotalInfo = async () => {
    const {userStore} = this.props;

    const params = await this.getParams();
    const candidates = await this.getCandidates();
    const assistances = await this.getAssistances(userStore.user.name, true);
    const investments = await this.getAssistances(userStore.user.name, false);

    // const candidateInfo = candidates.find(({candidate, cancelled}) => candidate === userStore.user.name && !cancelled);
    // const isProducer = !!candidateInfo?.isprod;
    // const isRealProducer = !candidateInfo && userStore.isProducerAccount;

    // console.log(investments);
    const investmentsDict = investments.reduce((acc, {candidate, sum, id, cancelled, supptime}) => {
      acc[candidate] = {sum, id, cancelled, supptime};
      return acc;
    }, {});

    this.setState({
      params,
      candidates,
      // isProducer,
      // isRealProducer,
      assistances: assistances.filter(({cancelled}) => !cancelled),
      investments: investmentsDict,
    });
  };

  componentDidMount = async () => {
    this.setState({isFetching: true});

    await this.getTotalInfo();
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1,
      scope: 'prodcand',
      table: 'candfee',
    });

    const candfee = data?.rows?.[0]?.price;

    this.setState({candfee, isFetching: false});
  };

  becomeCandidate = async pin => {
    const {eosStore, userStore} = this.props;
    const {candfee, url} = this.state;

    this.setState({isFetching: true});

    const {isError} = await eosStore.becomeCandidate({pin, userName: userStore.user.name, fee: candfee, memo: `mkcand ${url}`});

    if (!isError) {
      await userStore.getAccountInfo();
      await this.getTotalInfo();
      this.setState({isFetching: false, selectedTab: 1, url: ''});
      return;
    }

    this.setState({isFetching: false});
  };

  cancelTender = async pin => {
    const {eosStore, userStore} = this.props;

    this.setState({isFetching: true});

    const {isError} = await eosStore.cancelTender({
      pin,
      userName: userStore.user.name,
    });

    if (!isError) {
      await userStore.getAccountInfo();
      await this.getTotalInfo();
      this.setState({isFetching: false, selectedTab: 0});
      return;
    }

    this.setState({isFetching: false});
  };

  investToCandidate = async (pin, candidate) => {
    const {eosStore, userStore} = this.props;
    const {investSum} = this.state;

    this.setState({isFetching: true});

    const {isError} = await eosStore.investCand({
      pin,
      userName: userStore.user.name,
      quantity: `${investSum} CRU`,
      memo: `support ${candidate}`,
    });

    if (!isError) {
      await this.getTotalInfo();
    }

    this.setState({isFetching: false});
  };

  refundInvestments = async (pin, id) => {
    const {eosStore, userStore} = this.props;

    this.setState({isFetching: true});

    const {isError} = await eosStore.refundInvestments({
      pin,
      userName: userStore.user.name,
      id,
    });

    if (!isError) {
      await this.getTotalInfo();
    }

    this.setState({isFetching: false});
  };

  claimRewards = async pin => {
    const {eosStore, userStore} = this.props;

    this.setState({isFetching: true});

    const {isError} = await eosStore.claimRewards({pin, userName: userStore.user.name});

    if (!isError) {
      await userStore.getAccountInfo();
    }

    this.setState({isFetching: false});
  };

  openCancelModal = () => {
    const {modalStore} = this.props;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.cancelTender,
        }}
        btnTitle='producer_acc.cancel-tender-btn'
        btnTitleBack='common.cancel'
      >
        <div className='create-ba-modal-info'>
          <I18n tKey='producer_acc.cancel-tender-info' />
        </div>
      </EnterPin>
    ));
  };

  openBecomeModal = () => {
    const {modalStore} = this.props;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.becomeCandidate,
        }}
        btnTitle='producer_acc.prod-apply-btn'
        btnTitleBack='common.cancel'
      >
        <div className='create-ba-modal-info'>
          <I18n tKey='producer_acc.prod-apply-info' />
        </div>
      </EnterPin>
    ));
  };

  openClaimModal = () => {
    const {modalStore} = this.props;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.claimRewards,
        }}
        btnTitle='producer_acc.claim-rewards-btn'
        btnTitleBack='common.cancel'
      >
        <div className='create-ba-modal-info'>
          <I18n tKey='producer_acc.claim-rewards-info' />
        </div>
      </EnterPin>
    ));
  };

  openInvestModal = candidate => {
    const {modalStore} = this.props;
    const {investSum} = this.state;

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{onValidPin: pin => this.investToCandidate(pin, candidate)}}
      >
        <InvestSumInput
          onChange={value => {
            this.setState({investSum: value});
          }}
          value={investSum}
        />
      </EnterPin>
    ));
  };

  openRefundModal = id => {
    const {modalStore} = this.props;

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{onValidPin: pin => this.refundInvestments(pin, id)}}
      >
        {/* <InvestSumInput
          onChange={value => {
            this.setState({investSum: value});
          }}
          value={investSum}
        /> */}
      </EnterPin>
    ));
  };

  getValueForSort = (row, sortColumn) => {
    if (row[sortColumn] === undefined) {
      const column = this.candidatesColumnsExtended.find(({key, sortKey}) => {
        return key === sortColumn || sortKey === sortColumn;
      });

      return column.getValueForSort(row);
    }

    return row[sortColumn];
  };

  sortCandidates = sortParams => {
    const {candidates} = this.state;
    const {sortColumn, sortDirection} = sortParams;

    if (!sortColumn) {
      return candidates;
    }

    const sortedCandidates = [...candidates].sort((a, b) => {
      const aValue = this.getValueForSort(a, sortColumn);
      const bValue = this.getValueForSort(b, sortColumn);
      const compareRes = compareTokenAmountAsc(aValue, bValue);

      if (sortDirection === 'desc') {
        return compareRes * -1;
      }

      return compareRes;
    });

    return sortedCandidates;
  };

  render() {
    const {isFetching, assistances, candidates, candfee, url, selectedTab, params} = this.state;
    const {userStore} = this.props;
    const isBetweenComp = moment().isBetween(params?.startcomp, params?.endcomp);
    const isBeforeStartComp = moment().isBefore(params?.startcomp);
    const isAfterEndComp = moment().isAfter(params?.endcomp);
    const isAfterEndProd = moment().isAfter(params?.endprod);

    return (
      <div className='producer-page nested-route margin'>
        <div className='producer-page-wrapper'>
          <I18n tKey='producer_acc.title' className='title-page' />

          <div className='nav-bar'>
            <Tabs
              value={selectedTab}
              indicatorColor='primary'
              textColor='primary'
              onChange={(e, val) => {
                this.setState({selectedTab: val});
              }}
            >
              <Tab className='title-page' label={<I18n tKey='producer_acc.become_candidate_tab' />} />
              <Tab
                className='title-page'
                disabled={!userStore.isCandidatAccount}
                label={<I18n tKey='producer_acc.candidate_tab' />}
              />
              <Tab
                className='title-page'
                disabled={!userStore.isProducerAccount}
                label={<I18n tKey='producer_acc.claim_tab' />}
              />
            </Tabs>
          </div>

          {isAfterEndComp ? (
            <div className='notice-text-wrapper d-flex'>
              <div className='wrapper-flex-item wide centered-content'>
                <InfoIcon />
              </div>
              <div className='d-flex'>
                <I18n tKey='producer_acc.comp-ends-notify' value={candfee} />
              </div>
            </div> // userStore.isCandidatAccount  || !isBeforeStartComp &&
          ) : (
            <Timer
              title='producer_acc.comp-ends-timer'
              endTimeCallback={() => {
                this.getTotalInfo();
                userStore.getAccountInfo();
              }}
              endDateTime={params?.endcomp}
            />
          )}

          {selectedTab === 0 && isBeforeStartComp && !userStore.isCandidatAccount && !userStore.isProducerAccount && (
            <>
              <div className='notice-text-wrapper d-flex'>
                <div className='wrapper-flex-item wide centered-content'>
                  <InfoIcon />
                </div>
                <div className='d-flex'>
                  <I18n tKey='producer_acc.fee_cost' value={candfee} />
                </div>
              </div>

              <ActionPanel
                logo={<ProdCandLogo />}
                title='producer_acc.before_become_candidate'
                subtitle='producer_acc.enter_url_info'
                endTime={params?.startcomp}
                endTimeCallback={() => {
                  this.getTotalInfo();
                  userStore.getAccountInfo();
                }}
                action={
                  <div className='d-flex'>
                    <InputRow
                      placeholder='https://'
                      value={url}
                      inputChange={(name, value) => {
                        this.setState({url: value});
                      }}
                      wrapperClass='input-with-bg'
                    />
                    <Button
                      btnClass='with-bg blue'
                      title='producer_acc.prod-apply-btn'
                      disabled={userStore.isCandidatAccount}
                      onClick={this.openBecomeModal}
                    />
                  </div>
                }
              />
            </>
          )}

          {selectedTab === 1 &&
            !isBetweenComp &&
            (userStore.isProducerAccount && isAfterEndComp ? (
              <ActionPanel
                logo={<ProducerLogo />}
                title='producer_acc.become_producer'
                subtitle={isAfterEndProd ? 'producer_acc.prod_end_info' : isAfterEndComp ? '' : 'producer_acc.assistance_info'}
                action={
                  <>
                    {isAfterEndProd && (
                      <Button
                        btnClass='with-bg blue'
                        title='producer_acc.return-fee-btn'
                        disabled={
                          isFetching ||
                          moment().isBefore(
                            moment(
                              `${
                                candidates.find(({candidate}) => {
                                  return candidate === userStore.user.name;
                                }).created
                              }Z`
                            ).add(params?.freezesecs, 's')
                          )
                        }
                        onClick={this.openCancelModal}
                      />
                    )}
                    {/* <p>
                      <I18n tKey='producer_acc.cancel-tender-notice' />
                    </p> */}
                  </>
                }
              />
            ) : (
              <ActionPanel
                logo={<ProdCandsLogo />}
                title={isAfterEndComp ? 'producer_acc.not_become_candidate' : 'producer_acc.become_candidate'}
                subtitle={isAfterEndComp ? 'producer_acc.return_fee_info' : 'producer_acc.assistance_info'}
                action={
                  !userStore.isProducerAccount && (
                    <Button
                      btnClass='with-bg blue'
                      title={isAfterEndComp ? 'producer_acc.return-fee-btn' : 'producer_acc.cancel-tender-btn'}
                      disabled={
                        isFetching ||
                        moment().isBefore(
                          moment(
                            `${
                              candidates.find(({candidate}) => {
                                return candidate === userStore.user.name;
                              }).created
                            }Z`
                          ).add(params?.freezesecs, 's')
                        )
                      }
                      onClick={this.openCancelModal}
                    />
                  )
                }
              />
            ))}

          {selectedTab === 2 && (
            <ActionPanel
              logo={<ClaimLogo />}
              title='producer_acc.claim-rewards'
              subtitle='producer_acc.claim-rewards-notice'
              action={
                <Button
                  btnClass='with-bg blue'
                  title='producer_acc.claim-rewards-btn'
                  disabled={isFetching}
                  onClick={this.openClaimModal}
                />
              }
            />
          )}

          {selectedTab === 0 ? (
            <Table
              sort={this.sortCandidates}
              tableWrapperClass={classNames('relative extended-table min-height', {'single-action': !isBeforeStartComp})}
              data={{columns: this.candidatesColumnsExtended, rows: candidates}}
            />
          ) : (
            <Table tableWrapperClass='relative assistances-table' data={{columns: assistancesColumns, rows: assistances}} />
          )}
        </div>
      </div>
    );
  }
}
