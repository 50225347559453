import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class TableControls extends Component {
  onClick = () => {
    const {value, title, onClick} = this.props;
    onClick && onClick(value || title);
  };

  render() {
    const {active, title, tabClass} = this.props;
    return (
      <div onClick={this.onClick} className={classNames('control-btn', {'active-btn': active}, tabClass)}>
        {this.props.children ? this.props.children : <I18n tKey={title} />}
      </div>
    );
  }
}
