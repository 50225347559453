import React, {Component} from 'react';

export class NumberConverter extends Component {
  render() {
    const {value, unitCount, course, converterFunc} = this.props;
    let convertedValue = converterFunc ? converterFunc(value, course) : value;
    return (
      <div className='number-converter'>
        <span>&#8776; </span>
        <span>{convertedValue} </span>
        <span>{unitCount}</span>
      </div>
    );
  }
}
