import React, {Component} from 'react';
import moment from 'moment';

import {I18n} from '../i18n/I18n';
import {ReactComponent as ClockIcon} from '../../../img/clock.svg';
import './index.scss';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60000;
const ONE_HOUR = 3600000;
const ONE_DAY = 86400000;
// const oneWeek = 604800000;

export class Timer extends Component {
  intervalId = null;

  isTimeOnly = true;

  localeData = moment.localeData();

  constructor(props) {
    super(props);
    this.state = {
      time: moment(props.endDateTime),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidUpdate = prevProps => {
    const {endDateTime} = this.props;
    if (prevProps.endDateTime !== endDateTime) {
      clearInterval(this.intervalId);
      this.setState({time: moment(endDateTime)});
      this.setTimer();
    }
  };

  componentDidMount = () => {
    this.setTimer();
  };

  setTimer = () => {
    const {endDateTime, endTimeCallback} = this.props;
    const endDateTimeMoment = moment(endDateTime);
    // const diff = endDateTimeMoment.diff(moment());
    // const isTimeOnly = diff < ONE_DAY * 2;
    // this.isTimeOnly = isTimeOnly;

    const intervalId = setInterval(
      () => {
        if (this.isTimeOnly) {
          const difference = endDateTimeMoment.diff(moment());
          if (difference < 1000) {
            clearInterval(this.intervalId);
            endTimeCallback();
          }
          this.setState({
            time: moment('2021-09-30T00:00:00').add(difference, 'ms'),
            days: Math.trunc(difference / ONE_DAY),
            hours: Math.trunc((difference % ONE_DAY) / ONE_HOUR),
            minutes: Math.trunc(((difference % ONE_DAY) % ONE_HOUR) / ONE_MINUTE),
            seconds: Math.trunc((((difference % ONE_DAY) % ONE_HOUR) % ONE_MINUTE) / ONE_SECOND),
          });
          return;
        }

        this.setState({
          time: moment(endDateTime),
        });
      },
      this.isTimeOnly ? 1000 : ONE_HOUR
    );

    this.intervalId = intervalId;
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  render() {
    const {time, days, hours, minutes, seconds} = this.state;
    const {title} = this.props;

    return (
      <div className='timer-wrapper'>
        <ClockIcon className='timer-clock-icon' />
        <I18n
          value={
            this.isTimeOnly
              ? `
              ${this.localeData.relativeTime(days, true, 'dd', false)} 
              ${this.localeData.relativeTime(hours, true, 'hh', false)} 
              ${this.localeData.relativeTime(minutes, true, 'mm', false)} 
              ${this.localeData.relativeTime(seconds, true, 'ss', false)}`
              : time.toNow(true)
          }
          tKey={title || 'timer.left-time'}
        />
      </div>
    );
  }
}
