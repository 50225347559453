import React, {Component} from 'react';
import {withRouter} from 'react-router';

import {ReactComponent as SearchIcon} from '../../../img/search_icon.svg';

import {Input} from '../input/Input';
import {translate} from '../../../utils/translater';
import classNames from 'classnames';

@withRouter
export class SearchBox extends Component {
  state = {
    searchValue: '',
  };

  onChange = (name, val) => {
    this.setState({[name]: val});
  };
  onKeyDown = ev => {
    if (ev.which === 13 || ev.keyCode === 13) {
      this.state.searchValue && this.detectSearchType(this.state.searchValue);
    }
  };
  iconClick = () => {
    this.state.searchValue && this.detectSearchType(this.state.searchValue);
  };

  detectSearchType = value => {
    if (value.length >= 64) {
      this.props.history.push({
        state: {searchValue: this.state.searchValue},
        pathname: `/transactions/${value}`,
      });
    } else if (/^\d+$/.test(value)) {
      this.props.history.push({
        state: {searchValue: this.state.searchValue},
        pathname: `/blocks/${value}`,
      });
    } else if (value.length && value[0] !== '#') {
      this.props.history.push({
        state: {searchValue: this.state.searchValue},
        pathname: `/accounts/${value}`,
      });
    }
  };

  render() {
    const {searchValue} = this.state;
    const {placeholder} = this.props;
    return (
      <div className={classNames('search-block', {'active-search': searchValue})}>
        <Input allowClear value={searchValue} name='searchValue' onFocus={this.onFocus} onKeyDown={this.onKeyDown} onChange={this.onChange} placeholder={translate(placeholder)} />
        <SearchIcon onClick={this.iconClick} />
      </div>
    );
  }
}
