import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import BigNumber from 'bignumber.js';

import cruIcon from '../../../img/new_cru.png';
import wcruIcon from '../../../img/new_wcru.png';
import untbIcon from '../../../img/new_untb.png';
import usducon from '../../../img/new_usdu.png';

import {MSG_TYPES, MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';
import HEADER_TYPES, {ModalHeader} from '../walletDividends/ModalHeader';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {DropDown} from '../../helpers/dropDown/DropDown';
import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';

import {EnterPin} from '../../modals/enterPin/EnterPin';
import {changeFormat, getTokenIcon} from '../../../utils/utils';
import {getRoundedRegExp, TOKENS} from '../../../config/const';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const formatOptionLabel = data => (
  <div className='select-row'>
    <img src={data.icon} alt='token-icon' className='token-icon' />
    <div className='label-row'>
      <span className='label'> {data.label} </span>
      <span className='subtitle'>
        <I18n tKey={data.subtitle} />
      </span>
    </div>
  </div>
);

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class WalletSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.detectAmountRounding((this.props.match.params.token || '').toUpperCase()),
      receiver: '',
      memo: '',
      operationInProgress: false,
      token: '',
      errors: {},
      tokens: [],
    };
  }

  componentDidMount = async () => {
    this.setState({isTokensFetching: true});
    const {userStore} = this.props;

    const tokens = Object.keys(userStore?.userWallet || {}).map(token => {
      switch (token) {
        case 'UNTB':
          return {value: TOKENS.UNTB, label: TOKENS.UNTB, subtitle: 'dropdown.untb_title', icon: untbIcon};
        case 'CRU':
          return {value: TOKENS.CRU, label: TOKENS.CRU, subtitle: 'dropdown.cru_title', icon: cruIcon, className: 'custom-class'};
        case 'WCRU':
          return {value: TOKENS.WCRU, label: TOKENS.WCRU, subtitle: 'dropdown.wcru_title', icon: wcruIcon};
        case 'USDU':
          return {value: TOKENS.USDU, label: TOKENS.USDU, subtitle: 'dropdown.usdu_title', icon: usducon};
        default:
          return {value: token, label: token, subtitle: userStore.userWallet[token].fullName, icon: getTokenIcon(token)};
      }
    });

    this.setState({
      tokens,
      token: this.detectTokenType(tokens),
      isTokensFetching: false,
    });
  };

  detectTokenType = options =>
    this.props.match.params.token ? options.find(obj => obj.value === this.props.match.params.token.toUpperCase()) : options[0];

  onChange = (propName, value) => {
    const newState = {[propName]: value};
    this.state.errors[propName] && (newState.errors = {...this.state.errors, [propName]: false});
    this.setState(newState);
  };

  detectAmountRounding = token => {
    const {userStore} = this.props;
    const rounding = userStore.userWallet[token]?.rounding || 4;

    return '0.'.padEnd(2 + rounding, '0');
  };

  selectOption = token => {
    this.props.history.replace(`/wallet/send/${token.value.toLowerCase()}`);
    const newState = {token};
    newState.amount = this.detectAmountRounding(token.value);
    this.setState(newState);
  };

  makeTransfer = pin => {
    const {receiver, amount, token, memo} = this.state;
    const {loggerStore, userStore, eosStore} = this.props;
    this.setState({operationInProgress: true});
    const quantity = `${amount} ${token.value}`;
    eosStore
      .makeTransaction({quantity, sender: userStore.user.name, receiver, memo, pin, errorMsg: 'errors.send.tokens'})
      .then(r => {
        const newState = {operationInProgress: false};
        if (!r.isError) {
          userStore.getAccountInfo(); // todo process error
          newState.amount = this.detectAmountRounding(token.value);
          newState.memo = '';
          newState.receiver = '';
        }
        this.setState(newState);
      });
  };

  onInputBlur = () => {
    const {token, amount} = this.state;
    const {userStore} = this.props;
    const rounding = userStore.userWallet[token.value]?.rounding || 4;

    const splitedAmount = amount.split('.');
    const defaultAmount = `${+splitedAmount[0] || 0}.${(splitedAmount[1] || '').padEnd(rounding, '0')}`;

    defaultAmount && this.setState({amount: defaultAmount});
  };

  sendAllTokens = () => {
    const formatSettings = {
      decimalSeparator: '.',
      groupSeparator: '',
      groupSize: 0,
      secondaryGroupSize: 0,
      fractionGroupSeparator: '',
      fractionGroupSize: 0,
    };

    // todo refactor
    const {userStore} = this.props;
    const {token} = this.state;

    let balance = userStore.userWallet[token.value].available;
    if (token.value === TOKENS.USDU || !Object.keys(TOKENS).includes(token.value)) {
      balance = userStore.userWallet[token.value]?.total !== '—' ? userStore.userWallet[token.value]?.total || 0 : 0;
    }

    this.setState({amount: changeFormat(balance, userStore.userWallet[token.value].rounding || 4, formatSettings)});
  };

  openPinModal = () => {
    const {receiver, memo, token} = this.state;
    const {modalStore} = this.props;
    const errors = {};
    const fields = {receiver};
    const params = {className: 'sending', headerLabelText: 'common.you_sending', value: this.state.amount};
    const additionalClass = 'sending-content';

    Object.keys(fields).forEach(key => {
      !fields[key] && (errors[key] = true);
    });
    if (Object.keys(errors).length) {
      this.setState({errors});
    } else {
      modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
        <EnterPin
          isOpen={isOpen}
          closeModal={closeModal}
          onOk={onOk}
          onCancel={onCancel}
          params={{onValidPin: this.makeTransfer, pinContentClass: additionalClass}}
          operation={HEADER_TYPES.SENDING}
        >
          <ModalHeader
            operation={HEADER_TYPES.SENDING}
            params={params}
            memo={memo}
            receiver={receiver}
            token={token.value}
            walletSend
          />
        </EnterPin>
      ));
    }
  };

  render() {
    const {receiver, memo, errors, token, amount, tokenCard, operationInProgress, tokens, isTokensFetching} = this.state;
    const {userStore} = this.props;
    const {userWallet, user} = userStore;

    const toYourself = receiver === user.name;
    const zeroTransaction = +amount === 0;
    const rounding = userWallet[token.value]?.rounding || 4;

    let balance = new BigNumber(userWallet?.[token.value]?.available).toNumber() || 0;
    if (token.value === TOKENS.USDU || !Object.keys(TOKENS).includes(token.value)) {
      balance = userWallet[token.value]?.total !== '—' ? userWallet[token.value]?.total || 0 : 0;
    }

    return (
      <div className='wallet-send nested-route margin'>
        <div className='transfer-block'>
          <I18n tKey='wallet_send.title' className='title-page' />
          <div className='wallet-content'>
            <div className='inputs-block'>
              <InputRow
                wrapperClass='input-send'
                title='wallet_send.send_title'
                titleRow='wallet_send.balance'
                label='wallet_send.label_amount'
                name='amount'
                regEx={getRoundedRegExp(rounding)}
                round={rounding}
                token={token.value}
                countBalance={balance}
                onBlur={this.onInputBlur}
                inputChange={this.onChange}
                sendAllTokens={this.sendAllTokens}
                value={amount}
                sendAll
                clickAll
                dividedView
                onlyNumbers
                maxAmount={balance}
                // placeholder={token.value === TOKENS.UNTB ? "1.0000" : "1"}
              />
              <DropDown
                disabled={isTokensFetching}
                wrapperClass='react-select-container'
                onChange={this.selectOption}
                value={token}
                options={tokens}
                selectedOption={tokenCard}
                formatOptionLabel={formatOptionLabel}
              />
            </div>
            <InputRow
              wrapperClass='input-transfer'
              subtitle='wallet_send.label_subtitle'
              title='wallet_send.label_title'
              placeholder='wallet_send.send_placeholder'
              subtitleInput='wallet_send.input_check'
              name='receiver'
              error={errors.receiver}
              statusBorder
              value={receiver}
              inputChange={this.onChange}
            />
            <InputRow
              wrapperClass='input-transfer'
              subtitle='wallet_send.memo_subtitle'
              title='wallet_send.memo_label'
              placeholder='wallet_send.memo_placeholder'
              subtitleInput='wallet_send.memo_reminder'
              name='memo'
              value={memo}
              inputChange={this.onChange}
              statusBorder
              maxLength={256}
            />
            <Button
              disabled={operationInProgress || !receiver || toYourself || zeroTransaction}
              onClick={this.openPinModal}
              title='wallet_page.btn_send'
              btnClass='with-bg blue'
            />
            {toYourself && <MsgPrinter msgType={MSG_TYPES[1]} msg='common.cant_send' />}
          </div>
        </div>
      </div>
    );
  }
}
