import React from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export const StatisticRow = ({title, count, unit}) => (
  <div className='statistic-row'>
    <I18n className='row-title' tKey={title} />
    <span className='dots' />
    <span>{count} </span>
    <span>{unit}</span>
  </div>
);
