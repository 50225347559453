import React, {Component} from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import {inject, observer} from 'mobx-react';

import {ReactComponent as MainLogo} from '../../../img/cryptounit_logo.svg';

import {LinkList} from '../../mainPages/header/LinkList';
import {MenuBtn} from '../../helpers/menuBtn/MenuBtn';

const FormatOptionLabel = ({name, iso}) => (
  <div className='select-row'>
    <div className={`flag  flag-${iso}`} />
    {name && <span className='label'> {name} </span>}
  </div>
);

Modal.setAppElement('#root');
@inject('localizeStore')
@observer
export class MobileMenu extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.querySelector('#root').style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
    document.querySelector('#root').style.overflow = 'initial';
  }
  changeLng = data => {
    this.props.localizeStore.changeLng(data);
  };

  render() {
    const {languagesList, selectedLng} = this.props.localizeStore;
    const {isOpen, closeModal} = this.props;
    const className = classNames('mobile-modal-overlay');
    return (
      <Modal overlayClassName={className} className='modal-menu' isOpen={isOpen}>
        <div className='modal-menu-content'>
          <div className='header-modal'>
            <MenuBtn isOpen />
            <MainLogo />
          </div>
          <LinkList
            onClick={closeModal}
            class='select-lng-list'
            onChange={this.changeLng}
            value={selectedLng}
            options={languagesList}
            formatOptionLabel={FormatOptionLabel}
          />
        </div>
        <div className='click-block' onClick={closeModal} />
      </Modal>
    );
  }
}
