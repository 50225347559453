import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {Table} from '../../helpers/table/Table';
import {Pagination} from '../../helpers/pagination/Pagination';
import {getAccountTable} from '../../../utils/requester';
import {TIME_FORMAT} from '../../../config/const';

const tableData = {
  columns: [
    {name: 'common.table.col_id', key: 'id'},
    {name: 'common.table.col_amount', key: 'amount'},
    {name: 'common.table.col_withdrawed', key: 'withdrawed'},
    {name: 'common.table.col_algoritm', key: 'algorithm'},
    {name: 'common.table.col_distr', key: 'last_distribution_at', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
    {name: 'common.table.col_create', key: 'created', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
  ],
};

export class LockHistory extends Component {
  state = {
    locks: [],
    limit: 10,
    page: 0,
    more: false,
    upperBound: null,
    loverBound: null,
  };

  handleNext = () => {
    let ub = null;
    if (this.state.locks.length > 0) {
      ub = this.state.locks[this.state.locks.length - 1].id - 1;
    }
    this.setState({upperBound: ub, loverBound: null, page: ++this.state.page}, this.getLocks);
  };

  handlePrev = () => {
    let ub = null;
    if (this.state.locks.length > 0) {
      ub = this.state.locks[0].id + 1;
    }
    this.setState({upperBound: null, loverBound: ub, page: 0}, this.getLocks);
  };

  getLocks() {
    const requestData = {
      json: true,
      code: 'tokenlock',
      reverse: true,
      scope: this.props.accountName,
      table: 'locks',
      limit: this.state.limit,
      lower_bound: this.state.lowerBound,
      upper_bound: this.state.upperBound,
    };
    this.setState({isFetching: true});
    getAccountTable(requestData).then(r => {
      const st = {locks: [...this.state.locks], more: false, isFetching: false};
      if (!r.isError) {
        st.locks = r.data.rows;
        st.more = r.data.more;
      }

      this.setState(st);
    });
  }

  componentDidMount() {
    this.getLocks();
  }

  render() {
    return (
      <>
        <Table
          usePreloader
          isFetching={this.state.isFetching}
          data={{...tableData, rows: this.state.locks}}
          tableWrapperClass='lock-history-table'
        />
        <Pagination
          hasNext={this.state.more}
          hasPrev={this.state.page > 0}
          withoutNumbers
          handlePrev={this.handlePrev}
          handleNext={this.handleNext}
        />
      </>
    );
  }
}
