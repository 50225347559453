import React, {Component} from 'react';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import {ReactComponent as SendIcon} from '../../../img/send_arrow.svg';
import {ReactComponent as StakeIcon} from '../../../img/stake_arrow.svg';
import {ReactComponent as ExchangeIcon} from '../../../img/exchange_arrow.svg';

import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {DataRow} from './DataRow';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {NumberConverter} from '../../helpers/numberConverter/NumberConverter';
import {TOKENS} from '../../../config/const';

const tokensWithInfo = ['CRU', 'WCRU', 'UNTB'];

@withRouter
@inject('userStore', 'eosStore', 'modalStore')
export class WalletCardToken extends Component {
  goToSendPage = () => {
    const {token} = this.props;
    this.props.history.push(`/wallet/send/${token.toLowerCase()}`);
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.returnDebt});
  };

  returnDebt = async pin => {
    const {userStore, eosStore, debts} = this.props;
    const userName = userStore.user.name;

    for (let i = 0; i < debts.length; i += 1) {
      await eosStore.returnDebt(userName, debts[i].target, debts[i].debt, debts[i].memo, pin);
    }

    userStore.getAccountInfo();
  };

  goToConvertPage = () => {
    this.props.history.push('/wallet/convert');
  };

  goToStakePage = token => {
    if (token === TOKENS.CRU) {
      this.props.history.push('/wallet/staking/cru');
    } else if (token === TOKENS.UNTB) {
      this.props.history.push('/wallet/staking/untb');
    } else if (token === TOKENS.WCRU) {
      this.props.history.push('/wallet/staking/wcru');
    } else if (token === 'cru-for-cru') {
      this.props.history.push('/wallet/staking/cru-for-cru');
    }
  };

  toggleFavourite = () => {
    const {toggleFavourite, token} = this.props;

    toggleFavourite(token);
  };

  render() {
    const {
      fullTitle,
      cartTitle,
      token,
      tokenInfo,
      cardWrapper,
      fallBackIcon,
      btnStake,
      countToken,
      unitCount,
      converterCount,
      titleBtn,
      redirect,
      cardIcon,
      rounding,
      btnExchange,
      toggleFavourite,
      favourite,
      debts,
    } = this.props;
    // const dividedView = token === TOKENS.UNTB || token === TOKENS.USDU;
    const dividedView = true;
    const defaultRounding = 4;

    return (
      <div className={classNames('wallet-card-token', cardWrapper)}>
        <div className='name-card'>
          {fallBackIcon ? <img className='card-fallback-icon' src={fallBackIcon} alt='' /> : <i className='card-icon' />}
          <p className='title-block'>
            <I18n className='card-title' tKey={cartTitle} />
            <I18n className='full-title' tKey={fullTitle} />
          </p>
          {debts && (
            <Button btnClass='with-bg blue' role='presentation' onClick={this.openPinModal} style={{cursor: 'pointer'}}>
              <I18n
                tKey='wallet_card.return_debt'
                value={`${debts.reduce((sum, {amount}) => sum + amount, 0)} ${debts[0].token}`}
              />
            </Button>
          )}
        </div>
        <div className='data-card'>
          <I18n tKey='home.supply_block.col_total_token' value={token} className='total-title' />
          {cardIcon && <img className='card-sm-icon' src={cardIcon} alt='' />}
          <CountWithZero dividedView={dividedView} round={rounding || defaultRounding} count={countToken} />
          {converterCount && <NumberConverter unitCount={unitCount} value={converterCount} />}
          <div className='data-row-block'>
            <DataRow keysToShow={!tokensWithInfo.includes(token) && ['available', 'frozen']} token={token} data={tokenInfo} />
          </div>
        </div>
        <div className='btn-list'>
          {/* {btnExchange && ( */}
          {/*  <div className='wrapper-btn'> */}
          {/*    <Button ArrowIcon={ExchangeIcon} btnClass='circle-btn exchange-btn' onClick={this.goToConvertPage} /> */}
          {/*    <I18n className='title-btn' tKey='wallet_page.btn_exchange' /> */}
          {/*  </div> */}
          {/* )} */}
          {toggleFavourite && (
            <div className='wrapper-btn'>
              <Button
                ArrowIcon={StarBorderIcon}
                btnClass={classNames('circle-btn like-btn ', {favourite})}
                onClick={this.toggleFavourite}
              />
            </div>
          )}
          {btnStake && (
            <div className='wrapper-btn'>
              <Button
                ArrowIcon={StakeIcon}
                btnClass='circle-btn stake-btn'
                onClick={redirect || this.goToStakePage.bind(this, token)}
              />
              <I18n className='title-btn' tKey='wallet_page.btn_stake' />
            </div>
          )}
          <div className='wrapper-btn'>
            <Button ArrowIcon={SendIcon} btnClass='circle-btn send-btn' onClick={this.goToSendPage} />
            <I18n className='title-btn' tKey='wallet_page.btn_send' />
          </div>
        </div>
      </div>
    );
  }
}
