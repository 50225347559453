import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {I18n} from '../../helpers/i18n/I18n';
import {Blockchain} from '../../helpers/blockchain/Blockchain';

import {CountTxtRow} from '../../helpers/countTxtRow/CountTxtRow';
import {changeFormat, getTokenIcon} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';

const tokensWithPage = ['CRU', 'WCRU', 'UNTB', 'USDU'];

@inject('tokenStore')
@observer
export class SwitchToken extends Component {
  getTotalCirculating = tokenName => {
    const {tokens, reservedData} = this.props.tokenStore;

    return tokenName === TOKENS.CRU || tokenName === TOKENS.WCRU
      ? tokens[tokenName].supply - reservedData[tokenName]
      : tokens[tokenName].supply;
  };

  setActiveToken = token => {
    if (tokensWithPage.includes(token)) {
      this.props.tokenStore.setActiveToken(token);
      this.props.history.push('/');
    }
  };

  render() {
    const {tokenStore} = this.props;
    const {activeToken, tokens} = tokenStore;

    return (
      <div className='block-container'>
        <div className='top-block'>
          <I18n className='main-title md title-top-block' tKey='card_block.title' />
          <div className='tokens-table-wrapper'>
            <table className='tokens-table'>
              {Object.keys(tokens).map(tokenName => {
                const isTokenActive = activeToken === tokenName;

                return (
                  <tr
                    onClick={this.setActiveToken.bind(this, tokenName)}
                    className={tokensWithPage.includes(tokenName) && 'with-pointer'}
                  >
                    <td>
                      <div className='logo-wrapper'>
                        {isTokenActive && <div className='selected-row-marker' />}
                        <img alt={tokenName} src={getTokenIcon(tokenName)} className='card-icon cru-icon' />
                      </div>
                    </td>
                    <td>
                      <I18n className='tok-title' tKey={tokenName} />
                    </td>
                    <td>
                      <CountTxtRow
                        count={
                          changeFormat(tokenName === TOKENS.CRU ? tokens[tokenName].supply : tokens[tokenName].max_supply, 0) || 0
                        }
                        title={tokenName === TOKENS.CRU ? 'card_tokens.col_supply' : 'card_tokens.col_max'}
                      />
                    </td>
                    <td>
                      <CountTxtRow
                        count={
                          changeFormat(
                            tokensWithPage.includes(tokenName)
                              ? this.getTotalCirculating(tokenName)
                              : tokens[tokenName].supply || 0,
                            0
                          ) || 0
                        }
                        title={
                          tokensWithPage.includes(tokenName)
                            ? `home.supply_block.col_circul.${tokenName}`
                            : 'card_tokens.col_supply'
                        }
                        dotTitle
                        noWrapTitle
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <Blockchain />
      </div>
    );
  }
}
