import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {TableControls} from '../../helpers/tableСontrols/TableControls';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {Button} from '../../helpers/button/Button';
import {Table} from '../../helpers/table/Table';
import {I18n} from '../../helpers/i18n/I18n';

import {changeCurrencyFormat, changeFormat} from '../../../utils/utils';
import {REG_EXP, TIME_FORMAT, TOKENS} from '../../../config/const';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {getEosTable} from '../../../utils/requester';

import HEADER_TYPES, {ModalHeader} from './ModalHeader';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const tableRowClassName = row => (row.active ? 'active-row' : '');

const inputFormatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

const tableColumns = {
  columns: [
    {name: 'common.table.col_id', key: 'id'},
    {name: 'common.table.col_create', key: 'date_created', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
    {name: 'common.table.col_amount_wcru', key: 'wcru_total', parseFunc: value => changeCurrencyFormat(value)},
    {name: 'common.table.col_amount_usdu', key: 'usdu', parseFunc: value => changeCurrencyFormat(value)},
  ],
};

const tabs = [
  {title: 'wallet_usdu.tabs_send', value: 'send'},
  {title: 'wallet_usdu.tabs_mint', value: 'mint'},
  {title: 'wallet_usdu.tabs_burn', value: 'burn'},
];

@inject('eosStore', 'userStore', 'modalStore', 'loggerStore')
@observer
export class WalletDividends extends Component {
  state = {
    mintUsdu: '',
    burnUsdu: '',
    usduDistribution: '',
    memo: '',
    dividendsList: [],
    activeTab: tabs[0].value,
    isFetching: false,
  };

  componentDidMount() {
    this.getDividends();
  }

  getDividends = () => {
    let list = [];
    const request = () => {
      getEosTable({
        code: 'dividend',
        json: true,
        limit: 1000,
        reverse: true,
        scope: 'dividend',
        table: 'distrib',
      }).then(r => {
        if (!r.isError) {
          list = list.concat(r.data.rows);
          if (r.data.more) {
            request();
          } else {
            this.setState({
              dividendsList: list,
            });
          }
        }
      });
    };
    request();
  };

  returnHeaderParams = operation => {
    const {burnUsdu, mintUsdu, usduDistribution} = this.state;

    switch (operation) {
      case 'burning':
        return {className: 'burning', headerLabelText: 'common.you_burning', value: burnUsdu};
      case 'issuing':
        return {className: 'issuing', headerLabelText: 'common.you_issuing', value: mintUsdu};
      case 'sending':
        return {className: 'sending', headerLabelText: 'common.you_sending', value: usduDistribution};
      default:
        return {};
    }
  };

  openPinModal = (isIssueOp, isTransferOp, operation) => () => {
    const {modalStore} = this.props;
    const {memo} = this.state;
    const params = this.returnHeaderParams(operation);
    const additionalClass = operation === HEADER_TYPES.SENDING ? 'sending-content' : '';

    let cb;
    if (isTransferOp) {
      cb = this.makeTransfer;
    } else {
      cb = pin => this.makeUsduOp(isIssueOp, pin);
    }

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{onValidPin: cb, pinContentClass: additionalClass}}
        operation={operation}
      >
        <ModalHeader operation={operation} params={params} memo={memo} />
      </EnterPin>
    ));
  };

  makeTransfer = pin => {
    const {usduDistribution, memo} = this.state;
    const {eosStore, userStore} = this.props;
    const user = userStore.user.name;
    const receiver = 'dividend';

    this.setState({isFetching: true});
    eosStore.makeTransaction({quantity: `${usduDistribution} ${TOKENS.USDU}`, sender: user, receiver, memo, pin}).then(r => {
      this.processError(r, {usduDistribution: ''});

      if (!r.isError) {
        userStore.updateUsdu();
        this.getDividends();
      }
    });
  };

  makeUsduOp = (isIssueOp, pin) => {
    const {burnUsdu, mintUsdu, memo} = this.state;
    const {eosStore, userStore} = this.props;
    const user = userStore.user.name;

    this.setState({isFetching: true});
    eosStore.makeUsduOp(`${isIssueOp ? mintUsdu : burnUsdu} ${TOKENS.USDU}`, user, memo, isIssueOp, pin).then(r => {
      this.processError(r, {mintUsdu: '', burnUsdu: ''});
      if (!r.isError) userStore.updateUsdu();
    });
  };

  onInputBlur = () => {
    const {burnUsdu, mintUsdu, usduDistribution} = this.state;
    const values = {burnUsdu, mintUsdu, usduDistribution};
    const defaultAmount = {};

    Object.keys(values).forEach(key => {
      if (values[key]) {
        defaultAmount[key] = changeFormat(values[key], 4, inputFormatSettings);
      }
    });

    Object.keys(defaultAmount).length && this.setState(defaultAmount);
  };

  onChange = (propName, value) => {
    const newState = {[propName]: value};
    this.setState(newState);
  };

  setActiveTab = tabName => () => {
    this.setState({activeTab: tabName});
  };

  processError = (r, defaultState = {}) => {
    // const {loggerStore} = this.props;
    const newState = {
      isFetching: false,
      ...defaultState,
    };
    // defaultState stores default input value which wave to be 0

    // if (!r.isError) {
    //   const loggerResult = {type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'};
    //   loggerStore.addItem(loggerResult);
    // }

    this.setState(newState);
  };

  render() {
    const {mintUsdu, burnUsdu, dividendsList, activeTab, isFetching, usduDistribution, memo} = this.state;
    const {userStore} = this.props;
    const {userWallet} = userStore;

    return (
      <div className='nested-route wallet-dividends margin'>
        <div className='wallet-ram-block'>
          <I18n tKey='wallet_usdu.title' className='title-page' />
          <div className='wallet-content'>
            <div className='top-content'>
              <div className='available-usdu'>
                <I18n className='available-title' tKey='wallet_usdu.available_title' />
                <CountWithZero dividedView round={4} count={userWallet.USDU.total} />
              </div>
              <div className='toggle-tabs'>
                {tabs.map(elem => (
                  <TableControls
                    key={elem.value}
                    onClick={this.setActiveTab(elem.value)}
                    active={elem.value === activeTab}
                    title={elem.title}
                    tabClass='tab-item'
                  />
                ))}
              </div>
              <div
                className={classNames('input-with-btn mint', {
                  'hide-mobile-form': activeTab === tabs[2].value || activeTab === tabs[0].value,
                })}
              >
                <InputRow
                  wrapperClass='input-with-bg'
                  title='wallet_usdu.mint_title'
                  subtitle='wallet_usdu.mint_subtitle'
                  name='mintUsdu'
                  value={mintUsdu}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  statusBorder
                  inputChange={this.onChange}
                  onBlur={this.onInputBlur}
                  placeholder='0.0000'
                  label='USDU'
                />
                <Button
                  disabled={isFetching || !mintUsdu}
                  onClick={this.openPinModal(true, false, HEADER_TYPES.ISSUING)}
                  btnClass='with-bg green-bg'
                  title='wallet_usdu.btn_issue'
                />
              </div>
              <div
                className={classNames('input-with-btn', {
                  'hide-mobile-form': activeTab === tabs[1].value || activeTab === tabs[0].value,
                })}
              >
                <InputRow
                  wrapperClass='input-with-bg amount-input'
                  title='wallet_usdu.burn_title'
                  subtitle='wallet_usdu.burn_subtitle'
                  name='burnUsdu'
                  value={burnUsdu}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  statusBorder
                  inputChange={this.onChange}
                  onBlur={this.onInputBlur}
                  placeholder='0.0000'
                  label='USDU'
                />
                <Button
                  disabled={isFetching || !burnUsdu}
                  onClick={this.openPinModal(false, false, HEADER_TYPES.BURNING)}
                  btnClass='with-bg red-bg'
                  title='wallet_usdu.btn_retire'
                />
              </div>
            </div>
            <div
              className={classNames('center-content', {
                'hide-mobile-form': activeTab === tabs[1].value || activeTab === tabs[2].value,
              })}
            >
              <I18n tKey='wallet_usdu.send_title' className='title-page' />
              <div className='input-with-btn send'>
                <InputRow
                  wrapperClass='input-with-bg usdu-input'
                  title='wallet_usdu.send_amount_title'
                  subtitle='wallet_usdu.send_amount_subtitle'
                  name='usduDistribution'
                  value={usduDistribution}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  statusBorder
                  inputChange={this.onChange}
                  onBlur={this.onInputBlur}
                  placeholder='0.0000'
                />
                <Button
                  disabled={isFetching || !usduDistribution}
                  onClick={this.openPinModal(null, true, HEADER_TYPES.SENDING)}
                  btnClass='with-bg blue'
                  title='wallet_usdu.btn_send'
                />
              </div>
              <div className='input-with-btn send'>
                <InputRow
                  wrapperClass='input-with-bg usdu-input'
                  title='wallet_usdu.send_amount_memo'
                  subtitle='wallet_usdu.send_amount_memo_subtitle'
                  name='memo'
                  value={memo}
                  statusBorder
                  inputChange={this.onChange}
                  placeholder='Memo'
                />
              </div>
            </div>

            <div className='bottom-content'>
              <I18n tKey='wallet_dividends.title' className='title-page' />
              <Table
                data={{...tableColumns, rows: dividendsList}}
                tableClass='transactions-table'
                returnClass={tableRowClassName}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
