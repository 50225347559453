import React, {Component} from 'react';

import {I18n} from '../../helpers/i18n/I18n';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {CircleProgress} from '../../helpers/circleProgress/CircleProgress';

export class ScheduleRow extends Component {
  render() {
    const {days, date, countTotal, countUnfreeze, progress, onClick} = this.props;
    return (
      <div className='schedule-row' onClick={onClick}>
        <div className='left-block'>
          <div className='date-count-block'>
            <span className='date'>{date}</span>
            <div className='total-amount'>
              <CountWithZero round={4} count={countTotal} />
              <span> CRU</span>
            </div>
          </div>
          <span className='unfreeze-title-row'>
            <I18n tKey='convert_wcru.unfreeze_title' className='unfreeze-title' />
            &#58;
          </span>
          <div className='unfreeze-count'>
            <CountWithZero round={4} count={countUnfreeze} dividedView />
            <span> CRU</span>
          </div>
        </div>
        <div className='right-block'>
          <div className='info-unfreeze'>
            <I18n tKey='modal_schedule.full_unfreeze' className='txt-info' />
            <span>
              {days} <I18n tKey='common.days' />
            </span>
          </div>
          <CircleProgress radius={26} stroke={3} circleClass='unfreeze-status cpu-class' progress={progress} />
        </div>
      </div>
    );
  }
}
