import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

import {CountWithZero} from '../countWithZero/CountWithZero';

export class BalanceRow extends Component {
  render() {
    const {countBalance, round, dividedView, separator, sendAllTokens, token, titleRow} = this.props;
    return (
      <div className={classNames('balance-row', this.props.className)}>
        <I18n tKey={titleRow} />
        <span>&#58; </span>
        <CountWithZero round={round} dividedView={dividedView} separator={separator} count={countBalance} />
        {/*<span className="token-name"> {token}</span>*/}
        <I18n tKey={token} className='token-name' />
        {sendAllTokens && <I18n tKey='wallet_send.send_all' onClick={sendAllTokens} className='link-see-all' />}
      </div>
    );
  }
}
