import React, {Component} from 'react';
import classNames from 'classnames';
import {ReactComponent as Ok} from '../../../img/toasterItem/ok.svg';
import {ReactComponent as Info} from '../../../img/toasterItem/info.svg';
import {ReactComponent as Error} from '../../../img/toasterItem/error.svg';
import {ReactComponent as Warning} from '../../../img/toasterItem/warning.svg';
import cross from '../../../img/toasterItem/cross.png';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {I18n} from '../../helpers/i18n/I18n';

const infoIcons = {
  OK: Ok,
  ERROR: Error,
  WARNING: Warning,
  INFO: Info,
};

export class ToasterItem extends Component {
  timerLink = null;

  componentDidMount() {
    this.timerLink = setTimeout(this.props.destroyCb, 12000);
  }

  removeItem = () => {
    clearTimeout(this.timerLink);
    this.props.destroyCb();
    this.timerLink = null;
  };

  render() {
    const {type, txt, parsedError} = this.props;
    const Icon = infoIcons[type] || null;
    const className = classNames('logger-item', {
      info: type === LOGGER_TYPES[3],
      ok: type === LOGGER_TYPES[0],
      warn: type === LOGGER_TYPES[2],
      err: type === LOGGER_TYPES[1],
    });
    return (
      <div className={className}>
        <Icon />
        <div>
          <I18n tKey={txt} />
          {parsedError && (
            <>
              <br /> {parsedError}
            </>
          )}
        </div>
        <img onClick={this.removeItem} alt='cross ic' src={cross} />
      </div>
    );
  }
}
