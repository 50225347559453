import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as DotsIcon} from '../../../img/Union.svg';
import {I18n} from '../../helpers/i18n/I18n';

const validName = typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_VALID_NAME : process.env.REACT_APP_VALID_NAME;
const adminName = typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_ADMIN_NAME : process.env.REACT_APP_ADMIN_NAME;

export const LinkListWallet = ({userName, isProducer, onClick}) => (
  <div className='nav-bar'>
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet'>
      <I18n tKey='wallet_tabs.dashboard' />
    </NavLink>
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/convert'>
      <I18n tKey='wallet_cru.convert' />
    </NavLink>
    {(validName === userName || adminName === userName) && (
      <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/convert-rates'>
        <I18n tKey='wallet_tabs.convert_rates_tab' />
      </NavLink>
    )}
    <NavLink className='nav-item' activeClassName='nav-active' to='/wallet/send'>
      <I18n tKey='wallet_tabs.send_tab' />
    </NavLink>
    <NavLink className='nav-item' activeClassName='nav-active' to='/wallet/staking'>
      <I18n tKey='wallet_tabs.staking_tab' />
    </NavLink>
    {validName === userName && (
      <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/dividends-usdu'>
        <I18n tKey='wallet_tabs.usdu_tab' />
      </NavLink>
    )}
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/dividends'>
      <I18n tKey='wallet_tabs.dividends_tab' />
    </NavLink>

    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/business-account'>
      <I18n tKey='wallet_tabs.business_account' />
    </NavLink>
    {/* <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/issue-plan'>
      <I18n tKey='wallet_tabs.issue_plan_tab' />
    </NavLink> */}
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/limits'>
      <I18n tKey='wallet_tabs.limits' />
    </NavLink>

    {/* {isProducer && ( */}
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/producer'>
      <I18n tKey='wallet_tabs.producer_tab' />
    </NavLink>
    {/* )} */}
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/ram'>
      <I18n tKey='wallet_tabs.ram_tab' />
    </NavLink>
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/vote'>
      <I18n tKey='wallet_tabs.vote_tab' />
    </NavLink>
    <NavLink exact className='nav-item' activeClassName='nav-active' to='/wallet/history'>
      <I18n tKey='wallet_tabs.trx_tab' />
    </NavLink>
    {document.documentElement.clientWidth > 900 && (
      <NavLink exact className='nav-item' activeClassName='nav-active' onClick={onClick} to='/wallet/auth'>
        <I18n tKey='wallet_tabs.logout' />
      </NavLink>
    )}
    <div className='dropdown'>
      {document.documentElement.clientWidth < 900 && (
        <p className='dots-icon'>
          <DotsIcon />
        </p>
      )}
      <div className='dropdown-content'>
        <span className='nav-item logout-btn' onClick={onClick}>
          <I18n tKey='wallet_tabs.logout' />
        </span>
      </div>
    </div>
  </div>
);
