import React, {Component} from 'react';
import classNames from 'classnames';

export class StatusLine extends Component {
  state = {
    width: 0,
  };

  componentDidMount() {
    setTimeout(() => this.setState({width: this.props.size}));
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({width: nextProps.size});
  }

  render() {
    const {lineClass, percent, totalCount, countLine, title} = this.props;
    return (
      <div className={classNames('lines-wrapper', lineClass)}>
        <div className='line-header'>
          {title && <span className='line-title'>{title} </span>}
          <span className='percent'>{percent} </span>
          {countLine && (
            <p className='count-info'>
              <span className='count-line'> {countLine} &#47; </span>
              <span className='count-line'> {totalCount}</span>
            </p>
          )}
        </div>
        <div className='lines-container'>
          <div className='inactive-line' />
          <div className='active-line' style={{width: this.state.width}} />
        </div>
      </div>
    );
  }
}
