import React from 'react';
import {EOS_ACTION_TYPES} from '../../../config/const';
import {I18n} from '../i18n/I18n';
import {getTokenPrefix} from '../../../utils/utils';

export const OperationName = ({obj, currentUser}) => {
  if (obj.action_name === 'transfer') {
    let actionName;
    if (obj.token_to === currentUser) {
      actionName = 'action.label.receive';
    } else if (obj.token_to === 'staker') {
      actionName = 'action.label.stake';
    } else {
      actionName = 'action.label.sent';
    }
    return (
      <span>
        <I18n tKey={actionName} /> {obj.symbol}
      </span>
    );
  }
  return (
    <span>
      <I18n tKey={EOS_ACTION_TYPES.get(obj.action_name) || obj.action_name} />
      {(obj.action_name === 'stake' || obj.action_name === 'unstake') && obj.symbol}
      {(obj.action_name === 'issue' || obj.action_name === 'retire') && ` ${getTokenPrefix(obj.action_data.quantity)}`}
    </span>
  );
};
