import {localizeStore} from '../index';
// import dictionaryLocal from '../config/dictionary.yml';

function keyKeeper(input, props) {
  return input.map(x => exctractByProps(x, props));
}

function exctractByProps(obj, props) {
  return props.reduce((acc, prop) => (typeof acc === 'object' && prop in acc ? acc[prop] : undefined), obj);
}

export const translate = key => {
  const dictionary = localizeStore.dictionaryList || {};
  if (typeof key !== 'string') {
    return key;
  }
  if (!dictionary[key]) {
    // if remote dictionary is empty , look for translate in local file
    // const path = key.split('.');
    // let level = dictionaryLocal;
    // for (let bk of path) {
    //   if (!level[bk]) {
    //     level = null;
    //     break;
    //   }
    //   level = level[bk];
    // }
    // if (typeof level !== 'string') {
    //   return key;
    // }
    // return level || key;
    return key;
  }
  if (typeof dictionary[key] !== 'string') {
    return key;
  }
  return dictionary[key];
};
