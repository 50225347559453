import React, {Component} from 'react';
import Modal from 'react-modal';

import {ReactComponent as CloseIcon} from '../../../img/close_icon.svg';
import {ReactComponent as ArrowIcon} from '../../../img/arrow_back.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {ScheduleRow} from './ScheduleRow';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {CircleProgress} from '../../helpers/circleProgress/CircleProgress';
import {Table} from '../../helpers/table/Table';

const tableData = {
  columns: [
    {name: 'modal_schedule.col_mo', key: 'num'},
    {name: 'modal_schedule.col_date', key: 'date'},
    {name: 'modal_schedule.col_amount', key: 'CRUAmount'},
    {name: 'modal_schedule.col_rate', key: 'rate'},
    {name: 'modal_schedule.col_progress', key: 'progress'},
  ],
};

export class UnfreezeSchedule extends Component {
  state = {
    modalOpen: false,
    currentObj: null,
    rowsObj: [],
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
  }

  toggleContractDetails = obj => {
    const newState = {currentObj: obj, rowsObj: []};
    if (obj) {
      newState.rowsObj = obj.details;
    }
    this.setState(newState);
  };

  returnRowClass = row => (row.additionalClass ? 'paid-out' : '');

  render() {
    const {isOpen, closeModal} = this.props;
    const {data} = this.props.params;
    const {showDetail, currentObj, rowsObj} = this.state;
    return (
      <Modal className='modal-schedule' isOpen={isOpen}>
        <div className='header-modal'>
          {currentObj && <ArrowIcon onClick={this.toggleContractDetails.bind(this, null)} className='arrow-back' />}
          <I18n className='title-modal' tKey={!showDetail ? 'modal_schedule.title' : 'modal_schedule.details'} />
          <CloseIcon onClick={closeModal} className='arrow-close' />
        </div>
        {!currentObj ? (
          <div className='content'>
            {data.map((obj, i) => (
              <ScheduleRow
                days={obj.daysLeft}
                date={obj.startDate}
                countTotal={obj.amount}
                countUnfreeze={obj.countUnfreeze}
                progress={obj.unfreezePercent}
                onClick={this.toggleContractDetails.bind(this, obj)}
                key={i}
              />
            ))}
          </div>
        ) : (
          <div className='details-block'>
            <div className='top-content'>
              <div className='left-block'>
                <div className='date-count'>
                  <span>{currentObj.startDate}</span>
                  <div>
                    <CountWithZero round={4} count={currentObj.amount} />
                    <span className='name-token'> CRU</span>
                  </div>
                </div>
                <div className='count-cru'>
                  <CountWithZero round={4} count={currentObj.countUnfreeze} dividedView />
                  <span className='name-token'> CRU</span>
                </div>

                <I18n tKey='modal_schedule.left_unfreeze' className='explain-txt' />
              </div>
              <div className='right-block'>
                <CircleProgress radius={15} stroke={4} circleClass='unfreeze-status sm cpu-class' progress={currentObj.unfreezePercent} titleProgress='modal_schedule.unfrozen' />
                <span className='count-days'>
                  {currentObj.daysLeft} <I18n tKey='common.days' />
                </span>
                <I18n tKey='modal_schedule.full_unfreeze' className='explain-txt' />
              </div>
            </div>
            <div className='bottom-content'>
              <Table returnClass={this.returnRowClass} data={{...tableData, rows: rowsObj}} tableClass='details-table' />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
