import React, {Component} from 'react';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';

@inject('modalStore')
export class MenuBtn extends Component {
  state = {
    isOpen: this.props.isOpen,
  };

  toggleModal = () => {
    this.props.modalStore.open(this.props.modalCmp, null, null, null);
    // this.setState({isOpen: !this.state.isOpen})
  };

  render() {
    const className = classNames('menu-btn', {open: this.props.isOpen});
    return (
      <div className='btn-menu-wrapper'>
        <div onClick={this.toggleModal} className={className}>
          <div className='btn-delimiter top' />
          <div className='btn-delimiter mid' />
          <div className='btn-delimiter bot' />
        </div>
      </div>
    );
  }
}
