/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

import {ReactComponent as ConvertIcon} from '../../../img/convert.svg';
import {ReactComponent as Frozen} from '../../../img/frozen.svg';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';

import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import wcruIcon from '../../../img/new_wcru.png';
import cruIcon from '../../../img/new_cru.png';

import {defaultGraphData, TIME_FORMAT, TOKENS, unfreezeDateConst} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {
  changeFormat,
  changeCurrencyToNumber,
  CreateScheduleObj,
  cutTokenPrefix,
  getFrozenPercent,
  getEstMonths,
  getConvertedMonths,
} from '../../../utils/utils';
import {UnfreezeSchedule} from '../../modals/unfreezeSchedule/UnfreezeSchedule';
import HEADER_TYPES, {ModalHeader} from '../walletDividends/ModalHeader';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {Button} from '../../helpers/button/Button';
import {Table} from '../../helpers/table/Table';
import {ConverterInput} from './ConverterInput';
import {I18n} from '../../helpers/i18n/I18n';
import {FrozenTab} from './FrozenTab';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

import {translate} from '../../../utils/translater';
import {ReactComponent as RightArrow} from '../../../img/arrow_back.svg';
import {ReactComponent as CrossIcon} from '../../../img/cross.svg';

import {UserStore} from '../../../stores/UserStore';

const INTERCHANGE_ACC = 'interchange';

@inject('modalStore', 'userStore', 'eosStore', 'loggerStore')
@observer
export class WalletConvert extends Component {
  state = {
    amountToChange: '',
    errors: {},
    CRUWithCommission: 0,
    isFetching: false, // commission fetch flag
    frozenDataIsFEtching: false,
    prevOpIsFetching: false,
    prevOperation: null,
    exchangeIsFetching: false,
    symbolFrozen: false,
    graphData: {...defaultGraphData},
    scheduleData: [],
    freezeObjToConvert: {},
    activeValue: 0,
    sendUSDU: true,
    accountName: '',
    totalBalanceUSDU: '',
    displayAttentionMsg: false,
    leftOvers: [],
    leftOverSum: 0,
  };

  tableColumns = {
    columns: [
      {name: 'common.table.col_create', key: 'date', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
      {name: 'common.table.col_amount', key: 'cru_quantity'},
      {
        name: 'common.table.col_fee',
        key: 'cru_quantity',
        parseFunc: cru => `${changeFormat(this.calculateTransactionFee(changeCurrencyToNumber(cru)))} USDU`,
      },
      {
        render: obj => {
          return (
            <span>
              <Button
                tooltipTitle={translate('common.cancel')}
                ArrowIcon={CrossIcon}
                onClick={() => {
                  this.openCancelModal(obj.id);
                }}
                btnClass='next-btn right-arrow-btn'
              />
            </span>
          );
        },
      },
      {
        render: (obj, total, openRow, openRowFunc, openModal) => {
          // console.log('Start----------');
          // console.log('Calculation for row:', obj.id);
          // console.log('Client calculated fee:', this.calculateTransactionFee(changeCurrencyToNumber(obj.cru_quantity)));
          // console.log('Server calculated fee:', obj.usdu_quantity);
          // console.log('End----------');

          return (
            <span>
              <Button
                ArrowIcon={RightArrow}
                onClick={() => {
                  const transactionFee = this.calculateTransactionFee(changeCurrencyToNumber(obj.cru_quantity));

                  openModal(`${transactionFee} USDU`, obj.hash, obj.id);
                }}
                btnClass='next-btn right-arrow-btn'
              />
            </span>
          );
        },
      },
    ],
  };

  componentDidMount = async () => {
    const {
      userStore: {
        user: {name},
      },
    } = this.props;

    let wcrurat = (await this.getRate('wcrurate')).usdu;
    let crurat = (await this.getRate('crurate')).usdu;

    [, wcrurat = 0] = wcrurat.match(/(\d+(\.?\d+))(.+)?/) || [];
    [, crurat = 0] = crurat.match(/(\d+(\.?\d+))(.+)?/) || [];

    const comissrat = (await this.getRate('comissrate')).exchange_comission_pcnt || 0;

    this.setState({accountName: name, wcrurate: wcrurat, crurate: crurat, comissrate: comissrat});

    this.fetchPendingTransactions();
  };

  home = () => {
    this.props.history.push('/');
  };

  getRate = async table => {
    const {data} = await getEosTable({
      code: 'interchange',
      scope: 'interchange',
      json: true,
      key_type: 'uint64',
      limit: 1,
      table,
    });

    return data.rows?.[0] || {};
  };

  fetchPendingTransactions = async () => {
    const r = await this.props.userStore.getActiveExchangeObjects(this.props.userStore.user.name);

    const newState = {prevOpIsFetching: false, prevOperation: null};
    if (!r.isError && r.data.rows) {
      if (r.data.rows.length > 0) newState.prevOperation = r.data.rows;
    }
    this.setState(newState);
    this.isCruAmountTooSmall();
  };

  returnNewGraphState = () => ({
    frozenDataIsFEtching: false,
    withdrawedObjError: false,
    freezeObjToConvert: {},
    graphData: {
      labels: [],
      datasets: [
        {
          data: [],
          detailData: [],
          backgroundColor: [],
          borderWidth: 1,
        },
      ],
      monthArr: [],
    },
    scheduleData: [],
    leftOvers: [],
    leftOverSum: '',
    amountToChange: '',
    needUpd: '',
  });

  getWithdrawedObj = () => {
    const {name} = this.props.userStore.user;
    this.setState({frozenDataIsFEtching: true});
    getEosTable({
      json: true,
      code: 'tokenlock',
      scope: name,
      limit: 10000,
      table: 'locks3',
    }).then(r => {
      const newState = this.returnNewGraphState();
      if (!r.isError) {
        const filData = r.data.rows; // ?.filter(({id}) => id === 39072943);
        // const filData = r.data.rows?.filter(({id}) => id === 34039860);
        this.getFrozenObj(newState, name, filData || []);
      } else {
        newState.withdrawedObjError = true;
        this.setState(newState);
      }
    });
  };

  getFrozenObj = (newState, name, withdrawedObjs) => {
    const getLocks = (data = [], lower_bound = 0) =>
      getEosTable({
        json: true,
        code: 'tokenlock',
        scope: name,
        limit: 1000,
        table: 'locks',
        lower_bound,
      }).then(r => {
        if (!r.isError) {
          const filData = r.data.rows; // ?.filter(({id}) => id === 39072943);
          // const filData = r.data.rows?.filter(({id}) => id === 34039860);
          if (filData.length > 0) {
            data = data.concat(filData);
            getLocks(data, filData[filData.length - 1].id + 1);
          } else {
            const firstAlgorithmData = {};
            const secondAlgorithmData = {}; // create intermediate variable, to pass it into data in store in future

            console.log('withdrawedObjs', withdrawedObjs);

            (data || []).forEach(elem => {
              if (elem.amount === elem.withdrawed) return;
              this.getScheduleData(elem, newState.scheduleData);

              if (elem.algorithm < 3) {
                const {algorithm, created, last_distribution_at, id, available} = elem;
                const freeze_days = unfreezeDateConst[algorithm];

                const createdMoment = moment(`${created}Z`);
                let lastDistr = moment(`${last_distribution_at}Z`);

                if (lastDistr.isBefore(createdMoment)) {
                  lastDistr = createdMoment.add(freeze_days, 'd');
                }

                if (lastDistr.add(30, 'd').isBefore(moment()) || +cutTokenPrefix(available)) {
                  newState.needUpd = this.state.needUpd ? `${this.state.needUpd}, ${id}` : `${id}`;
                }

                this.checkleftOvers(elem, newState, withdrawedObjs);
                const startUnfreeze = moment(`${elem.created}Z`).add(
                  elem.algorithm === 1 ? unfreezeDateConst.firstAlgorithm : unfreezeDateConst.secondAlgorithm,
                  'day'
                );
                const use = true; // sessionStorage.getItem('qwerty'); // TODO remove

                use
                  ? this.updateObjectField(
                      startUnfreeze,
                      elem.algorithm === 1 ? firstAlgorithmData : secondAlgorithmData,
                      elem,
                      withdrawedObjs
                    )
                  : this.updateObjectFieldOld(
                      startUnfreeze,
                      elem.algorithm === 1 ? firstAlgorithmData : secondAlgorithmData,
                      elem,
                      withdrawedObjs
                    );
                if (newState.leftOvers.length > 0) {
                  newState.leftOverSum = newState.leftOvers
                    .reduce((one, two) => one.plus(two.amount), new BigNumber(0))
                    .toFixed(4)
                    .toString();
                  newState.amountToChange = newState.leftOverSum;
                }
              }
            });
            this.generateGraph(firstAlgorithmData, secondAlgorithmData, newState); // pass link to new state obj and formatted algObj, to convert it and save in newState
            this.setState(newState, () => {
              if (newState.graphData.scheduleLength) {
                this.onSliderChange(0);
              }
            });
          }
        }
      });
    getLocks();
  };

  getScheduleData = (elem, newScheduleData) => {
    elem.algorithm < 3 && newScheduleData.push(new CreateScheduleObj(elem));
  };

  checkleftOvers = (object, newState, withdrawedObjs) => {
    const withdraw = withdrawedObjs?.find(elem => elem.id === object.id)?.est_converted_month && 12;
    newState.leftOvers.push({
      lock: object,
      amount: new BigNumber(parseFloat(object.amount.split(' ')[0]) - parseFloat(object.withdrawed.split(' ')[0])),
      month: withdraw || 0,
    });
  };

  updateObjectFieldOld = (startUnfreeze, obj, curElem, withdrawedObjs) => {
    // let startDate = amount.ast_distribution_at?moment()
    let length = 35;
    const foundElem = withdrawedObjs.find(elem => elem.id === curElem.id);
    if (foundElem) {
      length -= foundElem.est_converted_month;
    }
    if (curElem.amount === curElem.withdrawed) {
      return;
    }
    const lastDistDay = moment(startUnfreeze).add(
      35 - parseInt(foundElem?.est_converted_month ? foundElem.est_converted_month : 0),
      'month'
    );
    for (let i = 1; i <= length; i++) {
      const currentDate = moment(startUnfreeze).add(i, 'month');
      // skip if this date is already in the past
      if (currentDate.isBefore(moment())) continue;
      // skip if current-date is in the greater then lastDistDay
      if (currentDate > lastDistDay) break;
      const durationName = `${moment(currentDate).year()}-${moment(currentDate).month()}`;
      const figure = Number(new BigNumber(cutTokenPrefix(curElem.amount)).multipliedBy(getFrozenPercent(i)).toFixed(4));
      if (figure <= 0) break;
      if (obj[durationName]) {
        obj[durationName].percentAmount = new BigNumber(obj[durationName].percentAmount).plus(figure).toFixed(4);
        obj[durationName].includedObjects.push({...curElem, partOfCommon: figure});
      } else {
        obj[durationName] = {
          percentAmount: figure,
          includedObjects: [{...curElem, partOfCommon: figure}],
        };
      }
    }
  };

  updateObjectField = (startUnfreeze, obj, curElem, withdrawedObjs) => {
    // let startDate = amount.ast_distribution_at?moment()
    const length = 35;
    const foundElem = withdrawedObjs.find(elem => elem.id === curElem.id);

    if (curElem.amount === curElem.withdrawed) {
      return;
    }

    let isVisible = false;
    let sum = 0;

    let startSum = +cutTokenPrefix(curElem.withdrawed) + +cutTokenPrefix(curElem.available);
    let excludedMonths = getConvertedMonths(startSum, +cutTokenPrefix(curElem.amount), curElem.id);

    for (let i = 1; i <= length; i++) {
      const currentDate = moment(startUnfreeze).add(i * 30, 'd');
      // skip if this date is already in the past
      if (currentDate.isBefore(moment())) {
        startSum -= +Number(new BigNumber(cutTokenPrefix(curElem.amount)).multipliedBy(getFrozenPercent(i)).toFixed(4));
        excludedMonths = getConvertedMonths(startSum, +cutTokenPrefix(curElem.amount), curElem.id, i);

        continue;
      }

      let figure = Number(new BigNumber(cutTokenPrefix(curElem.amount)).multipliedBy(getFrozenPercent(i)).toFixed(4));

      const durationName = `${moment(currentDate).year()}-${moment(currentDate).month()}`;
      if (figure <= 0) break;

      if (
        // eslint-disable-next-line no-loop-func
        Object.keys(excludedMonths).some(space => {
          const [bottom, top] = space.split('-');
          const hidden = excludedMonths[space] > 0 && bottom <= i && i <= top;
          if (hidden) {
            excludedMonths[space] -= 1;
          }
          return hidden;
        })
      ) {
        if (!isVisible) {
          continue;
        }
        figure = 0;
        // continue
      }
      isVisible = true;
      console.log('start');
      // sum += figure;
      if (obj[durationName]) {
        if (figure) {
          sum = sum < 0 ? figure : sum + figure;
          obj[durationName].percentAmount =
            obj[durationName].percentAmount < 0
              ? new BigNumber(figure).toFixed(4)
              : new BigNumber(obj[durationName].percentAmount).plus(figure).toFixed(4);
          obj[durationName].includedObjects.push({...curElem, partOfCommon: figure});
        }
      } else if (figure) {
        obj[durationName] = {
          percentAmount: figure,
          includedObjects: [{...curElem, partOfCommon: figure}],
        };
        sum = sum < 0 ? figure : sum + figure;
      } else {
        obj[durationName] = {
          percentAmount: -1,
          includedObjects: [],
        };
      }
    }

    for (let i = length; i >= 1; i--) {
      const currentDate = moment(startUnfreeze).add(i * 30, 'd');
      const durationName = `${moment(currentDate).year()}-${moment(currentDate).month()}`;

      if (obj[durationName]?.percentAmount === -1) {
        delete obj[durationName];
      } else {
        break;
      }
    }

    let missedSum = 0;
    excludedMonths = getConvertedMonths(
      +cutTokenPrefix(curElem.withdrawed) + +cutTokenPrefix(curElem.available),
      +cutTokenPrefix(curElem.amount)
    );
    const convertedObj = {};
    for (let i = 1; i <= 35; i++) {
      const currentDate = moment(startUnfreeze).add(i * 30, 'd');
      const figure = Number(new BigNumber(cutTokenPrefix(curElem.amount)).multipliedBy(getFrozenPercent(i)).toFixed(4));
      // calculate if this date is already in the past or fee for that month was payed
      if (currentDate.isBefore(moment())) {
        missedSum += figure;
      }

      if (
        Object.keys(excludedMonths).some(space => {
          const [bottom, top] = space.split('-');
          const hidden = excludedMonths[space] > 0 && bottom <= i && i <= top;
          if (hidden) {
            excludedMonths[space] -= 1;
          }
          return hidden;
        })
      ) {
        const durationName = `${moment(currentDate).year()}-${moment(currentDate).month()}`;

        if (figure <= 0) break;
        if (convertedObj[durationName]) {
          convertedObj[durationName].percentAmount = new BigNumber(convertedObj[durationName].percentAmount)
            .plus(figure)
            .toFixed(4);
          convertedObj[durationName].includedObjects.push({...curElem, partOfCommon: figure});
        } else {
          convertedObj[durationName] = {
            percentAmount: figure,
            includedObjects: [{...curElem, partOfCommon: figure}],
          };
        }
      }
    }
    console.log('------------------------------');

    console.log(
      'id',
      curElem.id,
      ', total sum:',
      curElem.amount,
      ', excahngeReady:',
      sum,
      ', withdrawed:',
      curElem.withdrawed,

      ', payed:',
      +cutTokenPrefix(curElem.withdrawed) -
        (this.state.prevOperation?.reduce((acc, {lock_id, cru_quantity}) => {
          if (lock_id === curElem.id) {
            acc += +cutTokenPrefix(cru_quantity);
          }
          return acc;
        }, 0) || 0),

      ', missed:',
      missedSum,
      ', est:',
      foundElem?.est_converted_month || 0,
      ', calcEst:',
      getEstMonths(cutTokenPrefix(curElem.withdrawed) + +cutTokenPrefix(curElem.available), cutTokenPrefix(curElem.amount)),
      curElem
    );
    console.log(
      'hidden months:',
      getConvertedMonths(cutTokenPrefix(curElem.withdrawed) + +cutTokenPrefix(curElem.available), +cutTokenPrefix(curElem.amount))
    );
    console.log('covertedPeriods', convertedObj);
    console.log('------------------------------');
  };

  generateGraph = (firstAlgorithmData, secondAlgorithmData, newState) => {
    // newState is link to obj which we will pass into store
    console.log('all actual periods:', {...firstAlgorithmData, ...secondAlgorithmData});
    // TODO: burn this code as soon as possible
    const getheredAlgorithms = Object.keys({...firstAlgorithmData, ...secondAlgorithmData})
      .map(k => ({
        value: new BigNumber((firstAlgorithmData[k] || {}).percentAmount || 0)
          .plus((secondAlgorithmData[k] || {}).percentAmount || 0)
          .toFixed(4),
        includedObjects: [
          ...((firstAlgorithmData[k] || {}).includedObjects || []),
          ...((secondAlgorithmData[k] || {}).includedObjects || []),
        ],
        date: moment(
          `${k.split('-')[0]}-${(() => {
            const month = parseInt(k.split('-')[1]) + 1;
            return month > 9 ? month : `${`0${month}`}`;
          })()}`
        ),
      }))
      .sort((a, b) => {
        return a.date.isBefore(b.date) ? -1 : 1;
      })
      .filter(a => {
        return moment(
          `${moment().year()}-${(() => {
            const month = moment().month() + 1;
            return month > 9 ? month : `${`0${month}`}`;
          })()}`
        ).isSameOrBefore(a.date);
      });

    let i = 0;

    getheredAlgorithms.forEach(elem => {
      const graphMonth = elem.date.format(TIME_FORMAT.TOOLTIP);
      newState.graphData.labels.push(elem.date.month() === 0 ? `${elem.date.year()}` : ''); // also we can push some date labels
      newState.graphData.datasets[0].data.push(elem.value);
      newState.graphData.datasets[0].detailData.push(elem);
      newState.graphData.datasets[0].backgroundColor.push('#DCE0E5');
      newState.graphData.monthArr.push(graphMonth);
      i++;
    });
    if (getheredAlgorithms.length) {
      // if there are objects, we will set last as default amount to convert, selected number on slider, and prepared freeze objects to convert
      newState.graphData.datasets[0].backgroundColor[getheredAlgorithms.length - 1] = '#4293F6';
      newState.graphData.scheduleLength = newState.graphData.labels.length - 1;
      let amount = 0.0;
      newState.activeValue = 0;
      newState.graphData.datasets[0].detailData[getheredAlgorithms.length - 1].includedObjects.forEach(freezeObj => {
        amount = new BigNumber(amount).plus(freezeObj.partOfCommon).toFixed(4);
        this.generateObjToConvert(newState.freezeObjToConvert, freezeObj);
      });
      this.onChange('amountToChange', amount); // todo refactor dont like that we change state twice =(
    }
  };

  generateObjToConvert = (objToState, curObj) => {
    // console.log('partOfCommon Obj', curObj);
    const maxAmount = new BigNumber(curObj.amount.split(' ')[0]).minus(new BigNumber(curObj.withdrawed.split(' ')[0])).toFixed(4);
    if (objToState[curObj.id]) {
      objToState[curObj.id].value = new BigNumber(objToState[curObj.id].value).plus(curObj.partOfCommon).toFixed(4);
      if (parseFloat(maxAmount) < parseFloat(objToState[curObj.id].value)) {
        objToState[curObj.id].value = maxAmount;
      }
      // console.log('ffff');
      objToState[curObj.id].length += 1;
    } else {
      objToState[curObj.id] = {
        value: new BigNumber(parseFloat(maxAmount) < curObj.partOfCommon ? maxAmount : curObj.partOfCommon).toFixed(4),
        length: 1,
      };
    }
  };

  userHasEnoughBalance = () => {};

  openModal = () => {
    const {scheduleData} = this.state;
    this.props.modalStore.open(UnfreezeSchedule, null, null, {data: scheduleData});
  };

  onChange = (name, value) => {
    if (parseFloat(value) === 0 && this.state.amountToChange.length < 1) {
      value += '.';
    }

    if (+value > -1) {
      this.setState({[name]: value});
    } else {
      this.setState({[name]: '', CRUWithCommission: '0'});
    }
  };

  makeExchange = pin => {
    const {amountToChange, symbolFrozen} = this.state;
    const {toggleExchangeParams} = this.props.userStore;
    this.setState({isFetching: true});
    toggleExchangeParams('isFetching', true);
    symbolFrozen ? this.exchangeFrozen(pin) : this.exchangeAvailable(`${amountToChange} CRU`, pin);
  };

  exchangeFrozen = pin => {
    const {freezeObjToConvert, leftOvers} = this.state;
    const {toggleExchangeParams} = this.props.userStore;
    console.log(freezeObjToConvert, 'freze');
    let objects = Object.keys(freezeObjToConvert)
      .map(key => ({
        id: key,
        amount: `${freezeObjToConvert[key].value} ${TOKENS.CRU}`,
        converted_month: freezeObjToConvert[key].length,
      }))
      .filter(candidate => {
        return new BigNumber(candidate.amount.split(' ')[0]) > 0;
      });
    if (objects.length === 0 && leftOvers.length > 0) {
      objects = leftOvers
        .map(obj => ({
          id: obj.lock.id,
          amount: `${obj.amount.toFixed(4).toString()} ${TOKENS.CRU}`,
          converted_month: obj.month,
        }))
        .filter(candidate => {
          return new BigNumber(candidate.amount.split(' ')[0]) > 0;
        });
    }
    this.props.eosStore
      .exchangeFrozenCRU({account: this.props.userStore.user.name, data: objects, pin, errorMsg: 'common.try_more'})
      .then(async r => {
        if (!r.isError) {
          this.getWithdrawedObj();
        }

        this.props.modalStore.close();
        toggleExchangeParams('isFetching', false);
        this.setState({isFetching: false});

        await this.fetchPendingTransactions();
        this.props.userStore.getAccountInfo();
      });
  };

  cancelExchange = ({pin, id}) => {
    const {symbolFrozen} = this.state;

    this.props.eosStore
      .cancelExchange({
        pin,
        id,
        userName: this.props.userStore.user.name,
      })
      .then(r => {
        if (!r.isError) {
          if (symbolFrozen) {
            this.getWithdrawedObj();
          }

          this.props.userStore.getAccountInfo();
          this.fetchPendingTransactions();
        }

        this.props.modalStore.close();
      });
  };

  finaliseExchange = (amount, memo, pin) => {
    const {toggleExchangeParams} = this.props.userStore;
    this.props.eosStore
      .makeTransaction({
        quantity: amount,
        sender: this.props.userStore.user.name,
        receiver: INTERCHANGE_ACC,
        memo,
        pin,
        errorMsg: 'common.try_more',
      })
      .then(r => {
        if (!r.isError) {
          // this.props.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'convert_wcru.done_convert'});

          this.fetchPendingTransactions();
        }

        this.props.modalStore.close();
        toggleExchangeParams('isFetching', false);
      });
  };

  exchangeAvailable = (amount, pin) => {
    const {toggleExchangeParams} = this.props.userStore;
    this.props.eosStore
      .makeTransaction({
        quantity: amount,
        sender: this.props.userStore.user.name,
        receiver: INTERCHANGE_ACC,
        pin,
        errorMsg: 'errors.exchange.available',
      })
      .then(r => {
        if (!r.isError) {
          this.fetchPendingTransactions();
          // this.props.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
        }

        this.props.modalStore.close();
        toggleExchangeParams('isFetching', false);
      });
  };

  getTotalBalanceOfUSDU = () => {
    const requests = UserStore.returnRequests(this.state.accountName);
    return Promise.all(requests)
      .then(UserStore.processAccRequests)
      .then(resp => {
        this.setState({totalBalanceUSDU: resp.calcData.usduTotal.slice(0, resp.calcData.usduTotal.length - 5)});
      });
  };

  openCancelModal = id => {
    const additionalClass = 'convert-wcru-content';

    this.props.modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: pin => {
            this.cancelExchange({id, pin});
          },
        }}
        btnTitle='convert_wcru.btn_conversion_cancel'
        btnTitleBack='common.cancel'
      />
    ));
  };

  openSendUSDUModal = (amount, memo, id) => {
    this.getTotalBalanceOfUSDU().then(() => {
      const {toggleExchangeParams} = this.props.userStore;
      const additionalClass = 'convert-wcru-content';
      this.setState({topBlockErr: null, bottomBlockErr: null});

      this.props.modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
        <EnterPin
          isOpen={isOpen}
          closeModal={closeModal}
          onOk={onOk}
          onCancel={onCancel}
          totalBalanceUSDU={this.state.totalBalanceUSDU}
          accountName={INTERCHANGE_ACC}
          params={{
            pinContentClass: additionalClass,
            onValidPin: pin => {
              this.finaliseExchange(amount, id, pin);
            },
            closeCb: toggleExchangeParams.bind(toggleExchangeParams, 'statusDone', false),
          }}
          operation={HEADER_TYPES.CONVERT}
          btnTitle='convert_wcru.btn_conversion_finish'
          btnTitleBack='common.cancel'
          ArrowIcon={ConvertIcon}
          sendUSDU={this.state.sendUSDU}
          amount={amount}
          memo={memo}
          id={id}
          totalBalanceUSDU={this.state.totalBalanceUSDU}
        />
      ));
    });
  };

  openConvertModal = () => {
    const {toggleExchangeParams} = this.props.userStore;
    const {crurate, wcrurate} = this.state;
    const courseWcru = 1;
    const params = {
      className: 'convert',
      headerLabelText: 'wallet_wcru.convert',
      value: this.state.amountToChange,
      valueWCRU: `${courseWcru * this.state.amountToChange}`,
    };
    const additionalClass = 'convert-wcru-content';
    this.setState({topBlockErr: null, bottomBlockErr: null});
    this.props.modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.makeExchange,
          closeCb: toggleExchangeParams.bind(toggleExchangeParams, 'statusDone', false),
          countConvert: params.valueWCRU,
        }}
        operation={HEADER_TYPES.CONVERT}
        btnTitle='convert_wcru.btn_convert'
        btnTitleBack='common.cancel'
        ArrowIcon={ConvertIcon}
        // fee={this.calculateTransactionFee()}
      >
        <ModalHeader
          hidePinTitle
          operation={HEADER_TYPES.CONVERT}
          params={params}
          rate={
            <>
              1.0000 CRU = 1.0000 WCRU <br />
              1.0000 CRU = {crurate} USDU <br />
              1.0000 WCRU = {wcrurate} USDU
            </>
          }
          fee={`${this.calculateTransactionFee()} USDU`}
          convert
          token={TOKENS.WCRU}
          showStatusDone
        />
        <div className='row-token'>
          {crurate < wcrurate
            ? '(WCRU/USDU Rate - CRU/USDU Rate) * CRU Quantity + Commision Rate % * CRU Quantity * WCRU/USDU Rate'
            : 'Commision Rate %  * CRU Quantity * WCRU/USDU Rate'}
        </div>
      </EnterPin>
    ));
  };

  toggleSymbolType = () => {
    this.setState({
      symbolFrozen: !this.state.symbolFrozen,
      amountToChange: '',
      activeValue: 0,
      freezeObjToConvert: this.state.symbolFrozen ? [] : this.state.freezeObjToConvert,
    });
  };

  onSliderChange = num => {
    const {graphData} = this.state;
    const data = {...graphData};
    let amountToChange = 0.0;
    const bgClr = [];
    const detailData = [];

    for (let i = 1; i <= data.scheduleLength + 1; i++) {
      if (+data.datasets[0].data[i - 1] <= 0) {
        bgClr.push('#ffae6a');
      } else if (i <= data.scheduleLength - num) {
        bgClr.push('#DCE0E5');
      } else {
        amountToChange = new BigNumber(amountToChange).plus(data.datasets[0].data[i - 1]).toFixed(4);
        detailData.push(data.datasets[0].detailData[i - 1]);
        bgClr.push('#4293F6');
      }
    }
    const objCollection = detailData.reduce((accumulator, currentValue) => {
      currentValue.includedObjects.forEach(freezeObj => {
        this.generateObjToConvert(accumulator, freezeObj);
      });
      return accumulator;
    }, {});
    data.datasets[0].backgroundColor = bgClr;
    this.setState({
      graphData: data,
      amountToChange,
      freezeObjToConvert: objCollection,
      activeValue: num,
    });
    this.getCommission(amountToChange);
  };

  getCommission = amount => {
    const newState = {};
    newState.CRUWithCommission = this.calculateTransactionFee(amount);
    this.setState(newState);
  };

  selectDateRange = n => {
    this.onSliderChange(n);
  };

  onInputBlur = () => {
    const {amountToChange} = this.state;
    if (!Number(amountToChange)) {
      this.setState({amountToChange: '', CRUWithCommission: '0'});
      return;
    }
    let defaultAmount = '';
    const splitedAmount = String(amountToChange).split('.');
    if (!splitedAmount[0]) {
      defaultAmount = '1.0000';
    } else if (!splitedAmount[1]) {
      defaultAmount = `${splitedAmount[0]}.0000`;
    } else if (splitedAmount[1].length < 4) {
      const emptyFigures = 4 - splitedAmount[1].length;
      defaultAmount = amountToChange;
      Array.from(new Array(emptyFigures)).forEach(i => (defaultAmount += '0'));
    }
    defaultAmount && this.onChange('amountToChange', defaultAmount);
  };

  openConverter = () => this.setState({prevOperation: null});

  calculateTransactionFee = amount => {
    const amountToChange = amount || this.state.amountToChange;
    const {crurate, wcrurate, comissrate, freezeObjToConvert} = this.state;
    if (!amount && Object.keys(freezeObjToConvert).length > 0) {
      return Object.keys(freezeObjToConvert).reduce((sum, key) => {
        return new BigNumber(sum).plus(new BigNumber(this.calculateTransactionFee(parseFloat(freezeObjToConvert[key].value))));
      }, 0);
    }

    const div = 10000;
    const crui = crurate * div;
    const wcrui = wcrurate * div;

    // console.log('amountToChange:', amountToChange, 'crurate:', crurate, 'wcrurate', wcrurate, 'comissrate:', comissrate);
    const cru_wcru_usdu = crui < wcrui ? (wcrui - crui) / div : 0;
    // console.log('cru_wcru_usdu:', cru_wcru_usdu);
    const comission = (((amountToChange * wcrui) / div) * comissrate) / 100;
    // console.log('comission:', comission);
    const usdu = amountToChange * cru_wcru_usdu;
    // console.log('usdu:', usdu);
    const usdui = Math.round(usdu * div);
    const comissioni = Math.round(comission * div);
    // console.log('total:', ((usdui + comissioni) / div).toFixed(4));
    return ((usdui + comissioni) / div).toFixed(4);
  };

  isCruAmountTooSmall = () => {
    const {prevOperation} = this.state;
    if (prevOperation && prevOperation !== 'undefined') {
      prevOperation.map((value, index) => {
        if (cutTokenPrefix(value.cru_quantity) <= 4 * cutTokenPrefix(value.usdu_quantity)) {
          this.setState({
            displayAttentionMsg: true,
          });
        }
      });
    }
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.refreshFrozen});
  };

  refreshFrozen = async pin => {
    const {userStore, eosStore, loggerStore} = this.props;
    const userName = userStore.user.name;

    const {data, isError} = await getEosTable({
      json: true,
      code: 'tokenlock',
      scope: userName,
      limit: 10000,
      table: 'locks',
    });

    if (!isError) {
      const apiRequester = await eosStore.returnApi(pin);
      const frozenObjects = [...(data.rows || [])];
      const objCount = frozenObjects.length;

      await eosStore.claimUnstackingTokens(userName, apiRequester);

      for (let i = 0; i < objCount; i += 1) {
        const {algorithm, available, created, last_distribution_at, id} = frozenObjects[i];
        const freeze_days = unfreezeDateConst[algorithm];

        const createdMoment = moment(`${created}Z`);
        let lastDistr = moment(`${last_distribution_at}Z`);

        if (lastDistr.isBefore(createdMoment)) {
          lastDistr = createdMoment.add(freeze_days, 'd');
        }

        if (lastDistr.add(30, 'd').isBefore(moment()) || +cutTokenPrefix(available)) {
          // eslint-disable-next-line no-await-in-loop
          await eosStore.updateFrozenObj(userName, id, apiRequester);
        }
      }
      const {data: updatedData, isErrorGetLocks} = await getEosTable({
        json: true,
        code: 'tokenlock',
        scope: userName,
        limit: 10000,
        table: 'locks',
      });

      if (!isErrorGetLocks) {
        for (let i = 0; i < updatedData.rows.length; i += 1) {
          const {available, id} = updatedData.rows[i];

          if (+cutTokenPrefix(available)) {
            // eslint-disable-next-line no-await-in-loop
            await eosStore.withdrawFrozenObj(userName, id, apiRequester);
          }
        }
      }

      this.getWithdrawedObj();
      userStore.getAccountInfo();
    } else {
      loggerStore.addItem({
        type: LOGGER_TYPES[1],
        msgTxt: 'wallet_page.dashboard.refresh.get_freeze_obj_err',
      });
    }
  };

  render() {
    const courseWcru = 1;
    const {
      amountToChange,
      isFetching,
      symbolFrozen,
      prevOperation,
      graphData,
      activeValue,
      freezeObjToConvert,
      displayAttentionMsg,
      leftOvers,
      leftOverSum,
      crurate,
      wcrurate,
      comissrate,
      needUpd,
    } = this.state;

    const disableBtn = +amountToChange <= 0 || (symbolFrozen && (Object(freezeObjToConvert).length > 0 || needUpd));

    const {userWallet} = this.props.userStore;
    const availableCRU = this.props.userStore.userWallet.CRU.available;

    if (this.state.prevOpIsFetching) {
      return (
        <div className='wallet-convert'>
          <div className='cru-convert'>
            <h2 className='title-page preloader-header'>
              <I18n tKey='convert_wcru.checking_unfinished_operations' />
              <RefreshIcon className='active' />
            </h2>
          </div>
        </div>
      );
    }

    return (
      <div className='nested-route margin wallet-convert'>
        <div className='cru-convert'>
          <h2 className='title-page'>
            <I18n tKey='convert_wcru.title' />
          </h2>
          <div className='notice-text-wrapper top d-flex'>
            <div className='wrapper-flex-item wide'>
              <InfoIcon className='circular__img' />
            </div>
            <div className='d-flex flex-column'>
              <I18n className='table-header sm' tKey='convert_wcru.table_attention' />
              <I18n className='table-header' className='sub-text' tKey='convert_wcru.warning_text' />
            </div>
          </div>
          <div className='content'>
            <div className='convert-block-container'>
              <div className='convert-block'>
                <div className='choose-type'>
                  <div className='choose-tip'>
                    <I18n tKey='convert_wcru.convertion_tip_header' />
                    <I18n tKey='convert_wcru.convertion_tip' />
                  </div>
                  <div className='choose-btns'>
                    <Button
                      btnClass={classNames('with-bg', {blue: symbolFrozen})}
                      title='common.frozen_cru'
                      onClick={this.toggleSymbolType}
                    />
                    <Button
                      btnClass={classNames('with-bg', {blue: !symbolFrozen})}
                      title='common.available_cru'
                      onClick={this.toggleSymbolType}
                    />
                  </div>
                </div>
                <div className='total-info'>
                  <span>
                    <I18n tKey={symbolFrozen ? 'common.total_frozen' : 'common.total_available'} />:
                  </span>
                  <CountWithZero round={4} count={symbolFrozen ? userWallet.CRU.frozen : userWallet.CRU.available} />
                  <span> CRU</span>
                </div>
                <ConverterInput
                  value={this.state.amountToChange}
                  name='amountToChange'
                  onChange={this.onChange}
                  leftHeader={
                    symbolFrozen ? 'convert_wcru.select_frozen_amount.title' : 'convert_wcru.select_available_amount.title'
                  }
                  readOnly={symbolFrozen}
                  leftDescription='convert_wcru.select_amount_notice'
                  tokenIc={cruIcon}
                  token={TOKENS.CRU}
                  onBlur={this.onInputBlur}
                  maxAmount={symbolFrozen ? userWallet.CRU.frozen : availableCRU}
                  wrapperClass='active-input'
                />
                {symbolFrozen && (
                  <>
                    {needUpd && (
                      <div className='notice-text-wrapper d-flex'>
                        <div className='wrapper-flex-item wide centered-content'>
                          <InfoIcon className='circular__img' />
                        </div>
                        <div className='d-flex flex-column'>
                          <I18n className='table-header sm' tKey='convert_wcru.table_attention' />
                          <I18n className='sub-text refresh-frozen' tKey='convert_wcru.need_refresh_frozen' />
                          <Button
                            btnClass='refresh-frzn-btn with-bg blue'
                            title='convert_wcru.refresh_frozen_btn'
                            ArrowIcon={RefreshIcon}
                            onClick={this.openPinModal}
                          />
                        </div>
                      </div>
                    )}

                    <FrozenTab
                      activeIndex={this.state.activeValue}
                      activeValue={activeValue}
                      changeLetfOver={this.openConvertModal}
                      fetching={this.state.frozenDataIsFEtching}
                      getWithdrawedObj={this.getWithdrawedObj}
                      graphData={graphData}
                      leftOvers={leftOverSum}
                      onSliderChange={this.onSliderChange}
                      openModal={this.openModal}
                      selectDateRange={this.selectDateRange}
                      withdrawedObjError={this.state.withdrawedObjError}
                    />
                  </>
                )}
              </div>

              {prevOperation && (
                <div>
                  <div className='d-flex flex-column'>
                    <I18n className='table-header' tKey='convert_wcru.table_header' />
                    <div className='notice-text-wrapper d-flex'>
                      <div className='wrapper-flex-item wide centered-content'>
                        <InfoIcon className='circular__img' />
                      </div>
                      <div className='d-flex flex-column'>
                        <I18n className='table-header sm' tKey='convert_wcru.table_attention' />
                        {displayAttentionMsg && <I18n className='sub-text' tKey='convert_wcru.table_notice' />}
                        <br />
                        <I18n className='sub-text' tKey='convert_wcru.usdu_comission_updates' />
                      </div>
                    </div>
                  </div>
                  <div className='table'>
                    <Table
                      data={{...this.tableColumns, rows: prevOperation}}
                      tableClass={classNames('transactions-table', 'wallet-converter-table')}
                      openModal={this.openSendUSDUModal}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='receive-block'>
              <ConverterInput
                leftHeader='common.you_will_receive'
                value={new BigNumber(courseWcru).multipliedBy(this.state.amountToChange || 0).toFixed(4)}
                tokenIc={wcruIcon}
                token={TOKENS.WCRU}
                wrapperClass='receive-input'
                readOnly
                preloader
              />
              <div className='convert-info'>
                <div className='info-row with-pad'>
                  <div className='left'>
                    <I18n tKey='convert_wcru.conversion_rate' />
                  </div>
                  <div className='right d-flex flex-column'>
                    <div>1.0000 CRU = {crurate} USDU </div>
                    <br />
                    <div>1.0000 WCRU = {wcrurate} USDU</div>
                    <br />
                    <div>Commission {comissrate} %</div>
                    <br />
                  </div>
                </div>
                <div className='info-row with-pad second-color'>
                  <div className='left'>
                    <I18n tKey='convert_wcru.transaction_fee' />
                  </div>
                  <div className='right'>
                    <I18n tKey={`${changeFormat(this.calculateTransactionFee())} USDU`} />
                  </div>
                  <div>
                    <span className='row-token'>
                      {crurate < wcrurate
                        ? '(WCRU/USDU Rate - CRU/USDU Rate) * CRU Quantity + Commision Rate % * CRU Quantity * WCRU/USDU Rate'
                        : 'Commision Rate %  * CRU Quantity * WCRU/USDU Rate'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='converter-notice'>
                <Frozen />
                <div>
                  <I18n tKey='common.notice' />
                  <I18n tKey='convert_wcru.description_convert' />
                  <br />
                  <I18n tKey='convert_wcru.description_convert_refresh' />
                  <br />
                  <I18n tKey='convert_wcru.usdu_comission_updates' />
                </div>
              </div>
              <div className='info-row with-btn'>
                <div className='right'>
                  <Button
                    btnClass='with-bg blue'
                    title='convert_wcru.with_arrow'
                    onClick={this.openConvertModal}
                    disabled={disableBtn}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
