import React, {Component, Fragment} from 'react';
import {BlockHeader} from '../../helpers/blockHeader/BlockHeader';
import {CountTxtRow} from '../../helpers/countTxtRow/CountTxtRow';
import {I18n} from '../../helpers/i18n/I18n';
import {changeFormat, percentFromNum} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';
import {BigNumber} from 'bignumber.js';
import {ProgressBar} from '../../helpers/progressBar/ProgressBar';

export class Distribution extends Component {
  returnPercents = (x, y) => {
    if (x === 0 || y === 0) {
      return false;
    } else {
      return `${percentFromNum(x, y)}%`;
    }
  };

  render() {
    const {activeToken, accountsAmount, totalAccounts, zeroBalances} = this.props;
    const format = {groupSeparator: ' ', groupSize: 3};
    const formatSettings = {
      decimalSeparator: ' ',
      groupSeparator: ' ',
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: ' ',
      fractionGroupSize: 0,
    };
    return (
      <div className='center-content'>
        <BlockHeader
          title={`home.tokens.distribution_title.${activeToken}`}
          linkTitle='home.tokens.view_all'
          linkUrl='/accounts'
        />
        <div className='data-container'>
          <CountTxtRow count={new BigNumber(totalAccounts).toFormat(format)} title='home.tokens.cru_block.row_total' />
          <CountTxtRow
            count={new BigNumber(zeroBalances).toFormat(format)}
            title='home.tokens.cru_block.row_balance'
            percent={this.returnPercents(zeroBalances, totalAccounts)}
          />
        </div>
        <div className='headers-block'>
          <I18n tKey='home.tokens.cru_block.col_account' />
          <I18n tKey='home.tokens.cru_block.col_amount' />
        </div>
        {accountsAmount.map((elem, i) => (
          <ProgressBar
            lineClass='cru-status-line'
            key={i}
            size={elem.size}
            count={changeFormat(elem.amount, 0, formatSettings)}
            amount={elem.score}
          />
        ))}
      </div>
    );
  }
}
