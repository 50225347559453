import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {I18n} from '../../helpers/i18n/I18n';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {Button} from '../../helpers/button/Button';
import {BalanceRow} from '../../helpers/balanceRow/BalanceRow';
import {TokenInfoBlock} from '../../helpers/tokenInfoBlock/TokenInfoBlock';
import {cutTokenPrefix} from '../../../utils/utils';
import {REG_EXP, TOKENS} from '../../../config/const';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {TableControls} from '../../helpers/tableСontrols/TableControls';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const percentBtn = [{value: '25'}, {value: '50'}, {value: '70'}, {value: '100'}];

const tabs = [
  {title: 'wallet_cru.tab_stake', value: 'stake'},
  {title: 'wallet_cru.tab_unstake', value: 'unstake'},
];

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class WalletUntb extends Component {
  state = {
    cpuAmount: '',
    netAmount: '',
    unstakeCpu: '',
    unstakeNet: '',
    opIsActive: false,
    activeTab: tabs[0].value,
  };

  onChange = (propName, value) => {
    const newState = {[propName]: value};
    this.setState(newState);
  };

  onInputBlur = () => {
    const {cpuAmount, netAmount, unstakeCpu, unstakeNet} = this.state;
    const values = {cpuAmount, netAmount, unstakeCpu, unstakeNet};
    const defaultAmount = {};
    Object.keys(values).forEach(key => {
      let defaultValue = '';
      const splitedAmount = values[key].split('.');
      if (!splitedAmount[0]) {
        return;
      }
      if (!splitedAmount[1]) {
        defaultValue = `${splitedAmount[0]}.0000`;
      } else if (splitedAmount[1].length < 4) {
        const emptyFigures = 4 - splitedAmount[1].length;
        defaultValue = values[key];
        Array.from(new Array(emptyFigures)).forEach(i => (defaultValue += '0'));
      }
      defaultValue && (defaultAmount[key] = defaultValue);
    });
    Object.keys(defaultAmount).length && this.setState(defaultAmount);
  };

  setPercentAmount = (percent, propName, selectedValue, frozenAmount, selectedFrozen) => {
    const calcValue = new BigNumber(selectedValue).minus(selectedFrozen).toNumber();
    const selectedFromAvailable = calcValue > 0 ? calcValue : 0;
    const nextValue = new BigNumber(this.props.userStore.userWallet.UNTB.available)
      .plus(frozenAmount)
      .minus(selectedFromAvailable)
      .multipliedBy(percent)
      .div(100)
      .toFixed(4);
    this.setState({[propName]: nextValue});
  };

  makeStakingOp = (isStake, pin) => {
    const {cpuAmount, unstakeCpu, netAmount, unstakeNet} = this.state;
    const cpu = isStake ? cpuAmount : unstakeCpu;
    const net = isStake ? netAmount : unstakeNet;
    const defaultAmount = '0.0000';
    this.setState({opIsActive: true});
    this.props.eosStore
      .makeStakingOpUntb({
        cpu: `${cpu || defaultAmount} UNTB`,
        net: `${net || defaultAmount} UNTB`,
        sender: this.props.userStore.user.name,
        isStake,
        pin,
        errorMsg: isStake ? 'errors.stake.untb' : 'errors.unstake.untb',
      })
      .then(r => {
        const newState = {opIsActive: false};
        if (!r.isError) {
          this.props.userStore.getAccountInfo();
          newState.cpuAmount = '';
          newState.netAmount = '';
          newState.unstakeCpu = '';
          newState.unstakeNet = '';
        }

        this.setState(newState);
      });
  };

  unstakeLimitIsExceeded = () => {
    const {
      total_resources = {cpu_weight: 0, net_weight: 0},
      cpu_limit = {used: 0, available: 0},
      net_limit = {used: 0, available: 0},
    } = this.props.userStore.accountDetails;
    const {unstakeCpu, unstakeNet} = this.state;
    const cpuTokenPerSec = new BigNumber(cpu_limit.available).div(cutTokenPrefix(total_resources.cpu_weight));
    const netTokenPerSec = new BigNumber(net_limit.available).div(cutTokenPrefix(total_resources.net_weight));
    const nextCpuAvailable = new BigNumber(cpu_limit.available).minus(+unstakeCpu * cpuTokenPerSec);
    const nextNetAvailable = new BigNumber(net_limit.available).minus(+unstakeNet * netTokenPerSec);
    let errorTxt = '';

    if (nextCpuAvailable <= cpu_limit.used) {
      errorTxt = 'wallet_send.error_cpu';
    }
    if (nextNetAvailable <= net_limit.used) {
      errorTxt = 'wallet_send.error_net';
    }

    errorTxt && this.props.loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: errorTxt});

    return !!errorTxt;
  };

  openPinModal = isStakingOp => {
    if (!isStakingOp && this.unstakeLimitIsExceeded()) {
      return;
    }
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.makeStakingOp.bind(this, isStakingOp)});
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  render() {
    const {netAmount, cpuAmount, unstakeCpu, unstakeNet, opIsActive, activeTab} = this.state;
    const {accountDetails, userWallet, userRefunds} = this.props.userStore;
    const stakedCPU = accountDetails.total_resources?.cpu_weight || 0;
    const stakedNET = accountDetails.total_resources?.net_weight || 0;
    const frozenNET = cutTokenPrefix(userRefunds?.net_amount || 0);
    const frozenCPU = cutTokenPrefix(userRefunds?.cpu_amount || 0);
    const stakedCPUAmount = new BigNumber(cutTokenPrefix(stakedCPU)).toNumber();
    const stakedNETAmount = new BigNumber(cutTokenPrefix(stakedNET)).toNumber();
    const availableCPUtoStake = new BigNumber(userWallet.UNTB.available)
      .plus(frozenCPU)
      .minus(netAmount || 0)
      .toNumber();
    const availableNETtoStake = new BigNumber(userWallet.UNTB.available)
      .plus(frozenNET)
      .minus(cpuAmount || 0)
      .toNumber();
    const stakeDisabled = +cpuAmount === 0 && +netAmount === 0;
    const unstakeDisabled = +unstakeCpu === 0 && +unstakeNet === 0;
    return (
      <div className='nested-route wallet-untb margin'>
        <div className='wallet-untb-block'>
          <I18n tKey='wallet_untb.title' className='title-page' />
          <div className='wallet-content'>
            <div className='top-content'>
              <TokenInfoBlock title='wallet_untb.staked_row' count={stakedCPU} />
              <TokenInfoBlock title='wallet_untb.staked_net_row' count={stakedNET} />
              <TokenInfoBlock title='wallet_untb.power_row' count={userWallet.UNTB.staked} />
            </div>
            <div className='toggle-tabs'>
              {tabs.map((elem, i) => (
                <TableControls
                  key={i}
                  onClick={this.setActiveTab.bind(this, elem.value)}
                  active={elem.value === activeTab}
                  title={elem.title}
                  tabClass='tab-item'
                />
              ))}
            </div>
            <div className='bottom-content'>
              <div className={classNames('stake-block', {'hide-mobile-form': activeTab === tabs[1].value})}>
                <I18n tKey='wallet_untb.stake_input' className='title-page' />
                <div className='balance-wrapper'>
                  <BalanceRow
                    className={`${frozenNET || frozenCPU ? 'empty-margin' : ''}`}
                    round={4}
                    countBalance={userWallet.UNTB.available}
                    token='UNTB'
                    titleRow='wallet_send.balance'
                  />
                  {frozenNET > 0 && (
                    <BalanceRow
                      className='empty-margin'
                      round={4}
                      countBalance={frozenNET}
                      token='UNTB'
                      titleRow='wallet_send.unstaked_net'
                    />
                  )}
                  {frozenCPU > 0 && (
                    <BalanceRow
                      className='empty-margin'
                      round={4}
                      countBalance={frozenCPU}
                      token='UNTB'
                      titleRow='wallet_send.unstaked_cpu'
                    />
                  )}
                </div>
                {/* <TokenInfoBlock */}
                {/*    title="Frozen net" */}
                {/*    count={frozenNET} */}
                {/* /> */}
                {/* <TokenInfoBlock */}
                {/*    title="Frozen CPU" */}
                {/*    count={frozenCPU} */}
                {/* /> */}
                <InputRow
                  wrapperClass='untb-input input-with-bg'
                  name='cpuAmount'
                  value={cpuAmount}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  placeholder='wallet_send.cpu_amout'
                  inputChange={this.onChange}
                  onBlur={this.onInputBlur}
                  statusBorder
                  label={cpuAmount && 'wallet_send.cpu_amout'}
                  maxAmount={availableCPUtoStake || frozenCPU}
                />
                <div className='btn-block'>
                  {percentBtn.map((elem, i) => (
                    <Button
                      key={i}
                      onClick={this.setPercentAmount.bind(this, elem.value, 'cpuAmount', netAmount, frozenCPU, frozenNET)}
                      title={`${elem.value}%`}
                      btnClass='percent-btn'
                    />
                  ))}
                </div>
                <InputRow
                  wrapperClass='untb-input input-with-bg'
                  name='netAmount'
                  value={netAmount}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  onBlur={this.onInputBlur}
                  placeholder='wallet_send.net_amount'
                  inputChange={this.onChange}
                  statusBorder
                  label={netAmount && 'wallet_send.net_amount'}
                  maxAmount={availableNETtoStake || frozenNET}
                />
                <div className='btn-block'>
                  {percentBtn.map((elem, i) => (
                    <Button
                      key={i}
                      onClick={this.setPercentAmount.bind(this, elem.value, 'netAmount', cpuAmount, frozenNET, frozenCPU)}
                      title={`${elem.value}%`}
                      btnClass='percent-btn'
                    />
                  ))}
                </div>
                <Button
                  onClick={this.openPinModal.bind(this, true)}
                  title='wallet_untb.stake_input'
                  disabled={opIsActive || stakeDisabled}
                  btnClass='with-bg blue'
                />
              </div>
              <div className={classNames('stake-block unstake', {'hide-mobile-form': activeTab === tabs[0].value})}>
                <I18n tKey='wallet_untb.unstake_input' className='title-page' />
                <div className='balance-wrapper'>
                  <BalanceRow
                    round={4}
                    titleRow='wallet_untb.staked_balance'
                    countBalance={userWallet.UNTB.staked}
                    token='UNTB'
                  />
                </div>
                <InputRow
                  label={unstakeCpu && 'wallet_untb.unstake_cpu'}
                  value={unstakeCpu}
                  countBalance={cutTokenPrefix(stakedCPU)}
                  wrapperClass='untb-input input-with-bg'
                  name='unstakeCpu'
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  placeholder='wallet_untb.unstake_cpu'
                  onBlur={this.onInputBlur}
                  inputChange={this.onChange}
                  statusBorder
                  token={TOKENS.UNTB}
                  round={4}
                  titleRow='wallet_untb.staked_CPU'
                  maxAmount={stakedCPUAmount}
                />
                <InputRow
                  wrapperClass='untb-input input-with-bg'
                  name='unstakeNet'
                  countBalance={cutTokenPrefix(stakedNET)}
                  value={unstakeNet}
                  onlyNumbers
                  regEx={REG_EXP.four_digits}
                  placeholder='wallet_untb.unstake_net'
                  inputChange={this.onChange}
                  onBlur={this.onInputBlur}
                  statusBorder
                  label={unstakeNet && 'wallet_untb.unstake_net'}
                  token={TOKENS.UNTB}
                  round={4}
                  titleRow='wallet_untb.staked_NET'
                  maxAmount={stakedNETAmount}
                />
                <Button
                  onClick={this.openPinModal.bind(this, false)}
                  title='wallet_untb.unstake_input'
                  disabled={opIsActive || unstakeDisabled}
                  btnClass='with-bg blue'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
