import * as axios from 'axios';
import {STORAGE_KEY} from '../config/const';

export const BASE_URL = typeof GLOBAL_ENV !== 'undefined' ? GLOBAL_ENV.REACT_APP_URL : process.env.REACT_APP_URL;
export const BASE_URL_V2 = typeof GLOBAL_ENV !== 'undefined' ? GLOBAL_ENV.REACT_APP_URL_V2 : process.env.REACT_APP_URL_V2;
export const BASE_URL_AGGREGATOR =
  typeof GLOBAL_ENV !== 'undefined' ? GLOBAL_ENV.REACT_APP_URL_AGGREGATOR : process.env.REACT_APP_URL_AGGREGATOR;
export const BASE_URL_HISTORY =
  typeof GLOBAL_ENV !== 'undefined' ? GLOBAL_ENV.REACT_APP_URL_HISTORY : process.env.REACT_APP_URL_HISTORY;

export const getXToken = () => sessionStorage.getItem(STORAGE_KEY);

export const getHeader = (header = {}) => {
  return {
    // 'x-token': getXToken(),
    ...header,
  };
};

const createMethod = (baseUrl, url, data, headers, method) =>
  axios.create({baseURL: baseUrl}).request({url, data, method, headers: getHeader(headers)});

const GET = (baseUrl, url, data, headers) => createMethod(baseUrl, url, data, headers, 'GET');
const POST = (baseUrl, url, data, headers) => createMethod(baseUrl, url, data, headers, 'POST');
// const PUT = (baseUrl, url, data, headers) => createMethod(baseUrl, url, data, headers, 'PUT');
// const DELETE = (baseUrl, url, data, headers) => createMethod(baseUrl, url, data, headers, 'DELETE');

async function makeCall(method, baseUrl, url, data, headers = {}) {
  let result = null;
  try {
    const resp = await method(baseUrl, url, data, headers);
    result = {
      data: resp.data,
      isError: false,
      url,
      arguments: data,
      base: baseUrl,
      response: resp,
    };
  } catch (e) {
    console.error(e);
    if (e.response?.status === 403) {
      // window.stores.app.stopSession();
    }

    result = {
      data: e.response?.data,
      isError: true,
      url,
      arguments: data,
      base: baseUrl,
      response: e.response,
    };
  }
  return result;
}

// accounts

export const accountSearch = name => makeCall(POST, BASE_URL_V2, `/v1/chain/get_account`, {account_name: name});
export const getAccountBalance = data => makeCall(POST, BASE_URL_V2, `/v1/chain/get_currency_balance`, data);
export const getAccountStats = (symbol, code = 'eosio.token', account = 'reserve') =>
  makeCall(POST, BASE_URL_V2, `/v1/chain/get_currency_stats`, {symbol, code, account});
export const getAccountTable = data => makeCall(POST, BASE_URL_V2, '/v1/chain/get_table_rows', data);
export const getAbi = accountName => makeCall(POST, BASE_URL_V2, '/v1/chain/get_abi', {account_name: accountName});

// blocks

// switch to API url
export const getHeadBlock = () => makeCall(GET, BASE_URL_V2, 'v1/chain/get_info');
export const getBlockDetailsFromApi = id => makeCall(POST, BASE_URL_V2, `v1/chain/get_block`, {block_num_or_id: id});

// transactions
export const searchForAccount = account => makeCall(POST, BASE_URL_V2, '/v1/chain/get_account', {account_name: account});

export const accountInfo = account => makeCall(POST, BASE_URL_V2, '/v1/chain/get_account', {account_name: account});

export const getChainInfo = () => makeCall(GET, BASE_URL_V2, `/v1/chain/get_info`);
export const getEosTable = data => makeCall(POST, BASE_URL_V2, `/v1/chain/get_table_rows`, data);
export const getTableByScope = data => makeCall(POST, BASE_URL_V2, `/v1/chain/get_table_by_scope`, data);

export const getBlock = number => makeCall(POST, BASE_URL_V2, `/v1/chain/get_block`, {block_num_or_id: number});

// FLASK api
export const getActions = (id, block_num) =>
  makeCall(GET, BASE_URL_HISTORY, `/get_actions?transaction_id=${id}&block_num=${block_num}`);
export const getTrTableFlask = ({limit, page}) =>
  makeCall(GET, BASE_URL_HISTORY, `/get_transactions?limit=${limit}&offset=${page * limit}`);
export const getUserActions = payload => makeCall(POST, BASE_URL_HISTORY, `/user_actions`, payload);

export const getTopHoldersFlask = (limit = 20, page = 0, symbol) =>
  makeCall(GET, BASE_URL_AGGREGATOR, `/toplist?currency=${symbol}&pageSize=${limit}&page=${page}`);
export const getStat = symbol => makeCall(GET, BASE_URL_AGGREGATOR, `/stat?currency=${symbol}`);
export const getTotalAmount = symbol => makeCall(GET, BASE_URL_AGGREGATOR, `/totalAmount?currency=${symbol}`);
export const getTotalAcounts = () => makeCall(GET, BASE_URL_AGGREGATOR, `/totalAccounts`);

export const getAccountsList = (limit = 0, offset = 20) =>
  makeCall(GET, BASE_URL_HISTORY, `/get_accounts?limit=${limit}&offset=${offset}`);
export const getBlocks = (count, showEmpty = true) =>
  makeCall(GET, BASE_URL_HISTORY, `/get_blocks?limit=${count}&non_empty=${!showEmpty}`);
export const getBlockDetails = numb => makeCall(GET, BASE_URL_HISTORY, `/block_summary${numb ? `?blocknum=${numb}` : ''}`);

export const getTransactionDetailsFlask = transactionId =>
  makeCall(GET, BASE_URL_HISTORY, `/get_transaction?id=${transactionId.toUpperCase()}`);

export const getLanguagesList = () => makeCall(GET, BASE_URL_AGGREGATOR, `/get_languages`);
export const getDictionary = lang => makeCall(GET, BASE_URL_AGGREGATOR, `/get_dictionary?lang=${lang}`);

// not used, don't remove
export const getTransactionBlock = transactionId =>
  makeCall(GET, BASE_URL_HISTORY, `/get_transaction_block?id=${transactionId.toUpperCase()}`);
export const getTransactionDetails = (transactionId, block_num) =>
  makeCall(POST, BASE_URL_V2, `v1/history/get_transaction`, {id: transactionId, block_num_hint: block_num});
