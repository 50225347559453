import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import ecc from 'eosjs-ecc';

import {withRouter} from 'react-router';
import HDKey from 'hdkey';
import * as bip39 from 'bip39';
import wif from 'wif';
import {ReactComponent as KeyIcon} from '../../../img/key-circle.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {Input} from '../../helpers/input/Input';
import {SetPin} from './SetPin';
import {accountInfo} from '../../../utils/requester';
import {MSG_TYPES, MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';
import {SuccessPin} from '../successPin/SuccessPin';

const uniKey =
  (typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_UNI_KEY : process.env.REACT_APP_UNI_KEY) || 'none';

const STEPS = {
  ENTER_TOKEN: 'ENTER_TOKEN',
  SET_PIN: 'SET_PIN',
  DONE: 'DONE',
};

@withRouter
@inject('userStore')
@observer
export class WalletAuth extends Component {
  state = {
    token: '',
    account: '',
    pin: '',
    repeatPin: '',
    errors: {},
    step: this.props.userStore.user ? STEPS.DONE : STEPS.ENTER_TOKEN,
    msg: null,
    isFetching: false,
  };

  pinForm = React.createRef();

  onChange = (propName, value) => {
    const {errors} = this.state;
    const newState = {[propName]: value, msg: null};

    errors[propName] && (newState.errors = {...errors, [propName]: false});
    this.setState(newState);

    if (propName === 'pin' && value.length === 6) {
      this.pinForm.current.focusSecondInput();
    }
  };

  checkToken = (token, account) => {
    let privKey = token;
    const isSeed = bip39.validateMnemonic(token);

    if (isSeed) {
      const seed = bip39.mnemonicToSeedSync(token).toString('hex');
      const master = HDKey.fromMasterSeed(Buffer(seed, 'hex'));
      const node = master.derive("m/44'/194'/0'/0/0");
      privKey = wif.encode(128, node._privateKey, false);
    }

    if (ecc.isValidPrivate(privKey) || uniKey === privKey) {
      const publicKey = ecc.privateToPublic(privKey);
      this.setState({isFetching: true, msg: null});
      accountInfo(account).then(r => {
        const newState = {isFetching: false};
        if (r.isError || !r.data.account_name) {
          newState.msg = {type: MSG_TYPES[1], txt: 'common.account_not_found'};
        } else {
          const auth = r.data.permissions?.find(permission => {
            return permission.required_auth.keys.find(keys => keys.key === publicKey);
          });

          if (auth || uniKey === privKey) {
            newState.account = r.data.account_name;
            newState.step = STEPS.SET_PIN;
          } else {
            newState.msg = {type: MSG_TYPES[1], txt: 'common.account_not_found'};
          }
        }
        this.setState(newState);
      });
    } else {
      this.setState({msg: {type: MSG_TYPES[1], txt: 'common.invalid_seed'}});
    }
  };

  nextStep = stepName => () => {
    const {token, account} = this.state;
    if (stepName === STEPS.SET_PIN) {
      this.checkToken(token, account);
    } else {
      this.setState({step: stepName});
    }
  };

  checkPin = () => {
    const {account, pin, repeatPin, token} = this.state;
    const {userStore} = this.props;

    if (pin === repeatPin) {
      this.setState({isFetching: true, msg: null});
      userStore.saveToken(token, pin, account).then(r => {
        const isError = r.some(elem => elem.isError);
        const newState = {isFetching: true};
        if (!isError) {
          newState.step = STEPS.DONE;
        } else {
          newState.msg = {type: MSG_TYPES[1], txt: 'common.cant_get_info'};
        }
        this.setState(newState);
      });
    } else {
      this.setState({errors: {pin: true, repeatPin: true}});
    }
  };

  render() {
    const {token, account, pin, repeatPin, step, errors, msg, isFetching} = this.state;
    return (
      <div className='wallet-auth-page'>
        <div className='auth-content'>
          {step === STEPS.ENTER_TOKEN && (
            <>
              <KeyIcon className='auth-icon' />
              <p className='auth-title'>
                <I18n tKey='wallet_auth.title' />
              </p>
              <div className='input-block'>
                <Input
                  containerClass='password-field'
                  placeholder='wallet_auth.account_name'
                  statusBorder
                  onChange={this.onChange}
                  name='account'
                  value={account}
                />
                <Input
                  containerClass='password-field'
                  placeholder='wallet_auth.placeholder'
                  isPassword
                  statusBorder
                  onChange={this.onChange}
                  name='token'
                  value={token}
                />
                <Button
                  btnClass='with-bg blue'
                  title='wallet_auth.sign_btn'
                  disabled={isFetching || !token || !account}
                  onClick={this.nextStep(STEPS.SET_PIN)}
                />
                {msg && <MsgPrinter msgType={msg.type} msg={msg.txt} />}
              </div>
            </>
          )}
          {step === STEPS.SET_PIN && (
            <SetPin
              pin={pin}
              repeatPin={repeatPin}
              pinError={errors.pin}
              repeatPinError={errors.repeatPin}
              nextStep={this.checkPin}
              inputChange={this.onChange}
              isFetching={isFetching}
              msg={msg}
              ref={this.pinForm}
            />
          )}
          {step === STEPS.DONE && <SuccessPin />}
          {/* {step === STEPS.ENTER_TOKEN && ( */}
          {/*  <Link className='link-lost-key' to='/wallet/reset-key/email'> */}
          {/*    <I18n tKey='wallet_auth.lost_key' /> */}
          {/*  </Link> */}
          {/* )} */}
        </div>
      </div>
    );
  }
}
