import React, {Component, Fragment} from 'react';
import classNames from 'classnames';

import {ReactComponent as LeftArrow} from '../../../img/arrow.svg';

import {I18n} from '../i18n/I18n';
import {CheckBox} from '../checkBox/CheckBox';
import {Button} from '../button/Button';

export class Pagination extends Component {
  returnPagesCount = () => {
    return this.props.totalCount && this.props.limit ? Math.ceil(this.props.totalCount / this.props.limit) : 0;
  };
  renderPages = pages => {
    const {currentPage} = this.props;
    const pagesInterval = 5;
    const visiblePageInterval = currentPage + pagesInterval;
    const nextFewPages = currentPage + pagesInterval < pages.length ? currentPage + pagesInterval - 1 : pages.length;
    const prevFewPages = currentPage - pagesInterval > 1 ? currentPage - pagesInterval - 1 : 0;
    return (
      <Fragment>
        <Button
          disabled={currentPage === 1}
          title='pagination.first'
          btnClass='pagin-btn'
          onClick={this.changePage.bind(this, 0)}
        />
        {currentPage > pagesInterval && <div onClick={this.changePage.bind(this, prevFewPages)}>&#8230;</div>}
        {pages.map((elem, i) => {
          const pageTab = (
            <div
              key={i}
              onClick={this.changePage.bind(this, elem - 1)}
              className={classNames({active: elem === currentPage})}
            >
              {elem}
            </div>
          );
          if (pages.length <= pagesInterval) {
            return pageTab;
          } else if (elem >= currentPage - 1 && elem < visiblePageInterval) {
            return pageTab;
          } else if (currentPage === pages.length && elem > currentPage - pagesInterval) {
            return pageTab;
          } else if (pages.length - pagesInterval - 1 < elem && currentPage > pages.length - pagesInterval) {
            return pageTab;
          }
        })}
        {pages.length > pagesInterval && currentPage + pagesInterval <= pages.length && (
          <div onClick={this.changePage.bind(this, nextFewPages)}>&#8230;</div>
        )}
        <Button
          btnClass='pagin-btn'
          disabled={pages.length < visiblePageInterval}
          title='pagination.last'
          onClick={this.changePage.bind(this, pages.length - 1)}
        />
      </Fragment>
    );
  };

  renderRowsOrder = () => {
    const {currentPage, limit, totalCount} = this.props;
    let from = 0;
    let to = 0;
    if (currentPage <= 1) {
      from = currentPage;
      to = currentPage * limit;
    } else if (currentPage > 1) {
      from = (currentPage - 1) * limit + 1;
      to = currentPage * limit;
    }
    if (to > totalCount) {
      to = totalCount;
    }
    return `${from} - ${to}`;
  };

  changePage = page => {
    this.props.changePage && this.props.changePage(page);
  };

  render() {
    const {
      totalCount = 0,
      wrapperClass,
      onBoxChange,
      isChecked,
      withCheckBox,
      labelTxt,
      withoutNumbers,
      handlePrev,
      handleNext,
      hasPrev,
      hasNext,
    } = this.props;
    if (withoutNumbers) {
      return (
        <div className='empty-numbers-pagination'>
          <Button
            disabled={!hasPrev}
            onClick={handlePrev}
            ArrowIcon={LeftArrow}
            title='home.block_list.pagin_btn.btn_prev'
            btnClass='prev-btn'
          />
          <Button
            disabled={!hasNext}
            onClick={handleNext}
            ArrowIcon={LeftArrow}
            title='home.block_list.pagin_btn.btn_next'
            btnClass='next-btn'
          />
        </div>
      );
    }
    const pagesCount = this.returnPagesCount();
    const pages = pagesCount ? [...new Array(pagesCount)].map((elem, i) => i + 1) : [];
    return pages.length >= 1 ? (
      <div className={classNames('pagination-block', wrapperClass)}>
        {!withCheckBox ? (
          <div className='count-pages'>
            <span className='count'>{this.renderRowsOrder()} </span>
            <I18n className='count-txt' tKey='pagination.range' />
            <span className='count'> {totalCount}</span>
          </div>
        ) : (
          <CheckBox onChange={onBoxChange} label={labelTxt} isChecked={isChecked} />
        )}
        <div className='pagination-content'>{this.renderPages(pages)}</div>
      </div>
    ) : null;
  }
}
