import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

import {getEosTable} from '../../../utils/requester';
import {cutTokenPrefix, getTokenPrefix, changeFormat} from '../../../utils/utils';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import {ReactComponent as BaLogo} from '../../../img/ba_lago.svg';
import './index.scss';

const formatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

@inject('userStore', 'modalStore', 'eosStore', 'loggerStore')
@observer
export class WalletCreateBusinesAcc extends Component {
  state = {
    isFetching: false,
    fee: '',
    feeToPay: '',
  };

  getRate = async () => {
    const {data} = await getEosTable({
      code: 'interchange',
      scope: 'interchange',
      json: true,
      key_type: 'uint64',
      limit: 1,
      table: 'crurate',
    });

    return data.rows?.[0] || {};
  };

  componentDidMount = async () => {
    const {usdu} = await this.getRate();

    const newState = {};

    const {data, isError} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1,
      scope: 'curcreator',
      table: 'bafee',
    });

    if (!isError) {
      newState.fee = data?.rows?.[0]?.price;
      const cruToPay = cutTokenPrefix(newState.fee) / cutTokenPrefix(usdu);
      newState.feeToPay = `${changeFormat(cruToPay, 4, formatSettings)} CRU`;
    }

    this.setState(newState);
  };

  createBusinessAcc = async pin => {
    const {eosStore, userStore, loggerStore} = this.props;
    const {feeToPay} = this.state;

    this.setState({isFetching: true});

    const {isError} = await eosStore.createToken({
      pin,
      fee: feeToPay,
      userName: userStore.user.name,
      memo: 'mkaccnt',
      errorMsg: 'common.create_ba_error',
    });
    if (!isError) {
      loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.create_ba_success'});
      userStore.getAccountInfo();
    }

    this.setState({isFetching: false});
  };

  openCreateModal = () => {
    const {modalStore} = this.props;
    const {fee, feeToPay} = this.state;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.createBusinessAcc,
        }}
        btnTitle='common.create-btn'
        btnTitleBack='common.cancel'
      >
        <div className='create-ba-modal-info'>
          <I18n value={`${feeToPay} (≈${fee})`} tKey='business_acc.fee-info' />
        </div>
      </EnterPin>
    ));
  };

  render() {
    const {isFetching, fee, feeToPay} = this.state;
    const {userStore} = this.props;
    const canPayFee = +cutTokenPrefix(feeToPay) <= +userStore.userWallet[getTokenPrefix(feeToPay)]?.available || 0; // ??????

    return (
      <div className='create-business-acc-page nested-route margin'>
        <div>
          <I18n tKey='business_acc.title' className='title-page' />
          <div className='wallet-content'>
            <BaLogo />
            <I18n tKey='business_acc.title-create' className='block-title' />
            <div className='notice-text-wrapper d-flex'>
              <div className='wrapper-flex-item wide centered-content'>
                <InfoIcon />
              </div>
              <div className='d-flex'>
                <I18n tKey='business_acc.fee_cost' value={`${feeToPay} (≈${fee})`} />
              </div>
            </div>

            <I18n className='sub-text' tKey='business_acc.fee_notice' />
            <Button
              btnClass='with-bg blue'
              title='common.create-btn'
              disabled={isFetching || !fee || !canPayFee}
              onClick={this.openCreateModal}
            />
          </div>
        </div>
      </div>
    );
  }
}
