import React, {Component} from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import {inject} from 'mobx-react';

import {MenuBtn} from '../../helpers/menuBtn/MenuBtn';
import {I18n} from '../../helpers/i18n/I18n';
import {LinkListWallet} from '../../mainPages/walletHeader/LinkListWallet';

Modal.setAppElement('#root');

@inject('userStore')
export class MobileMenuWallet extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.querySelector('#root').style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
    document.querySelector('#root').style.overflow = 'initial';
  }

  logout = () => {
    const {closeModal} = this.props;
    this.props.userStore.removeUser();
    closeModal();
  };

  render() {
    const {isOpen, closeModal} = this.props;
    const className = classNames('mobile-modal-overlay-wallet');
    return (
      <Modal overlayClassName={className} className='modal-menu-wallet' isOpen={isOpen}>
        <div className='modal-menu-content' onClick={closeModal}>
          <div className='header-modal'>
            <I18n tKey='wallet_page.wallet_menu' />
            <MenuBtn isOpen />
          </div>
          <LinkListWallet
            isProducer={this.props.userStore.isProducerAccount}
            onClick={this.logout}
            userName={this.props.userStore.user.name}
          />
        </div>
        <div className='click-block' onClick={closeModal} />
      </Modal>
    );
  }
}
