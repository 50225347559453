import React, {Component} from 'react';

import {OperationIcon} from '../operationIcon/OperationIcon';
import {OperationName} from '../operationName/OperationName';
import moment from 'moment';
import {TIME_FORMAT, TOKENS} from '../../../config/const';
import {changeFigureSign, setAmount} from '../../../utils/utils';
import {CountWithZero} from '../countWithZero/CountWithZero';

export class TrxRow extends Component {
  render() {
    const {obj, currentUser} = this.props;
    const data = setAmount(obj);
    return (
      <div className='trx-row-container'>
        <div className='row-icon'>
          <OperationIcon obj={obj} currentUser={currentUser} />
        </div>
        <div className='title-row'>
          <span>
            <OperationName obj={obj} currentUser={currentUser} />
          </span>
          <div className='data-row'>
            {data ? (
              <CountWithZero
                round={data.round}
                dividedView={true}
                plusBeforeSumm={obj.action_name === 'transfer' && obj.token_to === currentUser}
                count={data.amount * changeFigureSign(obj, currentUser)}
                countClass='count-row'
              >
                {` ${data.symbol}`}
              </CountWithZero>
            ) : (
              <span />
            )}
            <span> {`${moment(obj.timestamp).format(TIME_FORMAT.TRANSACTIONS)}`}</span>
          </div>
          {(obj.action_name === 'stake' || obj.action_name === 'unstake') && (obj.symbol === TOKENS.WCRU || obj.symbol === TOKENS.CRU) && (
            <span className='time-in-hours'>{moment(obj.timestamp).format(TIME_FORMAT.HOURS)}</span>
          )}
        </div>
      </div>
    );
  }
}
