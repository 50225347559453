import {Route, Switch, withRouter, Redirect} from 'react-router';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import classNames from 'classnames';

import {Helmet} from 'react-helmet';
import Home from './home/Home';
import {NoMatch} from './noMatch/NoMatch';
import {ModalListener} from '../modals/ModalListener';
import {Header} from './header/Header';
import {Blocks} from './blocks/Blocks';
import {Accounts} from './accounts/Accounts';
import {Transactions} from './transactions/Transactions';
import {AccountDetails} from './accountDetails/AccountDetails';
import {BlockDetails} from './blockDetails/BlockDetails';
import {TransactionDetails} from './transactionDetails/TransactionDetails';
import {SwitchToken} from './switchToken/SwitchToken';
import {WalletAuth} from './walletAuth/WalletAuth';
import {WalletRouter} from './WalletRouter';
import {CreateWallet} from './createWallet/CreateWallet';
import {Logger} from './logger/Logger';
import {ChangeKey} from './changeKey/ChangeKey';
import {SeedPhraseToKey} from './seedPhaseToKey/SeedPhraseToKey';
import {Producer} from './producer/Producer';

const ROUTES = {
  visibleForAll: [
    {path: '/', exact: true, component: Home},
    {path: '/accounts', exact: true, component: Accounts},
    {path: '/accounts/:name', exact: true, component: AccountDetails},
    {path: '/account', exact: true, component: Accounts},
    {path: '/account/:name', exact: true, component: AccountDetails},
    {path: '/blocks', exact: true, component: Blocks},
    {path: '/blocks/:number', exact: true, component: BlockDetails},
    {path: '/transactions', exact: true, component: Transactions},
    {path: '/transactions/:id', exact: true, component: TransactionDetails},
    {path: '/wallet/auth', exact: true, component: WalletAuth},
    {path: `/change-key`, component: ChangeKey},
    {path: `/seed-to-key`, component: SeedPhraseToKey},
    {path: `/producer`, component: Producer},
    {path: `/wallet/*`, component: WalletAuth},
    {path: '*', component: NoMatch},
  ],
  guest: [
    {
      path: [
        '/wallet',
        '/wallet/convert',
        '/wallet/converted-ok',
        '/wallet/converted-err',
        '/wallet/send',
        '/wallet/send/*',
        '/wallet/staking',
        '/wallet/staking/*',
        '/wallet/cru',
        '/wallet/untb',
        '/wallet/ram',
        '/wallet/history',
        '/wallet/vote',
        '/wallet/dividends',
        '/wallet/dividends-usdu',
        '/wallet/wcru',
      ],
      exact: true,
      component: WalletAuth,
    },
    {path: '/wallet/create', exact: true, component: CreateWallet},
  ],
  user: [
    {path: '/wallet/create', exact: true, component: () => <Redirect to='/wallet' />},
    {path: '/wallet', component: WalletRouter},
  ],
};

@withRouter
@inject('userStore', 'localizeStore')
@observer
class MainLayout extends Component {
  render() {
    const {
      userStore: {user},
      history: {location},
    } = this.props;

    const isWalletPage = /\/wallet/.test(location.pathname);
    const isPrivatePage = /\/wallet\/create|\/reset-key\/emai|\/reset-key\/confirm/.test(location.pathname);
    const routes = user ? ROUTES.user.concat(ROUTES.visibleForAll) : ROUTES.guest.concat(ROUTES.visibleForAll);

    return (
      <main className='main-wrapper'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Cryptounit Explorer</title>
          <meta name='description' content='Blockchain explorer' />
        </Helmet>
        <ModalListener />
        <Logger />
        {!isPrivatePage && <Header />}
        <div className={classNames('content-wrapper', {column: isWalletPage})}>
          {!isWalletPage && !isPrivatePage && <SwitchToken history={this.props.history} />}
          <Switch>
            {routes.map(route => (
              <Route key={route.path} {...route} />
            ))}
          </Switch>
        </div>
      </main>
    );
  }
}

export default MainLayout;
