import React, {Component} from 'react';
import classNames from 'classnames';

import {ReactComponent as EyeIcon} from '../../../img/eye-open.svg';
import {ReactComponent as EyeIconLocked} from '../../../img/eye-locked.svg';
import {ReactComponent as IconClear} from '../../../img/icon-close.svg';

import {I18n} from '../i18n/I18n';
import {translate} from '../../../utils/translater';

export class Input extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      inputType: props.isPassword ? 'password' : 'text',
    };
  }

  componentDidMount() {
    const {autoFocus} = this.props;

    autoFocus && this.inputRef.current.focus();
  }

  switchVisibility = () => {
    this.setState(prevState => ({
      inputType: prevState.inputType === 'password' ? 'text' : 'password',
    }));
  };

  clearValue = () => {
    const {onChange, name} = this.props;
    onChange && onChange(name, '');
  };

  onChange = ev => {
    const {onlyNumbers = false, onChange, maxLength, regEx, maxAmount, useTrim} = this.props;
    let canSetNewValue = true;
    let {value} = ev.target;
    if (useTrim) {
      value = (value || '').trim();
    }
    if (onlyNumbers) {
      canSetNewValue = /^\d+$/.test(value) || !value.length;
    }
    if (regEx) {
      canSetNewValue = regEx.test(value) || !value.length;
    }
    if (maxLength) {
      value.length > maxLength && (canSetNewValue = false);
    }
    if (maxAmount !== undefined) {
      Number(value) > Number(maxAmount) && (canSetNewValue = false);
    }
    canSetNewValue && onChange && onChange(ev.target.name, value);
  };

  onFocus = () => {
    const {onFocus} = this.props;

    onFocus && onFocus();
  };

  onBlur = e => {
    const {onBlur} = this.props;

    onBlur && onBlur(e);
  };

  render() {
    const {
      showEye,
      readOnly,
      statusBorder,
      checkIsFilled,
      allowClear,
      name,
      onlyNumbers,
      onKeyPress,
      label,
      value,
      onKeyDown,
      onClick,
      error,
      containerClass,
      placeholder,
    } = this.props;
    const {inputType} = this.state;
    const canRenderCrossIcon = !!value && allowClear;
    const showFilledInBorder = checkIsFilled ? checkIsFilled() : value;
    const wrapperClass = classNames(
      {
        'show-status-border': statusBorder,
        'filled-in': showFilledInBorder,
        'default-label': true,
        'error-state': error,
        'with-clear-icon': canRenderCrossIcon,
      },
      containerClass
    );
    const PassIcon = inputType === 'password' ? EyeIconLocked : EyeIcon;
    return (
      <div className={wrapperClass}>
        {label && <I18n tKey={label} className='label-input' />}
        <div className='input-container'>
          {showEye && <PassIcon onClick={this.switchVisibility} className='eye-img' />}
          <input
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            ref={this.inputRef}
            placeholder={translate(placeholder)}
            onClick={onClick}
            value={value}
            type={inputType}
            name={name}
            autoComplete='one-time-code' // removes remember password pop up (working on latest chrome version)
            className='default-input'
            {...(onlyNumbers && {pattern: '[0-9]*', inputMode: 'numeric'})}
          />
          {canRenderCrossIcon && <IconClear onClick={this.clearValue} className='clear-icon' />}
        </div>
      </div>
    );
  }
}
