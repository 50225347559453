import React, {Component} from 'react';
import {Prompt} from 'react-router';
import {inject, observer} from 'mobx-react';
import {Tabs, Tab} from '@material-ui/core';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import {ReactComponent as ChipIcon} from '../../../img/chip.svg';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import cruIcon from '../../../img/mobile_cru.png';
import wcruIcon from '../../../img/mobile_wcru.png';
import usduIcon from '../../../img/mobile_usdu.png';
import untbIcon from '../../../img/mobile_untb.png';

import {UpdateFrozenBtn} from './UpdateFrozenBtn';

import {WalletCardToken} from './WalletCardToken';

import {Button} from '../../helpers/button/Button';
import {Filter} from '../../helpers/filter/Filter';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {TOKENS} from '../../../config/const';
import {Preloader} from '../../helpers/preloader/Preloader';

import {translate} from '../../../utils/translater';
import {getTokenIcon} from '../../../utils/utils';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {UpdateFrozenUserTokensBtn} from './UpdateFrozenUserTokensBtn';

const commonTokens = ['CRU', 'WCRU', 'UNTB', 'USDU'];
const filter = [
  {label: 'wallet_dashboard.token_filter.all', value: 'All'},
  {label: 'wallet_dashboard.token_filter.favourite', value: 'Favourite'},
];

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class WalletDashboard extends Component {
  state = {
    fetching: false,
    selectedTokenFilter: filter[0],
    selectedTab: 0,
    favouriteTokens: this.props.userStore.userSettings?.tokens || [],
  };

  componentDidUpdate = prevProps => {
    const {userStore} = this.props;

    if (prevProps.userStore.userSettings?.tokens !== userStore.userSettings?.tokens) {
      this.setState({favouriteTokens: userStore.userSettings?.tokens || []});
    }
  };

  toggleFavourite = token => {
    this.setState(prevState => {
      const favouriteTokens = [...prevState.favouriteTokens];
      const index = favouriteTokens.indexOf(token);

      if (index > -1) {
        favouriteTokens.splice(index, 1);
      } else {
        favouriteTokens.push(token);
      }

      return {favouriteTokens};
    });
  };

  updateDashboardInfo = async () => {
    const {userStore} = this.props;

    this.setState({fetching: true});
    await userStore.getAccountInfo(userStore.user.name);
    this.setState({fetching: false});
  };

  redirectCruForCruStaking = () => {
    this.props.history.push('/wallet/staking');
  };

  changeOwnState = newState => {
    this.setState(newState);
  };

  checkIsTokenChanged = () => {
    const {favouriteTokens} = this.state;
    const {userSettings} = this.props.userStore;

    return !(
      favouriteTokens.length === userSettings.tokens.length &&
      favouriteTokens.every(token => {
        return userSettings?.tokens.includes(token);
      })
    );
  };

  saveTokens = async pin => {
    const {eosStore, userStore, loggerStore} = this.props;
    const {favouriteTokens} = this.state;

    const {isError} = await eosStore.setUserSettings({
      pin,
      userName: userStore.user.name,
      settingName: 'tokens',
      value: JSON.stringify(favouriteTokens),
      errorMsg: 'common.save_favourite_tokens_error',
    });

    if (!isError) {
      loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.save_favourite_tokens_success'});
      userStore.getAccountInfo();
    }
  };

  openSaveTokensModal = () => {
    const {modalStore} = this.props;

    const additionalClass = 'convert-wcru-content';

    modalStore.open(({isOpen, closeModal, onOk, onCancel}) => (
      <EnterPin
        isOpen={isOpen}
        closeModal={closeModal}
        onOk={onOk}
        onCancel={onCancel}
        params={{
          pinContentClass: additionalClass,
          onValidPin: this.saveTokens,
        }}
        btnTitle='common.save-btn'
        btnTitleBack='common.cancel'
      >
        <div className='create-ba-modal-info'>
          <I18n value='asd' tKey='wallet_dashboard.save-favourite-tokens' />
        </div>
      </EnterPin>
    ));
  };

  render() {
    const {fetching, selectedTab, favouriteTokens} = this.state;
    const {userWallet, userDebts} = this.props.userStore;

    return (
      <div className='dashboard-page nested-route margin'>
        {fetching && <Preloader />}
        <div className='dashboard-portfolio'>
          <div className='portfolio-header'>
            <div className='nav-bar'>
              <Tabs
                value={selectedTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={(e, val) => {
                  this.setState({selectedTab: val});
                }}
              >
                <Tab label={<I18n tKey='wallet_page.dashboard.title' className='title-page' />} />
                <Tab label={<I18n tKey='wallet_dashboard.user_tokens_filter' className='title-page' />} />
                <Tab
                  label={
                    <>
                      <I18n tKey='wallet_dashboard.token_filter.favourite' className='title-page' />
                      <StarBorderIcon />
                    </>
                  }
                />
              </Tabs>
            </div>
          </div>

          <div className='tokens-filter-wrapper'>
            <div role='presentation' onClick={this.updateDashboardInfo} className='refresh-block'>
              <RefreshIcon />
              <I18n tKey='wallet_page.dashboard.refresh' className='refresh-title' />
              <ChipIcon />
            </div>
            {selectedTab === 0 ? (
              <UpdateFrozenBtn changeParentState={this.changeOwnState} />
            ) : (
              <UpdateFrozenUserTokensBtn changeParentState={this.changeOwnState} />
            )}

            <Prompt when={this.checkIsTokenChanged()} message={translate('wallet_dashboard.not-saved-favourite-tokens')} />
            <Button disabled={!this.checkIsTokenChanged()} onClick={this.openSaveTokensModal}>
              <I18n tKey='wallet_dashboard.save-favourite-btn' />
            </Button>
          </div>

          {selectedTab === 0 && (
            <>
              <WalletCardToken
                countToken={userWallet.CRU.total}
                tokenInfo={userWallet.CRU}
                token={TOKENS.CRU}
                redirect={this.redirectCruForCruStaking}
                cardWrapper='cru-token'
                cartTitle='wallet_card.cru_title'
                fullTitle='wallet_card.cru_full'
                btnStake
                cardIcon={cruIcon}
                debts={userDebts.CRU}
                // converterCount="1.254"
                unitCount='USDU'
                btnExchange
              />
              <WalletCardToken
                countToken={userWallet.WCRU.total}
                tokenInfo={userWallet.WCRU}
                token={TOKENS.WCRU}
                cardWrapper='wcru-token'
                cartTitle='wallet_card.wcru_title'
                fullTitle='wallet_card.wcru_full'
                btnStake
                cardIcon={wcruIcon}
                debts={userDebts.WCRU}
                // converterCount="1.254"
                unitCount={TOKENS.WCRU}
              />
              <WalletCardToken
                countToken={userWallet.UNTB.total}
                tokenInfo={userWallet.UNTB}
                token={TOKENS.UNTB}
                cardWrapper='untb-token'
                cartTitle='wallet_card.untb_title'
                fullTitle='wallet_card.untb_full'
                btnStake
                cardIcon={untbIcon}
                debts={userDebts.UNTB}
                // converterCount="1.254 "
                unitCount='USDU'
              />
              <WalletCardToken
                countToken={userWallet.USDU.total}
                tokenInfo={userWallet.USDU}
                token={TOKENS.USDU}
                cardWrapper='usdu-token'
                cartTitle='wallet_card.usdu_title'
                fullTitle='wallet_card.usdu_full'
                cardIcon={usduIcon}
                debts={userDebts.USDU}
              />
            </>
          )}

          {(selectedTab === 1 || selectedTab === 2) &&
            Object.keys(userWallet)
              .filter(token => {
                const active = !commonTokens.includes(token);

                if (selectedTab === 2) {
                  return active && favouriteTokens.includes(token);
                }

                return active;
              })
              .map(token => {
                return (
                  <WalletCardToken
                    key={token}
                    countToken={userWallet?.[token]?.total || 'No balance'}
                    tokenInfo={userWallet[token]}
                    token={token}
                    cartTitle={token}
                    fullTitle={userWallet?.[token]?.fullName}
                    cardIcon={getTokenIcon(token)}
                    fallBackIcon={getTokenIcon(token)}
                    rounding={userWallet?.[token]?.rounding}
                    toggleFavourite={this.toggleFavourite}
                    favourite={favouriteTokens.includes(token)}
                  />
                );
              })}
        </div>
      </div>
    );
  }
}
