import React from 'react';
import {NavLink} from 'react-router-dom';
import {I18n} from '../../helpers/i18n/I18n';
import {DropDown} from '../../helpers/dropDown/DropDown';

export const LinkList = props => (
  <div className='nav-bar'>
    <DropDown
      wrapperClass={props.class}
      onChange={props.onChange}
      value={props.value}
      options={props.options}
      formatOptionLabel={props.formatOptionLabel}
      placeholder=''
    />
    <NavLink onClick={props.onClick} exact className='nav-item' activeClassName='nav-active' to='/'>
      <I18n tKey='header.home_tab' />
    </NavLink>
    <NavLink onClick={props.onClick} exact className='nav-item' activeClassName='nav-active' to='/accounts'>
      <I18n tKey='header.accounts_tab' />
    </NavLink>
    <NavLink onClick={props.onClick} exact className='nav-item' activeClassName='nav-active' to='/blocks'>
      <I18n tKey='header.blocks_tab' />
    </NavLink>
    <NavLink onClick={props.onClick} exact className='nav-item' activeClassName='nav-active' to='/transactions'>
      <I18n tKey='header.transact_tab' />
    </NavLink>
    <NavLink onClick={props.onClick} className='nav-item' activeClassName='nav-active' to='/wallet'>
      <I18n tKey='header.wallet_tab' />
    </NavLink>
    <NavLink
      onClick={props.onClick}
      exact
      className={`nav-item ${props.isLoggedIn ? 'disable' : ''}`}
      activeClassName='nav-active'
      to='/wallet/create'
    >
      <I18n tKey='header.wallets_create_tab' />
      <I18n tKey='' />
    </NavLink>
    <NavLink onClick={props.onClick} exact className='nav-item' activeClassName='nav-active' to='/producer'>
      <I18n tKey='header.producer_tab' />
    </NavLink>
    {/* <NavLink onClick={props.onClick} exact className='nav-item disable' activeClassName='nav-active' to='/validators'>
      <I18n tKey='header.validators_tab' />
      <I18n tKey='header.soon' />
    </NavLink> */}
    <NavLink onClick={props.onClick} exact className='nav-item disable' activeClassName='nav-active' to='/dex'>
      <I18n tKey='header.dex_tab' />
      <I18n tKey='header.soon' />
    </NavLink>
  </div>
);
