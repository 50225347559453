import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {CMP_STEPS} from './CreateWallet';
import {Preloader} from '../../helpers/preloader/Preloader';
import shield from '../../../img/shield.svg';
import vectorLine from '../../../img/vector-line.svg';
import wallet from '../../../img/wallet.svg';
import person from '../../../img/person.svg';

export default class RegisterWallet extends Component {
  state = {
    isFetching: false,
    msg: null,
  };

  setStep = () => {
    const {setParams, setStep, step} = this.props;

    const newStep = step === CMP_STEPS.CREATE_WALLET_PHASE ? CMP_STEPS.RECOVERY_SEED_PHRASE : CMP_STEPS.RECOVERY_SEED_PHRASE;

    setParams(this.state);
    setStep(newStep);
  };

  render() {
    const {isFetching, msg} = this.state;

    return (
      <div>
        {isFetching && <Preloader className='white-transparent-bg' />}
        <h1 className='block-font lg-size'>
          <I18n tKey='user-create.lets-start' />
        </h1>
        <div className='subtitle-row'>
          <I18n tKey='user-create.wallet-intro'> </I18n>
        </div>
        <h1 className='subtext subheadline'>
          {' '}
          <I18n tKey='user-create.follow-steps'> </I18n>
        </h1>

        <div className='wrapper-flex'>
          <div className='wrapper-flex-item wide'>
            <div className='circular__img'>
              <img alt='shield' src={shield} />
            </div>
          </div>
          <div className='wrapper-flex-item '>
            <img alt='line' src={vectorLine} />
          </div>

          <div className='wrapper-flex-item wide'>
            <div className='circular__img'>
              <img alt='person' src={person} />
            </div>
          </div>
          <div className='wrapper-flex-item'>
            <img alt='line' src={vectorLine} />
          </div>

          <div className='wrapper-flex-item wide'>
            <div className='circular__img'>
              <img alt='wallet' src={wallet} />
            </div>
          </div>
        </div>
        <div className='wrapper-flex'>
          <div className='wrapper-flex-item wide'>
            {' '}
            <p className='subtext'>
              <I18n tKey='user-create.backup' />
            </p>{' '}
          </div>
          <div className='wrapper-flex-item' />
          <div className='wrapper-flex-item wide'>
            {' '}
            <p className='subtext'>
              {' '}
              <I18n tKey='user-create.choose-name' />
            </p>
          </div>
          <div className='wrapper-flex-item' />
          <div className='wrapper-flex-item wide'>
            {' '}
            <p className='subtext'>
              {' '}
              <I18n tKey='user-create.top-up' />
            </p>
          </div>
        </div>
        <Button
          disabled={msg || isFetching}
          btnClass='next-step-btn with-bg blue'
          onClick={this.setStep}
          title='user-create.start'
        />
      </div>
    );
  }
}
