import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export const MSG_TYPES = ['ok', 'error', 'warning'];

export class MsgPrinter extends Component {
  render() {
    const {msgType, msg, className} = this.props;
    const wrapperClass = classNames('msg-printer-view', className, {
      'ok-txt': MSG_TYPES[0] === msgType,
      'error-txt': MSG_TYPES[1] === msgType,
      'warning-txt': MSG_TYPES[2] === msgType,
    });
    return (
      <div className={wrapperClass}>
        <div className='msg-content'>
          <I18n tKey={msg} />
        </div>
      </div>
    );
  }
}
