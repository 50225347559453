import React, {Component} from 'react';

import {ReactComponent as Arrow} from '../../../img/blue_arrow.svg';
import cardImg from '../../../img/cards.svg';

import {I18n} from '../../helpers/i18n/I18n';

export class CardBlockInfo extends Component {
  render() {
    const {onClick, countBalance, subtitle, title} = this.props;
    return (
      <div className='user-info-block' onClick={onClick}>
        <p className='user-img'>
          <img alt='' src={cardImg} />
        </p>
        <div>
          <I18n tKey={title} className='user-title' />
        </div>
        {/*<Arrow />*/}
      </div>
    );
  }
}
