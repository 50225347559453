import BigNumber from 'bignumber.js';
import React, {Component} from 'react';

import wcruCard from '../../../img/new_wcru.png';
import cruCard from '../../../img/new_cru.png';
import usduCard from '../../../img/new_usdu.png';
import untbCard from '../../../img/new_untb.png';

import {TableBalance} from './TableBalance';
import {I18n} from '../../helpers/i18n/I18n';
import {UserStore} from '../../../stores/UserStore';
import {StatusLine} from '../../helpers/statusLine/StatusLine';
import {calcPercent, convertBytes, cutTokenPrefix, timeConversion, getTokenIcon} from '../../../utils/utils';

const tableBalance = {
  columns: [
    {name: '', type: true},
    {name: 'account.details.table_column.col_total', key: 'total'},
    {name: 'account.details.table_column.col_available', key: 'available'},
    {name: 'account.details.table_column.col_stake', key: 'staked'},
    {name: 'account.details.table_column.col_frozen', key: 'frozen'},
  ],
};

export class AccountBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balances: {
        CRU: {
          title: 'wallet_card.cru_full',
          shortName: 'CRU',
          total: '0.0000',
          available: '0.0000',
          staked: '0.0000',
          frozen: '0.0000',
          icon: cruCard,
        },
        UNTB: {
          title: 'wallet_card.untb_full',
          shortName: 'UNTB',
          total: '0.0000',
          available: '0.0000',
          staked: '0.0000',
          frozen: '0.0000',
          icon: untbCard,
        },
        USDU: {
          title: 'wallet_card.usdu_full',
          shortName: 'USDU',
          total: '0.0000',
          available: '0.0000',
          staked: '—',
          frozen: '—',
          icon: usduCard,
        },
        WCRU: {
          title: 'wallet_card.wcru_full',
          shortName: 'WCRU',
          total: '0.0000',
          available: '0.0000',
          staked: '0.0000',
          frozen: '0.0000',
          icon: wcruCard,
        },
      },
      isFetching: true,
      statusLine: this.processAccountInfo(props.account),
      isError: false,
    };
  }

  componentDidMount() {
    this.getAccountInfo();
  }

  getAccountInfo = async () => {
    const {account = {}} = this.props;
    const requests = UserStore.returnRequests(account.account_name);
    const userTokens = await UserStore.getUserTokens(account.account_name);
    const extraTokens = Object.keys(userTokens).reduce((acc, token) => {
      const total = cutTokenPrefix(userTokens[token].total);

      acc[token] = {
        title: userTokens[token].fullName,
        shortName: token,
        total: total || '0.0000',
        available: total || '0.0000',
        staked: '—',
        frozen: '—',
        icon: getTokenIcon(token),
        rounding: userTokens[token].rounding,
      };

      return acc;
    }, {});

    return Promise.all(requests)
      .then(UserStore.processAccRequests)
      .then(resp => {
        const {
          refundsUntb,
          stakers,
          tbalance,
          untbStaked,
          untbAvailable,
          untbFrozen,
          accountDetails,
          cruAvailable,
          wcruAvailable,
          untbTotal,
          usduTotal,
          cruTotal,
          wcruTotal,
          cruForCruStaked,
          cruForCruStakedActual,
        } = resp.calcData;

        const newState = {isFetching: false};
        const isError = resp.r.some(elem => elem.isError);
        newState.balances = {
          CRU: {
            ...this.state.balances.CRU,
            ...UserStore.returnCurrencyFields(
              cruTotal,
              cruAvailable,
              new BigNumber(cutTokenPrefix(stakers.staked_cru_balance)).plus(cruForCruStakedActual).toNumber(),
              tbalance.cru_frozen
            ),
          },
          WCRU: {
            ...this.state.balances.WCRU,
            ...UserStore.returnCurrencyFields(wcruTotal, wcruAvailable, stakers.staked_wcru_balance, tbalance.wcru_frozen),
          },
          UNTB: {
            ...this.state.balances.UNTB,
            ...UserStore.returnCurrencyFields(untbTotal, untbAvailable.valueOf(), untbStaked.valueOf(), untbFrozen.valueOf()),
          },
          USDU: {
            ...this.state.balances.USDU,
            ...UserStore.returnCurrencyFields(usduTotal, usduTotal),
          },
          ...extraTokens,
        };

        if (isError) {
          newState.isError = true;
        }
        this.setState(newState);
      });
  };

  processAccountInfo = account => {
    const cpuPercent = calcPercent(account.cpu_limit.used, account.cpu_limit.max);
    const netPercent = calcPercent(account.net_limit.used, account.net_limit.max);
    const ramPercent = calcPercent(account.ram_usage, account.ram_quota);
    return [
      {
        title: 'RAM',
        percent: `${ramPercent}%`,
        size: `${ramPercent}%`,
        countLine: convertBytes(account.ram_usage),
        totalCount: convertBytes(account.ram_quota),
      },
      {
        title: 'CPU',
        percent: `${cpuPercent}%`,
        size: `${cpuPercent}%`,
        // countLine: `${account.cpu_limit.used / 1000} ms`,
        countLine: timeConversion(account.cpu_limit.used),
        totalCount: timeConversion(account.cpu_limit.max),
      },
      {
        title: 'NET',
        percent: `${netPercent}%`,
        size: `${netPercent}%`,
        countLine: convertBytes(account.net_limit.used),
        totalCount: convertBytes(account.net_limit.max),
      },
    ];
  };

  render() {
    const balances = Object.keys(this.state.balances).map(key => ({...this.state.balances[key]}));
    return (
      <div className='balance-block'>
        <I18n className='main-title md' tKey='account.details.table_title' />
        <TableBalance
          accName={this.props.accountName}
          isError={this.state.isError}
          isFetching={this.state.isFetching}
          data={{...tableBalance, rows: balances}}
        />
        <div className='lines-block'>
          {this.state.statusLine.map((elem, i) => (
            <StatusLine
              lineClass='balance-line'
              key={i}
              title={elem.title}
              percent={elem.percent}
              countLine={elem.countLine}
              totalCount={elem.totalCount}
              size={elem.size}
            />
          ))}
        </div>
      </div>
    );
  }
}
