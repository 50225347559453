import {inject, observer} from 'mobx-react';
import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {BlockList} from './BlockList';
import {SupplyAndStaking} from './SupplyAndStaking';

import {Table} from '../../helpers/table/Table';
import {Button} from '../../helpers/button/Button';
import {Carousel} from '../../helpers/carousel/Carousel';
import {CardToken} from '../../helpers/cardToken/CardToken';
import {Preloader} from '../../helpers/preloader/Preloader';
import {Blockchain} from '../../helpers/blockchain/Blockchain';
import {Pagination} from '../../helpers/pagination/Pagination';

import {TOKENS} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {Emission} from './Emission';
import {Distribution} from './Distribution';
import {LineGraph} from './LineGraph';
import {changeFormat, percentFromNum} from '../../../utils/utils';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import {I18n} from '../../helpers/i18n/I18n';
import ShimmerLayout from './ShimmerLayout';

const tableTop = {
  columns: [
    {name: 'home.table.col_rank', key: 'rank'},
    {name: 'home.table.col_name', url: obj => `accounts/${obj.name}`, key: 'name'},
    {name: 'home.table.col_balance_cru', key: 'amount', render: obj => <span>{changeFormat(obj.amount, 0)}</span>},
    {
      name: 'home.table.col_hold',
      key: 'holding',
      render: (obj, amount) => <span>{percentFromNum(obj.amount, amount === 0 ? '1' : amount)}%</span>,
    },
  ],
};

const cardToken = [
  {cardTitle: 'cru', subtitle: 'card_tokens.subtitle_cru', cardClass: 'cru-card', token: TOKENS.CRU},
  {cardTitle: 'wcru', subtitle: 'card_tokens.subtitle_wcru', cardClass: 'wcru-card', token: TOKENS.WCRU},
  {cardTitle: 'UNTB', subtitle: 'card_tokens.subtitle_untb', cardClass: 'untb-card', token: TOKENS.UNTB},
  {cardTitle: 'usdu', subtitle: 'card_tokens.subtitle_usdu', cardClass: 'usdu-card ', token: TOKENS.USDU},
];

@inject('tokenStore')
@observer
class Home extends Component {
  state = {
    activated_stake: 0,
    holdersAreUpdating: false,
  };

  componentDidMount() {
    getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'global'}).then(r => {
      const newState = {activated_stake: 0};
      if (!r.isError) {
        newState.activated_stake = r.data.rows[0].thresh_activated_stake_time;
      }
      this.setState(newState);
    });
  }

  setActiveToken = token => {
    this.props.tokenStore.setActiveToken(token);
  };

  renderSlides = () => {
    const {activeToken} = this.props.tokenStore;
    return cardToken.map((elem, i) => (
      <div key={i} className='card-slider-wrapper'>
        <CardToken
          cardWrapper={elem.cardClass}
          fullTitle={elem.subtitle}
          cartTitle={elem.cardTitle}
          cardClicked={this.setActiveToken.bind(this, elem.token)}
          active={activeToken === elem.token}
        />
      </div>
    ));
  };

  updateHoldersTable = () => {
    if (!this.state.holdersAreUpdating) {
      this.setState({holdersAreUpdating: true});
      this.props.tokenStore.setPage(0).then(r => {
        this.setState({holdersAreUpdating: false});
      });
    }
  };

  render() {
    const {tokenStore} = this.props;
    const {activeToken, tokenInfo, tokenInfoIsFetching, tokens, reservedData, totalAccountsWithBalance} = tokenStore;
    const {page, tableData, limit, accountsAmount, totalAccounts, zeroBalances, totalAmount, holders} = tokenInfo;
    const sliderSettings = {
      slidesPerView: 'auto',
      // spaceBetween: 10,
      slideToClickedSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      slidesPerGroup: 1,
      // centeredSlides: true,
      touchRatio: 0.2,
    };
    if (activeToken === TOKENS.WCRU) {
      tableTop.columns[2].name = 'home.table.col_balance_wcru';
    } else if (activeToken === TOKENS.USDU) {
      tableTop.columns[2].name = 'home.table.col_balance_usdu';
    } else if (activeToken === TOKENS.UNTB) {
      tableTop.columns[2].name = 'home.table.col_balance_untb';
    } else {
      tableTop.columns[2].name = 'home.table.col_balance_cru';
    }
    const maxTableRows = 500;
    return (
      <div className='home-page'>
        <Carousel wrapperClass='card-slider' renderSlides={this.renderSlides} settings={sliderSettings} />
        <div className='centered-block'>
          {tokenInfoIsFetching ? (
            <Preloader className='white-bg' />
          ) : (
            <>
              <SupplyAndStaking reservedData={reservedData} tokens={tokens} activeToken={activeToken} />

              {
                Number.isInteger(totalAccounts) ? (
                  <div>
                    {activeToken === TOKENS.UNTB && (
                      <Emission activateState={this.state.activated_stake} emission={tokenStore.emission} />
                    )}
                    <Distribution
                      totalAccounts={totalAccounts}
                      zeroBalances={zeroBalances}
                      activeToken={activeToken}
                      accountsAmount={accountsAmount}
                    />
                    <div className='bottom-content'>
                      <LineGraph activeToken={activeToken} totalAmount={totalAmount} holders={holders} />
                      <div
                        className={classNames('update-btn', {active: this.state.holdersAreUpdating})}
                        onClick={this.updateHoldersTable}
                      >
                        <I18n tKey='common.table.update' />
                        <RefreshIcon />
                      </div>
                      <Table totalAmount={totalAmount} data={{...tableTop, rows: tableData}} />
                      <Pagination
                        changePage={this.props.tokenStore.setPage}
                        currentPage={page + 1}
                        limit={limit}
                        totalCount={
                          totalAccountsWithBalance[activeToken] > maxTableRows
                            ? maxTableRows
                            : totalAccountsWithBalance[activeToken]
                        }
                      />
                      <div className='more-wrapper-btn'>
                        <Button btnClass='more-btn' title='home.more_btn' />
                      </div>
                    </div>
                  </div>
                ) : (
                  <ShimmerLayout blockListLayout={false} />
                ) // because && and operator will render "0"
              }
            </>
          )}
        </div>
        {
          Number.isInteger(totalAccounts) ? <BlockList /> : <BlockList />
          //  <ShimmerLayout blockListLayout={true}/>
          // TODO:  fully responsive right-block with shimmer
        }
        <Blockchain />
      </div>
    );
  }
}

export default Home;
