import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import JSONTree from 'react-json-tree';
import {ArrowUpward, ArrowDownward, Sort} from '@material-ui/icons';
import {I18n} from '../i18n/I18n';
import {Preloader} from '../preloader/Preloader';
import {JSON_TREE_THEME} from '../../../config/const';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';

export class Table extends Component {
  state = {
    openedRow: null,
    sortColumn: '',
    sortDirection: '',
    sortedRows: [],
  };

  openRow = key => {
    if (this.state.openedRow === key) {
      this.setState({openedRow: null});
      return;
    }
    this.setState({openedRow: key});
  };

  rowClick = row => {
    this.props.rowClick && this.props.rowClick(row);
  };

  sort = column => () => {
    if (column.withSort) {
      const {sort} = this.props;

      const {sortKey, key} = column;

      this.setState(({sortColumn, sortDirection}) => {
        let sortParams = {
          sortColumn: '',
          sortDirection: '',
        };

        if ((sortColumn !== key && sortColumn !== sortKey) || sortDirection === '') {
          sortParams = {
            sortColumn: sortKey || key,
            sortDirection: 'asc',
          };
        }

        if (sortDirection === 'asc') {
          sortParams = {
            sortColumn: sortKey || key,
            sortDirection: 'desc',
          };
        }

        const sortedRows = sort(sortParams);

        return {...sortParams, sortedRows};
      });
    }
  };

  render() {
    const {
      onRetry,
      showRetryButton,
      usePreloader,
      useSerializedTab,
      tableClass,
      voteTable,
      data = {},
      returnClass,
      tableWrapperClass,
      isFetching,
      totalAmount,
      openModal,
      pageNum,
      sort,
    } = this.props;
    const {sortedRows, sortColumn, sortDirection} = this.state;
    const {columns = [], rows = []} = data;

    return (
      <div className={classNames('table-wrapper', tableWrapperClass)}>
        {usePreloader && isFetching && <Preloader className='white-bg' />}
        {showRetryButton && onRetry ? (
          <div className='retry-load-table-wrapper'>
            <div>
              <I18n tKey='table.retry_info' />
            </div>
            <div role='presentation' onClick={onRetry} className='refresh-block'>
              <I18n tKey='table.retry_button' />
              <RefreshIcon />
            </div>
          </div>
        ) : (
          <table className={classNames('table-content', tableClass)}>
            <thead className='header-table'>
              <tr>
                {voteTable && (
                  <th>
                    <I18n tKey='wallet_tabs.vote_tab' />
                  </th>
                )}
                {columns.map(column => (
                  <th onClick={column.withSort ? this.sort(column) : undefined} key={column.name}>
                    <div className={classNames('header-cell-wrapper', {'with-sort': column.withSort})}>
                      <I18n tKey={column.name} />
                      {sort && (
                        <div className='sort-direction-icon-wrapper'>
                          {sortColumn === column.key || sortColumn === column.sortKey ? (
                            <>
                              {sortDirection === 'asc' && <ArrowUpward className='sort-direction-icon' />}
                              {sortDirection === 'desc' && <ArrowDownward className='sort-direction-icon' />}
                            </>
                          ) : (
                            column.withSort && <Sort />
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='body-table'>
              {((sortColumn && sortedRows) || rows).map((row, i) => (
                <Fragment key={i}>
                  <tr onClick={this.rowClick.bind(this, row)} className={`body-row ${returnClass ? returnClass(row) : ''}`}>
                    {this.props.children && (
                      <td>{React.cloneElement(this.props.children, {isChecked: row.isVoted, value: row.owner})}</td>
                    )}
                    {columns.map((column, index) => {
                      const rowValue = column.parseFunc
                        ? column.parseFunc(row[column.key || column.name])
                        : row[column.key || column.name];
                      if (column.render) {
                        return (
                          <td key={index}>
                            {column.render(
                              row,
                              totalAmount || 0,
                              this.openRow.bind(this, row.trx_id),
                              this.state.openedRow,
                              openModal
                            )}
                          </td>
                        );
                      }
                      return (
                        <td key={index}>
                          {!column.url ? (
                            rowValue
                          ) : (
                            <Link
                              to={{
                                pathname: typeof column.url === 'function' ? column.url(row) : column.url,
                                state: {page: pageNum},
                              }}
                            >
                              {rowValue}
                            </Link>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {useSerializedTab && (
                    <tr className={`body-row serialized-view-wrapper ${this.state.openedRow === row.trx_id ? 'open' : ''}`}>
                      <td colSpan='100%'>
                        <JSONTree theme={JSON_TREE_THEME} data={row || []} hideRoot />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
