import {action, observable} from 'mobx';
import {getBlockDetails, getBlockDetailsFromApi, getBlocks, getHeadBlock} from '../utils/requester';
import {getRandomInt} from '../utils/utils';

class BlockListStore {
  @observable isFetching = false;

  @observable blocks = [];

  @observable headBlocks = [];

  @observable showEmpty = true;

  @observable isPaused = false;

  @observable isForced = false;

  @observable blockIndex = 0;

  @observable iteration = 0;

  @action toggleEmptyFlag = () => {
    this.removeTimer();
    const count = this.blocks.length;
    this.showEmpty = !this.showEmpty;
    this.getBlocksSetInterval(count);
  };

  @action getBlocksSetInterval = (count, forceSecondUrl) => {
    const interval = 500;
    this.isFetching = true;

    if (forceSecondUrl) {
      this.isForced = true;

      this.timerRef = setInterval(this.getBlocks, interval);
    } else {
      getBlocks(count, this.showEmpty).then(r => {
        const blocks = [];
        if (!r.isError) {
          this.isPaused = false;
          const requests = [];
          (r.data || []).reverse().forEach(elem =>
            requests.push(
              getBlockDetails(elem.block_num).then(resp => {
                const details = {actions: 0, cpu: 0, net: 0};
                if (!resp.isError && resp.data) {
                  details.actions += Number(resp.data.actions);
                  details.cpu += Number(resp.data.cpu);
                  details.net += Number(resp.data.net);
                }

                return {
                  ...elem,
                  timestamp: `${elem.timestamp}Z`,
                  actions: details.actions,
                  cpuUsage: details.cpu,
                  netUsage: details.net,
                };
              })
            )
          );
          Promise.all(requests).then(r => {
            this.isFetching = false;
            this.blocks = r;
            this.isPaused = false;
            this.blockIndex = 0;
            this.timerRef = setInterval(this.getBlocks, interval);
          });
        } else {
          this.isFetching = false;
          this.blocks = blocks;
          this.isPaused = false;
          this.blockIndex = 0;

          // recursion in case the primary URL has failed.
          this.isForced = true;
          // getBlocksSetInterval(count)
        }
      });
    }
  };

  @action getBlocks = () => {
    const maxRowsCount = 100;
    if (this.isForced) {
      getHeadBlock().then(r => {
        if (!r.isError) {
          this.headBlocks.push(r.data.head_block_num);
          getBlockDetailsFromApi(this.headBlocks[this.iteration]).then(resp => {
            resp.data.transaction_count = resp.data.transactions.length; // We do this to add transaction_count property to response object in order to maintain previous code structure
            this.blocks.push(resp.data);
            this.iteration += 1;
          });

          this.isFetching = false;
        }
      });
    } else {
      getBlockDetails().then(r => {
        const details = {actions: 0, cpu: 0, net: 0};
        if (!this.isPaused && !r.isError && r.data) {
          details.actions += Number(r.data.actions);
          details.cpu += Number(r.data.cpu);
          details.net += Number(r.data.net);
        }

        this.blocks.length >= maxRowsCount && this.blocks.shift();
        this.blocks.push({
          ...r.data,
          timestamp: `${r.data.timestamp}Z`,
          actions: details.actions,
          cpuUsage: details.cpu,
          netUsage: details.net,
        });
      });
    }
  };

  togglePause = () => {
    this.isPaused = !this.isPaused;
    if (!this.isPaused) {
      this.getBlocksSetInterval(this.blocks.length, this.isForced);
    } else {
      this.removeTimer();
    }
  };

  removeTimer = () => {
    this.isPaused = true;
    clearInterval(this.timerRef);
  };

  setBlockIndex = index => {
    !this.isPaused && (this.isPaused = !this.isPaused);
    this.isPaused && this.removeTimer();
    this.blockIndex = index;
  };

  changeBlockIndex = increase => {
    !this.isPaused && (this.isPaused = !this.isPaused);
    this.isPaused && this.removeTimer();
    if (increase) {
      if (this.blockIndex + 1 < this.blocks.length) {
        this.blockIndex += 1;
      }
    } else if (this.blockIndex > 0) {
      this.blockIndex -= 1;
    }
  };
}

export default BlockListStore;
