import React, {Component} from 'react';
import Modal from 'react-modal';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';

import {ReactComponent as CloseIcon} from '../../../img/close_icon.svg';

import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';
import {TOKENS} from '../../../config/const';

const tableData = {
  columns: [
    {name: 'modal_rewards.date', key: 'date'},
    {name: 'modal_rewards.day', key: 'day'},
    {name: 'modal_rewards.reward', key: 'reward', render: obj => <span> &#43; {obj.reward} </span>},
    {
      name: 'modal_rewards.total_reward',
      key: 'totalReward',
      render: obj => (
        <span>
          {obj.totalReward} {TOKENS.CRU}
        </span>
      ),
    },
  ],
};

export class RewardsShedule extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
  }
  render() {
    const {isOpen, closeModal} = this.props;
    const {data} = this.props.params;
    return (
      <Modal className='modal-rewards' isOpen={isOpen}>
        <div className='header-modal'>
          <div className='left-header'>
            <I18n tKey='modal_rewards.title' className='title-modal' />
            <p className='info-row'>
              <I18n tKey='modal_rewards.staking_period' />
              &#58;
              <span className='time'>{data.months} months</span>
            </p>
          </div>
          <div className='right-header'>
            <p className='info-row'>
              <I18n tKey='wallet_cru.col_ammount' />
              &#58;
              <CountWithZero round={4} count={+data.amountStake} />
              <span>{TOKENS.CRU}</span>
            </p>
            <p className='info-row'>
              <I18n tKey='modal_rewards.total_benefit' />
              &#58;
              <CountWithZero round={4} count={data.totalBenefit} />
              <span>{TOKENS.CRU}</span>
            </p>
          </div>
          <CloseIcon onClick={closeModal} />
        </div>
        <div className='body-modal'>
          <I18n tKey='modal_rewards.staking_reward' className='header-table' />
          <Table data={{...tableData, rows: data.rewardRows}} tableClass='rewards-table' />
        </div>
      </Modal>
    );
  }
}
