import React, {Component} from 'react';
import Select from 'react-select';

import {translate} from '../../../utils/translater';

export class DropDown extends Component {
  handleChange = selectedOption => {
    this.props.onChange(selectedOption);
  };

  render() {
    const {formatOptionLabel, wrapperClass, placeholder, isLoading, disabled, menuPlacement} = this.props;

    return (
      <Select
        isDisabled={disabled}
        isOptionSelected={this.props.isOptionSelected}
        defaultValue={this.props.defaultValue}
        isLoading={isLoading}
        onChange={this.handleChange}
        options={this.props.options}
        className={wrapperClass}
        classNamePrefix='react-select'
        formatOptionLabel={formatOptionLabel}
        value={this.props.value}
        isClearable={false}
        isSearchable={false}
        placeholder={translate(placeholder)}
        menuPlacement={menuPlacement}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F5F6F7',
            primary: '#F5F6F7',
            primary50: '#F5F6F7',
          },
        })}
      />
    );
  }
}
