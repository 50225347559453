import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {I18n} from '../../helpers/i18n/I18n';

export class TransactionDetail extends Component {
  valueRender = () => {
    const {url, checkStatus, value} = this.props;
    const valueClass = classNames('info-txt', {'info-second-txt': checkStatus && value !== 'executed'});
    if (url) {
      return (
        <Link to={url}>
          <span className={valueClass}>{value}</span>
        </Link>
      );
    } else {
      return <span className={valueClass}>{value}</span>;
    }
  };

  render() {
    const {label} = this.props;
    return (
      <div className='info-content'>
        <I18n className='title' tKey={label} />
        <p className='row-txt'>{this.valueRender()}</p>
      </div>
    );
  }
}
