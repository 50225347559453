import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as ErrIcon} from '../../../img/icon-err.svg';
import {ReactComponent as StatusIcon} from '../../../img/success.svg';
import {I18n} from '../../helpers/i18n/I18n';
import {changeFormat} from '../../../utils/utils';

export class WalletConvertResult extends Component {
  componentDidMount() {
    this.returnConvertedAmount();
  }

  returnConvertedAmount = () => {
    const qrParam = (this.props.location.search || '').split('?amount=');
    return qrParam[1];
  };

  render() {
    const {isError} = this.props;
    const amount = this.returnConvertedAmount();
    return (
      <div className='convert-result-page'>
        <div className='show-status-done'>
          <p className='status-icon'>{isError ? <ErrIcon className='result-icon' /> : <StatusIcon className='result-icon ok' />}</p>
          {isError ? <I18n className='status-title' tKey='convert-result.failed' /> : <I18n className='status-title' tKey='convert_wcru.done_convert' />}
          <div className='count-trx-info'>
            {!isError && amount && (
              <Fragment>
                <span className='count-convert'>{changeFormat(amount, 4)}</span>
                <I18n tKey='convert_wcru.status_txt' />.
              </Fragment>
            )}{' '}
            <I18n tKey='convert_wcru.go_to_history' />
            <Link to='/wallet/history'>
              {' '}
              <I18n tKey='common.here' />{' '}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
