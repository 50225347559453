import React from 'react';

import './index.scss';

export const UrlPopupCell = ({url}) => {
  return (
    <div className='url-popup-cell'>
      <div className='link-wrapper'>
        <a target='blank' href={url}>
          {url}
        </a>
      </div>

      <div className='link-popup'>{url}</div>
    </div>
  );
};
