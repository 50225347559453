import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ToasterItem} from './ToasterItem';

@inject('loggerStore')
@observer
export class Logger extends Component {
  destroyToast = index => {
    this.props.loggerStore.removeItem(index);
  };

  render() {
    const {msgs} = this.props.loggerStore;
    return (
      <div className='logger-body'>
        {Object.keys(Object.fromEntries(msgs)).map(key => {
          const msg = msgs.get(key);
          if (!msg) {
            return null;
          }
          return (
            <ToasterItem
              destroyCb={this.destroyToast.bind(this, key)}
              key={key}
              type={msg.type}
              txt={msg.msgTxt}
              parsedError={msg.parsedError}
            />
          );
        })}
      </div>
    );
  }
}
