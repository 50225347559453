import React, {Component, Fragment} from 'react';

import {ReactComponent as LockIcon} from '../../../img/lock-shield.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';

export class SetPin extends Component {
  pinRef = React.createRef();

  focusSecondInput = () => {
    this.pinRef.current.inputRef.current.focus();
  };

  render() {
    const {inputChange, pin, repeatPin, isFetching, msg, nextStep, pinError, repeatPinError} = this.props;
    const pinLength = 6;
    const nextStepIsDisabled = pin.length < pinLength || repeatPin.length < pinLength;
    return (
      <div className='pin-form'>
        <LockIcon className='lock-icon' />
        <p className='auth-title safety'>
          <I18n tKey='wallet_auth.safe_title' />
          <I18n tKey='wallet_auth.safe_subtitle' />
        </p>
        <div className='input-list'>
          <I18n tKey='wallet_auth.label.pin_txt' className='label-input' />
          <Input
            containerClass='pin-field'
            placeholder='000000'
            onChange={inputChange}
            onlyNumbers
            name='pin'
            value={pin}
            maxLength={pinLength}
            error={pinError}
            statusBorder
            isPassword
          />
          <I18n tKey='wallet_auth.label.repeat_pin_txt' className='label-input' />
          <Input
            containerClass='pin-field'
            placeholder='000000'
            onChange={inputChange}
            onlyNumbers
            name='repeatPin'
            value={repeatPin}
            maxLength={pinLength}
            error={repeatPinError}
            statusBorder
            isPassword
            ref={this.pinRef}
          />
        </div>
        <Button
          disabled={isFetching || nextStepIsDisabled}
          btnClass='with-bg blue'
          title='wallet_auth.done_btn'
          onClick={nextStep}
        />
        {msg && <MsgPrinter msgType={msg.type} msg={msg.txt} />}
      </div>
    );
  }
}
