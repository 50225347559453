import React, {Component} from 'react';
import moment from 'moment';
import {inject, observer} from 'mobx-react';

import {Table} from '../../helpers/table/Table';
import {Pagination} from '../../helpers/pagination/Pagination';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';

import {TIME_FORMAT} from '../../../config/const';
import {returnNextOffset} from '../../../utils/utils';
import {getAccountsList, getTotalAcounts} from '../../../utils/requester';
import {I18n} from '../../helpers/i18n/I18n';

const tableData = {
  columns: [
    {name: 'account.table.col_name', url: obj => `accounts/${obj.name}`, key: 'name'},
    {name: 'account.table.col_create', key: 'creation_date', parseFunc: time => moment(time).format(TIME_FORMAT.BLOCK)},
  ],
};
const pages = [{title: 'breadcrumbs.home', url: '/'}];
@inject('tokenStore')
@observer
export class Accounts extends Component {
  state = {
    accounts: [],
    page: 0,
    limit: 20,
    totalElements: 0,
    fetching: true,
  };

  componentDidMount = async () => {
    const resp = await getTotalAcounts();

    this.setState({totalElements: +resp.data.totalAccounts});
    this.getAccounts();
  };

  getAccounts = () => {
    const {limit, page} = this.state;
    const offset = returnNextOffset(limit, page);
    this.setState({fetching: true});
    getAccountsList(this.state.limit, offset).then(r => {
      const newState = {accounts: [], fetching: false};
      if (!r.isError) {
        newState.accounts = r.data;
      }
      this.setState(newState);
    });
  };

  setPage = num => {
    this.setState({page: num}, this.getAccounts);
  };

  render() {
    const {page, accounts, limit, totalElements, fetching} = this.state;
    return (
      <div className='accounts-page'>
        <div className='page-content'>
          <div className='header-page'>
            <Breadcrumbs currentTitle='account.title' pages={pages} />
          </div>
          {accounts.length > 0 ? (
            <div>
              <Table
                tableWrapperClass='relative min-height'
                usePreloader
                isFetching={fetching}
                data={{...tableData, rows: accounts}}
              />
              <Pagination changePage={this.setPage} currentPage={page + 1} limit={limit} totalCount={totalElements} />
            </div>
          ) : (
            <h1>
              <span className='main-title md title-top-block not-found'>
                <I18n tKey='common.no_data' />
              </span>
            </h1>
          )}
        </div>
      </div>
    );
  }
}
