import classNames from 'classnames';
import React from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {REG_EXP} from '../../../config/const';

export const ConverterInput = ({
  leftHeader,
  name,
  value,
  onChange,
  leftDescription,
  readOnly,
  token,
  tokenIc,
  onBlur,
  maxAmount,
  preloader,
  isFetching,
  wrapperClass,
}) => {
  return (
    <div className={classNames('converter-row', wrapperClass, {disabled: readOnly})}>
      <div className='left'>
        <I18n tKey={leftHeader} />
        <I18n tKey={leftDescription} />
      </div>
      <div className='right'>
        {preloader && isFetching && (
          <div className='preloader'>
            <I18n tKey='preloader.convert' />
          </div>
        )}
        <Input
          regEx={REG_EXP.four_digits}
          value={value}
          name={name}
          onChange={onChange}
          placeholder='0.0000'
          label='wallet_send.label_amount'
          onlyNumbers
          readOnly={readOnly}
          onBlur={onBlur}
          maxAmount={maxAmount}
        />
        <div className='token-type'>
          {token}
          <img src={tokenIc} alt='' />
        </div>
      </div>
    </div>
  );
};
