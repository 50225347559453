import moment from 'moment';
import BigNumber from 'bignumber.js';
import {NavLink} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import React, {Component, Fragment} from 'react';
import {Serialize} from 'eosjs';
import classNames from 'classnames';

import {ReactComponent as OkIcon} from '../../../img/ok-icon.svg';
import {ReactComponent as ArrowBack} from '../../../img/arrow_back.svg';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {StakingInfo} from './StakingInfo';
import {Button} from '../../helpers/button/Button';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {DropDown} from '../../helpers/dropDown/DropDown';
import {Pagination} from '../../helpers/pagination/Pagination';
import {BalanceRow} from '../../helpers/balanceRow/BalanceRow';
import {RewardsShedule} from '../../modals/rewardsShedule/RewardsShedule';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';

import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {getEosTable, getUserActions} from '../../../utils/requester';
import {REG_EXP, TIME_FORMAT, TOKENS} from '../../../config/const';
import {getTokenPrefix, cutTokenPrefix, RewardRows} from '../../../utils/utils';

export const dropdownOptions = [
  {
    value: 6,
    title: 'wallet_cru-for-cru.eventi',
    month: 36,
    percent: 8,
  },
  {
    value: 5,
    title: 'wallet_cru-for-cru.profitable',
    month: 24,
    percent: 2.5,
  },
  {
    value: 4,
    title: 'wallet_cru-for-cru.sure',
    month: 18,
    percent: 2,
  },
  {
    value: 3,
    title: 'wallet_cru-for-cru.optimal',
    month: 12,
    percent: 1.8,
  },
  {
    value: 2,
    title: 'wallet_cru-for-cru.classical',
    month: 6,
    percent: 1.5,
  },
  {
    value: 1,
    title: 'wallet_cru-for-cru.cumulative',
    month: 3,
    percent: 1.2,
  },
];

const tableData = {
  columns: [
    {
      name: 'wallet_cru.tariff',
      render: obj => {
        const actionData = obj.action_data;
        const tariff = dropdownOptions.find(tariff => tariff.value === (+actionData.memo || -1));
        return (
          <div>
            <NavLink to={`/transactions/${obj.trx_id}`} className='tx-title'>
              <span>Tx: </span>
              <span className='tr-id'>{obj.trx_id}</span>
              <OkIcon />
            </NavLink>
            {tariff && (
              <p className='start-date'>
                <I18n tKey={tariff.title} />
              </p>
            )}
          </div>
        );
      },
    },
    {
      name: 'wallet_cru.col_ammount',
      render: obj => {
        const actionData = obj.action_data;
        return <p className={obj.action_name}>{actionData.quantity}</p>;
      },
    },
    {
      name: 'wallet_cru.col_duration',
      render: obj => {
        let periodEnds;
        const actionData = obj.action_data;
        const tariff = dropdownOptions.find(({value}) => value === (+actionData.memo || -1));
        const periodStarts =
          tariff && tariff.value === 6
            ? moment(obj.timestamp).add(90, 'days').format(TIME_FORMAT.TRANSACTIONS)
            : moment(obj.timestamp).format(TIME_FORMAT.TRANSACTIONS);
        if (tariff) {
          // todo add pause from tariff, and maybe it is needed to be shown in stake block (pause days/months)
          periodEnds = moment(obj.timestamp)
            .add(tariff.month * 30, 'days')
            .format(TIME_FORMAT.TRANSACTIONS);
        }
        return <div>{periodEnds ? `${periodStarts} - ${periodEnds}` : periodStarts}</div>;
      },
    },
  ],
};

const formatOptionLabel = data => (
  <div className='select-row'>
    <I18n className='plan-name' tKey={data.title} />{' '}
    <span className='percents'>
      ({data.percent} %){' '}
      <span className='percents'>
        {data.month} <I18n tKey='common.month' />
      </span>
    </span>
  </div>
);

@inject('userStore', 'tokenStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class CruForCruStaking extends Component {
  objectsTable = {
    columns: [
      {
        name: 'wallet_cru.plan',
        render: obj => {
          const tariff = dropdownOptions.find(tariff => tariff.value === (+obj.plan_id || -1));

          return (
            <div>
              {tariff && (
                <p className='start-date'>
                  <I18n tKey={tariff.title} />
                </p>
              )}
            </div>
          );
        },
      },
      {
        name: 'wallet_cru.date',
        render: obj => {
          const {created_at, last_update_at, freeze_until, last_pay_should_be_at} = obj;

          return (
            <>
              <p className='date-label '>
                <I18n tKey='wallet_cru.created_at' />:
              </p>
              <p className='date-value'>{moment(created_at).format(TIME_FORMAT.TRANSACTIONS)}</p>
              <p className='date-label '>
                <I18n tKey='wallet_cru.last_update_at' />:
              </p>
              <p className='date-value'>{moment(last_update_at).format(TIME_FORMAT.TRANSACTIONS)}</p>
              <p className='date-label '>
                <I18n tKey='wallet_cru.freeze_until' />:
              </p>
              <p className='date-value'>{moment(freeze_until).format(TIME_FORMAT.TRANSACTIONS)}</p>
              <p className='date-label '>
                <I18n tKey='wallet_cru.last_pay_should_be_at' />:
              </p>
              <p className='date-value'>{moment(last_pay_should_be_at).format(TIME_FORMAT.TRANSACTIONS)}</p>
            </>
          );
        },
      },
      {
        name: 'wallet_cru.staked_balance',
        render: obj => {
          return (
            <CountWithZero
              countClass='count-row'
              round={4}
              plusBeforeSumm={false}
              count={cutTokenPrefix(obj.staked_balance)}
              dividedView
            >
              {` ${getTokenPrefix(obj.staked_balance)}`}
            </CountWithZero>
          );
        },
      },
      {
        name: 'wallet_cru.plan_to_pay',
        render: obj => {
          return (
            <CountWithZero
              countClass='count-row'
              round={4}
              plusBeforeSumm={false}
              count={cutTokenPrefix(obj.plan_to_pay)}
              dividedView
            >
              {` ${getTokenPrefix(obj.plan_to_pay)}`}
            </CountWithZero>
          );
        },
      },

      {
        name: 'wallet_cru.withdrawed',
        render: obj => {
          return (
            <CountWithZero
              countClass='count-row'
              round={4}
              plusBeforeSumm={false}
              count={cutTokenPrefix(obj.withdrawed)}
              dividedView
            >
              {` ${getTokenPrefix(obj.withdrawed)}`}
            </CountWithZero>
          );
        },
      },
      {
        name: 'wallet_cru.closed',
        render: obj => {
          return (obj.closed && <OkIcon className='stackClosedIcon' />) || null;
        },
      },
      {
        name: 'wallet_cru.possible_claim',
        render: obj => {
          const {soclaim} = this.state;

          const claim = soclaim.find(({id}) => id === obj.id);

          return claim?.possible_claim ? (
            <>
              <CountWithZero
                countClass='count-row'
                round={4}
                plusBeforeSumm={false}
                count={cutTokenPrefix(claim.possible_claim)}
                dividedView
              >
                {` ${getTokenPrefix(claim.possible_claim)}`}
              </CountWithZero>

              {cutTokenPrefix(claim.possible_claim) < 0 && (
                <Button
                  btnClass='return-reward-btn'
                  type='button'
                  onClick={() => {
                    this.openReturnRewardModal(obj.id, claim.possible_claim);
                  }}
                >
                  <I18n tKey='wallet_cru.return_reward_btn' />
                </Button>
              )}
            </>
          ) : (
            '-'
          );
        },
      },
    ],
  };

  contracts = {};

  state = {
    amountStake: '',
    isFetching: false,
    recentTrxIsFetching: false,
    tariffsAreFetching: true,
    rows: [],
    tariffs: [],
    lastIndex: 0,
    limit: 10,
    page: 0,
    stakePlan: null,
    rewardRows: [],
    totalBenefit: null,
    firstFetch: true,
    soclaim: [],
  };

  componentDidMount = () => {
    this.getRecentTrx();
    this.getSoclaimTable();
    // this.getObjects();
    // this.getTariffs()
    // this.timer = setInterval(this.getRecentTrx, 15000);
  };

  componentWillUnmount() {
    // clearInterval(this.timer);
  }

  getSoclaimTable = async () => {
    const {userStore} = this.props;
    const {user} = userStore;
    const {data, isError} = await getEosTable({json: true, code: 'staker', scope: user.name, table: 'soclaim'});

    if (!isError) {
      this.setState({soclaim: data.rows || []});
    }
  };

  getTariffs = () => {
    const data = {json: true, code: 'staker', scope: 'staker', limit: 10000, table: 'plans'};
    getEosTable(data).then(r => {
      const newState = {tariffsAreFetching: false, tariffs: []};
      if (!r.isError) {
        const secondsInMonth = 30 * 86400; // here month has only 30 days
        newState.tariffs = (r.data.rows || [])
          .filter(tariff => tariff.is_active)
          .map(tariff => {
            const duration = new BigNumber(tariff.duration).dividedBy(secondsInMonth).toNumber();
            const emit_percent = +new BigNumber(tariff.emit_percent).plus(tariff.bonus_percent).dividedBy(10000).toFixed(1);
            const pauseInMonth = +new BigNumber(tariff.pause).dividedBy(secondsInMonth).toFixed(0, BigNumber.ROUND_DOWN);
            return {...tariff, duration, emit_percent, pause: pauseInMonth}; // idk why 10000, but it is =)
          });
      }
      this.setState(newState);
    });
  };

  getRecentTrx = () => {
    const {page, limit, firstFetch} = this.state;
    if (firstFetch) this.setState({recentTrxIsFetching: true, firstFetch: false});

    getUserActions({
      offset: page * limit,
      limit,
      account: this.props.userStore.user.name,
      symbol: 'CRU',
      type: 'Staking',
    }).then(async r => {
      const newState = {recentTrxIsFetching: false};
      const {loggerStore, eosStore} = this.props;

      if (!r.isError) {
        newState.rows = await Promise.all(
          r.data.map(async action => {
            const {action_name, act_data, contract: contractName} = action;

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              action_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            console.log(action_name, dataa);

            return {...action, action_data: dataa};
          })
        );
        console.log(newState.rows);
        newState.lastIndex = r.data.length;
      } else {
        loggerStore.addItem({type: LOGGER_TYPES[2], msgTxt: 'common.try_again_later'});
      }

      this.setState(newState);
    });
  };

  onChange = (propName, value) => {
    const {stakePlan} = this.state;
    const newState = {[propName]: value, rewardRows: [], totalBenefit: null};
    if (propName === 'stakePlan') {
      const {amountStake} = this.state;
      this.setSchedule(value, newState, amountStake);
    }
    if (propName === 'amountStake' && stakePlan) {
      this.setSchedule(stakePlan, newState, value);
    }
    this.setState(newState);
  };

  setSchedule = (stakePlan, newState, amountStake) => {
    for (let i = 0; i <= stakePlan.month; i++) {
      const currDate = moment().add(i, 'month');
      const dateDiff = currDate.diff(moment(), 'day');
      const obj = new RewardRows(stakePlan, amountStake, currDate.format(TIME_FORMAT.REWARD_SCHEDULE), dateDiff, i);
      newState.rewardRows.push(obj);
    }
    newState.totalBenefit = new BigNumber(+amountStake)
      .multipliedBy(stakePlan.month)
      .multipliedBy(stakePlan.percent)
      .div(100)
      .plus(+amountStake)
      .toFixed(4);
  };

  makeStakingOp = pin => {
    const {stakePlan, amountStake} = this.state;
    const quantity = `${amountStake} CRU`;
    this.setState({isFetching: true});
    this.props.eosStore
      .makeTransaction({
        quantity,
        sender: this.props.userStore.user.name,
        receiver: 'staker',
        memo: stakePlan.value,
        pin,
        errorMsg: 'errors.stake.cru_for_cru',
      })
      .then(r => {
        const newState = {isFetching: false};

        this.props.userStore.getAccountInfo().then(r => {
          this.setState(newState);
        });
      });
  };

  onInputBlur = () => {
    const {amountStake} = this.state;
    if (!amountStake) {
      return;
    }
    let defaultAmount = '';
    const splitedAmount = String(amountStake).split('.');
    if (!splitedAmount[0]) {
      defaultAmount = '1.0000';
    } else if (!splitedAmount[1]) {
      defaultAmount = `${splitedAmount[0]}.0000`;
    } else if (splitedAmount[1].length < 4) {
      const emptyFigures = 4 - splitedAmount[1].length;
      defaultAmount = amountStake;
      Array.from(new Array(emptyFigures)).forEach(i => (defaultAmount += '0'));
    }
    defaultAmount && this.setState({amountStake: defaultAmount});
  };

  setPage = num => {
    this.setState({page: num}, this.getRecentTrx);
  };

  setPercentValue = value => {
    const availableAmount = this.props.userStore.userWallet.CRU.available;
    const amountStake = new BigNumber(availableAmount).multipliedBy(value).div(100).toFixed(4);
    this.setState({amountStake});
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.makeStakingOp});
  };

  openReturnRewardModal = (stakeId, quantity) => {
    this.props.modalStore.open(EnterPin, null, null, {
      onValidPin: pin => this.returnReward({pin, stakeId, quantity: quantity.slice(1)}),
    });
  };

  returnReward = async ({pin, stakeId, quantity}) => {
    const {eosStore, userStore} = this.props;

    this.setState({isFetching: true});

    await eosStore.makeTransaction({
      quantity,
      sender: userStore.user.name,
      receiver: 'staker',
      memo: `Reward return to stake ${stakeId}`,
      pin,
      errorMsg: 'errors.stake.cru_for_cru_return_reward_error',
    });

    await userStore.getAccountInfo();
    await this.getSoclaimTable();
    this.setState({isFetching: false});
  };

  backToStakingPage = () => {
    this.props.history.push('/wallet/staking/');
  };

  openRewardSchedule = () => {
    const {rewardRows, totalBenefit, amountStake, stakePlan} = this.state;
    const data = {rewardRows, totalBenefit, amountStake, months: stakePlan.month};

    this.props.modalStore.open(RewardsShedule, null, null, {
      data,
    });
  };

  handleNext = () => {
    this.setState(
      prevState => ({
        page: prevState.page + 1,
      }),
      this.getRecentTrx
    );
  };

  handlePrev = () => {
    this.setState(
      prevState => ({
        page: prevState.page - 1,
      }),
      this.getRecentTrx
    );
  };

  render() {
    const {amountStake, lastIndex, limit, page, rows, isFetching, stakePlan, soclaim, recentTrxIsFetching} = this.state;
    const {userWallet, objsForCruClaim} = this.props.userStore;
    const availableAmount = userWallet.CRU.available;
    const minAmount = +amountStake <= 0.99;
    const startDate = stakePlan && moment().format(TIME_FORMAT.CRU_FOR_CRU);
    const endDate = stakePlan && moment().add(stakePlan.month, 'month').format(TIME_FORMAT.CRU_FOR_CRU);
    const profit = stakePlan ? new BigNumber(amountStake || 0).multipliedBy(stakePlan.percent).div(100).toFixed(4) : 0;
    const rounding = 4;
    return (
      <div className='nested-route wallet-cru'>
        <div className='cru-block'>
          <div className='back-btn' onClick={this.backToStakingPage}>
            &#60; <I18n tKey='wallet_cru-for-cru.back_stake' />
          </div>
          <div className='page-title'>
            <ArrowBack onClick={this.backToStakingPage} />
            <I18n tKey='wallet_cru-for-cru.title' />
          </div>
          <StakingInfo updateSoclaimTable={this.getSoclaimTable} soclaim={soclaim} isFetching={isFetching} />
          <div className='wallet-content'>
            <div className='staking-input-block'>
              <div className='input-title'>
                <I18n tKey='wallet_cru-for-cru.select_amount' />
                <div className='mobile-view'>
                  <BalanceRow
                    countBalance={availableAmount}
                    token='wallet_cru.token_title'
                    titleRow='wallet_send.balance'
                    round={4}
                    separator='.'
                  />
                  <I18n tKey='common.convert_all' onClick={this.setPercentValue.bind(this, 100)} />
                </div>
              </div>
              <InputRow
                wrapperClass='input-with-bg'
                name='amountStake'
                value={amountStake}
                onlyNumbers
                placeholder='wallet_cru.stake_placeholder'
                inputChange={this.onChange}
                statusBorder
                label={amountStake ? 'wallet_cru.stake_placeholder' : null}
                countBalance={availableAmount}
                token='wallet_cru.token_title'
                titleRow='wallet_send.balance'
                maxAmount={availableAmount}
                onBlur={this.onInputBlur}
                percentBtns
                onPercentBtnClick={this.setPercentValue}
                regEx={REG_EXP.four_digits}
                round={rounding}
              />
            </div>
            <div className='staking-plan-block'>
              <div className='dropdown-description'>
                <I18n tKey='wallet_cru-for-cru.select_plan' />
                <I18n tKey='wallet_cru-for-cru.profit_duration' />
              </div>
              <div className='plan-select'>
                <DropDown
                  // isLoading={this.state.tariffsAreFetching}
                  wrapperClass='select-amount'
                  onChange={this.onChange.bind(this, 'stakePlan')}
                  value={stakePlan}
                  options={dropdownOptions}
                  formatOptionLabel={formatOptionLabel}
                  placeholder='wallet_cru-for-cru.dropDown_placeholder'
                />
                {stakePlan && (
                  <>
                    <div className='plan-description'>
                      <div className='duration'>
                        <I18n tKey='wallet_cru-for-cru.duration' />
                        <span className='mobile'>
                          <I18n tKey='wallet_cru-for-cru.duration' />:
                        </span>
                        <span className='month'>
                          {stakePlan.month} <I18n tKey='common.months' />
                        </span>
                      </div>
                      <div className='reward'>
                        <I18n tKey='wallet_cru-for-cru.reward' />
                        <span className='mobile'>
                          <I18n tKey='wallet_cru-for-cru.reward' />:
                        </span>
                        <span className='month'>
                          {stakePlan.percent}% / <I18n tKey='common.month' />
                        </span>
                      </div>
                      {amountStake && amountStake > 0 && (
                        <div className='time-period'>
                          <I18n tKey='wallet_cru-for-cru.time_period' />
                          <span className='mobile'>
                            <I18n tKey='wallet_cru-for-cru.period' />:
                          </span>
                          <span className='month' onClick={this.openRewardSchedule}>
                            {startDate}–{endDate}
                          </span>
                        </div>
                      )}
                    </div>
                    {stakePlan.value === 6 && <I18n className='description-text' tKey='wallet_cru-for-cru.description' />}
                    <div className='profit-block'>
                      <I18n tKey='wallet_cru-for-cru.profit_phrase' />
                      <span>{new BigNumber(profit || 0).multipliedBy((stakePlan || {month: 0}).month).toFixed(4)} CRU</span>
                    </div>
                    <div className='btn-block'>
                      <div className='d-flex row-column'>
                        <I18n tKey='wallet_cru-for-cru.op_notice' />
                        <I18n className={amountStake < 1 ? 'warning-text' : 'hidden'} tKey='wallet_cru-for-cru.minimum_amount' />
                      </div>
                      <Button
                        onClick={this.openPinModal}
                        title='wallet_cru-for-cru.btn_stake'
                        disabled={isFetching || !amountStake || minAmount}
                        btnClass='with-bg blue'
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='staking-history'>
            <div className='header-block'>
              <I18n tKey='wallet_cru-for-cru.objects_title' className='title-block' />{' '}
              {/* <RefreshIcon onClick={this.getObjects} className='update-ic' /> */}
            </div>
            <Table
              data={{...this.objectsTable, rows: objsForCruClaim}}
              tableWrapperClass='staking-objects-table'
              usePreloader
              // isFetching={recentTrxIsFetching}
            />

            <div className='header-block'>
              <I18n tKey='wallet_cru-for-cru.history_title' className='title-block' />{' '}
              <RefreshIcon onClick={this.getRecentTrx} className='update-ic' />
            </div>
            <Table
              data={{...tableData, rows}}
              tableWrapperClass='staking-history-table'
              usePreloader
              isFetching={recentTrxIsFetching}
            />
            <Pagination
              withoutNumbers
              hasNext={lastIndex === limit}
              hasPrev={page !== 0}
              handleNext={this.handleNext}
              handlePrev={this.handlePrev}
              changePage={this.setPage}
              currentPage={page + 1}
              limit={limit}
              totalCount={lastIndex}
            />
            {rows.length === 0 && (
              <p className='no-transactions'>
                <I18n tKey='common.no_trx' />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
