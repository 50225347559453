import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {I18n} from '../../helpers/i18n/I18n';
import {InputRow} from '../../helpers/inputRow/InputRow';
import {REG_EXP} from '../../../config/const';
import {changeFormat} from '../../../utils/utils';
import './index.scss';

const inputFormatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

@inject('userStore')
@observer
export class InvestSumInput extends Component {
  state = {
    sum: '',
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value});
  };

  onBlur = () => {
    const {onChange} = this.props;
    const {sum} = this.state;

    const formattedQuantity = sum ? changeFormat(sum, 4, inputFormatSettings) : '';

    this.setState({sum: formattedQuantity});
    onChange(formattedQuantity);
  };

  render() {
    const {sum} = this.state;
    const {userStore} = this.props;
    const {userWallet} = userStore;
    const balance = +userWallet?.CRU?.available || 0;

    return (
      <div className='invest-sum-input-wrapper'>
        <I18n tKey='create_token.invest-sum-label' className='input-label' />
        <InputRow
          autoFocus
          wrapperClass='input-with-bg'
          placeholder='create_token.invest-sum-placeholder'
          label='CRU'
          statusBorder
          round={4}
          regEx={REG_EXP.four_digits}
          inputChange={this.onChange}
          name='sum'
          value={sum}
          onBlur={this.onBlur}
          onlyNumbers
          maxAmount={balance}
          countBalance={balance}
          titleRow='producer_acc.invest-balance'
          token='CRU'
        />
      </div>
    );
  }
}
