import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

@inject('modalStore')
@observer
export class ModalListener extends Component {
  state = {
    isOpen: true,
  };

  toggleModalState = () => {
    this.setState({modalOpen: !this.state.modalOpen});
  };

  closeModal = () => {
    if (this.state.isOpen) {
      this.toggleModalState();
      // setTimeout(() => {
      this.props.modalStore.close();
      this.toggleModalState();
      // }, 300)
    }
  };

  render() {
    const {isOpen} = this.state;
    const ModalCmp = this.props.modalStore.activeModal;
    const modalParams = this.props.modalStore.modalParams;
    return ModalCmp ? (
      <div className='modal-wrapper'>
        <ModalCmp
          isOpen={isOpen}
          onOk={this.props.modalStore.modals.onOk}
          onCancel={this.props.modalStore.modals.onCancel}
          closeModal={this.closeModal}
          params={modalParams}
        />
      </div>
    ) : null;
  }
}
