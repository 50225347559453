import React, {Component} from 'react';
import {inject} from 'mobx-react';
import moment from 'moment';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import {I18n} from '../../helpers/i18n/I18n';
import {ReactComponent as ChipIcon} from '../../../img/chip.svg';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {getEosTable} from '../../../utils/requester';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {cutTokenPrefix, getTokenPrefix} from '../../../utils/utils';

const getPlans = async () => {
  const {data, isError} = await getEosTable({
    code: 'block',
    scope: 'block',
    json: true,
    limit: 1000,
    table: 'plan',
  });

  const plans = data?.rows;

  if (!isError && plans) {
    return plans;
  }

  return [];
};

const getPhasePercent = async (token, phase) => {
  const {data, isError} = await getEosTable({
    code: 'block',
    scope: token,
    json: true,
    limit: 1000,
    table: 'percents',
    lower_bound: phase,
    upper_bound: phase,
  });

  const phasePerncent = data?.rows?.[0]?.percent;

  if (!isError && phasePerncent) {
    return +phasePerncent;
  }

  return null;
};

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
export class UpdateFrozenUserTokensBtn extends Component {
  state = {
    progress: '',
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.updateInfo});
  };

  updateInfo = async pin => {
    const {changeParentState, userStore, eosStore, loggerStore} = this.props;
    const userName = userStore.user.name;

    changeParentState({fetching: true});

    const {data, isError} = await getEosTable({
      json: true,
      code: 'block',
      scope: userName,
      limit: 10000,
      table: 'deposit',
    });

    const plans = await getPlans();

    const phasesLength = plans.reduce((acc, {phase_in_secs, active_phases, frozen_phases, balance, end}) => {
      const token = getTokenPrefix(balance);
      acc[token] = {phase_in_secs: +phase_in_secs, end, active_phases, frozen_phases};
      return acc;
    }, {});

    if (!isError) {
      const frozenObjects = [...(data.rows || [])];
      const objCount = frozenObjects.length;

      if (objCount > 0) {
        this.setState({progress: `0 / ${objCount}`});
      }

      changeParentState({fetching: false});

      let progress = 0;
      for (let i = 0; i < objCount; i += 1) {
        const {created, sum, id: deposit_id, withdrawed} = frozenObjects[i];
        const token = getTokenPrefix(sum);

        if (withdrawed !== sum) {
          const createdMoment = moment(`${created}Z`);
          const nowMoment = moment();

          const diff = nowMoment.diff(createdMoment, 'seconds');
          const plan = phasesLength[token];
          const currentPhase = Math.floor(diff / plan.phase_in_secs) - plan.frozen_phases;

          const percent = await getPhasePercent(token, currentPhase);

          if (currentPhase > plan.active_phases || +cutTokenPrefix(withdrawed) < +cutTokenPrefix(sum) * (+percent || 0 / 100)) {
            const {isError: objUpdateError} = await eosStore.refreshUserDeposit({pin, userName, deposit_id});

            if (!objUpdateError) {
              progress += 1;
              this.setState({progress: `${progress} / ${objCount}`});
            }
          } else {
            progress += 1;
            this.setState({progress: `${progress} / ${objCount}`});
          }
        } else {
          progress += 1;
          this.setState({progress: `${progress} / ${objCount}`});
        }
      }

      userStore.getAccountInfo();
      // this.setState({progress: ''});
    } else {
      loggerStore.addItem({
        type: LOGGER_TYPES[1],
        msgTxt: 'wallet_page.dashboard.refresh.get_freeze_obj_err',
      });
      changeParentState({fetching: false});
    }
  };

  render() {
    const {progress} = this.state;

    return (
      <div role='presentation' onClick={this.openPinModal} className='refresh-block'>
        <RefreshIcon />
        {progress ? (
          <span className='refresh-title'>{progress}</span>
        ) : (
          <I18n tKey='wallet_page.dashboard.refresh_frozen' className='refresh-title' />
        )}
        <ChipIcon />
      </div>
    );
  }
}
