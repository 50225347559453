import React, {Component} from 'react';

import {ReactComponent as ResultIcon} from '../../../img/search_result.svg';
import {I18n} from '../i18n/I18n';

export class EmptyResults extends Component {
  render() {
    const {searchValue} = this.props;
    return (
      <div className='empty-result'>
        <ResultIcon />
        <div className='result-txt'>
          <I18n className='sorry-txt' tKey='search_result.sorry' />
          <span className='search-value'>&#8220;{searchValue}&#8220;</span>
          <p className='subtitle'>
            <I18n tKey='search_result.support' />
            <span> &#35; &#46;</span>
            <br />
            <I18n tKey='search_result.confirm' />
          </p>
        </div>
      </div>
    );
  }
}
