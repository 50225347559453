import React, {Component, Fragment} from 'react';
import JSONTree from 'react-json-tree';
import moment from 'moment';
import {inject} from 'mobx-react';
import {Serialize} from 'eosjs';

import {I18n} from '../../helpers/i18n/I18n';
import {Table} from '../../helpers/table/Table';
import {Preloader} from '../../helpers/preloader/Preloader';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';
import {TableControls} from '../../helpers/tableСontrols/TableControls';
import {ReactComponent as GrayArrow} from '../../../img/gray-arrow.svg';

import {TransactionDetail} from './TransactionDetail';
import {getTransactionDetailsFlask, getActions} from '../../../utils/requester';
import {JSON_TREE_THEME, TIME_FORMAT} from '../../../config/const';
import {EmptyResults} from '../../helpers/emptyResults/EmptyResults';
import {ActionColumn} from '../../helpers/trxHistoryTable/ActionColumn';
import {MemoColumn} from '../../helpers/trxHistoryTable/MemoColumn';
import {AmountColumn} from '../../helpers/trxHistoryTable/AmountColumn';

const pages = [
  {title: 'breadcrumbs.home', url: '/'},
  {title: 'breadcrumbs.trx', url: '/transactions'},
];
const tabs = [
  {title: 'trx_details.control_btn.btn_act', value: 'actions'},
  {title: 'trx_details.control_btn.btn_trx', value: 'rawData'},
];

const tableData = {
  columns: [
    {name: 'trx_details.table.col_contract', key: 'contract'},
    {name: 'trx_details.table.col_name', render: obj => <ActionColumn obj={obj} isExplorer />},
    {
      name: 'wallet_send.label_amount',
      render: obj => {
        console.log('qqqq', obj);
        return <AmountColumn obj={obj} />;
      },
    },
    {
      name: 'wallet_send.memo_label',
      render: obj => <MemoColumn obj={obj} />,
    },
    {
      name: 'trx_details.table.col_auth',
      render: obj => (
        <span>
          {obj.name === 'pay' ? (
            <>
              {obj.actor} <GrayArrow /> {obj.token_to}
            </>
          ) : (
            obj.permission
          )}
        </span>
      ),
    },
    // {name: 'trx_details.table.col_data', render: obj => <span className='hex_data'>{obj.hex_data}</span>},
  ],
};

@inject('eosStore')
export class TransactionDetails extends Component {
  contracts = {};

  state = {
    fetching: true,
    transaction: {},
    actions: [],
    activeTab: tabs[0].value,
    page: 0,
    searchValue: '',
  };

  componentWillUnmount() {
    this.state.page = null;
  }

  componentDidMount() {
    const {id} = this.props.match.params;
    this.getTransaction(id);
    if (this.props.location.state) {
      this.setState({page: this.props.location.state.page});

      history.pushState(null, document.title, location.href);
      window.addEventListener('popstate', event => {
        if (this.state.page) {
          this.props.history.push({
            state: {page: this.state.page},
            pathname: '/transactions',
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.getTransaction(nextProps.match.params.id);
      this.setState({searchValue: nextProps.match.params.id});
    }
  }

  getTransaction = async id => {
    const {data: trxData, isError} = await getTransactionDetailsFlask(id);

    if (isError) {
      this.setState({fetching: false});
      return;
    }

    const {block_num, expiration, cpu_usage_us, net_usage_words, status} = trxData;

    const transaction = {
      id,
      block_num,
      trx: {
        trx: {
          expiration,
        },
        receipt: {
          cpu_usage_us,
          net_usage_words,
          status,
        },
      },
    };

    const {data: actions} = await getActions(id.toUpperCase(), block_num);

    const actionsDetails = await Promise.all(
      actions.map(async action => {
        const {act_account: account, act_name: name, permission, actor, act_data, contract: contractName} = action;
        const authorization = [
          {
            permission,
            actor,
          },
        ];

        let contract = this.contracts[contractName];
        if (!contract) {
          contract = await this.props.eosStore.getContract({name: contractName});
          this.contracts[contractName] = contract;
        }

        const dataa = Serialize.deserializeActionData(
          contract,
          contractName,
          name,
          act_data,
          new TextEncoder(),
          new TextDecoder()
        );

        console.log(name, dataa);
        return {...action, account, action_name: name, name, authorization, action_data: dataa};
      })
    );

    const obj = {actions: actionsDetails, fetching: false, transaction};

    this.setState(obj);
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  render() {
    const {transaction, fetching, actions, activeTab} = this.state;
    if (fetching) {
      return (
        <div className='transaction-details-page'>
          <Preloader className='white-bg' />
        </div>
      );
    }
    return (
      <div className='transaction-details-page'>
        {Object.keys(transaction).length ? (
          <div className='page-content'>
            <div className='header-page'>
              <Breadcrumbs currentTitle='trx_details.title' pages={pages} />
            </div>
            <div className='account-info'>
              <TransactionDetail label='trx_details.info.row_id' value={transaction.id.toUpperCase()} />
              <TransactionDetail
                label='trx_details.info.row_time'
                value={moment(`${transaction?.trx.trx.expiration}.000Z`).format(TIME_FORMAT.BLOCK)}
              />
              <TransactionDetail label='trx_details.info.row_status' value={transaction?.trx.receipt?.status} checkStatus />
              <TransactionDetail
                url={`/blocks/${transaction.block_num}`}
                label='trx_details.info.row_block'
                value={transaction.block_num}
              />
              <TransactionDetail label='trx_details.info.row_cpu' value={`${transaction?.trx.receipt?.cpu_usage_us} µs`} />
              <TransactionDetail
                label='trx_details.info.row_net'
                value={`${transaction?.trx.receipt?.net_usage_words * 8} Bytes`}
              />
            </div>
            <div className='header-block-table'>
              <I18n
                className='main-title md'
                tKey={`${activeTab === tabs[0].value ? 'trx_details.control_btn.btn_act' : 'trx_details.subtitle.data_json'}`}
              />
              <div className='table-controls'>
                {tabs.map((elem, i) => (
                  <TableControls
                    key={i}
                    onClick={this.setActiveTab.bind(this, elem.value)}
                    active={elem.value === activeTab}
                    title={elem.checkCount ? `${elem.title} (${actions.length})` : elem.title}
                  />
                ))}
              </div>
            </div>
            {activeTab === tabs[1].value && <JSONTree theme={JSON_TREE_THEME} data={{...transaction, actions}} hideRoot />}
            {actions.length > 0 && activeTab === tabs[0].value && (
              <>
                <Table data={{...tableData, rows: actions}} />
                {/* <Pagination currentPage={1} limit={15} totalCount={678}/> */}
              </>
            )}
          </div>
        ) : (
          <EmptyResults searchValue={this.state.searchValue} />
        )}
      </div>
    );
  }
}
