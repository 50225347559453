import React, {Component} from 'react';
import classNames from 'classnames';

import {I18n} from '../i18n/I18n';
import {Input} from '../input/Input';
import {BalanceRow} from '../balanceRow/BalanceRow';
import {percentBtn} from '../../../config/const';
import {Button} from '../button/Button';

export class InputRow extends Component {
  render() {
    const {
      autoFocus,
      wrapperClass,
      onKeyPress,
      sendAllTokens,
      onBlur,
      error,
      round,
      regEx,
      dividedView,
      separator,
      title,
      subtitle,
      subtitleInput,
      placeholder,
      countBalance,
      inputChange,
      value,
      name,
      label,
      onlyNumbers,
      statusBorder,
      inputClass,
      token,
      titleRow,
      maxLength,
      maxAmount,
      percentBtns,
      onPercentBtnClick,
      readOnly,
      titleValue,
    } = this.props;
    return (
      <div className={classNames('input-row-block', wrapperClass)}>
        {(title || subtitle) && (
          <div className='label-block'>
            {title && <I18n value={titleValue} tKey={title} className='title-row' />}
            {subtitle && <I18n tKey={subtitle} className='subtitle-row' />}
          </div>
        )}
        <div className='input-block'>
          <Input
            autoFocus={autoFocus}
            readOnly={readOnly}
            placeholder={placeholder}
            onChange={inputChange}
            error={error}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            name={name}
            regEx={regEx}
            value={value}
            label={label}
            onlyNumbers={onlyNumbers}
            statusBorder={statusBorder}
            containerClass={inputClass}
            maxLength={maxLength}
            maxAmount={maxAmount}
          />
          {subtitleInput && <I18n tKey={subtitleInput} className={classNames('subtitle-input', {'change-color': value})} />}
          {((countBalance !== undefined && countBalance !== false && countBalance !== null) || percentBtns) && (
            <div className='additional-info'>
              {countBalance !== undefined && countBalance !== false && countBalance !== null && (
                <BalanceRow
                  round={round}
                  dividedView={dividedView}
                  separator={separator}
                  countBalance={countBalance}
                  sendAllTokens={sendAllTokens}
                  token={token}
                  titleRow={titleRow}
                />
              )}
              {percentBtns && (
                <div className='btn-block'>
                  {percentBtn.map((elem, i) => (
                    <Button
                      key={i}
                      title={`${elem.value}%`}
                      btnClass='percent-btn'
                      onClick={onPercentBtnClick.bind(this, elem.value)}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
