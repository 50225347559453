import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {CircleProgress} from '../../helpers/circleProgress/CircleProgress';
import {I18n} from '../../helpers/i18n/I18n';
import {calcPercent, convertBytes, convertMicroSec} from '../../../utils/utils';

export class ResourcesStat extends Component {
  render() {
    const {linkTxt, countUntb, maxValue, currentValue, linkUrl, resourceType, circleClass} = this.props;
    const progress = calcPercent(currentValue, maxValue);
    const convertTosec = resourceType === 'CPU' || resourceType === 'NET';
    return (
      <div className='resources-stat'>
        <CircleProgress titleProgress={resourceType} radius={40} stroke={3} progress={progress} circleClass={circleClass} />
        <div className='statistic-content'>
          <p className='data-row'>
            <span>{convertTosec ? convertMicroSec(currentValue) : convertBytes(currentValue)}</span>
            <span> &#47; {convertTosec ? convertMicroSec(maxValue) : convertBytes(maxValue)}</span>
          </p>
          <span>{countUntb} UNTB</span>
          <Link to={linkUrl} className='link-title'>
            <I18n tKey={linkTxt} /> &#8594;
          </Link>
        </div>
      </div>
    );
  }
}
