import React, {Component} from 'react';
import {ReactComponent as CopySvg} from '../../../img/icon-copy.svg';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class CopyField extends Component {
  state = {
    cbClass: false,
    timer: null,
  };

  copyField = React.createRef();

  setTimer() {
    return setTimeout(() => {
      this.setState({timer: null, show: false});
    }, 1000);
  }

  copyTxt = () => {
    if (!this.state.timer) {
      this.copyField.current.value = '';
      this.copyField.current.value = this.props.copyTxtValue || this.props.txt;
      this.copyField.current.select();
      this.copyField.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.setState({timer: this.setTimer(), show: true});
    }
  };

  render() {
    const {txt, label, labelIsClickable, cdTxt = 'common.copied'} = this.props;
    return (
      <div className={classNames('copy-field-container', this.props.className, {copied: this.state.show})}>
        <input ref={this.copyField} type='text' defaultValue={txt} />
        <div className='visible-part'>
          {label && (
            <div className='label' onClick={labelIsClickable ? this.copyTxt : undefined}>
              <I18n tKey={label} />
            </div>
          )}
          <div className='copy-content'>
            <span>{txt}</span>
            <CopySvg onClick={this.copyTxt} />
          </div>
        </div>
        <div className='cb-txt'>
          <I18n tKey={cdTxt} />
        </div>
      </div>
    );
  }
}
