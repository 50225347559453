import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {withRouter} from 'react-router';

// import {ReactComponent as MainLogo} from '../../../img/cryptounit_logo.svg';
import mainLogo from '../../../img/cryptounit_logo.png';

import {LinkList} from './LinkList';
import {MenuBtn} from '../../helpers/menuBtn/MenuBtn';
import {SearchBox} from '../../helpers/searchBox/SearchBox';
import {MobileMenu} from '../../modals/mobileMenu/MobileMenu';

const FormatOptionLabel = ({name, iso}) => (
  <div className='select-row'>
    <div className={`flag  flag-${iso}`} />
    {name && <span className='label'> {name} </span>}
  </div>
);

@withRouter
@inject('userStore', 'localizeStore')
@observer
export class Header extends Component {
  goToHome = () => {
    this.props.history.push('/');
  };

  changeLng = data => {
    this.props.localizeStore.changeLng(data);
  };

  render() {
    const {languagesList, selectedLng} = this.props.localizeStore;
    const isLoggedIn = !!this.props.userStore.user?.name;
    return (
      <header className='main-header'>
        <div className='icon-block'>
          <MenuBtn modalCmp={MobileMenu} />
          <img alt='Cryptounit home' className='main-logo' onClick={this.goToHome} role='presentation' src={mainLogo} />
          {/* <MainLogo onClick={this.goToHome} /> */}
        </div>
        <LinkList
          class='select-lng-list'
          onChange={this.changeLng}
          value={selectedLng}
          isLoggedIn={isLoggedIn}
          options={languagesList}
          formatOptionLabel={FormatOptionLabel}
        />
        <SearchBox placeholder='header.search_box.placeholder' />
      </header>
    );
  }
}
