import moment from 'moment';
import BigNumber from 'bignumber.js';
import {action, observable} from 'mobx';

import {STORAGE_KEY, TOKENS} from '../config/const';
import {checkBalance, detectRounding, getTokenPrefix, cutTokenPrefix, encode} from '../utils/utils';
import {accountSearch, getAccountBalance, getTableByScope, getEosTable} from '../utils/requester';

const commonTokens = ['CRU', 'WCRU', 'UNTB', 'USDU'];

const getFrozenDep = async (userName, token) => {
  const {data, isError} = await getEosTable({
    json: true,
    code: 'block',
    scope: token,
    limit: 1,
    table: 'depositor',
    lower_bound: userName,
    upper_bound: userName,
  });

  if (isError) {
    return {};
  }

  return data.rows[0] || {};
};

const getBalances = async userName => {
  const {data} = await getEosTable({
    code: 'eosio.token',
    scope: userName,
    json: true,
    limit: 1000,
    table: 'accounts',
  });

  const balances = data.rows.reduce((acc, {balance}) => {
    acc[getTokenPrefix(balance)] = cutTokenPrefix(balance);

    return acc;
  }, {});

  return balances;
};

export class UserStore {
  @observable user = null;

  @observable accountDetails = null;

  @observable userSettings = {
    tokens: [],
  };

  @observable isBusinessAccount = false;

  @observable isProducerAccount = false;

  @observable isCandidatAccount = false;

  @observable userIsFetching = false;

  @observable userStacking = null;

  @observable objsForCruClaim = [];

  @observable unclaimedCruSum = 0;

  @observable cruForCruStaked = 0;

  @observable cruForCruStakedActual = 0;

  @observable cruOnWithdraw = 0;

  @observable wcruOnWithdraw = 0;

  @observable userRefunds = {cpu_amount: 0, net_amount: 0};

  @observable userDebts = {};

  @observable userWallet = {
    CRU: {total: '0', staked: '0', frozen: '0', available: '0', unstaked: '0'},
    WCRU: {total: '0', staked: '0', frozen: '0', available: '0', unstaked: '0', frozenStaked: ''},
    UNTB: {total: '0', staked: '0', available: '0', unstaked: '0'},
    USDU: {total: '0'},
  };

  @observable exchange = {
    isFetching: false,
    statusDone: false,
  };

  constructor(cb) {
    this.checkUser(cb);
  }

  @action
  toggleExchangeParams = (param, value) => {
    this.exchange[param] = value;
  };

  static checkIsBusinessAccount = async account => {
    const {data, isError} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: 'curcreator',
      table: 'baccnt',
    });

    if (isError) return false;

    const isBusinessAcc = !!data.rows.find(({owner}) => {
      return owner === account;
    });

    return isBusinessAcc;
  };

  static checkIsProducerAccount = async account => {
    const {data, isError} = await getEosTable({
      code: 'eosio',
      json: true,
      limit: 1000,
      scope: 'eosio',
      table: 'producers',
    });

    if (isError) return false;

    const isProducerAcc = !!data.rows.find(({owner}) => {
      return owner === account; // isActive??
    });

    return isProducerAcc;
  };

  static checkIsCandidateAccount = async account => {
    const {data, isError} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1000,
      scope: 'prodcand',
      table: 'candprod',
    });

    if (isError) return false;
    console.log(data);
    const isCandidateAcc = !!data.rows.some(({candidate, cancelled}) => {
      return candidate === account && !cancelled;
    });

    return isCandidateAcc;
  };

  static getUserSettings = async account => {
    const {data, isError} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: account,
      table: 'cookie',
    });

    if (isError) return {};

    const settings = data.rows.reduce((acc, {key, value}) => {
      try {
        acc[key] = JSON.parse(value);
      } catch (e) {
        acc[key] = value;
      }

      return acc;
    }, {});

    return settings;
  };

  static getAllUserTokens = async () => {
    const {data: scopes} = await getTableByScope({
      code: 'eosio.token',
      json: true,
      limit: 100000,
      table: 'stat',
    });

    const rawTokensPromices = scopes.rows.map(async ({scope}) => {
      const {data: token} = await getEosTable({
        code: 'eosio.token',
        scope,
        json: true,
        limit: 1,
        table: 'stat',
      });

      const tokenName = getTokenPrefix(token.rows[0].supply);

      const {data: fullNames} = await getEosTable({
        code: 'curcreator',
        json: true,
        limit: 1000,
        scope: token.rows[0].issuer,
        table: 'curconfig',
      });

      if (!commonTokens.includes(tokenName)) {
        return {
          name: tokenName,
          rounding: detectRounding(token.rows[0].max_supply),
          issuer: token.rows[0].isuer,
          fullName: fullNames.rows.find(({cursymbol}) => cursymbol === tokenName)?.name,
        };
      }

      return '';
    });

    const tokens = await Promise.all(rawTokensPromices);
    const userTokens = tokens.filter(value => value);

    return userTokens;
  };

  static getUserTokens = async userName => {
    const tokens = await UserStore.getAllUserTokens();
    const balances = await getBalances(userName);

    const tokensWithExtraInfo = await Promise.all(
      tokens.map(async token => {
        const {total, withdrawed} = await getFrozenDep(userName, token.name);
        return {...token, total, withdrawed};
      })
    );

    const userTokens = tokensWithExtraInfo.reduce((acc, {name, rounding, fullName, total, withdrawed}) => {
      acc[name] = {
        total: balances[name] ? +balances[name] + +cutTokenPrefix(total) - +cutTokenPrefix(withdrawed) : '—',
        available: balances[name] || 0,
        rounding,
        fullName,
        frozen: +cutTokenPrefix(total) - +cutTokenPrefix(withdrawed),
      };

      return acc;
    }, {});

    return userTokens;
  };

  @action
  checkAccount = (cb, storageData) => {
    accountSearch(storageData.name).then(resp => {
      // call validation token HERE
      if (!resp.isError) {
        this.user = storageData;
        this.getAccountInfo(storageData.name).then(r => {
          const isError = r.some(elem => elem.isError);
          cb(!isError);
        });
      } else {
        this.removeUser();
        cb(true);
      }
    });
  };

  @action
  saveToken = (token, key, name) => {
    const savedToken = JSON.stringify({name, token: encode(token, key)});
    sessionStorage.setItem(STORAGE_KEY, savedToken);
    return this.getAccountInfo(name).then(r => {
      const isError = r.some(elem => elem.isError);
      if (!isError) {
        this.user = {name, token: savedToken};
      }
      return r;
    });
  };

  @action
  removeUser = () => {
    sessionStorage.removeItem(STORAGE_KEY);
    this.user = null;
  };

  checkUser = cb => {
    const storageData = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    if (storageData && storageData.name) {
      this.checkAccount(cb, storageData);
    } else {
      setTimeout(cb.bind(this, true));
    }
  };

  static returnRequests = account => [
    getAccountBalance({code: 'eosio.token', account, symbol: TOKENS.CRU}),
    getEosTable({
      json: true,
      code: 'tokenlock',
      scope: 'tokenlock',
      table: 'tbalance',
      limit: 1,
      lower_bound: account,
      upper_bound: account,
    }),
    getEosTable({
      json: true,
      account,
      code: 'eosio',
      scope: 'eosio',
      table: 'stakers',
      limit: 1,
      lower_bound: account,
      upper_bound: account,
    }),
    getAccountBalance({code: 'eosio.token', account, symbol: TOKENS.UNTB}),
    accountSearch(account),
    getAccountBalance({code: 'eosio.token', account, symbol: TOKENS.USDU}),
    getEosTable({
      json: true,
      code: 'eosio',
      scope: account,
      table: 'refunds',
      limit: 1,
      lower_bound: account,
      upper_bound: account,
    }),
    getAccountBalance({code: 'eosio.token', account, symbol: TOKENS.WCRU}),
    getEosTable({json: true, code: 'staker', scope: account, limit: 10000, table: 'stakeobjects'}),
    getEosTable({json: true, code: 'eosio', scope: 'eosio', limit: 1, lower_bound: account, table: 'stakers3'}),
  ];

  static processAccRequests = r => {
    const refundsUntb = (r[6].data.rows || [])[0] || {cpu_amount: 0, net_amount: 0};
    const total_resources = r[4].data.total_resources || {cpu_weight: 0, net_weight: 0}; // prevents app crash
    const stakers = (r[2].data.rows || [])[0] || {
      staked_frozen_wcru_balance: 0,
      staked_wcru_balance: 0,
      staked_cru_balance: 0,
      emitted_balance: 0,
      last_update_at: moment().format(),
    }; // prevents app crash
    const tbalance = (r[1].data.rows || [])[0] || {cru_total: 0, wcru_total: 0, wcru_frozen: 0, cru_frozen: 0}; // prevents app crash
    const untbStaked = new BigNumber(cutTokenPrefix(total_resources.cpu_weight)).plus(cutTokenPrefix(total_resources.net_weight));
    const untbAvailable = new BigNumber(cutTokenPrefix(r[3].data[0]));
    const untbFrozen = new BigNumber(cutTokenPrefix(refundsUntb.cpu_amount)).plus(cutTokenPrefix(refundsUntb.net_amount));
    const accountDetails = r[4].data;
    const cruAvailable = r[0].data[0] || 0;
    const untbTotal = new BigNumber(untbFrozen).plus(untbAvailable).plus(untbStaked).valueOf() || 0;
    const usduTotal = r[5].data[0] ? r[5].data[0] : '0'; // if new user there is [] in data
    const userStakedObj = r[8].data.rows || [];
    const objsForCruClaim = [];

    let unclaimedCruSum = 0;
    let cruForCruStakedActual = 0;
    const cruForCruStaked = (r[8].data.rows || []).reduce((accumulator, curVal) => {
      let quantity = 0;
      if (!curVal.closed) {
        cruForCruStakedActual = new BigNumber(cruForCruStakedActual).plus(cutTokenPrefix(curVal.staked_balance)).toNumber();

        if (moment().isBefore(moment(curVal.last_pay_should_be_at))) {
          quantity = cutTokenPrefix(curVal.emitted_balance);
        } else {
          quantity = new BigNumber(cutTokenPrefix(curVal.emitted_balance))
            .plus(cutTokenPrefix(curVal.staked_balance))
            .plus(cutTokenPrefix(curVal.bonus_balance))
            .toFixed(4);
        }
      }
      unclaimedCruSum = new BigNumber(unclaimedCruSum).plus(quantity).toFixed(4);
      objsForCruClaim.push({
        ...curVal,
        quantity,
      });

      return new BigNumber(accumulator).plus(cutTokenPrefix(curVal.staked_balance)).toNumber();
    }, 0);

    const cruTotal =
      new BigNumber(cutTokenPrefix(cruAvailable))
        .plus(cruForCruStakedActual)
        .plus(cutTokenPrefix(stakers.staked_cru_balance))
        .plus(cutTokenPrefix(tbalance.cru_frozen))
        .toNumber() || 0;

    const wcruAvailable = r[7].data[0] || 0;
    const wcruStacked =
      new BigNumber(cutTokenPrefix(stakers.staked_wcru_balance))
        .minus(cutTokenPrefix(stakers.staked_frozen_wcru_balance))
        .toNumber() || 0;
    const wcruFrozen = new BigNumber(cutTokenPrefix(tbalance.wcru_frozen)).toNumber() || 0;
    const wcruFrozenStaked = cutTokenPrefix(stakers.staked_frozen_wcru_balance);
    const wcruUnstaking = cutTokenPrefix(r[9].data?.rows[0]?.wcru_on_widthdraw);

    const wcruTotal =
      new BigNumber(cutTokenPrefix(wcruAvailable))
        .plus(wcruStacked)
        .plus(wcruUnstaking)
        .plus(wcruFrozen)
        .plus(wcruFrozenStaked)
        .toNumber() || 0;

    return {
      calcData: {
        refundsUntb,
        total_resources,
        stakers,
        tbalance,
        untbStaked,
        untbAvailable,
        untbFrozen,
        accountDetails,
        cruAvailable,
        wcruAvailable,
        wcruStacked,
        wcruUnstaking,
        wcruFrozenStaked,
        wcruFrozen,
        untbTotal,
        usduTotal,
        cruTotal,
        wcruTotal,
        cruForCruStaked,
        cruForCruStakedActual,
        userStakedObj,
        objsForCruClaim,
        unclaimedCruSum,
      },
      r,
    };
  };

  static returnCurrencyFields = (...args) => {
    // conditionally add a member to an object
    return {
      ...(args[0] !== undefined && {total: checkBalance(cutTokenPrefix(args[0]))}),
      ...(args[1] !== undefined && {available: checkBalance(cutTokenPrefix(args[1]))}),
      ...(args[2] !== undefined && {staked: checkBalance(cutTokenPrefix(args[2]))}),
      ...(args[4] !== undefined && {unstaked: checkBalance(cutTokenPrefix(args[4]))}),
      ...(args[3] !== undefined && {frozen: checkBalance(cutTokenPrefix(args[3]))}),
      ...(args[5] !== undefined && {frozenStaked: checkBalance(cutTokenPrefix(args[5]))}),
    };
  };

  @action
  getActiveExchangeObjects = async userName => {
    const rs = await getEosTable({
      json: true,
      code: 'interchange',
      scope: 'interchange',
      index_position: '2',
      key_type: 'name',
      limit: 100,
      reverse: true,
      upper_bound: userName,
      lower_bound: userName,
      table: 'changesext',
    });
    return rs;
  };

  @action
  getDebts = async userName => {
    const {data, isError} = await getEosTable({
      json: true,
      code: 'limiter',
      scope: userName,
      limit: 100,
      table: 'debt',
    });

    if (isError) {
      return {};
    }

    const res = data.rows.reduce((acc, item) => {
      const {sum: debt, target, memo} = item;

      const token = getTokenPrefix(debt);
      const amount = +cutTokenPrefix(debt);
      const fullDebt = {token, amount, target, debt, memo};
      if (!acc[token]) {
        acc[token] = [fullDebt];
      } else {
        acc[token].push(fullDebt);
      }

      return acc;
    }, {});

    return res;
  };

  @action
  getAccountInfo = async userName => {
    const account = userName || this.user.name;

    const userDebts = await this.getDebts(account);

    const userSettings = await UserStore.getUserSettings(account);
    const isBusinessAcc = await UserStore.checkIsBusinessAccount(account);
    const isProducerAcc = await UserStore.checkIsProducerAccount(account);
    const isCandidateAcc = await UserStore.checkIsCandidateAccount(account);
    const tokens = await UserStore.getUserTokens(account);
    const userTokens = Object.keys(tokens).reduce((acc, token) => {
      acc[token] = UserStore.returnCurrencyFields(tokens[token].total, tokens[token].available, 0, tokens[token].frozen);
      acc[token].rounding = tokens[token].rounding;
      acc[token].fullName = tokens[token].fullName;
      return acc;
    }, {});

    const requests = UserStore.returnRequests(account);
    return Promise.all(requests)
      .then(UserStore.processAccRequests)
      .then(resp => {
        const {
          refundsUntb,
          stakers,
          tbalance,
          untbStaked,
          untbAvailable,
          untbFrozen,
          accountDetails,
          cruAvailable,
          wcruAvailable,
          wcruStacked,
          wcruUnstaking,
          wcruFrozenStaked,
          wcruFrozen,
          untbTotal,
          usduTotal,
          cruTotal,
          wcruTotal,
          cruForCruStaked,
          cruForCruStakedActual,
          userStakedObj,
          objsForCruClaim,
          unclaimedCruSum,
        } = resp.calcData;

        if (this.user?.name === resp.r[9]?.data?.rows[0]?.username) {
          this.cruOnWithdraw = cutTokenPrefix(resp.r[9].data?.rows[0]?.cru_on_widthdraw);
          this.wcruOnWithdraw = wcruUnstaking;
        } else {
          // Failsafe - username must match fetched data
          this.cruOnWithdraw = 0;
          this.wcruOnWithdraw = 0;
        }
        this.userStacking = stakers;
        this.cruForCruStaked = cruForCruStaked;
        this.cruForCruStakedActual = cruForCruStakedActual;
        this.userStakedObj = userStakedObj;
        this.objsForCruClaim = objsForCruClaim;
        this.unclaimedCruSum = new BigNumber(unclaimedCruSum).toFixed(4);
        this.userRefunds = refundsUntb;
        this.accountDetails = accountDetails;

        this.isBusinessAccount = isBusinessAcc;
        this.isProducerAccount = isProducerAcc;
        this.isCandidatAccount = isCandidateAcc;
        this.userSettings = {...this.userSettings, ...userSettings};

        this.userDebts = userDebts;
        this.userWallet = {
          ...this.userWallet,
          CRU: UserStore.returnCurrencyFields(
            cruTotal,
            cruAvailable,
            new BigNumber(cutTokenPrefix(stakers.staked_cru_balance)).plus(cruForCruStakedActual).toNumber(),
            tbalance.cru_frozen,
            this.cruOnWithdraw
          ),
          WCRU: UserStore.returnCurrencyFields(
            wcruTotal,
            wcruAvailable,
            wcruStacked,
            wcruFrozen,
            wcruUnstaking,
            wcruFrozenStaked
          ),
          UNTB: UserStore.returnCurrencyFields(untbTotal, untbAvailable.valueOf(), untbStaked.valueOf(), untbFrozen.valueOf(), 0),
          USDU: UserStore.returnCurrencyFields(usduTotal),
          ...userTokens,
        };

        return resp.r;
      });
  };

  @action
  updateUsdu = () => {
    return getAccountBalance({code: 'eosio.token', account: this.user.name, symbol: TOKENS.USDU}).then(r => {
      !r.isError && this.changeTokenField(TOKENS.USDU, 'total', cutTokenPrefix(r.data[0]));
    });
  };

  @action
  changeTokenField = (token, filed, value) => {
    this.userWallet[token][filed] = value;
  };
}
