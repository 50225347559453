import React from 'react';
import classNames from 'classnames';

import {translate} from '../../../utils/translater';

export const I18n = ({onClick, className, value, tKey}) => {
  return (
    <span role='presentation' onClick={onClick} className={classNames(className)}>
      {value === undefined || value === null ? translate(tKey) : translate(tKey).replace('{value}', value)}
    </span>
  );
};
