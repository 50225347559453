import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {CMP_STEPS} from './CreateWallet';
import {MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';
import {Preloader} from '../../helpers/preloader/Preloader';
import {shuffle} from '../../../utils/utils';
import classNames from 'classnames';
import {CopyField} from '../../helpers/copyField/CopyField';

const bip39 = require('bip39');

export class SeedPhrase extends Component {
  state = {
    seedPhrase: [],
    isFetching: false,
    randomSeed: [],
    movedWords: [],
  };

  componentDidMount() {
    this.setState({isFetching: true});
    const seed = bip39.generateMnemonic();
    const newState = {isFetching: false, msg: null};
    const wordsArr = seed.split(' ');
    newState.seedPhrase = [...wordsArr];
    newState.randomSeed = shuffle(wordsArr);
    // newState.randomSeed = wordsArr;
    this.setState(newState);
  }

  generateSeed = () => {
    return bip39.generateMnemonic();
  };

  setStep = () => {
    const step = this.props.step === CMP_STEPS.VERIFY_SEED_PHRASE ? CMP_STEPS.CHOOSE_STORAGE_TYPE : CMP_STEPS.VERIFY_SEED_PHRASE;
    this.props.setParams(this.state);
    this.props.setStep(step);
  };

  moveWord = elem => {
    const {movedWords} = this.state;
    const newArr = [...movedWords, elem];
    this.setState({movedWords: newArr});
  };

  removeWord = elem => {
    const {movedWords} = this.state;
    const newArr = movedWords.filter(word => word !== elem);
    this.setState({movedWords: newArr});
  };

  render() {
    const {step} = this.props;
    const {seedPhrase, isFetching, msg, randomSeed, movedWords} = this.state;
    const btnDisabled = step === CMP_STEPS.VERIFY_SEED_PHRASE ? movedWords.join('') === seedPhrase.join('') : true;
    const copyPhrase = seedPhrase.join(' ');
    return (
      <div className='recovery-phrase'>
        {isFetching && <Preloader className='white-transparent-bg' />}
        <h1 className='block-font lg-size'>
          <I18n tKey={step === CMP_STEPS.VERIFY_SEED_PHRASE ? 'backup_phrase.verify_title' : 'backup_phrase.title_recovery'} />
        </h1>
        {step === CMP_STEPS.VERIFY_SEED_PHRASE ? (
          <div>
            <p className='subtitle-row'>
              <I18n tKey='backup_phrase.verify_subtitle' />
            </p>
            <div className='words-wrapper for-choice'>
              {movedWords.length === 0 && <I18n tKey='backup_phrase.select_word' className='title-block' />}
              {movedWords.map((elem, i) => (
                <div className='word-item recovery-item' key={i} onClick={this.removeWord.bind(this, elem)}>
                  <span className='num-word'>{i + 1} &#46;</span>
                  <span className='word'>{elem}</span>
                </div>
              ))}
            </div>
            <div className='choose-words'>
              {randomSeed.map((elem, i) => (
                <div className={classNames('word-item choose-item', {inactive: movedWords.some(word => word === elem)})} onClick={this.moveWord.bind(this, elem)} key={i}>
                  <span className='word'>{elem}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <p className='subtitle-row'>
              <I18n tKey='backup_phrase.subtitle_recovery' />
            </p>
            <div className='words-wrapper'>
              <div className='words-content'>
                {seedPhrase ? (
                  seedPhrase.map((elem, i) => (
                    <div className='word-item recovery-item' key={i}>
                      <span className='num-word'>{i + 1} &#46;</span>
                      <span className='word'>{elem}</span>
                    </div>
                  ))
                ) : (
                  <MsgPrinter msgType={msg.type} msg={msg.txt} />
                )}
                {msg && (
                  <div className='msg'>
                    <MsgPrinter msgType={msg.type} msg={msg.txt} />
                  </div>
                )}
              </div>
              <div className='copy-btn'>
                <CopyField cdTxt='common.done' labelIsClickable className='only-label' label='backup_phrase.copy_btn' copyTxtValue={copyPhrase} />
              </div>
            </div>
          </div>
        )}
        <Button
          disabled={msg || isFetching || !btnDisabled}
          btnClass='next-step-btn with-bg blue'
          onClick={this.setStep}
          title={step === CMP_STEPS.VERIFY_SEED_PHRASE ? 'backup_phrase.next_btn' : 'backup_phrase.recovery_btn'}
        />
      </div>
    );
  }
}
