import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';

import {ReactComponent as CloseIcon} from '../../../img/cross.svg';
import {ReactComponent as SearchIcon} from '../../../img/search_icon.svg';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {Table} from '../../helpers/table/Table';
import {DotRow} from '../walletHistory/dotRow';
import {accountSearch, getEosTable} from '../../../utils/requester';
import {CheckBox} from '../../helpers/checkBox/CheckBox';

import {EnterPin} from '../../modals/enterPin/EnterPin';
import {Preloader} from '../../helpers/preloader/Preloader';
import {Pagination} from '../../helpers/pagination/Pagination';
import {Input} from '../../helpers/input/Input';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const tableData = {
  columns: [
    {name: 'wallet_vote.col_position', key: 'position'},
    {name: 'wallet_vote.col_name', key: 'owner'},
    {name: 'wallet_vote.col_region', ley: 'location'},
    {name: 'wallet_vote.col_ratio'},
    {name: 'wallet_vote.col_voters', key: 'total_votes'},
    {name: 'wallet_vote.col_blocks', key: 'unpaid_blocks'},
    {name: 'wallet_vote.col_reward'},
  ],
};

@inject('eosStore', 'userStore', 'modalStore', 'loggerStore')
@observer
export class WalletVote extends Component {
  state = {
    fetching: true,
    producers: [],
    nameFilter: '',
    filteredProducers: [],
    voteFor: [],
    page: 0,
    pageRows: 25,
  };

  componentDidMount() {
    const user = this.props.userStore.user.name;
    Promise.all([
      getEosTable({json: true, limit: 100, code: 'eosio', scope: 'eosio', table: 'producers'}),
      accountSearch(user),
    ]).then(r => {
      const isError = r.some(elem => elem.isError);
      const newState = {fetching: false};
      if (!isError) {
        newState.producers = (r[0].data.rows || [])
          // newState.producers = [...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows, ...r[0].data.rows]
          .sort((a, b) => Number(b.total_votes) - Number(a.total_votes))
          .map((elem, i) => ({
            ...elem,
            position: i + 1,
            isVoted: (r[1].data.voter_info?.producers || []).findIndex(voter => voter === elem.owner) !== -1,
          }));
        newState.producers.map((elem, i) => {
          if (elem.isVoted) {
            this.setState({
              voteFor: [...this.state.voteFor, elem.owner],
            });
          }
        });
      }
      this.setState(newState);
    });
  }

  selectProducer = data => {
    const {voteFor, producers} = this.state;
    const newState = {producers: [...producers]};
    const index = producers.findIndex(elem => elem.owner === data.value);
    const curentVote = newState.producers[index].isVoted;
    if (voteFor.findIndex(elem => elem === data.value) > -1) {
      newState.voteFor = voteFor.filter(elem => elem !== data.value);
      newState.producers[index].isVoted = !curentVote;
    } else if (voteFor.length < 30) {
      newState.voteFor = [...voteFor, data.value];
      newState.producers[index].isVoted = !curentVote;
    }
    this.setState(newState);
  };

  voteToProducers = pin => {
    const producers = this.state.producers

      .filter(producer => producer.isVoted)
      .map(producer => producer.owner)
      .sort();
    const user = this.props.userStore.user.name;
    this.setState({isFetching: true});
    this.props.eosStore.voteToProducers(user, producers, pin).then(r => {
      const newState = {producers: [...this.state.producers], isFetching: false};

      accountSearch(user).then(resp => {
        if (!resp.isError && !r.isError) {
          newState.producers.forEach(elem => {
            elem.isVoted = (resp.data.voter_info?.producers || []).some(voter => voter === elem.owner);
          });
          // newState.voteFor = [];
          // this.props.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'wallet_vote.passed'});
        }
        this.setState(newState);
      });
    });
  };

  changePage = page => {
    this.setState({page});
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.voteToProducers});
  };

  filterProducers = (propsName, value) => {
    const newState = {[propsName]: value, filteredProducers: []};
    if (value) {
      newState.filteredProducers = [...this.state.producers].filter(producer => producer.owner.includes(value));
    }
    this.setState(newState);
  };

  checkProducerActiveState = row => (!row.is_active ? 'warning' : '');

  render() {
    const {voteFor, fetching, producers, page, pageRows, nameFilter, filteredProducers, isFetching} = this.state;
    const rowsForView = nameFilter ? filteredProducers : producers;
    const rows = rowsForView.slice(page > 0 ? page * pageRows : 0, page > 0 ? (page + 1) * pageRows : pageRows);
    return (
      <div className='wallet-vote'>
        {fetching && <Preloader />}
        <div className='top-content'>
          <I18n tKey='wallet_vote.title' className='title-page' />
          <div className='selected-block'>
            <I18n tKey='wallet_vote.subtitle' className='subtitle-page' />
            <span> ({`${voteFor.length} / ${producers.length}`})</span>
          </div>
          <div className='selected-row'>
            {voteFor.sort().map((elem, i) => (
              <p key={i}>
                {elem}
                <CloseIcon onClick={this.selectProducer.bind(this, {value: elem})} />
              </p>
            ))}
          </div>
          <Button
            disabled={isFetching || !voteFor.length}
            onClick={this.openPinModal}
            value={voteFor.length}
            title='wallet_vote.vote-to-producers'
            btnClass='with-bg blue'
          />
        </div>
        <div className='bottom-content'>
          <div className='header-bottom-content'>
            <div className='info-table'>
              <DotRow rows={[{title: 'wallet_vote.top_block', rowClass: 'green'}]} />
              <DotRow rows={[{title: 'wallet_vote.producer', rowClass: 'orange'}]} />
              <DotRow rows={[{title: 'common.total', totalCount: producers.length}]} />
            </div>
            <div className={classNames('search-block', {'change-style': nameFilter})}>
              <Input
                allowClear
                value={nameFilter}
                name='nameFilter'
                onChange={this.filterProducers}
                placeholder='wallet_vote.placeholder'
              />
              <SearchIcon />
            </div>
          </div>
          <Table returnClass={this.checkProducerActiveState} voteTable data={{...tableData, rows}} tableClass='vote-table'>
            <CheckBox onChange={this.selectProducer} />
          </Table>
          <Pagination changePage={this.changePage} currentPage={page + 1} limit={pageRows} totalCount={rows.length} />
        </div>
      </div>
    );
  }
}
