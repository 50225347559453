import {RouterStore} from 'mobx-react-router';
import ReactDOM from 'react-dom';
import React from 'react';
import moment from 'moment';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AppError} from './components/mainPages/appError/AppError';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/ro';
import 'moment/locale/pt';
import 'moment/locale/kk';
import 'moment/locale/it';
import 'moment/locale/hi';
import 'moment/locale/de';
import 'moment/locale/et';
import 'moment/locale/cs';
import 'moment/locale/en-au';
import 'moment/locale/zh-cn';

import ModalStore from './stores/ModalStore';
import TokenStore from './stores/TokenStore';
import BlockListStore from './stores/BlockListStore';
import {UserStore} from './stores/UserStore';
import EosStore from './stores/EosStore';
import {LNG_LOCAL_KEY, LocalizeStore} from './stores/LocalizeStore';
import {LoggerStore} from './stores/LoggerStore';

const loadFlags = {
  tokenStore: false,
  userStore: false,
  localize: false,
};

const waitLoader = storeName => {
  return status => {
    if (status) {
      loadFlags[storeName] = true;
      // if (Object.values(loadFlags).filter(f => !f).length === 0) {

      if (Object.values(loadFlags)[1] && loadFlags.localize) {
        // console.log('Start app');
        startApp();
      }
      //  else {
      //   // Pushed this else loop inside, to prevent page reload / re-render (when URL V1 is not working/down)

      //   // console.log(Object.values(loadFlags))
      //   // console.log('Error')
      //   ReactDOM.render(<AppError />, document.getElementById('root'));
      // }
    } else if (!status && !Object.values(loadFlags)[1]) {
      ReactDOM.render(<AppError />, document.getElementById('root'));
    }
  };
};

const userStore = new UserStore(waitLoader('userStore'));
const tokenStore = new TokenStore(waitLoader('tokenStore'));
export const localizeStore = new LocalizeStore(waitLoader('localize'));
const loggerStore = new LoggerStore();
const eosStore = new EosStore(loggerStore);
const blockListStore = new BlockListStore();
const modalStore = new ModalStore();
const routingStore = new RouterStore();
const curLng = JSON.parse(localStorage.getItem(LNG_LOCAL_KEY)) || {iso: 'en'};

moment.locale(curLng.iso);

const store = {
  routing: routingStore,
  blockListStore,
  localizeStore,
  loggerStore,
  modalStore,
  tokenStore,
  userStore,
  eosStore,
};

const startApp = () => {
  ReactDOM.render(<App store={store} />, document.getElementById('root'));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
