import React, {Component} from 'react';

import BigNumber from 'bignumber.js';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {TokenInfoBlock} from '../../helpers/tokenInfoBlock/TokenInfoBlock';
import {NumberConverter} from '../../helpers/numberConverter/NumberConverter';
import {getEosTable} from '../../../utils/requester';
import {convertBytes, cutTokenPrefix} from '../../../utils/utils';
import {TOKENS, percentBtn, REG_EXP} from '../../../config/const';
import {Preloader} from '../../helpers/preloader/Preloader';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {TableControls} from '../../helpers/tableСontrols/TableControls';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';

const tabs = [
  {title: 'wallet_ram.tab_buy', value: 'buy'},
  {title: 'wallet_ram.tab_sell', value: 'sell'},
];

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
@observer
export class WalletRam extends Component {
  state = {
    sellRam: '',
    byRam: '',
    rammarket: null,
    ramPrice: null,
    marketIsFetching: true,
    opIsActive: false,
    activeTab: tabs[0].value,
  };

  componentDidMount() {
    getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'rammarket'}).then(r => {
      const newState = {marketIsFetching: false, rammarket: null, ramPrice: null};
      if (!r.isError) {
        const rammarket = r.data.rows[0];
        newState.rammarket = rammarket;
        newState.ramPrice = new BigNumber(cutTokenPrefix(rammarket.quote.balance))
          .div(new BigNumber(cutTokenPrefix(rammarket.base.balance)).multipliedBy(cutTokenPrefix(rammarket.quote.weight)))
          .toNumber();
      }
      this.setState(newState);
    });
  }

  static convertInBytes(value, course) {
    if (!value) {
      return 0;
    }
    return new BigNumber(value).div(course).toFixed(2);
  }

  static convertInUntb(value, course) {
    if (!value) {
      return 0.0;
    }
    return new BigNumber(value).multipliedBy(course).toFixed(4);
  }

  onChange = (propName, value) => {
    const newState = {[propName]: value};
    this.setState(newState);
  };

  makeRamTransaction = (isBuyOp, pin) => {
    const {sellRam, byRam} = this.state;
    const quantity = isBuyOp ? `${byRam} ${TOKENS.UNTB}` : Number(sellRam);
    this.setState({opIsActive: true});
    this.props.eosStore.makeRamTransaction(this.props.userStore.user.name, quantity, isBuyOp, pin).then(r => {
      const newState = {opIsActive: false};
      if (!r.isError) {
        this.props.userStore.getAccountInfo();
        newState.byRam = '';
        newState.sellRam = '';
      }
      this.setState(newState);
    });
  };

  onInputBlur = () => {
    const {byRam} = this.state;
    let defaultAmount = '';
    const splitedAmount = byRam.split('.');
    if (!splitedAmount[0]) {
      return;
    }
    if (!splitedAmount[1]) {
      defaultAmount = `${splitedAmount[0]}.0000`;
    } else if (splitedAmount[1].length < 4) {
      const emptyFigures = 4 - splitedAmount[1].length;

      // defaultAmount = amount     // this variable is never declared
      defaultAmount = byRam;
      Array.from(new Array(emptyFigures)).forEach(i => (defaultAmount += '0'));
    }
    defaultAmount && this.setState({byRam: defaultAmount});
  };

  openPinModal = isBuyOp => {
    if (!isBuyOp) {
      const {ram_quota, ram_usage} = this.props.userStore.accountDetails;
      const sellLimit = new BigNumber(ram_quota).minus(this.state.sellRam).toNumber();
      if (sellLimit <= ram_usage) {
        this.props.loggerStore.addItem({type: LOGGER_TYPES[2], msgTxt: 'wallet_ram.warning'});
        return;
      }
    }
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.makeRamTransaction.bind(this, isBuyOp)});
  };

  setActiveTab = tabName => {
    this.setState({activeTab: tabName});
  };

  setPercentValue = (percent, availableRam) => this.setState({sellRam: ((percent * availableRam) / 100).toFixed(0)});

  render() {
    const {byRam, sellRam, opIsActive, ramPrice, marketIsFetching, activeTab} = this.state;
    const {accountDetails, userWallet} = this.props.userStore;
    // console.log({...this.props.userStore.accountDetails});
    // console.log(this.props.userStore)
    const bought = accountDetails.ram_quota;
    const inUse = accountDetails.ram_usage;
    const availableRam = new BigNumber(bought).minus(inUse).toNumber();
    const availableUNTB = new BigNumber(userWallet.UNTB.available).toNumber();
    const zeroByRam = +byRam === 0;
    const zeroSellRam = +sellRam === 0;

    if (marketIsFetching) {
      return (
        <div className='nested-route wallet-ram'>
          <Preloader className='white-bg' />
        </div>
      );
    }

    if (!ramPrice) {
      return (
        <div className='nested-route wallet-ram'>
          <div>
            <I18n className='process-msg' tKey='wallet_ram.cant_find_price' />
          </div>
        </div>
      );
    }

    return (
      <div className='nested-route wallet-ram margin'>
        <div className='wallet-ram-block'>
          <I18n tKey='wallet_ram.title' className='title-page' />
          <div className='wallet-content'>
            <div className='top-content'>
              <TokenInfoBlock
                title='wallet_ram.info_row'
                count={new BigNumber(ramPrice).toFixed(8)}
                unit='UNTB'
                subtitle='wallet_ram.subtitle'
              />
              {/* <NumberConverter value="1.254" unitCount="USDU per KB"/> */}
            </div>
            <div className='toggle-tabs'>
              {tabs.map((elem, i) => (
                <TableControls
                  key={i}
                  onClick={this.setActiveTab.bind(this, elem.value)}
                  active={elem.value === activeTab}
                  title={elem.title}
                  tabClass='tab-item'
                />
              ))}
            </div>
            <div className={classNames('center-content', {'hide-mobile-form': activeTab === tabs[1].value})}>
              <I18n tKey='wallet_ram.buy_ram' className='title-page' />
              <div className='input-with-btn'>
                <div>
                  <Input
                    placeholder='wallet_ram.buy_placeholder'
                    value={byRam}
                    regEx={REG_EXP.four_digits}
                    onBlur={this.onInputBlur}
                    name='byRam'
                    label={byRam && 'wallet_ram.buy_placeholder'}
                    onChange={this.onChange}
                    statusBorder
                    onlyNumbers
                    maxAmount={availableUNTB}
                  />
                  <NumberConverter converterFunc={WalletRam.convertInBytes} course={ramPrice} value={byRam} unitCount='Bytes' />
                </div>
                <Button
                  btnClass='with-bg blue'
                  disabled={opIsActive || !byRam || zeroByRam}
                  onClick={this.openPinModal.bind(this, true)}
                  title='wallet_ram.btn_buy'
                />
              </div>
            </div>
            <div className={classNames('bottom-content', {'hide-mobile-form': activeTab === tabs[0].value})}>
              <I18n tKey='wallet_ram.sell_ram' className='title-page' />
              <div className='info-blocks'>
                <TokenInfoBlock
                  title='wallet_ram.sell_info.bought'
                  unitCount={TOKENS.UNTB}
                  count={convertBytes(bought)}
                  converterCount={WalletRam.convertInUntb(bought, ramPrice)}
                />
                <TokenInfoBlock
                  title='wallet_ram.sell_info.use'
                  unitCount={TOKENS.UNTB}
                  count={convertBytes(inUse)}
                  converterCount={WalletRam.convertInUntb(inUse, ramPrice)}
                />
                <TokenInfoBlock
                  title='wallet_ram.sell_info.available'
                  unitCount={TOKENS.UNTB}
                  count={convertBytes(availableRam)}
                  converterCount={WalletRam.convertInUntb(availableRam, ramPrice)}
                />
              </div>
              <div className='input-with-btn'>
                <div>
                  <Input
                    placeholder='wallet_ram.sell_placeholder'
                    value={sellRam}
                    name='sellRam'
                    label={sellRam && 'wallet_ram.sell_placeholder'}
                    onChange={this.onChange}
                    statusBorder
                    onlyNumbers
                    maxAmount={availableRam}
                  />
                  <div className='btn-block'>
                    {percentBtn.map((elem, i) => (
                      <Button
                        key={i}
                        title={`${elem.value}%`}
                        btnClass='percent-btn'
                        onClick={this.setPercentValue.bind(this, elem.value, availableRam)}
                      />
                    ))}
                  </div>
                  <NumberConverter converterFunc={WalletRam.convertInUntb} course={ramPrice} value={sellRam} unitCount='UNTB' />
                </div>
                <Button
                  btnClass='with-bg blue'
                  disabled={opIsActive || !sellRam || zeroSellRam}
                  onClick={this.openPinModal.bind(this, false)}
                  title='wallet_ram.btn_sell'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
