import React, {Component} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {inject, observer} from 'mobx-react';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {changeFormat, cutTokenPrefix} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';

import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {getEosTable} from '../../../utils/requester';

const isDevMode = window.location.href.includes('explorer-uatbc') || window.location.href.includes('localhost:3000');
const formatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

@inject('eosStore', 'userStore', 'modalStore', 'loggerStore')
@observer
export class StakingInfo extends Component {
  state = {
    claimOpIsFetching: false,
    claimBalanceUpdating: false,
    devUnixDate: '',
    name: '',
    key: '',
  };

  claimCRU = async pin => {
    const {userStore, eosStore, loggerStore, soclaim, updateSoclaimTable} = this.props;

    this.setState({claimOpIsFetching: true});

    const requests = soclaim.map(objForCru => {
      if (this.getObjUnclaimedCount(objForCru) === 0) {
        return Promise.resolve({});
      }

      const fallbackName = isDevMode && this.state.name;
      const pinToUse = (isDevMode && this.state.key) || pin;

      return eosStore.claimCru(
        userStore.user.name,
        `${changeFormat(this.getObjUnclaimedCount(objForCru), 4, formatSettings)} CRU`,
        objForCru.id,
        pinToUse,
        this.state.devUnixDate,
        fallbackName
      );
    });

    await Promise.all(requests);

    await userStore.getAccountInfo();
    await updateSoclaimTable();

    this.setState({claimOpIsFetching: false});
  };

  openPinModal = () => {
    const {modalStore} = this.props;

    modalStore.open(EnterPin, null, null, {onValidPin: this.claimCRU});
  };

  makeForceUpdate = async pin => {
    const {userStore, eosStore, loggerStore, updateSoclaimTable} = this.props;
    const {objsForCruClaim, user} = userStore;

    this.setState({claimBalanceUpdating: true});

    const fallbackName = isDevMode && this.state.name;
    const pinToUse = (isDevMode && this.state.key) || pin;

    if (objsForCruClaim.length > 0) {
      const requests = objsForCruClaim.map(objForCru =>
        objForCru.closed
          ? Promise.resolve({})
          : eosStore.updateStakedCru(user.name, objForCru.id, pinToUse, this.state.devUnixDate, fallbackName)
      );
      await Promise.all(requests);
    }

    await userStore.getAccountInfo();
    await updateSoclaimTable();

    this.setState({claimBalanceUpdating: false});
  };

  forceUpdate = () => {
    const {modalStore} = this.props;

    modalStore.open(EnterPin, null, null, {onValidPin: this.makeForceUpdate});
  };

  getLastUpdateDate = () => {
    const {userStore} = this.props;
    const {objsForCruClaim} = userStore;

    const lastUpdateDate = objsForCruClaim.reduce((curentOldestDate, {closed, last_update_at}) => {
      return closed ? curentOldestDate : moment.min([moment(curentOldestDate), moment(`${last_update_at}+0000`)]);
    }, moment((objsForCruClaim[0] && `${objsForCruClaim[0].last_update_at}+0000`) || ''));

    const lastUpdate = moment(lastUpdateDate).local().fromNow();

    return lastUpdate;
  };

  getObjUnclaimedCount = claimObj => {
    return +cutTokenPrefix(claimObj.possible_claim);
  };

  getUnclaimedCountTotal = () => {
    const {soclaim} = this.props;

    const unclaimedCount = soclaim.reduce((count, claimObj) => {
      const newCount = count + this.getObjUnclaimedCount(claimObj);

      return newCount;
    }, 0);

    return unclaimedCount;
  };

  onChangeInput = e => {
    this.setState({devUnixDate: moment(e.target.value).unix()});
  };

  onChangeName = e => {
    this.setState({name: e.target.value});
  };

  onChangeKey = e => {
    this.setState({key: e.target.value});
  };

  render() {
    const {isFetching, userStore, soclaim} = this.props;
    const {claimOpIsFetching, claimBalanceUpdating} = this.state;

    const unclaimedCount = this.getUnclaimedCountTotal();
    const lastUpdate = this.getLastUpdateDate();

    const needsUpdate = +unclaimedCount <= 0 || soclaim.length === 0;

    const {objsForCruClaim} = userStore;

    const isAllClosed = objsForCruClaim.every(({closed}) => !!closed);

    return (
      <>
        {isDevMode && (
          <>
            <input
              onChange={this.onChangeInput}
              style={{border: '2px solid black', width: '200px', margin: '40px'}}
              type='date'
            />
            <input
              placeholder='name'
              onChange={this.onChangeName}
              style={{border: '2px solid black', width: '200px', margin: '40px'}}
            />
            <input
              placeholder='key'
              onChange={this.onChangeKey}
              style={{border: '2px solid black', width: '200px', margin: '40px'}}
            />
          </>
        )}
        {!isAllClosed && (
          <div className={classNames('staking-update', {'staking-claim': !needsUpdate})}>
            <div className='update-title'>
              <div className='title-count'>
                {soclaim.length !== 0 && (
                  <p className='staking-count'>
                    {isFetching ? (
                      <I18n tKey='common.loading' />
                    ) : (
                      <span>
                        {changeFormat(unclaimedCount, 4)} {TOKENS.CRU}
                      </span>
                    )}
                  </p>
                )}
                <p className='staking-title'>
                  <I18n tKey='wallet_cru.staking_title' />
                  <I18n className='subtitle' tKey='wallet_cru.staking_subtitle' />
                </p>
              </div>
            </div>
            <div className='update-info'>
              <div className='update-info-text'>
                <I18n tKey='wallet_cru.update_info' />
                <span> {lastUpdate} </span>
              </div>
              <RefreshIcon
                className={classNames({active: claimBalanceUpdating, disabled: claimOpIsFetching || claimBalanceUpdating})}
                onClick={this.forceUpdate}
              />
            </div>

            <Button
              disabled={claimOpIsFetching || claimBalanceUpdating || isFetching || needsUpdate}
              onClick={this.openPinModal}
              btnClass='claim-btn'
              title='wallet_cru.claim_btn_cru'
            />
          </div>
        )}
      </>
    );
  }
}
