import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {I18n} from '../../helpers/i18n/I18n';
import {changeFormat} from '../../../utils/utils';
import {TOKENS} from '../../../config/const';
import {NumberConverter} from '../../helpers/numberConverter/NumberConverter';

import {ReactComponent as ArrowBack} from '../../../img/arrow_back.svg';
import {CardBlockInfo} from './CardBlockInfo';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {CheckBox} from '../../helpers/checkBox/CheckBox';

import {ReactComponent as VisaIcon} from '../../../img/visa_logo.svg';
import {translate} from '../../../utils/translater';
import {MSG_TYPES, MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';

const HEADER_TYPES = {
  ISSUING: 'issuing',
  SENDING: 'sending',
  BURNING: 'burning',
  SLICE: 'slice',
  CONVERT: 'convert',
};

export class ModalHeader extends Component {
  state = {
    paymentShow: false,
    cardNumber: '',
    cardThru: '',
    cardName: '',
    cvv: '',
    city: '',
    zipCode: '',
    address: '',
    country: '',
  };

  showPayment = () => {
    this.setState({paymentShow: !this.state.paymentShow});
  };

  render() {
    const {paymentShow, cardNumber, cardThru, cardName, cvv, city, zipCode, address, country} = this.state;
    const {operation, params, memo, receiver, token, walletSend, fee, rate, convert, inputChange, hidePinTitle} = this.props;
    const rounding = 4;
    // let rounding = 0;
    // if (token === TOKENS.UNTB) {
    //   rounding = 4;
    // } else if (token === TOKENS.USDU) {
    //   rounding = 2;
    // }
    return (
      <>
        {paymentShow && (
          <div className='payment-block'>
            <div className='header-payment'>
              <div
                className='arrow-back'
                // onClick={this.showPayment}
              >
                <ArrowBack />
              </div>
              <I18n tKey='convert_wcru.payment_block.title' className='header-title' />
            </div>
            <span className='amount'>$1.84 </span>
            <div className='amount-count'>
              <I18n tKey='wallet_wcru.convert' />
              <span className='value'>{changeFormat(params.value, rounding)}</span>
              {convert && (
                <div className='convert-row'>
                  <span className='token-value'>{TOKENS.CRU}</span>
                  <I18n tKey='common.to' />
                  <span className='value-wcru'>{changeFormat(params.valueWCRU, 4)}</span>
                </div>
              )}
              <span className='token-value'>{walletSend || convert ? token : TOKENS.USDU}</span>
              {/* {walletSend && <NumberConverter value={params.value} unitCount='USDU' />} */}
            </div>
            <div className='cards-block'>
              <div className='data-card'>
                <VisaIcon />
                <Input
                  containerClass='card-number'
                  label='convert_wcru.payment_block.card_number'
                  placeholder='0000 0000 0000 0000'
                  onChange={inputChange}
                  onlyNumbers
                  inputType='tel'
                  maxlength={19}
                  name='cardNumber'
                  value={cardNumber}
                  // maxLength={pinLength}
                  // error={pinError}
                  statusBorder
                  readOnly
                />
                <div className='sm-inputs'>
                  <Input
                    containerClass='card-thru'
                    label='convert_wcru.payment_block.valid_thru'
                    placeholder='MM/YY'
                    onChange={inputChange}
                    onlyNumbers
                    name='cardThru'
                    value={cardThru}
                    maxlength={7}
                    readOnly
                    // error={pinError}
                  />
                  <Input
                    containerClass='card-name'
                    label='convert_wcru.payment_block.card_name'
                    placeholder='Jhohn Doe'
                    onChange={inputChange}
                    name='cardName'
                    value={cardName}
                    readOnly
                    // error={pinError}
                  />
                </div>
              </div>
              <div className='data-cvv-card'>
                <p className='gray-stripe' />
                <div className='card-content'>
                  <Input
                    containerClass='card-cvv'
                    placeholder='convert_wcru.payment_block.cvv_code'
                    onChange={inputChange}
                    name='cvv'
                    value={cvv}
                    readOnly
                  />
                  <I18n tKey='convert_wcru.payment_block.subtitle_zip_code' className='input-subtitle' />
                </div>
              </div>
            </div>
            <div className='inputs-container'>
              <CheckBox label='convert_wcru.payment_block.remember_card' />
              <Input
                containerClass='card-input'
                placeholder='convert_wcru.payment_block.country_residence'
                onChange={inputChange}
                name='country'
                value={country}
                readOnly
              />
              <div className='city-zip'>
                <Input
                  containerClass='card-input city'
                  placeholder='convert_wcru.payment_block.city'
                  onChange={inputChange}
                  name='city'
                  value={city}
                  readOnly
                />
                <Input
                  containerClass='card-input zip'
                  placeholder='convert_wcru.payment_block.zip_code'
                  onChange={inputChange}
                  name='zipCode'
                  value={zipCode}
                  readOnly
                />
              </div>
              <Input
                containerClass='card-input'
                placeholder='convert_wcru.payment_block.address'
                onChange={inputChange}
                name='address'
                value={address}
                readOnly
              />
              <Button btnClass='with-bg blue' title='Pay $ 1.84' />
              <p className='notice-txt'>
                &#42;
                <I18n tKey='convert_wcru.payment_block.notice_txt' />
              </p>
            </div>
          </div>
        )}
        {!paymentShow && (
          <div className='modal-dividend-header'>
            <h1 className={`operation ${params.className}`}>
              <I18n tKey={params.headerLabelText} />
            </h1>
            {params.value && (
              <div className='amount-count'>
                <span className='value'>{changeFormat(params.value, rounding)}</span>
                {convert && (
                  <div className='convert-row'>
                    <span className='token-value'>{TOKENS.CRU}</span>
                    <I18n tKey='common.to' />
                    <span className='value-wcru'>{changeFormat(params.valueWCRU, 4)}</span>
                  </div>
                )}
                <span className='token-value'>{walletSend || convert ? token : TOKENS.USDU}</span>
                {/* {walletSend && <NumberConverter value={params.value} unitCount='USDU' />} */}
              </div>
            )}
            {operation === HEADER_TYPES.SENDING && (
              <>
                <div className='to-wrapper'>
                  <I18n tKey='wallet_dividends_distribute.to' className='to-field' />
                  <I18n tKey={receiver || 'wallet_dividends_distribute.dividend'} className='to-info-field' />
                </div>
                {memo && (
                  <div className='memo-wrapper'>
                    <I18n tKey='wallet_dividends_distribute.memo' className='memo-field' />
                    <div className='memo-info-field'>{memo}</div>
                  </div>
                )}
              </>
            )}
            {operation === HEADER_TYPES.CONVERT && (
              <>
                <div className='row-wrapper rate'>
                  <I18n tKey='convert_wcru.conversion_rate' className='name-field' />
                  <p>{rate}</p>
                </div>
                <div className='row-wrapper'>
                  <I18n tKey='convert_wcru.transaction_fee' className='name-field' />
                  <p>{fee}</p>
                </div>
                {/* <div className='row-wrapper pay-from'>
                  <I18n tKey='convert_wcru.pay_method' className='name-field' />
                  <CardBlockInfo
                    // onClick={this.showPayment}
                    title='convert_wcru.bank_card'
                  />
                </div> */}
              </>
            )}
            {operation === HEADER_TYPES.SLICE && (
              <>
                <div className='rectangle-wrapper'>
                  <I18n tKey='wallet_dividends_distribute.rectangle-wrapper-text' className='rectangle-wrapper-text' />
                </div>
              </>
            )}
            {!hidePinTitle && <I18n tKey='wallet_dividends_distribute.enter-pin-field' className='enter-pin' />}
          </div>
        )}
      </>
    );
  }
}

export default HEADER_TYPES;
