import React, {Component} from 'react';
import {ReactComponent as Panel} from '../../../img/speedometer.svg';
import {I18n} from '../i18n/I18n';
import {getRandomInt} from '../../../utils/utils';

export class Speedometer extends Component {
  state = {value: 0};
  calcArrowPosition = num => {
    const maxPoint = 180;
    return Math.round(maxPoint * (num / 100));
  };
  returnShadowClass = num => {
    if (num < 20) {
      return 'first-state';
    } else if (num >= 20 && num < 40) {
      return 'second-state';
    } else if (num >= 40 && num < 60) {
      return 'third-state';
    } else if (num >= 60 && num < 80) {
      return 'fourth-state';
    } else if (num >= 80) {
      return 'fifth-state';
    } else {
      return 'first-state';
    }
  };

  componentDidMount() {
    // setInterval(() => this.setState({value: getRandomInt(100)}), 3000)
  }

  render() {
    const {value} = this.props;
    //const {value = 73} = this.state;
    const deg = this.calcArrowPosition(value);
    return (
      <div className='speedometer-widget'>
        <div className='speedometer-wrapper'>
          <div className='speedometer-panel'>
            <Panel />
          </div>
          <div className={`widget-shadow ${this.returnShadowClass(value)}`} />
          <div className='speedometer-info'>
            <div className='speedometer-arrow' style={{transform: `rotateZ(${deg}deg)`}} />
            <div className='speedometer-value'>{value}%</div>
          </div>
        </div>
        <div className='widget-title'>
          <I18n tKey='wallet_page.dashboard.staked' />
        </div>
      </div>
    );
  }
}
