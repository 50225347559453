import {Api, JsonRpc, RpcError} from 'eosjs';
import {JsSignatureProvider} from 'eosjs/dist/eosjs-jssig';
import {observable} from 'mobx';
import {getEosTable} from '../utils/requester';
import {LOGGER_TYPES} from './LoggerStore';

const isMagic = sessionStorage.getItem('magic');
const isDevMode = isMagic && (window.location.href.includes('explorer-uatbc') || window.location.href.includes('localhost:3000'));

class EosStore {
  @observable debugMode = typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.DEBUG_MODE : false;

  constructor(loggerStore) {
    this.loggerStore = loggerStore;
  }

  returnApi = privateKey => {
    const rpc = new JsonRpc(
      typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_URL_V2 : process.env.REACT_APP_URL_V2
    );
    const signatureProvider = new JsSignatureProvider(privateKey ? [privateKey] : []);
    return new Api({rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder()});
  };

  printDebugPayload = (payload, extraParams) => {
    if (this.debugMode) {
      try {
        if (payload.actions.length === 1) {
          console.log(`Action name: ${payload.actions[0]?.name}, account: ${payload.actions[0]?.authorization[0].actor}`);
          console.log('payload:', payload);
        } else {
          console.log(`Actions: ${payload.actions.map(({name}) => name).join(', ')}`);
          console.log(payload);
        }
        extraParams && console.log('extraParams: ', extraParams);
      } catch (e) {
        console.error(e);
        console.log('unexpected payload: ', payload);
      }
    }
  };

  /**
     *
     * Passing authData as
     *
     * {
                account: account.account_name,
                permission: 'active',
                parent: 'owner',
                auth: authorization_object
       }
     *
     * @param account
     * @param authData
     * @param pin
     * @returns {Promise<any|{msg: string, isError: boolean}>}
     */
  updateAccountAuth = async (account, authData, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'updateauth',
            authorization: [
              {
                actor: account.account_name,
                permission: authData.permission,
              },
            ],
            data: authData,
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.change_key_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  exchangeFrozenCRU = async ({account, data, pin, errorMsg}) => {
    try {
      //       actor: 'znbwo5vmkhoi',
      const transactionData = {
        actions: data.map(exchangeObj => {
          return {
            account: 'tokenlock',
            name: 'intchange',
            authorization: [
              {
                actor: isDevMode ? 'magic' : account,
                permission: 'active',
              },
            ],
            data: {
              username: account,
              lock_id: exchangeObj.id,
              quantity: exchangeObj.amount,
              est_converted_month: exchangeObj.converted_month,
            },
          };
        }),
      };

      const chunks = transactionData.actions.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 10);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);

      console.log(chunks);

      const params = {blocksBehind: 3, expireSeconds: 30};
      const result = await Promise.all(
        chunks.map(actions => {
          return this.returnApi(pin).transact({actions}, params);
        })
      );

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  makeAccountRequest = async (account, publicKey) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'creatorcruac',
            name: 'regaccount',
            authorization: [{actor: 'creatorcruac', permission: 'registrator'}],
            data: {username: account, owner_key: publicKey},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi('5JqGt2iBYUYgL2oSuu1pYQyv7y9Rxknz2bwRo2vvQ4KvpuCpmzX').transact(
        transactionData,
        params
      );

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      console.log(e);
      return this.processErrorOperation(e);
    }
  };

  makeUsduOp = async (quantity, sender, memo = '', isIssueOp, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: isIssueOp ? 'issue' : 'retire',
            authorization: [{actor: sender, permission: 'active'}],
            data: {from: sender, to: sender, quantity, memo, username: sender},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  processErrorOperation = (e, errorMsg, isSilentMode) => {
    const commonMsg = 'errors.common.operation_error';
    const result = {isError: true, msg: commonMsg};
    console.log(`\nCaught exception: ${e}`);
    let parsedError = '';
    if (e instanceof RpcError) {
      (e.json?.error?.details || []).forEach(error => {
        parsedError += `${error.message}.`;
      });
      parsedError && (result.msg = parsedError);
    }

    let knownError = '';
    switch (true) {
      case parsedError.includes('CPU') || parsedError?.includes('transaction was executing for too long'):
        knownError = 'common.cpu';
        break;
      case parsedError.includes('Not all wcru delayed balances have been updated'):
        knownError = 'errors.common.dividend_payment_in_progress';
        break;
      case parsedError.includes('assertion failure with message: to account does not exist'):
        knownError = 'errors.account';
        break;
      case parsedError.includes('before full refresh balance'):
        knownError = 'errors.transaction.update_balance';
        break;
      default:
        knownError = '';
    }

    !isSilentMode && this.loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: knownError || errorMsg || commonMsg, parsedError});
    return result;
  };

  makeTransaction = async ({quantity, sender, receiver, memo = '', pin, errorMsg = ''}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{actor: isDevMode ? 'magic' : sender, permission: 'active'}],
            data: {from: isDevMode ? 'magic' : sender, to: receiver, quantity, memo},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  makeStakingOp = async ({quantity, sender, isStake, pin, stakeActionName, unstakeActionName, contract, errorMsg = ''}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: contract,
            name: isStake ? stakeActionName : unstakeActionName,
            authorization: [{actor: sender, permission: 'active'}],
            data: {username: sender, quantity},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  makeStakingOpUntb = async ({cpu, net, sender, isStake, pin, errorMsg = ''}) => {
    const additionalFields = isStake
      ? {
          stake_net_quantity: net,
          stake_cpu_quantity: cpu,
          transfer: false,
        }
      : {unstake_net_quantity: net, unstake_cpu_quantity: cpu};
    const data = {from: sender, receiver: sender, ...additionalFields};

    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: isStake ? 'delegatebw' : 'undelegatebw',
            authorization: [{actor: sender, permission: 'active'}],
            data,
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  cruForceUpdate = async (username, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'refresh',
            authorization: [{actor: username, permission: 'active'}],
            data: {username},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  updateStakedCru = async (username, stake_id, pin, unixDate, fallbackName) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'staker',
            name: isDevMode && unixDate ? 'refreshtest' : 'refresh',
            authorization: [{actor: isDevMode && fallbackName ? fallbackName : username, permission: 'active'}],
            data: {staker: username, stake_id, refresh_utc_time: (isDevMode && unixDate) || undefined},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  claimUnstackingTokens = async (username, api) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'frwithdraw',
            authorization: [{actor: isDevMode ? 'magic' : username, permission: 'active'}],
            data: {username},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await api.transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  // exchangeFrozenObj = async (pin, username, lock_id, quantity, est_converted_month) => {
  //   // console.log('eos action intchange', {username, lock_id, quantity, est_converted_month});

  //   try {
  //     const transactionData = {
  //       actions: [
  //         {
  //           account: 'tokenlock',
  //           name: 'intchange',
  //           authorization: [{actor: username, permission: 'active'}],
  //           data: {username, lock_id, quantity, est_converted_month},
  //         },
  //       ],
  //     };
  //     const params = {blocksBehind: 3, expireSeconds: 30};

  //     this.printDebugPayload(transactionData, params);

  //     const result = await this.returnApi(pin).transact(transactionData, params);

  //     return result;
  //   } catch (e) {
  //     return this.processErrorOperation(e);
  //   }
  // };

  // distributeDividends = async (username, id, slice_at, pin, quantity) => {
  //   try {
  //     const transactionData = {
  //       actions: [
  //         {
  //           account: 'dividend',
  //           name: 'distribute',
  //           authorization: [{actor: username, permission: 'active'}],
  //           data: {id, slice_at, quantity},
  //         },
  //       ],
  //     };
  //     const params = {blocksBehind: 3, expireSeconds: 30};

  //     this.printDebugPayload(transactionData, params);

  //     const result = await this.returnApi(pin).transact(transactionData, params);

  //     return result;
  //   } catch (e) {
  //     return this.processErrorOperation(e);
  //   }
  // };

  makeRamTransaction = async (sender, quantity, isBuyOp, pin) => {
    const data = isBuyOp ? {payer: sender, receiver: sender, quant: quantity} : {account: sender, bytes: quantity};

    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: isBuyOp ? 'buyram' : 'sellram',
            authorization: [{actor: sender, permission: 'active'}],
            data,
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, isBuyOp ? 'errors.ram.buy' : 'errors.ram.sell');
    }
  };

  updateFrozenObj = async (username, id, api) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'tokenlock',
            name: 'refresh',
            authorization: [{actor: isDevMode ? 'magic' : username, permission: 'active'}], // todo remove
            data: {username, id},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await api.transact(transactionData, params);

      // this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, 'errors.common.operation_error');
    }
  };

  withdrawFrozenObj = async (username, id, api) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'tokenlock',
            name: 'withdraw',
            authorization: [{actor: isDevMode ? 'magic' : username, permission: 'active'}], // todo tok1 isDevMode? 'tok1' : username
            data: {username, id},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await api.transact(transactionData, params);

      // this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, '', true);
    }
  };

  claimUntb = async (username, amount, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'getreward',
            authorization: [{actor: username, permission: 'active'}],
            data: {username, to_withdraw: amount},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  claimCru = async (username, quantity, stake_id, pin, unixDate, fallbackName) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'staker',
            name: isDevMode && unixDate ? 'withdrawtest' : 'withdraw',
            authorization: [{actor: (isDevMode && fallbackName) || username, permission: 'active'}],
            data: {staker: username, quantity, stake_id, refresh_utc_time: (isDevMode && unixDate) || undefined},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.trx_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  // getActiveExchanges = username => {
  //   return getEosTable({
  //     json: true,
  //     code: 'interchange',
  //     scope: 'interchange',
  //     limit: 1,
  //     lower_bound: username,
  //     table: 'changext',
  //   });
  // };

  voteToProducers = async (username, producers, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'voteproducer',
            authorization: [{actor: username, permission: 'active'}],
            data: {
              voter: username,
              proxy: '',
              producers,
            },
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'wallet_vote.passed'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, 'wallet_vote.error');
    }
  };

  // deleteDividendObj = async (username, distribution_id, pin) => {
  //   try {
  //     const transactionData = {
  //       actions: [
  //         {
  //           account: 'dividend',
  //           name: 'cancel',
  //           authorization: [{actor: username, permission: 'active'}],
  //           data: {distribution_id},
  //         },
  //       ],
  //     };
  //     const params = {blocksBehind: 3, expireSeconds: 30};

  //     this.printDebugPayload(transactionData, params);

  //     const result = await this.returnApi(pin).transact(transactionData, params);

  //     return result;
  //   } catch (e) {
  //     return this.processErrorOperation(e);
  //   }
  // };

  cancelExchange = async ({pin, userName, id}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'interchange',
            name: 'cancel',
            authorization: [{actor: isDevMode ? 'magic' : userName, permission: 'active'}],
            data: {username: userName, id, est_converted_month: 0},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  setCurrencyRate = async ({pin, userName, actionName, field, value, contract}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: contract || 'interchange',
            name: actionName,
            authorization: [{actor: userName || 'interchange', permission: 'active'}],
            data: {[field]: value},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, 'errors.set_covert_rates');
    }
  };

  getContract = async ({name}) => {
    try {
      const result = await this.returnApi().getContract(name);
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  claimRewards = async ({pin, userName}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'claimrewards',
            authorization: [{actor: userName, permission: 'active'}],
            data: {owner: userName},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.claim_rewards_success'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, 'common.claim_rewards_error');
    }
  };

  createToken = async ({pin, userName, memo, fee, errorMsg}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{actor: userName, permission: 'active'}],
            data: {from: userName, to: 'curcreator', quantity: fee, memo},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  setUserSettings = async ({pin, userName, settingName, value, errorMsg}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'curcreator',
            name: 'setcookies',
            authorization: [{actor: userName, permission: 'active'}],
            data: {user: userName, key: settingName, value},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e, errorMsg);
    }
  };

  setTokenName = async ({pin, name, cursymbol, fullName}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'curcreator',
            name: 'setcurname',
            authorization: [{actor: name, permission: 'active'}],
            data: {issuer: name, cursymbol, description: fullName},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  claimRewards = async ({pin, userName}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio',
            name: 'claimrewards',
            authorization: [{actor: userName, permission: 'active'}],
            data: {owner: userName},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  becomeCandidate = async ({pin, userName, fee, memo}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{actor: userName, permission: 'active'}],
            data: {from: userName, to: 'prodcand', quantity: fee, memo},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  cancelTender = async ({pin, userName}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'prodcand',
            name: 'cancelcand',
            authorization: [{actor: userName, permission: 'active'}],
            data: {candidate: userName},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  investCand = async ({pin, userName, quantity, memo}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{actor: userName, permission: 'active'}],
            data: {from: userName, to: 'prodcand', quantity, memo},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  refundInvestments = async ({pin, userName, id}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'prodcand',
            name: 'cancelsupp',
            authorization: [{actor: userName, permission: 'active'}],
            data: {assistant: userName, supportid: id},
          },
        ],
      };
      const params = {blocksBehind: 3, expireSeconds: 30};

      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.done'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  returnDebt = async (sender, receiver, quantity, memo, pin) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'transfer',
            authorization: [
              {
                actor: sender,
                permission: 'active',
              },
            ],
            data: {from: sender, to: receiver, quantity, memo},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.debts_returned'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  setTokenMonthLimit = async (pin, sender, limit) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'limiter',
            name: 'addlimit',
            authorization: [
              {
                actor: sender,
                permission: 'active',
              },
            ],
            data: {limit},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.limit_setted'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  addToTokenWhitelist = async (pin, account, userName, token) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'limiter',
            name: 'addwhitelist',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {username: userName, currency_code: token},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.added_to_whitelist'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  addToTokenWhitelistTo = async (pin, account, userName, token) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'limiter',
            name: 'addwhiteto',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {username: userName, currency_code: token},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.added_to_whitelist_to'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  removeFromTokenWhitelist = async (pin, account, userName, token) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'limiter',
            name: 'rmwhitelist',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {username: userName, currency_code: token},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.removed_from_whitelist'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  removeFromTokenWhitelistTo = async (pin, account, userName, token) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'limiter',
            name: 'rmwhiteto',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {username: userName, currency_code: token},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.removed_from_whitelist'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  setMetadata = async ({pin, userName, currency, plan_name, desc, url}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'setmetadata',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {currency, plan_name, desc, url},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.metadata_setted'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  initPlan = async ({pin, userName, currency, phase_in_days, frozen_phases, active_phases}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'initplan',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {currency, phase_in_days, frozen_phases, active_phases},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.plan_initied'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  setPhasePercent = async ({pin, userName, currency, phase, percent}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'setpercent',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {currency, phase: +phase, percent: +percent},
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.percent_setted'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  activatePlan = async ({pin, userName, currency, start = ''}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'activate',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              currency,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.plan_activated'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  deactivatePlan = async ({pin, userName, currency, end = ''}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'deactivate',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              currency,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.plan_deactivated'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  issueToken = async ({pin, userName, quantity, issueTo}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'eosio.token',
            name: 'issue',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              to: 'block',
              quantity,
              memo: issueTo,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.tokens_issued'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  unfreezeUser = async ({pin, userName, currency, user}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'unfreezeuser',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              username: user,
              currency,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.user_unfreezed'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  refreshUserPlans = async ({pin, userName, currency, user}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'refreshuser',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              username: user,
              currency,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.user_unfreezed'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };

  refreshUserDeposit = async ({pin, userName, deposit_id}) => {
    try {
      const transactionData = {
        actions: [
          {
            account: 'block',
            name: 'refreshobj',
            authorization: [
              {
                actor: userName,
                permission: 'active',
              },
            ],
            data: {
              username: userName,
              deposit_id,
            },
          },
        ],
      };

      const params = {blocksBehind: 3, expireSeconds: 30};
      this.printDebugPayload(transactionData, params);

      const result = await this.returnApi(pin).transact(transactionData, params);

      this.loggerStore.addItem({type: LOGGER_TYPES[0], msgTxt: 'common.object_refreshed'});
      return result;
    } catch (e) {
      return this.processErrorOperation(e);
    }
  };
}

export default EosStore;
