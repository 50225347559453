import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {I18n} from '../i18n/I18n';

import imgAccount from '../../../img/img_account.svg';

export class Breadcrumbs extends Component {
  render() {
    const {currentTitle, icon, pages} = this.props;
    return (
      <div className='breadcrumbs'>
        <div className='before-items'>
          {pages.map((elem, i) => (
            <Link className='before-title' to={elem.url} key={i}>
              <I18n tKey={elem.title} />
            </Link>
          ))}
        </div>
        <div className='title-img'>
          {icon && <img alt='' src={imgAccount} className='img-account' />}
          <I18n className='main-title md' tKey={currentTitle} />
        </div>
      </div>
    );
  }
}
