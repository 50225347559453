import React, {Component} from 'react';
import moment from 'moment';

import {AccountDetail} from './AccountDetail';
import {AccountBalance} from './AccountBalance';
import {AccountTransactions} from './AccountTransactions';

import {Preloader} from '../../helpers/preloader/Preloader';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';

import {TIME_FORMAT} from '../../../config/const';
import {accountSearch, getAbi} from '../../../utils/requester';
import {LockHistory} from './LockHistory';
import {EmptyResults} from '../../helpers/emptyResults/EmptyResults';

const pages = [
  {title: 'breadcrumbs.home', url: '/'},
  {title: 'breadcrumbs.accounts', url: '/accounts'},
];

export class AccountDetails extends Component {
  state = {
    fetching: false,
    account: null,
    smartContract: null,
    searchValue: '',
  };

  componentDidMount() {
    const {name} = this.props.match.params;
    this.searchAccount(name);

    if (parseInt(this.state.searchValue.length) < 2) {
      this.setState({searchValue: this.props.match.params.name});
      this.searchAccount(this.props.match.params.name);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.match.params.name !== nextProps.match.params.name) {
      this.setState({searchValue: nextProps.match.params.name});
      this.searchAccount(nextProps.match.params.name);
    } else if (parseInt(this.state.searchValue.length) < 2) {
      this.setState({searchValue: this.props.match.params.name});
      this.searchAccount(this.props.match.params.name);
    }
  }

  searchAccount = name => {
    this.setState({fetching: true});
    Promise.all([getAbi(name), accountSearch(name)]).then(r => {
      const isError = r.some(elem => elem.isError);
      const newState = {fetching: false, account: null, smartContract: null};
      if (!isError) {
        newState.smartContract = r[0].data;
        newState.account = r[1].data;
      }
      this.setState(newState);
    });
  };

  render() {
    const {fetching, account, smartContract} = this.state;
    if (fetching) {
      return (
        <div className='account-details-page'>
          <Preloader className='white-bg' />
        </div>
      );
    }
    return (
      <div className='account-details-page'>
        {account ? (
          <div className='page-content'>
            <div className='header-page'>
              <Breadcrumbs titleBefore='breadcrumbs.home' icon currentTitle={account.account_name} pages={pages} />
            </div>
            <div className='account-info'>
              <AccountDetail title='account.info.create' value={moment(account.created).format(TIME_FORMAT.BLOCK)} />
              <AccountDetail title='account.info.owner_key' account={account} permissionName='owner' />
              <AccountDetail title='account.info.active_key' account={account} permissionName='active' />
              <AccountDetail
                title='account.info.contract'
                value={smartContract.abi ? smartContract.account_name : 'account.none'}
              />
            </div>
            <AccountBalance account={account} accountName={this.state.account.account_name} />
            <AccountTransactions accountName={this.state.account.account_name} />
          </div>
        ) : (
          <EmptyResults searchValue={this.state.searchValue} />
        )}
      </div>
    );
  }
}
