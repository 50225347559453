import wcruCard from '../img/new_wcru.png';
import cruCard from '../img/new_cru.png';
import usduCard from '../img/new_usdu.png';
import untbCard from '../img/new_untb.png';
import {translate} from '../utils/translater';

export const STORAGE_KEY = 'CRYPTO_WALLET';

export const getRoundedRegExp = rounding => {
  return new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,${rounding}}$)`);
};

export const REG_EXP = {
  float_digits: new RegExp(/(^\d{0,9}$)|(^\d*\.\d{0,}$)/),
  four_digits: new RegExp(/(^\d{0,9}$)|(^\d*\.\d{0,4}$)/),
  four_letters: new RegExp(/^[a-zA-Z]{1,4}$/),
  alphanumeric: new RegExp(/^[a-zA-Z0-9]+$/),
  alphanumericWithSpace: new RegExp(/^[a-zA-Z0-9 ]+$/),
};

export const EOS_ACTION_TYPES = new Map([
  ['transfer', 'action.label.transfer'], // 'Transfer'],
  ['updateauth', 'action.label.update_auth'], // 'Update auth'],
  ['issue', 'action.label.create'], // 'Create USDU'],
  ['retire', 'action.label.burn'], // 'Burn USDU'],
  ['stake', 'action.label.stake'], // 'Stake'],
  ['unstake', 'action.label.unstake'], // 'Unstake'],
  ['delegatebw', 'action.label.stake_untb'], // 'Stake UNTB'],
  ['undelegatebw', 'action.label.unstake_untb'], // 'Unstake UNTB'],
  ['buyram', 'action.label.buy_ram'], // 'Buy RAM'],
  ['sellram', 'action.label.sell_ram'], // 'Sell RAM'],
  ['refresh', 'action.label.force_update'], // 'Force update'],
  ['getreward', 'action.label.claim_untb'], // 'Claim UNTB'],
  ['distribute', 'action.label.distribute'], // 'Distribute'],
  ['voteproducer', 'action.label.producer_vote'], // 'Vote for producer'],
  ['cancel', 'action.label.delete_dividend'], // 'Delete dividend'],
  ['approvech', 'action.label.exchange_approved'], // 'Exchange approved'],
  ['frstake', 'action.label.stake_frozen_wcru'], // 'Stake Frozen WCRU'],
  ['frunstake', 'action.label.unstake_frozen_wcru'], // 'Unstake Frozen WCRU'],
  ['intchange', 'action.label.cru_exchange'], // 'CRU exchange'],
]);

export const TIME_FORMAT = {
  BLOCK: 'YYYY-MMM-D, HH:mm:ss',
  CREATED: 'MMMM D YYYY.',
  DIVIDEND: 'YYYY-MM-DDTHH:mm:ss.SSS',
  TRANSACTIONS: 'll',
  HOURS: 'h:mm:ss a',
  CONVERT: 'Do MMM YYYY',
  CONVERT_TABLE: 'DD/MM/YYYY',
  CRU_FOR_CRU: 'D.MM.YYYY',
  REWARD_SCHEDULE: 'D MMM YYYY',
  TOOLTIP: 'MMMM Y',
};

export const TOKENS = {
  CRU: 'CRU',
  USDU: 'USDU',
  UNTB: 'UNTB',
  WCRU: 'WCRU',
};

export const JSON_TREE_THEME = {
  scheme: 'bright',
  author: 'chris kempson (http://chriskempson.com)',
  base00: '#000000',
  base01: '#303030',
  base02: '#505050',
  base03: '#b0b0b0',
  base04: '#d0d0d0',
  base05: '#e0e0e0',
  base06: '#f5f5f5',
  base07: '#ffffff',
  base08: '#fb0120',
  base09: '#fc6d24',
  base0A: '#fda331',
  base0B: '#a1c659',
  base0C: '#76c7b7',
  base0D: '#6fb3d2',
  base0E: '#d381c3',
  base0F: '#be643c',
  tree: {
    border: '1px solid #ECEFF1',
    borderRadius: 9,
    paddingTop: 40,
    paddingLeft: 34,
    paddingBottom: 40,
    paddingRight: 10,
    fontSize: '0.9375rem',
    lineHeight: '1.375rem',
    fontFamily: 'IBM Plex Sans',
    listStyle: 'none',
    // listStyleType: 'decimal',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  valueText: ({style}, nodeType) => ({
    style: {
      ...style,
      color: valueColorMap(colors)[nodeType],
    },
  }),

  label: {
    display: 'inline-block',
    color: '$dark',
  },
  arrowSignInner: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

const colorMap = () => ({
  STRING_COLOR: '#52AA55',
  NUMBER_COLOR: '#F2994A',
  BOOLEAN_COLOR: '#428BCA',
  NULL_COLOR: '#9B51E0',
  UNDEFINED_COLOR: '$dark',
  FUNCTION_COLOR: '#9B51E0',
  // SYMBOL_COLOR: 'red',
});
export const colors = colorMap();

const valueColorMap = colors => ({
  String: colors.STRING_COLOR,
  Number: colors.NUMBER_COLOR,
  Boolean: colors.BOOLEAN_COLOR,
  Null: colors.NULL_COLOR,
  Undefined: colors.UNDEFINED_COLOR,
  Function: colors.FUNCTION_COLOR,
  // Symbol: colors.SYMBOL_COLOR
});

export const graphOptions = (detailData, monthArr) => ({
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        gridLines: [
          {
            display: false,
          },
        ],
      },
    ],
    xAxes: [
      {
        ticks: {
          padding: 15,
          beginAtZero: true,
          minRotation: 89,
          autoSkip: false,
        },
        gridLines: [
          {
            display: false,
          },
        ],
      },
    ],
  },
  legend: {
    display: false,
  },

  tooltips: {
    enabled: false,
    backgroundColor: '#FFFFFF',
    mode: 'index',
    position: 'nearest',
    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
      }
      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        const elemIndex = tooltipModel.dataPoints[0].index;
        const titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '<thead>';

        innerHtml += '</thead><tbody>';

        bodyLines.forEach(function () {
          const amount = detailData[elemIndex].value;
          const formatDate = monthArr[elemIndex];
          const trxAmount = detailData[elemIndex].includedObjects.length;
          const leftHeader = `<div class="left-header">${formatDate}<span>${trxAmount} ${translate(
            'convert_wcru.chart-tooltip-transactions'
          )}</span></div>`;
          const rightHeader = `<div class="right-header">${translate('convert_wcru.chart-tooltip-total')}<span>${
            amount < 0 ? '0.0000' : amount
          } CRU </span></div>`;
          innerHtml += `<tr> <td> <div class="header-tooltip">${leftHeader}${rightHeader}</div></td></tr>`;
          // innerHtml += '<tr> <td> <div>' + frozen + '</div></td></tr>';
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      const position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
      tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
      if (document.documentElement.clientWidth < 779) {
        tooltipEl.style.left = '40' + '%';
        tooltipEl.style.top = `${position.top + window.pageYOffset}px`;
      }
      tooltipEl.style.pointerEvents = 'none';
    },
  },
});

export const defaultGraphData = {
  labels: [],
  datasets: [
    {
      data: [],
      detailData: [],
      backgroundColor: [],
      borderWidth: 1,
    },
  ],
  monthArr: [],
};

export const unfreezeDateConst = {
  firstAlgorithm: 300,
  secondAlgorithm: 510,
  fullUnfreeze: 1050,
  1: 300,
  2: 510,
  3: 365,
};

export const percentBtn = [{value: '25'}, {value: '50'}, {value: '70'}, {value: '100'}];

export const TOKEN_ICONS = {CRU: cruCard, WCRU: wcruCard, USDU: usduCard, UNTB: untbCard};
