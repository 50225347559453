/* eslint-disable no-nested-ternary */
import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import ecc from 'eosjs-ecc';
import HDKey from 'hdkey';
import * as bip39 from 'bip39';
import wif from 'wif';
import {inject, observer} from 'mobx-react';
import {changeFormat, cutTokenPrefix, decode} from '../../../utils/utils';

import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import {ReactComponent as LockIcon} from '../../../img/lock-shield.svg';
import statusIcon from '../../../img/success.svg';

import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {MSG_TYPES, MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';

import {STORAGE_KEY} from '../../../config/const';
import HEADER_TYPES from '../../mainPages/walletDividends/ModalHeader';
import {Preloader} from '../../helpers/preloader/Preloader';
import {translate} from '../../../utils/translater';
import {CopyField} from '../../helpers/copyField/CopyField';

Modal.setAppElement('#root');

@inject('userStore')
@observer
export class EnterPin extends Component {
  state = {
    pin: '',
    email: '',
    invalidPin: false,
    invalidEmail: false,
    isChecking: true,
    totalBalanceUSDU: 100,
    accountName: '',
  };

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp);

    document.body.style.overflow = 'hidden';
    const {totalBalanceUSDU, accountName} = this.props;
    this.setState({
      isChecking: false,
      totalBalanceUSDU: totalBalanceUSDU === '' ? 0 : totalBalanceUSDU,
      accountName,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
    document.body.style.overflow = 'initial';
  }

  onKeyUp = event => {
    const {pin} = this.state;
    const keyName = event.key;

    switch (keyName) {
      case 'Enter':
        if (pin.length >= 6) {
          this.checkPin();
        }
        break;

      default:
    }
  };

  checkPin = () => {
    const {pin, email} = this.state;
    const {requireEmail, openTable} = this.props;
    openTable && openTable();
    this.setState({invalidPin: false, invalidEmail: false, isChecking: true});
    const privateKey = decode(JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '{}').token, pin);

    let privKey = privateKey;
    const isSeed = bip39.validateMnemonic(privateKey);
    if (isSeed) {
      const seed = bip39.mnemonicToSeedSync(privateKey).toString('hex');
      const master = HDKey.fromMasterSeed(Buffer(seed, 'hex'));
      const node = master.derive("m/44'/194'/0'/0/0");
      privKey = wif.encode(128, node._privateKey, false);
    }
    if (requireEmail && !email) {
      return this.setState({invalidEmail: true, isChecking: false});
    }
    if (ecc.isValidPrivate(privKey)) {
      this.props.operation !== HEADER_TYPES.CONVERT && this.props.closeModal();
      requireEmail ? this.props.params.onValidPin(privKey, email) : this.props.params.onValidPin(privKey);
    } else {
      this.setState({invalidPin: true, isChecking: false});
    }
  };

  onChange = (propName, value) => {
    const newState = {[propName]: value, invalidPin: false, invalidEmail: false};
    this.setState(newState);
  };

  checkInputLength = () => this.state.pin.length >= 6;

  closeStatusModal = closeModal => {
    this.props.params.closeCb && this.props.params.closeCb();
    closeModal();
  };

  render() {
    const {
      isOpen,
      closeModal,
      params,
      operation,
      btnTitle,
      btnTitleBack,
      ArrowIcon,
      requireEmail,
      sendUSDU,
      amount,
      memo,
      fee,
      id,
    } = this.props;
    const {pin, invalidPin, email, invalidEmail} = this.state;
    const {exchange} = this.props.userStore;
    const className = classNames('mobile-modal-overlay');
    const isConvert = operation === HEADER_TYPES.CONVERT;
    const contentClass = classNames('pin-content', {'status-modal': exchange.statusDone}, params.pinContentClass);

    return (
      <Modal
        overlayClassName={className}
        className='enter-pin-modal'
        isOpen={isOpen}
        shouldCloseOnEsc
        onRequestClose={closeModal}
      >
        <div className={contentClass}>
          {this.state.isChecking && <Preloader className='white-bg' />}

          {parseFloat(this.state.totalBalanceUSDU) < parseFloat(cutTokenPrefix(amount)) ? (
            <div className='requirement-wrapper'>
              <h1>
                <I18n className='modal-header' tKey='wallet_wcru.requirement' />
                <span className='highlight__span'> {this.state.accountName} </span>
                <I18n className='modal-header' tKey='wallet_wcru.amount-of' />
                <span className='highlight__span'> {amount} </span>
              </h1>
              <span className='name-field'>
                {' '}
                <I18n tKey='convert_wcru.insufficient' />{' '}
              </span>
              <CopyField cdTxt='Done' className='blue-theme' label='convert_wcru.account_name' txt={this.state.accountName} />
              <CopyField cdTxt='Done' className='blue-theme' label='convert_wcru.memo' txt={id} />
              <div className='btn-wrapper'>
                <Button btnClass='with-bg blue' ArrowIcon={ArrowIcon} disabled={false} onClick={closeModal} title='common.ok' />
                <p onClick={closeModal} />
              </div>
            </div>
          ) : exchange.statusDone ? (
            <div className='show-status-done'>
              <p className='status-icon'>
                <img src={statusIcon} alt='' />
              </p>
              <I18n className='status-title' tKey='convert_wcru.done_convert' />
              {isConvert && (
                <div className='count-trx-info'>
                  <span className='count-convert'>{changeFormat(params.countConvert, 4)}</span>
                  <I18n tKey='convert_wcru.status_txt' />
                </div>
              )}
              <Button btnClass='with-bg blue' onClick={this.closeStatusModal.bind(this, closeModal)} title='common.done' />
            </div>
          ) : (
            <>
              {this.props.children ? (
                this.props.children
              ) : (
                <>
                  <LockIcon className='lock-icon' />
                  <h1>
                    <I18n tKey='wallet_modal.enter_pin' />
                  </h1>
                </>
              )}
              {requireEmail && (
                <div className='input-wrapper email'>
                  <Input
                    containerClass='email-field hide-placeholder'
                    placeholder={translate('email')} // todo key
                    onChange={this.onChange}
                    error={invalidEmail}
                    statusBorder
                    name='email'
                    useTrim
                    maxLength={100}
                    value={email}
                  />
                </div>
              )}
              <div className='input-wrapper'>
                <Input
                  containerClass={requireEmail ? 'password-field hide-placeholder' : 'password-field'}
                  placeholder='000000'
                  statusBorder
                  onlyNumbers
                  checkIsFilled={this.checkInputLength}
                  onChange={this.onChange}
                  error={invalidPin}
                  name='pin'
                  maxLength={6}
                  value={pin}
                  isPassword
                />
                <div className='msg-container'> {invalidPin && <MsgPrinter msgType={MSG_TYPES[1]} msg='common.wrong_pin' />}</div>
              </div>
              {/* <div className='password-remind'>Fogrot PIN?</div> */}
              {/* <div className='vector'/> */}
              {isConvert && (
                <div className='notice-block'>
                  <div>
                    <InfoIcon className='circular__img' />
                  </div>
                  <div className='notice-info-text-wrapper'>
                    <I18n tKey='common.attention' />
                    <div>
                      {/* {sendUSDU ? <I18n tKey='convert_wcru.paying_exchange' /> : <I18n tKey='convert_wcru.after_conversion' />} */}
                      {sendUSDU ? (
                        <span>
                          {' '}
                          <I18n tKey='wallet_wcru.notice' /> <b>{id} </b>
                          <I18n tKey='wallet_wcru.amount_of' /> <b>{amount}</b>{' '}
                        </span>
                      ) : (
                        <>
                          <I18n tKey='convert_wcru.after_conversion' />
                          {/* <br /><br /> */}
                          <I18n className='alert' tKey='convert_wcru.usdu_comission_updates' />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='btn-wrapper'>
                <Button
                  btnClass='with-bg blue'
                  ArrowIcon={ArrowIcon}
                  disabled={pin.length < 6}
                  onClick={this.checkPin}
                  title={btnTitle || 'common.confirm_pin'}
                />
                <p onClick={closeModal}>
                  <I18n tKey={btnTitleBack || 'common.back'} />
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}
