import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {Serialize} from 'eosjs';

import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';
import {Pagination} from '../../helpers/pagination/Pagination';
import {getTrTableFlask} from '../../../utils/requester';
import {I18n} from '../../helpers/i18n/I18n';
import {TrxHistoryTable} from '../../helpers/trxHistoryTable/TrxHistoryTable';

const pages = [{title: 'breadcrumbs.home', url: '/'}];

@inject('eosStore')
export class Transactions extends Component {
  contracts = {};

  state = {
    totalCount: 0,
    limit: 20,
    page: 0,
    transactions: [],
    isFetching: true,
  };

  componentDidMount() {
    this.getTransactions();
    if (this.props.location.state && this.props.location.state.page !== 'undefined') {
      this.setPage(this.props.location.state.page);
    }
  }

  getTransactions = () => {
    this.setState({isFetching: true});
    const data = {
      page: this.state.page,
      limit: 20,
      orderBy: {column: 'id', order: 'desc'},
    };
    getTrTableFlask(data).then(async r => {
      const newState = {isFetching: false};
      if (!r.isError) {
        newState.transactions = await Promise.all(
          r.data.map(async transaction => {
            const {act_name, act_data, contract: contractName} = transaction.actions[0];

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await this.props.eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              act_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            console.log(act_name, dataa);

            return {...transaction, action_data: dataa};
          })
        );

        newState.totalCount = Number(r.data.length);
      }
      this.setState(newState);
      console.log(r.data);
    });
  };

  setPage = num => {
    this.setState({page: num}, this.getTransactions);
  };

  setPrev = () => {
    this.setPage(this.state.page - 1);
  };

  setNext = () => {
    this.setPage(this.state.page + 1);
  };

  render() {
    const {transactions, limit, page, totalCount, isFetching} = this.state;
    return (
      <div className='transactions-page'>
        <div className='page-content'>
          <div className='header-page'>
            <Breadcrumbs currentTitle='trx_page.title' pages={pages} />
          </div>
          {isFetching || totalCount > 0 ? (
            <div>
              <TrxHistoryTable
                // isError={isError}
                onRetry={this.getHistory}
                isFetching={isFetching}
                rows={transactions}
                tableWrapperClass='relative min-height'
              />

              <Pagination
                handlePrev={this.setPrev}
                handleNext={this.setNext}
                hasNext={totalCount == 20}
                hasPrev={page != 0}
                currentPage={page + 1}
                withoutNumbers
                limit={limit}
                totalCount={totalCount}
              />
            </div>
          ) : (
            <h1>
              <span className='main-title md title-top-block not-found'>
                <I18n tKey='common.no_data' />
              </span>
            </h1>
          )}
        </div>
      </div>
    );
  }
}
