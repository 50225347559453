import React, {Component} from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import {ReactComponent as FirstType} from '../../../img/storageTypeV1.svg';
import {MsgPrinter} from '../../helpers/msgPrinter/MsgPrinter';
import {Preloader} from '../../helpers/preloader/Preloader';
import {generateName, getPrivateKeyFromSeed, privateToPublic} from '../../../utils/utils';
import {I18n} from '../../helpers/i18n/I18n';
import {searchForAccount} from '../../../utils/requester';
import {translate} from '../../../utils/translater';
import {Button} from '../../helpers/button/Button';
import {CopyField} from '../../helpers/copyField/CopyField';

const letters = /^[1-5a-z]+$/;

export class ChooseStorageType extends Component {
  state = {
    accountName: generateName(12),
    msg: false,
    privateKey: getPrivateKeyFromSeed(this.props.requestData.seedPhrase.join(' ')),
    publicKey: privateToPublic(getPrivateKeyFromSeed(this.props.requestData.seedPhrase.join(' '))),
  };

  runSearch = async accountName => {
    return await searchForAccount(accountName);
  };

  validateAccount = async (e, value) => {
    value = e.target.value;

    this.setState({accountError: true});

    this.setState({dupName: false});

    if (e.target.value.match(letters) || value === '') {
      // save search term so far.
      this.setState({accountName: value, isFetching: false, accontNameBC: ''});
      if (value.length === 12) {
        // trigger search run
        this.setState({isFetching: true});
        const {data} = await this.runSearch(value);
        this.setState({accountError: !!data.account_name, dupName: !!data.account_name, isFetching: false});
      }
    }
  };

  render() {
    const {isFetching, accountCreated} = this.props;
    const {quoteUNTB} = this.props.requestData;
    const {accountName, accountError, dupName, privateKey} = this.state;
    return (
      <div>
        {!accountCreated ? (
          <div className='storage-type-block'>
            {isFetching && <Preloader className='white-bg' />}

            <h1 className='block-font lg-size'>
              <I18n tKey='user-create.ur-wallet-promise' />
              <div className='d-flex align-items-center' />
            </h1>

            <I18n className='block-font inline-block' tKey='user-create.choose-name-tip' />
            <CopyField
              cdTxt='Done'
              className='blue-theme light'
              label='user-create.ur-private-key'
              txt={privateKey}
              onClick={() => {
                navigator.clipboard.writeText(privateKey);
              }}
            />
            <div className='storage-types-wrapper'>
              <div className='storage-type-container active'>
                <FirstType />
                <h2 className='block-font md-weight'>
                  <I18n tKey='user-create.account-name' />
                </h2>

                <TextField
                  id='filled-basic'
                  label={<I18n tKey='user-create.account-name-label' />}
                  variant='filled'
                  inputProps={{maxLength: 12}}
                  name='accountName'
                  value={accountName}
                  onChange={e => this.validateAccount(e)}
                />
              </div>
            </div>

            {dupName && (
              <span className='block-font warning-color inline-block'>
                {' '}
                <I18n tKey='user-create.account-name-exists' />
              </span>
            )}
            <Button
              btnClass='next-step-btn with-bg blue'
              title='user-create.continue'
              disabled={accountError && !isFetching}
              onClick={() => {
                this.props.setAccount(this.state.accountName, this.state.publicKey);
              }}
            />
          </div>
        ) : (
          <div>
            <h1 className='block-font lg-size success-color'>
              <I18n tKey='common.account_success' />
            </h1>
            <span className='block-font inline-block'>
              {quoteUNTB} <I18n tKey='user-create.account-send-funds' />
            </span>

            <div className='d-flex align-items-center'>
              <CopyField
                cdTxt='Done'
                className='blue-theme light short'
                label='wallet_send.ur-transfer'
                txt='creatorcruac'
                onClick={() => {
                  navigator.clipboard.writeText('creatorcruac');
                }}
              />
            </div>
            <div className='d-flex align-items-center'>
              <CopyField
                cdTxt='Done'
                className='blue-theme light short'
                label='user-create.ur-memo'
                txt={accountName}
                onClick={() => {
                  navigator.clipboard.writeText(accountName);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
