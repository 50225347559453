import React, {Component} from 'react';
import {inject} from 'mobx-react';
import BigNumber from 'bignumber.js';

import {I18n} from '../../helpers/i18n/I18n';
import {CountTxtRow} from '../../helpers/countTxtRow/CountTxtRow';
import {Speedometer} from '../../helpers/speedometer/Speedometer';
import {TOKENS} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {changeFormat, changeCurrencyToNumber, cutTokenPrefix} from '../../../utils/utils';

@inject('tokenStore')
export class SupplyAndStaking extends Component {
  state = {
    lastMonthPayout: '',
    payoutPerCru: '0',
  };

  componentDidMount = async () => {
    const {activeToken} = this.props;

    if (activeToken === TOKENS.USDU) {
      const distrib = await this.getDividendsDistributions();
      const activeDistrib = distrib.find(({active}) => !!active);
      const prevDistrib = (activeDistrib && distrib.find(({id}) => id === activeDistrib.id - 1)) || distrib[distrib.length - 1];

      prevDistrib &&
        this.setState({
          lastMonthPayout: changeFormat(changeCurrencyToNumber(prevDistrib.usdu)),
          payoutPerCru: changeFormat(
            (changeCurrencyToNumber(prevDistrib.usdu) / changeCurrencyToNumber(prevDistrib.wcru_total)) * 10000
          ),
        });
    }
  };

  returnHeader = () => {
    const {activeToken} = this.props;

    switch (activeToken) {
      case TOKENS.CRU:
        return 'home.supply_block.cru_title';
      case TOKENS.USDU:
        return 'home.supply_block.usdu_title';
      case TOKENS.UNTB:
        return 'home.supply_block.untb_title';
      case TOKENS.WCRU:
        return 'home.supply_block.wcru_title';
      default:
        return '';
    }
  };

  getStakedPercent = token => {
    return new BigNumber(this.getTotalStake(token)).multipliedBy(100).div(new BigNumber(this.getTotalCirculating())).toNumber();
  };

  getTotalStake = token => {
    const {
      activeToken,
      tokenStore: {accountInfo},
    } = this.props;

    if (token === TOKENS.CRU || token === TOKENS.WCRU) {
      return cutTokenPrefix(accountInfo[activeToken].totalStake);
    }
    if (token === TOKENS.UNTB) {
      return cutTokenPrefix(accountInfo.UNTB.stake);
    }

    return '';
  };

  getTotalCirculating = () => {
    const {activeToken, tokens, reservedData} = this.props;

    return activeToken === TOKENS.CRU || activeToken === TOKENS.WCRU
      ? tokens[activeToken].supply - reservedData[activeToken]
      : tokens[activeToken].supply;
  };

  getDividendsDistributions = async () => {
    const {isError, data} = await getEosTable({
      code: 'dividend',
      json: true,
      limit: 1000,
      scope: 'dividend',
      table: 'distrib',
    });

    return isError ? [] : data.rows;
  };

  render() {
    const {lastMonthPayout, payoutPerCru} = this.state;
    const {activeToken, tokens} = this.props;
    return (
      <div className='top-content'>
        <I18n className='main-title md' tKey={this.returnHeader()} />

        <div className='direction-container'>
          <div className='data-container'>
            {activeToken === TOKENS.USDU ? (
              <>
                <CountTxtRow count={changeFormat(tokens[activeToken].max_supply, 0)} title='card_tokens.col_max' />
                <CountTxtRow
                  wrapperClass='circulating-row'
                  count={changeFormat(tokens[activeToken].supply, 0)}
                  title={`home.supply_block.col_circul.${activeToken}`}
                  dotTitle
                />
                {lastMonthPayout && (
                  <CountTxtRow wrapperClass='green' count={lastMonthPayout} title='home.supply_block.col_month' />
                )}
                <CountTxtRow title='home.supply_block.col_est_month'>
                  <span>
                    <span className='green'>+{payoutPerCru}</span>
                    <span>/ 10000 WCRU</span>
                  </span>
                </CountTxtRow>
              </>
            ) : (
              <>
                <CountTxtRow
                  count={
                    changeFormat(activeToken === TOKENS.CRU ? tokens[activeToken].supply : tokens[activeToken].max_supply, 0) || 0
                  }
                  title={activeToken === TOKENS.CRU ? 'card_tokens.col_supply' : 'card_tokens.col_max'}
                />
                <CountTxtRow
                  wrapperClass='circulating-row'
                  count={changeFormat(this.getTotalCirculating(), 0) || 0}
                  title={`home.supply_block.col_circul.${activeToken}`}
                  dotTitle
                />
                <CountTxtRow
                  wrapperClass='staked-row'
                  count={changeFormat(this.getTotalStake(activeToken), 0) || 0}
                  title={`home.supply_block.col_staked.${activeToken}`}
                  dotTitle
                />
                <div className='row-container'>
                  <Speedometer value={(this.getStakedPercent(activeToken) || 0).toFixed(2)} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
