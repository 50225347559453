import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import moment from 'moment';
import QRCode from 'qrcode.react';

import {ResourcesStat} from './ResourcesStat';
import {LinkListWallet} from './LinkListWallet';
import {TIME_FORMAT} from '../../../config/const';

import {I18n} from '../../helpers/i18n/I18n';
import {MenuBtn} from '../../helpers/menuBtn/MenuBtn';
import {MobileMenuWallet} from '../../modals/mobileMenuWallet/MobileMenuWallet';
import {CopyField} from '../../helpers/copyField/CopyField';

@inject('userStore')
@observer
export class WalletHeader extends Component {
  state = {
    statusShow: false,
  };

  toggleVisibility = () => {
    this.setState(prevState => ({
      statusShow: !prevState.statusShow,
    }));
  };

  logout = () => {
    const {userStore} = this.props;
    userStore.removeUser();

    // todo if used
  };

  render() {
    const {statusShow} = this.state;
    const {userName, userStore} = this.props;
    const {accountDetails = {}, isProducerAccount} = userStore;
    return (
      <div className='wallet-header'>
        <div className='mobile-header'>
          <div className='user-dropdown'>
            <span className='user-name'>{userName}</span>
            <i role='presentation' className='arrow-icon' onClick={this.toggleVisibility} />
          </div>
          <div className='menu-block'>
            <I18n tKey='wallet_page.wallet_menu' />
            <MenuBtn modalCmp={MobileMenuWallet} />
          </div>
        </div>
        <div className={classNames('header-content', {'hide-mobile-status': !statusShow})}>
          <div className='user-info-block'>
            <div className='qr-code'>
              <QRCode value={userStore.user.name} renderAs='svg' />
            </div>
            <div className='name-date'>
              <div className='name-row'>
                <CopyField cdTxt='common.done' className='dark-blue-theme' txt={userName} />
              </div>
              <p className='date-row'>
                <I18n tKey='wallet_page.dashboard.date_txt' />
                <span> {moment(accountDetails.created).format(TIME_FORMAT.CREATED)}</span>
              </p>
              <p role='presentation' className='sign-out-btn' onClick={this.logout}>
                <I18n tKey='common.sign_out' />
              </p>
            </div>
          </div>
          <div className='resources-block'>
            <ResourcesStat
              currentValue={accountDetails.ram_usage}
              maxValue={accountDetails.ram_quota}
              countUntb='0.25'
              resourceType='RAM'
              convertation='KB'
              linkTxt='wallet_ram.title'
              linkUrl='/wallet/ram'
              circleClass='ram-class'
            />
            <ResourcesStat
              currentValue={accountDetails.cpu_limit.used}
              maxValue={accountDetails.cpu_limit.available}
              countUntb='0.25'
              resourceType='CPU'
              convertation='µS'
              linkTxt='wallet_page.dashboard.btn_stake'
              linkUrl='/wallet/staking/untb'
              circleClass='cpu-class'
            />
            <ResourcesStat
              currentValue={accountDetails.net_limit.used}
              maxValue={accountDetails.net_limit.available}
              countUntb='0.25'
              resourceType='NET'
              convertation='KB'
              linkTxt='wallet_page.dashboard.btn_stake'
              linkUrl='/wallet/staking/untb'
              circleClass='net-class'
            />
          </div>
        </div>
        <div className='nav-bar-wrapper'>
          <LinkListWallet isProducer={isProducerAccount} userName={userName} onClick={this.logout} />
          <div className='menu-block'>
            <MenuBtn modalCmp={MobileMenuWallet} />
          </div>
        </div>
      </div>
    );
  }
}
