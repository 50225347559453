import React, {Component} from 'react';
import {inject} from 'mobx-react';
import moment from 'moment';
import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import {I18n} from '../../helpers/i18n/I18n';
import {ReactComponent as ChipIcon} from '../../../img/chip.svg';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {getEosTable} from '../../../utils/requester';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {cutTokenPrefix} from '../../../utils/utils';
import {unfreezeDateConst} from '../../../config/const';

@inject('userStore', 'eosStore', 'modalStore', 'loggerStore')
export class UpdateFrozenBtn extends Component {
  state = {
    progress: '',
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.updateInfo});
  };

  updateInfo = async pin => {
    const {changeParentState, userStore, eosStore, loggerStore} = this.props;
    const userName = userStore.user.name;

    changeParentState({fetching: true});

    const {data, isError} = await getEosTable({
      json: true,
      code: 'tokenlock',
      scope: userName,
      limit: 10000,
      table: 'locks',
    });

    if (!isError) {
      const apiRequester = await eosStore.returnApi(pin);
      const frozenObjects = [...(data.rows || [])];
      const objCount = frozenObjects.length;

      // console.log('frozenObjects', frozenObjects);

      await eosStore.claimUnstackingTokens(userName, apiRequester);

      if (objCount > 0) {
        this.setState({progress: `0 / ${objCount}`});
      }

      changeParentState({fetching: false});

      let progress = 0;
      for (let i = 0; i < objCount; i += 1) {
        const {algorithm, created, amount, withdrawed, last_distribution_at, id} = frozenObjects[i];
        const freeze_days = unfreezeDateConst[algorithm];

        const createdMoment = moment(`${created}Z`);
        let lastDistr = moment(`${last_distribution_at}Z`);

        if (lastDistr.isBefore(createdMoment)) {
          lastDistr = createdMoment.add(freeze_days, 'd');
        }

        if (lastDistr.add(30, 'd').isBefore(moment()) && amount !== withdrawed) {
          // eslint-disable-next-line no-await-in-loop
          // const {isError: objUpdateError} = await eosStore.updateFrozenObj(userName, id, apiRequester);

          if (!objUpdateError) {
            progress += 1;
            this.setState({progress: `${progress} / ${objCount}`});
          }
        } else {
          progress += 1;
          this.setState({progress: `${progress} / ${objCount}`});
        }
      }

      const {data: updatedData, isErrorGetLocks} = await getEosTable({
        json: true,
        code: 'tokenlock',
        scope: userName,
        limit: 10000,
        table: 'locks',
      });

      if (!isErrorGetLocks) {
        for (let i = 0; i < updatedData.rows.length; i += 1) {
          const {available, id} = updatedData.rows[i];

          if (+cutTokenPrefix(available)) {
            // eslint-disable-next-line no-await-in-loop
            await this.props.eosStore.withdrawFrozenObj(userName, id, apiRequester);
          }
        }
      }

      userStore.getAccountInfo();
      // this.setState({progress: ''});
    } else {
      loggerStore.addItem({
        type: LOGGER_TYPES[1],
        msgTxt: 'wallet_page.dashboard.refresh.get_freeze_obj_err',
      });
      changeParentState({fetching: false});
    }
  };

  render() {
    const {progress} = this.state;

    return (
      <div role='presentation' onClick={this.openPinModal} className='refresh-block'>
        <RefreshIcon />
        {progress ? (
          <span className='refresh-title'>{progress}</span>
        ) : (
          <I18n tKey='wallet_page.dashboard.refresh_frozen' className='refresh-title' />
        )}
        <ChipIcon />
      </div>
    );
  }
}
