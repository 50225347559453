import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {I18n} from '../i18n/I18n';
import {CountTxtRow} from '../countTxtRow/CountTxtRow';

export class CardToken extends Component {
  render() {
    const {active, cartTitle, fullTitle, count, subtitle, percent, cardWrapper, cardClicked, subtitleStaked, price} = this.props;

    return (
      <Link className={classNames('card-wrapper', {active: active}, cardWrapper)} to='/'>
        <div onClick={cardClicked} className='card-token'>
          <div className='headers-title'>
            <i className='card-icon' />
            <div className='card-title'>
              <I18n className='full-title' tKey={fullTitle} />
              <div className='flex-row d-flex w-100 space-between'>
                <I18n className='main-title lg' tKey={cartTitle} />
                {(cartTitle === 'cru' || cartTitle === 'card_tokens.title_cru') && (
                  <CountTxtRow count={price} title={'CRU/USD'} />
                )}
              </div>
            </div>
          </div>
          {(count || percent) && (
            <div className='block-count'>
              <CountTxtRow count={count} title={subtitle} />
              <CountTxtRow count={percent} title={subtitleStaked} dot />
            </div>
          )}
        </div>
      </Link>
    );
  }
}
