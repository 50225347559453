import {inject} from 'mobx-react';
import React, {Component} from 'react';
import {I18n} from '../i18n/I18n';
import {ReactComponent as GrayArrow} from '../../../img/gray-arrow.svg';
import {dropdownOptions} from '../../mainPages/cruForCruStaking/CruForCruStaking';

@inject('userStore')
export class MemoColumn extends Component {
  render() {
    const {obj} = this.props;

    if (obj.action_name === 'transfer' && obj.token_to === 'staker') {
      const data = dropdownOptions.find(({value}) => +value === +obj.action_data?.memo);

      if (data) {
        return (
          <div className='memo-column'>
            <p className='memo-title'>
              <I18n tKey='wallet_send.memo_label' />
            </p>
            <p className='table-row'>
              <I18n className='plan-name' tKey={data.title} />{' '}
              <span className='percents'>
                ({data.percent} %){' '}
                <span className='percents'>
                  {data.month} <I18n tKey='common.month' />
                </span>
              </span>
            </p>
          </div>
        );
      }
    }

    return obj.action_name === 'pay' ? (
      <div className='memo-column'>
        <p className='memo-title'>
          <I18n tKey='wallet_send.memo_label' />
        </p>
        <p className='table-row'>
          <span>{obj.actor}</span>
          <GrayArrow />
          <span>{obj.action_data?.pay_to}</span>
        </p>
      </div>
    ) : (
      <div className='memo-column'>
        <p className='memo-title'>
          <I18n tKey='wallet_send.memo_label' />
        </p>
        <p className='table-row'>{obj.action_data?.memo || obj.action_data?.id || obj.action_data?.algorithm}</p>
      </div>
    );
  }
}
