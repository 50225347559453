import {observable, action} from 'mobx';
import {TOKENS} from '../config/const';
import {
  getAccountBalance,
  getAccountStats,
  getEosTable,
  getTopHoldersFlask,
  getStat,
  getTotalAmount,
  getTableByScope,
} from '../utils/requester';
import {cutTokenPrefix, returnNextOffset, getTokenPrefix} from '../utils/utils';
import {UserStore} from './UserStore';

class TokenStore {
  @observable activeToken = TOKENS.CRU;

  @observable tokenInfoIsFetching = false;

  @observable reservedData = {};

  @observable emission = {};

  @observable tokens = {
    [TOKENS.CRU]: [],
    [TOKENS.USDU]: [],
    [TOKENS.UNTB]: [],
    [TOKENS.WCRU]: [],
  };

  @observable tokenInfo = this.setDefaultData();

  @observable totalAccountsWithBalance = {};

  @observable accountInfo = {
    CRU: {
      totalStake: 0,
      supply: 0,
      interchange: 0,
      reserve: 0,
    },
    WCRU: {
      totalStake: 0,
      supply: 0,
      interchange: 0,
      reserve: 0,
    },
    UNTB: {
      supply: 0,
      stake: 0,
    },
  };

  constructor(cb) {
    this.getTokens().then(() => {
      const requests = [
        this.getEmission(),
        this.getTokenInfo(TOKENS.CRU),
        this.getTokenInfo(TOKENS.USDU),
        this.getTokenInfo(TOKENS.UNTB),
        this.getSupply(),
        this.getTopHolder(this.activeToken),
        this.getReservedToken(),
        this.getStat(this.activeToken),
        this.getTotalAmount(),
        this.getTokenInfo(TOKENS.WCRU),
        this.getGlobalTable(),
        this.getAccountStats(TOKENS.CRU),
        this.getAccountStats(TOKENS.WCRU),
        this.getAccountStats(TOKENS.UNTB),
        this.getAccountBalance('interchange', TOKENS.CRU),
        this.getAccountBalance('reserve', TOKENS.CRU),
        this.getAccountBalance('interchange', TOKENS.WCRU),
        this.getAccountBalance('reserve', TOKENS.WCRU),
        this.getAccountBalance('eosio.stake', TOKENS.UNTB),
      ];
      Promise.all(requests).then(r => {
        const isError = r.some(elem => elem.isError);
        if (!isError) {
          // to do
        }
        cb(!isError);
        // cb(true);
      });
    });
  }

  setDefaultData() {
    return {
      tableData: [],
      accountsAmount: [],
      totalAccounts: 0,
      zeroBalances: 0,
      totalAmount: 0,
      holders: null,
      page: 0,
      limit: 10,
    };
  }

  @action getTotalAmount = () => {
    return getTotalAmount(this.activeToken).then(r => {
      if (!r.isError) {
        this.tokenInfo.totalAmount = Number(r.data.totalAmount);
      }
      return r;
    });
  };

  @action getStat = symbol => {
    return getStat(symbol).then(async r => {
      if (!r.isError) {
        await this.getTotalAmount();
        this.tokenInfo.zeroBalances = Number(r.data.zeroAccounts);
        this.tokenInfo.totalAccounts = Number(r.data.totalAccounts);
        this.totalAccountsWithBalance = {...this.totalAccountsWithBalance, [symbol]: r.data.totalAccounts - r.data.zeroAccounts};
        const {distribution, topDistribution} = r.data;

        const holders = {};
        let sum = 0;
        topDistribution.forEach(elem => {
          holders[`${elem.from}-${elem.to}`] = elem.sum;
          sum += elem.sum;
        });
        holders['500+'] = this.tokenInfo.totalAmount - sum;

        this.tokenInfo.holders = holders;

        let accountsAmount = [];
        if (!r.isError && (distribution || []).length) {
          const commonCount = distribution.map(data => data.count).sort((a, b) => Number(b) - Number(a));

          const sorted = distribution.sort((a, b) => Number(b.from) - Number(a.from));
          accountsAmount = sorted.map(elem => ({
            score: `${elem.from}${elem.to === 'INF' ? '+' : ` - ${elem.to}`}`,
            amount: elem.count,
            size: `${(100 / (commonCount[0] / elem.count)).toFixed(2)}%`,
          }));
        }

        this.tokenInfo.accountsAmount = accountsAmount;
        return r;
      }
      return r;
    });
  };

  @action getReservedToken = () => {
    const data = {
      json: true,
      code: 'eosio.token',
      scope: 'reserve',
      table: 'accounts',
    };
    return getEosTable(data).then(r => {
      if (!r.isError) {
        const regex = new RegExp('CRU|USDU|UNTB|WCRU');
        const findValue = str => {
          const res = (r.data.rows || []).filter(elem => elem.balance.includes(str));
          return res.length > 0 ? res : [{balance: '0'}];
        };
        this.reservedData = {
          [TOKENS.CRU]: Number(findValue(TOKENS.CRU)[0].balance.replace(regex, '')),
          [TOKENS.UNTB]: Number(findValue(TOKENS.UNTB)[0].balance.replace(regex, '')),
          [TOKENS.USDU]: Number(findValue(TOKENS.USDU)[0].balance.replace(regex, '')),
          [TOKENS.WCRU]: Number(findValue(TOKENS.WCRU)[0].balance.replace(regex, '')),
        };
      }
      return r;
    });
  };

  getEmission() {
    return getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'global4'}).then(r => {
      if (!r.isError) {
        this.emission = r.data.rows[0];
      }
      return r;
    });
  }

  getGlobalTable = () => {
    return getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'global4'}).then(r => {
      if (!r.isError) {
        this.accountInfo.CRU.totalStake = r.data.rows[0].total_stakers_cru_balance;
        this.accountInfo.WCRU.totalStake = r.data.rows[0].total_stakers_wcru_balance;
      }
      return r;
    });
  };

  getAccountStats = token => {
    return getAccountStats(token).then(r => {
      if (!r.isError) {
        this.accountInfo[token].supply = r.data[token].supply;

        if (token === TOKENS.WCRU) {
          const account = {
            account_name: 'tokenlock',
          };
          const req = UserStore.returnRequests(account.account_name);
          Promise.all(req)
            .then(UserStore.processAccRequests)
            .then(resp => {
              const {wcruAvailable} = resp.calcData;
              // const newState = {isFetching: false};
              // const isError = resp.r.some(elem => elem.isError);

              const tmp = cutTokenPrefix(this.accountInfo[token].supply) - cutTokenPrefix(wcruAvailable);
              this.reservedData.WCRU = tmp;
            });
        }
      }
      return r;
    });
  };

  getAccountBalance = (account, symbol) => {
    return getAccountBalance({code: 'eosio.token', account, symbol}).then(r => {
      if (!r.isError) {
        let acc = account;
        if (account === 'eosio.stake') {
          acc = 'stake';
        }
        this.accountInfo[symbol][acc] = r.data[0] || 0;
      }
      return r;
    });
  };

  @action getTokenInfo(token) {
    const data = JSON.stringify({
      json: true,
      code: 'eosio.token',
      scope: token,
      table: 'stat',
    });
    return getEosTable(data).then(r => {
      if (!r.isError && (r.data.rows || []).length) {
        // this.tokens[token] = {...r.data.rows[0], supply:0};
        const regex = new RegExp('CRU|USDU|UNTB|WCRU');
        this.tokens[token] = {
          max_supply: Number(r.data.rows[0].max_supply.replace(regex, '')),
          supply: Number(r.data.rows[0].supply.replace(regex, '')),
        };
      }
      return r;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  @action async getTokens() {
    const {data: scopes} = await getTableByScope({
      code: 'eosio.token',
      json: true,
      limit: 100000,
      table: 'stat',
    });
    const tokens = {};
    const rawTokensPromices = scopes.rows.map(async ({scope}) => {
      const {data: token} = await getEosTable({
        code: 'eosio.token',
        scope,
        json: true,
        limit: 1,
        table: 'stat',
      });
      const tokenName = getTokenPrefix(token.rows[0].supply);
      const tokenData = {
        max_supply: Number(cutTokenPrefix(token.rows[0].max_supply)),
        supply: Number(cutTokenPrefix(token.rows[0].supply)),
      };
      tokens[tokenName] = tokenData;
      this.tokens[tokenName] = tokenData;

      return tokenData;
    });

    const tokensArr = await Promise.all(rawTokensPromices);

    return tokensArr;
  }

  getSupply() {
    return new Promise(resolve => {
      resolve({isError: false});
    });
  }

  @action getTopHolder = async token => {
    const {limit, page} = this.tokenInfo;
    const r = await getTopHoldersFlask(limit, page, token);

    let tableData = [];
    if (!r.isError && (r.data.elements || []).length) {
      tableData = r.data.elements.map((elem, i) => ({
        ...elem,
        name: elem.account,
        rank: (page + 1) * limit - (limit - i - 1),
        holding: `0%`,
      }));
    }

    this.tokenInfo.tableData = tableData;

    return r;
  };

  @action setPage = num => {
    this.tokenInfo.page = num;
    return this.getTopHolder(this.activeToken);
  };

  @action setActiveToken = token => {
    this.activeToken = token;
    this.tokenInfo = this.setDefaultData();
    const requests = [this.getTotalAmount(), this.getSupply(token), this.getTopHolder(token), this.getStat(token)];
    if (token === TOKENS.UNTB) {
      requests.push(this.getEmission(token));
    }
    this.tokenInfoIsFetching = true;
    Promise.all(requests).then(() => {
      this.tokenInfoIsFetching = false;
    });
  };
}

export default TokenStore;
