import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {InputRow} from '../../helpers/inputRow/InputRow';
import {Table} from '../../helpers/table/Table';
import {EnterPin} from '../../modals/enterPin/EnterPin';
import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';
import {DropDown} from '../../helpers/dropDown/DropDown';

import {getEosTable, getTableByScope} from '../../../utils/requester';
import {changeFormat, cutTokenPrefix, detectRounding, getTokenPrefix} from '../../../utils/utils';
import {getRoundedRegExp} from '../../../config/const';
import {ReactComponent as InfoIcon} from '../../../img/gray-info.svg';
import './index.scss';

const maxDigitsLengthOfMaxSupply = 18;

const formatOptionLabel = data => {
  return (
    <div className='select-row'>
      <I18n className='plan-name' tKey={data.title} />{' '}
    </div>
  );
};

const generateRoundingsOptions = maxRounding => {
  const options = [];
  const startStr = '.';
  for (let i = 1; i < maxRounding; i += 1) {
    const value = {
      title: startStr.padEnd(i + 1, '0'),
      value: i,
      maxSupplyDigitsLimit: maxDigitsLengthOfMaxSupply - i,
    };

    options.push(value);
  }

  return options;
};

const inputFormatSettings = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: '',
  fractionGroupSize: 0,
};

@inject('userStore', 'modalStore', 'eosStore', 'loggerStore')
@observer
export class WalletLimits extends Component {
  state = {
    selectedToken: null,
    tokens: [],
    limitQuantity: '',
    userName: '',
  };

  tableColumns = {
    columns: [
      {
        name: 'common.table.col_name',
        key: 'username',
        render: obj => {
          const {username} = obj;

          return (
            <div>
              {username && <span className='token-full-name'>{username}</span>}
              <button
                type='button'
                className='edit-full-name_btn'
                onClick={() => {
                  this.openRemoveWhitelistPinModal(username);
                }}
              >
                <I18n tKey='token_limit.remove_from_whitelist' />
              </button>
            </div>
          );
        },
      },
    ],
  };

  tableColumnsWhitelistTo = {
    columns: [
      {
        name: 'common.table.col_name',
        key: 'username',
        render: obj => {
          const {username} = obj;

          return (
            <div>
              {username && <span className='token-full-name'>{username}</span>}
              <button
                type='button'
                className='edit-full-name_btn'
                onClick={() => {
                  this.openRemoveWhitelistToPinModal(username);
                }}
              >
                <I18n tKey='token_limit.remove_from_whitelist' />
              </button>
            </div>
          );
        },
      },
    ],
  };

  onChangeSelectedToken = value => {
    const limitQuantity = value.limit || '';

    this.setState({selectedToken: value, limitQuantity});

    this.getWhitelist(value?.title);
    this.getWhitelistTo(value?.title);
  };

  componentDidMount = async () => {
    this.getCreationHistory();
  };

  getAllTokensForLimiter = async () => {
    const {
      userStore: {user},
    } = this.props;

    if (user.name === 'limiter') {
      const {data: scopes} = await getTableByScope({
        code: 'eosio.token',
        json: true,
        limit: 100000,
        table: 'stat',
      });

      const rawTokensPromices = scopes.rows.map(async ({scope}) => {
        const {data: token} = await getEosTable({
          code: 'eosio.token',
          scope,
          json: true,
          limit: 1,
          table: 'stat',
        });

        return {
          currency: token.rows[0].max_supply,
          issuer: token.rows[0].isuer,
        };
      });

      const tokens = await Promise.all(rawTokensPromices);
      const userTokens = tokens.filter(value => value);

      return userTokens;
    }

    return [];
  };

  getCreationHistory = async () => {
    const {
      userStore: {user},
    } = this.props;
    const {selectedToken} = this.state;

    const {data} = await getEosTable({
      code: 'curcreator',
      json: true,
      limit: 1000,
      scope: 'curcreator',
      table: 'feelog',
    });

    const {data: limits} = await getEosTable({
      code: 'limiter',
      json: true,
      limit: 100,
      scope: 'limiter',
      table: 'tokenlimit',
    });

    const allTokens = await this.getAllTokensForLimiter();

    const historyData = user.name === 'limiter' ? allTokens : data?.rows?.filter(({issuer}) => user.name === issuer) || [];

    const tokens = historyData.map(({currency}) => {
      const title = getTokenPrefix(currency);
      const limitFull = limits.rows.find(({month_limit}) => {
        return title === getTokenPrefix(month_limit);
      })?.month_limit;
      const limit = cutTokenPrefix(limitFull) || '';

      return {
        title,
        rounding: detectRounding(currency),
        maxSupply: +cutTokenPrefix(currency),
        limit,
      };
    });

    if (selectedToken) {
      const newToken = tokens.find(({title}) => {
        return title === selectedToken.title;
      });

      this.onChangeSelectedToken(newToken || null);
    }

    this.setState({tokens});
  };

  openPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.setLimit});
  };

  openAddWhitelistPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.addToWhitelist});
  };

  openAddWhitelistToPinModal = () => {
    this.props.modalStore.open(EnterPin, null, null, {onValidPin: this.addToWhitelistTo});
  };

  openRemoveWhitelistPinModal = userName => {
    this.props.modalStore.open(EnterPin, null, null, {
      onValidPin: pin => {
        this.removeFromWhitelist(pin, userName);
      },
    });
  };

  openRemoveWhitelistToPinModal = userName => {
    this.props.modalStore.open(EnterPin, null, null, {
      onValidPin: pin => {
        this.removeFromWhitelistTo(pin, userName);
      },
    });
  };

  setLimit = async pin => {
    const {userStore, eosStore} = this.props;
    const {selectedToken, limitQuantity} = this.state;
    const userName = userStore.user.name;

    const {isError} = await eosStore.setTokenMonthLimit(pin, userName, `${limitQuantity} ${selectedToken.title}`);

    if (!isError) {
      this.getCreationHistory();
    }
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value});
  };

  onLimitInputBlur = () => {
    const {limitQuantity, selectedToken} = this.state;

    const formattedQuantity = limitQuantity ? changeFormat(limitQuantity, selectedToken?.rounding || 4, inputFormatSettings) : '';

    this.setState({limitQuantity: formattedQuantity});
  };

  addToWhitelist = async pin => {
    const {userStore, eosStore} = this.props;
    const {userName, selectedToken} = this.state;

    const {isError} = await eosStore.addToTokenWhitelist(pin, userStore.user.name, userName, selectedToken.title);

    if (!isError) {
      this.setState({userName: ''});
      this.getWhitelist(selectedToken?.title);
    }
  };

  removeFromWhitelist = async (pin, userName) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;

    const {isError} = await eosStore.removeFromTokenWhitelist(pin, userStore.user.name, userName, selectedToken.title);

    if (!isError) {
      this.getWhitelist(selectedToken?.title);
    }
  };

  addToWhitelistTo = async pin => {
    const {userStore, eosStore} = this.props;
    const {userNameTo, selectedToken} = this.state;

    const {isError} = await eosStore.addToTokenWhitelistTo(pin, userStore.user.name, userNameTo, selectedToken.title);

    if (!isError) {
      this.setState({userNameTo: ''});
      this.getWhitelistTo(selectedToken?.title);
    }
  };

  removeFromWhitelistTo = async (pin, userName) => {
    const {userStore, eosStore} = this.props;
    const {selectedToken} = this.state;

    const {isError} = await eosStore.removeFromTokenWhitelistTo(pin, userStore.user.name, userName, selectedToken.title);

    if (!isError) {
      this.getWhitelistTo(selectedToken?.title);
    }
  };

  getWhitelist = async token => {
    const {data: whitelist, isError} = await getEosTable({
      code: 'limiter',
      json: true,
      limit: 1000,
      scope: token,
      table: 'whitelist',
    });
    if (isError) {
      return;
    }

    this.setState({whitelist: whitelist.rows});
  };

  getWhitelistTo = async token => {
    const {data: whitelist, isError} = await getEosTable({
      code: 'limiter',
      json: true,
      limit: 1000,
      scope: token,
      table: 'whitelistto',
    });
    if (isError) {
      return;
    }

    this.setState({whitelistto: whitelist.rows});
  };

  render() {
    const {tokens, selectedToken, limitQuantity, userName, userNameTo, whitelist, whitelistto} = this.state;

    return (
      <div className='create-token-page nested-route margin'>
        <div>
          <I18n tKey='token_limit.title' className='title-page' />
          <div className='wallet-content' style={{width: '100%'}}>
            <I18n tKey='token_limit.set_limit_title' className='block-title' />
            <div className='notice-text-wrapper d-flex'>
              <div className='wrapper-flex-item wide centered-content'>
                <InfoIcon />
              </div>
              <div className='d-flex flex-column'>
                <I18n className='sub-text' tKey='token_limit.set_limit_notice' />
              </div>
            </div>

            <div className='input-with-btn'>
              <DropDown
                wrapperClass='select-rounding'
                onChange={this.onChangeSelectedToken}
                value={selectedToken}
                isOptionSelected={option => {
                  return option === selectedToken;
                }}
                options={tokens}
                formatOptionLabel={formatOptionLabel}
                placeholder='create_token.dropDown_token'
                menuPlacement='auto'
              />
              <InputRow
                wrapperClass='input-with-bg'
                name='limitQuantity'
                value={limitQuantity}
                onlyNumbers
                maxAmount={selectedToken?.maxSupply || 0}
                regEx={getRoundedRegExp(selectedToken?.rounding)}
                statusBorder
                inputChange={this.onChange}
                onBlur={this.onLimitInputBlur}
                placeholder={changeFormat(0, selectedToken?.rounding || 4, inputFormatSettings)}
                label={selectedToken?.title}
                readOnly={!selectedToken}
              />
              <Button
                disabled={!selectedToken}
                onClick={this.openPinModal}
                btnClass='with-bg green-bg'
                title='token_limit.set_limit_btn'
              />
            </div>
            {selectedToken && (
              <>
                <div className='history-table-wrapper'>
                  <I18n tKey='token_limit.add_to_whitelist' className='block-title' />
                  <div className='input-with-btn'>
                    <InputRow
                      wrapperClass='input-with-bg'
                      name='userName'
                      value={userName}
                      statusBorder
                      inputChange={this.onChange}
                      placeholder='token_limit.userName_placeholder'
                      readOnly={!selectedToken}
                    />
                    <Button
                      disabled={!selectedToken || !userName}
                      onClick={this.openAddWhitelistPinModal}
                      btnClass='with-bg green-bg'
                      title='token_limit.add_to_whitelist_btn'
                    />
                  </div>

                  <I18n tKey='token_limit.whitelist' className='block-title' />
                  <Table data={{...this.tableColumns, rows: whitelist}} tableClass='transactions-table' />

                  <I18n tKey='token_limit.add_to_whitelist_to' className='block-title second-block' />
                  <div className='input-with-btn'>
                    <InputRow
                      wrapperClass='input-with-bg'
                      name='userNameTo'
                      value={userNameTo}
                      statusBorder
                      inputChange={this.onChange}
                      placeholder='token_limit.userName_placeholder'
                      readOnly={!selectedToken}
                    />
                    <Button
                      disabled={!selectedToken || !userNameTo}
                      onClick={this.openAddWhitelistToPinModal}
                      btnClass='with-bg green-bg'
                      title='token_limit.add_to_whitelist_to_btn'
                    />
                  </div>
                  <I18n tKey='token_limit.receiver_whitelist' className='block-title' />
                  <Table data={{...this.tableColumnsWhitelistTo, rows: whitelistto}} tableClass='transactions-table' />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
