import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import {OperationIcon} from '../operationIcon/OperationIcon';
import {ReactComponent as GrayArrow} from '../../../img/gray-arrow.svg';
import {OperationName} from '../operationName/OperationName';
import {ExplorerOperationName} from '../explorerOperationName/ExplorerOperationName';

@inject('userStore')
@observer
export class ActionColumn extends Component {
  render() {
    const {obj, isExplorer} = this.props;
    const {from, receiver, payer, to, username, creator, name, owner} = obj.action_data || {};
    const actionName = isExplorer ? obj.name : obj.action_name;
    const tokenFrom = from || payer || creator || obj.token_from;
    const tokenTo = to || receiver || obj.token_to || username || name || owner;
    const actor = isExplorer ? obj.actor : this.props.userStore.user?.name;

    return (
      <div className='content-row'>
        <OperationIcon obj={obj} currentUser={actor} isExplorer={isExplorer} />
        <div>
          <p className='title'>
            {isExplorer ? (
              <ExplorerOperationName obj={obj} currentUser={actor} />
            ) : (
              <OperationName obj={obj} currentUser={actor} />
            )}
          </p>
          {(tokenFrom || tokenTo) && (
            <p className='table-row'>
              <Link to={`/accounts/${tokenFrom}`}>{tokenFrom}</Link>
              <GrayArrow />
              <Link to={`/accounts/${tokenTo}`}>{tokenTo}</Link>
            </p>
          )}
        </div>
      </div>
    );
  }
}
