/* eslint-disable max-classes-per-file */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {inject} from 'mobx-react';
import {Serialize} from 'eosjs';
import BigNumber from 'bignumber.js';

import {ReactComponent as RefreshIcon} from '../../../img/refresh.svg';
import {I18n} from '../i18n/I18n';
import {TrxRow} from '../trxRow/TrxRow';
import {getEosTable, getUserActions} from '../../../utils/requester';
import {MSG_TYPES, MsgPrinter} from '../msgPrinter/MsgPrinter';
import {StatusLine} from '../statusLine/StatusLine';
import {CountWithZero} from '../countWithZero/CountWithZero';
import {calcPercent, cutTokenPrefix} from '../../../utils/utils';

class StalingPool extends Component {
  state = {
    plan_to_pay: 0,
    total_staked: 0,
  };

  componentDidMount() {
    getEosTable({json: true, code: 'staker', scope: 'staker', limit: 10000, table: 'gstate'}).then(r => {
      const newState = {plan_to_pay: 0, total_staked: 0};
      if (!r.isError) {
        const data = (r.data.rows || []).length ? r.data.rows[0] : {plan_to_pay: 0, total_staked: 0};
        newState.plan_to_pay = cutTokenPrefix(data.plan_to_pay);
        newState.total_staked = cutTokenPrefix(data.total_staked);
      }
      this.setState(newState);
    });
  }

  render() {
    const createdTokens = 3000000000;
    const remainder = 0.0171; // new BigNumber(createdTokens).minus(this.state.plan_to_pay).toNumber();
    const percent = calcPercent(remainder, createdTokens);
    return (
      <div className='staking-pool'>
        <I18n tKey='wallet_page.staking_pool' className='title-page' />
        <div className='staking-content'>
          <CountWithZero round={4} count={remainder} />
          <StatusLine size={`${percent > 100 ? 100 : percent}%`} />
        </div>
      </div>
    );
  }
}

@inject('userStore', 'eosStore')
export class RecentTrxBlock extends Component {
  contracts = {};

  state = {
    transactions: [],
    msg: null,
    isFetching: false,
    first_fetch: true,
  };

  componentDidMount() {
    // this.getRecentTrx();
    // this.timer = setInterval(this.getRecentTrx, 5000);
  }

  componentWillUnmount() {
    // clearInterval(this.timer);
  }

  getRecentTrx = () => {
    this.setState({isFetching: true});

    getUserActions({
      offset: 0,
      limit: 5,
      account: this.props.userStore.user.name,
      symbol: '',
      type: 'All',
    }).then(async r => {
      const newState = {isFetching: false, msg: null, first_fetch: false};
      if (!r.isError) {
        newState.transactions = await Promise.all(
          r.data.map(async action => {
            const {action_name, act_data, contract: contractName} = action;

            let contract = this.contracts[contractName];
            if (!contract) {
              contract = await this.props.eosStore.getContract({name: contractName});
              this.contracts[contractName] = contract;
            }

            const dataa = Serialize.deserializeActionData(
              contract,
              contractName,
              action_name,
              act_data,
              new TextEncoder(),
              new TextDecoder()
            );

            console.log(action_name, dataa);

            return {...action, action_data: dataa};
          })
        );
      } else {
        newState.msg = {type: MSG_TYPES[1], txt: 'common.cant_trx_history'};
      }
      this.setState(newState);
    });
  };

  render() {
    const {isCruForCru} = this.props;
    const {transactions, msg, isFetching, first_fetch} = this.state;

    return (
      <div className='right-block-wrapper'>
        {isCruForCru && <StalingPool />}
        <div className='recent-trx-block'>
          <I18n tKey='wallet_page.dashboard.recent_title' className='title-page' />
          <div className='trx-block-content'>
            <div className='process-msg no-transactions'>
              <div className='recent-block' onClick={this.getRecentTrx}>
                <RefreshIcon />
                <I18n className='process-msg no-transactions' tKey='wallet_page.first_fetch' />
              </div>
            </div>

            {transactions &&
              transactions.map(obj => (
                <Link key={obj.id} to={`/transactions/${obj.trx_id}`}>
                  <TrxRow obj={obj} currentUser={this.props.userStore.user.name} />
                </Link>
              ))}
            {!isFetching && msg && <MsgPrinter className='msg-printer-view' msgType={msg.type} msg={msg.txt} />}
            {isFetching && !transactions && <I18n className='process-msg' tKey='common.looking_trx' />}
            {(transactions || []).length < 1 && !first_fetch && (
              <I18n className='process-msg no-transactions' tKey='common.no_trx_yet' />
            )}

            <Link className='link-history' to='/wallet/history'>
              <I18n tKey='wallet_page.btn_trx' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
