import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {REG_EXP} from '../../../config/const';
import './index.scss';

const MAX_FULL_NAME_LENGTH = 50;
export class TokenFullNameInput extends Component {
  state = {
    curFullName: this.props.value || '',
  };

  onChange = (propName, value) => {
    const {onChange} = this.props;
    this.setState({[propName]: value});
    onChange(value);
  };

  render() {
    const {curFullName} = this.state;

    return (
      <div className='token-full-name-input-wrapper'>
        <I18n tKey='create_token.full-name-label' className='input-label' />
        <Input
          containerClass='password-field token-full-name-input'
          placeholder='create_token.full-name'
          statusBorder
          maxLength={MAX_FULL_NAME_LENGTH}
          regEx={REG_EXP.alphanumericWithSpace}
          onChange={this.onChange}
          name='curFullName'
          value={curFullName}
        />
      </div>
    );
  }
}
