import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';
import BigNumber from 'bignumber.js';

export class CircleProgress extends Component {
  constructor(props) {
    super(props);
    const {radius, stroke} = this.props;
    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    this.state = {progress: 0};
  }

  componentDidMount() {
    setTimeout(() => this.setState({progress: this.props.progress}));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.progress !== this.state.progress) {
      this.setState({progress: this.props.progress});
    }
  }

  render() {
    const {radius, stroke, titleProgress, circleClass} = this.props;
    const {progress} = this.state;
    const strokeDashoffset = new BigNumber(this.circumference).minus((progress / 100) * this.circumference).toNumber();
    const strokeDashoffsetEmpty = new BigNumber(this.circumference).minus((100 / 100) * this.circumference).toNumber();
    return (
      <div className={classNames('circle-progress', circleClass)}>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke='rgba(117, 133, 150, 0.25)'
            fill='transparent'
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{strokeDashoffset: strokeDashoffsetEmpty}}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke='white'
            fill='transparent'
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{strokeDashoffset}}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <div className='circle-txt'>
          <p>
            <span>{this.props.progress}</span>
            <span> &#37;</span>
          </p>
          <span className='title'>
            <I18n tKey={titleProgress} />
          </span>
        </div>
      </div>
    );
  }
}
