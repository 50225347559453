import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';
import {DropDown} from '../dropDown/DropDown';

const formatOptionLabel = data => {
  return (
    <div className='select-row'>
      <I18n className='plan-name' tKey={data.label} />
    </div>
  );
};

export class Filter extends Component {
  render() {
    const {title, filters, selectFilter, activeItem} = this.props;
    return (
      <div className='filter-history'>
        <I18n tKey={title} className='title-filter' />
        <div className='filter-block'>
          {filters.map((filterName, i) =>
            Array.isArray(filterName) ? (
              <DropDown
                wrapperClass='select-filter'
                onChange={selectFilter}
                value={activeItem}
                isOptionSelected={option => {
                  return option === activeItem;
                }}
                options={filterName}
                formatOptionLabel={formatOptionLabel}
                placeholder='create_token.dropDown_token'
                menuPlacement='auto'
              />
            ) : (
              <div
                role='presentation'
                onClick={selectFilter.bind(this, filterName)}
                className={classNames('filter-item', {'active-filter': filterName.label === activeItem.label})}
                key={filterName.label}
              >
                <I18n tKey={filterName.label} />
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}
