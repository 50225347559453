import React, {Component, Fragment} from 'react';
import classNames from 'classnames';

import {I18n} from '../i18n/I18n';

export class Button extends Component {
  render() {
    const {title, tooltipTitle, ArrowIcon, btnClass, onClick, disabled, value} = this.props;
    return (
      <button title={tooltipTitle} disabled={disabled} className={classNames('click-btn', btnClass)} onClick={onClick}>
        {this.props.children ? (
          this.props.children
        ) : (
          <>
            {ArrowIcon && <ArrowIcon className='btn-icon' />}
            {title && <I18n value={value} className='title-btn' tKey={title} />}
          </>
        )}
      </button>
    );
  }
}
