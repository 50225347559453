import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {Tabs, Tab} from '@material-ui/core';

import {Table} from '../../helpers/table/Table';
import {Breadcrumbs} from '../../helpers/breadcrumbs/Breadcrumbs';

import {TIME_FORMAT} from '../../../config/const';
import {getEosTable} from '../../../utils/requester';
import {I18n} from '../../helpers/i18n/I18n';
import {UrlPopupCell} from '../../helpers/urlPopupCell/UrlPopupCell';
import {compareTokenAmountAsc, compareTimeAsc} from '../../../utils/utils';

import {ReactComponent as ProducerIcon} from '../../../img/status-producer.svg';
import {ReactComponent as ActiveProducerIcon} from '../../../img/active-producer.svg';
import {ReactComponent as CandidateIcon} from '../../../img/status-candidate.svg';

import './index.scss';

const producersColumns = {
  columns: [
    {
      name: 'account.table.col_name',
      key: 'owner',
      url: ({owner}) => `/accounts/${owner}`,
      render: ({owner}) => {
        return (
          <Link className='link-btn' to={`/accounts/${owner}`}>
            {owner}
          </Link>
        );
      },
    },
    {
      name: 'producers.table.col_url',
      key: 'url',
      render: ({url}) => <UrlPopupCell url={url} />,
    },
    {name: 'producers.table.col_total_votes', key: 'total_votes', withSort: true, render: ({total_votes}) => +total_votes},
    {
      name: 'producers.table.col_is_active',
      key: 'is_active',
      render: ({is_active}) => {
        return is_active ? <ActiveProducerIcon className='status-icon' /> : <ProducerIcon className='status-icon' />;
      },
    },
    {name: 'producers.table.col_blocks', key: 'unpaid_blocks', withSort: true},
    {
      name: 'producers.table.col_last_claim',
      key: 'last_claim_time',
      withSort: true,
      render: ({last_claim_time}) => moment(last_claim_time).format(TIME_FORMAT.CONVERT_TABLE),
    },
  ],
};

const candidatesColumns = [
  {name: 'account.table.col_name', key: 'candidate'},
  {name: 'producers.table.col_url', key: 'url', render: ({url}) => <UrlPopupCell url={url} />},
  {name: 'producers.table.col_total_cru', key: 'totalsum'},
  {name: 'producers.table.col_total_assistance', key: 'assistcount'},
  {
    name: 'producers.table.col_status',
    render: ({isprod}) => (isprod ? <ProducerIcon className='status-icon' /> : <CandidateIcon className='status-icon' />),
  },
];

const pages = [{title: 'breadcrumbs.home', url: '/'}];

@inject('tokenStore')
@observer
export class Producer extends Component {
  state = {
    producers: [],
    candidates: [],
    selectedTab: 0,
    fetching: false,
  };

  componentDidMount = async () => {
    this.setState({fetching: true});
    const producers = await this.getProducers();
    const candidates = await this.getCandidates();
    this.setState({producers, candidates: candidates.filter(({cancelled}) => !cancelled), fetching: false});
  };

  getCandidates = async () => {
    const {data} = await getEosTable({
      code: 'prodcand',
      json: true,
      limit: 1000,
      scope: 'prodcand',
      table: 'candprod',
    });

    return data?.rows || [];
  };

  getProducers = async () => {
    const {data} = await getEosTable({
      code: 'eosio',
      json: true,
      limit: 1000,
      scope: 'eosio',
      table: 'producers',
    });

    return data?.rows || [];
  };

  sortProducers = sortParams => {
    const {producers} = this.state;
    const {sortColumn, sortDirection} = sortParams;

    if (!sortColumn) {
      return producers;
    }

    const sortedCandidates = [...producers].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      const compareRes =
        sortColumn === 'last_claim_time' ? compareTimeAsc(aValue, bValue) : compareTokenAmountAsc(aValue, bValue);

      if (sortDirection === 'desc') {
        return compareRes * -1;
      }

      return compareRes;
    });

    return sortedCandidates;
  };

  render() {
    const {producers, candidates, selectedTab, fetching} = this.state;

    return (
      <div className='producers-page'>
        <div className='page-content'>
          <div className='header-page'>
            <Breadcrumbs currentTitle='producer.title' pages={pages} />
          </div>
          <div className='nav-bar'>
            <Tabs
              value={selectedTab}
              indicatorColor='primary'
              textColor='primary'
              onChange={(e, val) => {
                this.setState({selectedTab: val});
              }}
            >
              <Tab className='title-page' label={<I18n tKey='producer.producers_tab' />} />
              <Tab className='title-page' label={<I18n tKey='producer.candidates_tab' />} />
            </Tabs>
          </div>

          {selectedTab === 0 && (
            <Table
              tableWrapperClass='relative producers-table'
              usePreloader
              sort={this.sortProducers}
              isFetching={fetching}
              data={{...producersColumns, rows: producers}}
            />
          )}

          {selectedTab === 1 && (
            <Table
              tableWrapperClass='relative candidates-table'
              usePreloader
              isFetching={fetching}
              data={{columns: candidatesColumns, rows: candidates}}
            />
          )}
        </div>
      </div>
    );
  }
}
