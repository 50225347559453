import React, {Component} from 'react';

import {ReactComponent as Trigger} from './img/trigger.svg';

export class DragLine extends Component {
  state = {
    value: 0,
    mouseUpListener: null,
    mouseDownListener: null,
  };

  moveTrigger = React.createRef();
  maxValueLine = React.createRef();

  componentDidMount() {
    let isOnTrigger = false;
    let m_pos;
    const {setAmountStake} = this.props;
    const border_size = 16;
    const m_start = this.maxValueLine.current.getBoundingClientRect().left + pageXOffset;
    const maxLineWidth = this.maxValueLine.current.clientWidth;

    const mouseUpListener = () => {
      if (isOnTrigger) {
        setAmountStake(this.state.value);
        document.removeEventListener('mousemove', resize, false);
        isOnTrigger = false;
      }
    };

    const mouseDownListener = e => {
      if (e.offsetX < border_size) {
        isOnTrigger = true;
        m_pos = e.x;
        document.addEventListener('mousemove', resize, false);
      }
    };

    const resize = e => {
      const dxPX = m_pos - m_start;
      const dx = ((dxPX * 100) / maxLineWidth).toFixed(2);
      m_pos = e.x;
      const nextState = {value: dx};
      if (dx > 100) {
        nextState.value = 100;
      } else if (dx < 0) {
        nextState.value = 0;
      }
      this.setState(nextState);
    };

    this.moveTrigger.current.addEventListener('mousedown', mouseDownListener, false);

    document.addEventListener('mouseup', mouseUpListener, false);

    this.setState({
      mouseUpListener: mouseUpListener,
      mouseDownListener: mouseDownListener,
    });
  }

  componentWillUnmount() {
    const {mouseUpListener, mouseDownListener} = this.state;
    document.removeEventListener('mouseup', mouseUpListener, false);
    document.removeEventListener('mousedown', mouseDownListener, false);
  }

  setDragValue = (amount, maxAmount) => {
    const currentVal = +maxAmount === 0 ? 0 : ((amount * 100) / maxAmount).toFixed(2);
    this.setState({
      value: currentVal >= 100 ? 100 : currentVal,
    });
  };

  render() {
    const {value} = this.state;
    return (
      <div className='drag-line-wrapper'>
        <div className='value-line' ref={this.maxValueLine} />
        <div className='value-line selected' style={{width: `${value}%`}}>
          <div className='drag-cursor'>
            <div className='active-value'>{value} %</div>
            <Trigger ref={this.moveTrigger} />
          </div>
        </div>
      </div>
    );
  }
}
