import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ReactComponent as Logo} from '../../../img/user-create-logo.svg';
import {ChooseStorageType} from './ChooseStorageType';
import {I18n} from '../../helpers/i18n/I18n';
import {SeedPhrase} from './SeedPhrase';
import {ProgressBar} from '../../helpers/progressBar/ProgressBar';
import {MSG_TYPES} from '../../helpers/msgPrinter/MsgPrinter';
import RegisterWallet from './RegisterWallet';
import {getEosTable} from '../../../utils/requester';
import BigNumber from 'bignumber.js';

export const CMP_STEPS = {
  CREATE_WALLET_PHASE: 'CREATE_WALLET_PHASE',
  RECOVERY_SEED_PHRASE: 'RECOVERY_SEED_PHRASE',
  VERIFY_SEED_PHRASE: 'VERIFY_SEED_PHRASE',
  CHOOSE_STORAGE_TYPE: 'CHOOSE_STORAGE_TYPE',
  SET_PAYMENT_DETAILS: 'SET_PAYMENT_DETAILS',
};

@inject('eosStore')
@observer
export class CreateWallet extends Component {
  state = {
    step: CMP_STEPS.CREATE_WALLET_PHASE,
    msg: null,
    isFetching: true,
    accountCreated: false,
  };

  componentWillMount() {
    const requests = [getEosTable({json: true, code: 'eosio', scope: 'eosio', table: 'rammarket'})];
    Promise.all(requests).then(r => {
      const {quote, base} = r[0].data.rows[0];
      const ramPrice = new BigNumber(quote.balance.split(' ')[0]).dividedBy(
        new BigNumber(base.balance.split(' ')[0]).multipliedBy(new BigNumber(quote.weight))
      );
      this.setState({
        quoteUNTB: ramPrice.multipliedBy(16384).integerValue(BigNumber.ROUND_UP).toFixed(),
        ...this.state,
      });
    });
  }

  setStep = step => {
    this.setState({step, msg: null});
  };

  setParams = params => {
    this.setState(params);
  };

  setPreviousStep = () => {
    const {step} = this.state;
    const newState = {msg: null};

    switch (step) {
      case 'CREATE_WALLET_PHASE':
        newState.step = CMP_STEPS.CREATE_WALLET_PHASE;
        break;
      case 'VERIFY_SEED_PHRASE':
        newState.step = CMP_STEPS.RECOVERY_SEED_PHRASE;
        break;
      case 'CHOOSE_STORAGE_TYPE':
        newState.step = CMP_STEPS.VERIFY_SEED_PHRASE;
        break;
      case 'DONE_VERIFY_PHRASE':
        newState.step = CMP_STEPS.VERIFY_SEED_PHRASE;
        break;
      case 'SET_PAYMENT_DETAILS':
        newState.step = CMP_STEPS.CHOOSE_STORAGE_TYPE;
        break;
      default:
    }

    this.setState(newState);
  };

  redirect = () => {
    const {history} = this.props;
    history.push('/wallet/auth');
  };

  home = () => {
    const {history} = this.props;
    history.push('/');
  };

  setAccount = (accountName, publicKey) => {
    const {eosStore} = this.props;
    this.setState({isFetching: true});
    eosStore.makeAccountRequest(accountName, publicKey).then(r => {
      const newState = {isFetching: false, msg: null};
      if (!r.isError) {
        newState.accountCreated = true;
      } else {
        newState.msg = {type: MSG_TYPES[1], txt: r.data?.error || 'common.request_error'};
      }
      this.setState(newState);
    });
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value});
  };

  render() {
    const {accountCreated, step, isFetching, msg} = this.state;
    return (
      <div className='wallet-service'>
        <Logo className='logo-pic' onClick={this.home} />
        <div className='wrapper-with-progress'>
          {(step === CMP_STEPS.RECOVERY_SEED_PHRASE || step === CMP_STEPS.VERIFY_SEED_PHRASE) && (
            <ProgressBar lineClass='create-status-line' size={`${step === CMP_STEPS.RECOVERY_SEED_PHRASE ? 50 : 100}%`} />
          )}

          <div className='steps-container'>
            {step === CMP_STEPS.CREATE_WALLET_PHASE && <RegisterWallet setStep={this.setStep} setParams={this.setParams} />}

            {(step === CMP_STEPS.RECOVERY_SEED_PHRASE || step === CMP_STEPS.VERIFY_SEED_PHRASE) && (
              <SeedPhrase step={step} setStep={this.setStep} setParams={this.setParams} requestData={this.state} />
            )}
            {step === CMP_STEPS.CHOOSE_STORAGE_TYPE && (
              <ChooseStorageType
                setAccount={this.setAccount}
                setStep={() => this.setStep(CMP_STEPS.SET_PAYMENT_DETAILS)}
                requestData={this.state}
                isFetching={isFetching}
                msg={msg}
                accountCreated={accountCreated}
              />
            )}
          </div>
        </div>
        {/* //TODO: make it more structural */}
        {step !== CMP_STEPS.CREATE_WALLET_PHASE && step !== CMP_STEPS.RECOVERY_SEED_PHRASE && !accountCreated && (
          <div role='presentation' className='back-btn' onClick={this.setPreviousStep}>
            <I18n tKey='user-create.back-btn' />
          </div>
        )}
        {step === CMP_STEPS.CREATE_WALLET_PHASE && !accountCreated && (
          <div role='presentation' className='back-btn' onClick={this.home}>
            <I18n tKey='user-create.back-btn' />
          </div>
        )}
        {accountCreated && (
          <div role='presentation' className='back-btn' onClick={this.redirect}>
            <I18n tKey='user-create.continue' />
          </div>
        )}
      </div>
    );
  }
}
