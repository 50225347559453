import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export class ProgressScale extends Component {
  render() {
    const {scale, countDays = '29d 12h 33m 14s'} = this.props;
    return (
      <div className='progress-scale'>
        {scale.map((elem, i) => (
          <div key={i} className='active-scale' />
        ))}
        {/*<div className="info-block">*/}
        {/*<I18n tKey="progress_scale.left"/>*/}
        {/*<div className="info-txt"> {countDays}</div>*/}
        {/*</div>*/}
      </div>
    );
  }
}
