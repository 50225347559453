import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {WalletCreateToken} from '../walletCreateToken/WalletCreateToken';
import {WalletCreateBusinesAcc} from '../walletCreateBusinesAcc/WalletCreateBusinesAcc';

@inject('userStore')
@observer
export class WalletBusinesAcc extends Component {
  render() {
    const {
      userStore: {isBusinessAccount},
    } = this.props;

    return isBusinessAccount ? <WalletCreateToken /> : <WalletCreateBusinesAcc />;
  }
}
