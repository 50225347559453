import React, {Component} from 'react';
import {Area, AreaChart, CartesianGrid, Tooltip, ResponsiveContainer, XAxis} from 'recharts';
import {CustomTooltip} from './CustomTooltip';

export class BlocksChart extends Component {
  render() {
    const {width, height} = this.props;
    return (
      <div className='wrapper'>
        <ResponsiveContainer width={width} height={height}>
          <AreaChart data={this.props.data}>
            <CartesianGrid strokeDasharray='2 0' vertical={false} stroke='#ECEFF1' />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <XAxis dataKey='block_num' hide={true} />
            <Area
              isAnimationActive={false}
              type='monotone'
              dataKey='actions'
              stackId='1'
              stroke='#345EA9'
              strokeWidth='1.5'
              fill='#345EA9'
              fillOpacity={0.1}
              dot={{fill: '#345EA9', fillOpacity: 1, stroke: 'none', r: 2.2}}
              activeDot={{r: 2.5, stroke: 'rgba(66, 147, 246, 0.1)', strokeWidth: 7}}
            />
            <Area
              isAnimationActive={false}
              type='monotone'
              dataKey='cpuUsage'
              stackId='1'
              stroke='#52AA55'
              strokeWidth='1.5'
              fill='#52AA55'
              fillOpacity={0.1}
              dot={{fill: '#52AA55', fillOpacity: 1, stroke: 'none', r: 2.2}}
              activeDot={{r: 2.5, stroke: 'rgba(96, 227, 100, 0.2)', strokeWidth: 7}}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
