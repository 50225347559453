import React, {Component} from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router';

import {I18n} from '../../helpers/i18n/I18n';
import {TOKENS} from '../../../config/const';
import {Button} from '../../helpers/button/Button';
import {CountWithZero} from '../../helpers/countWithZero/CountWithZero';

import {ReactComponent as StakeIcon} from '../../../img/stake_arrow.svg';

@withRouter
export class CardStaking extends Component {
  goToStakePage = token => {
    if (token === TOKENS.CRU) {
      this.props.history.push('/wallet/staking/cru');
    } else if (token === TOKENS.UNTB) {
      this.props.history.push('/wallet/staking/untb');
    } else if (token === TOKENS.WCRU) {
      this.props.history.push('/wallet/staking/wcru');
    } else if (token === 'cru-for-cru') {
      this.props.history.push('/wallet/staking/cru-for-cru');
    }
  };

  render() {
    const {
      titleCard,
      subtitleCard,
      cardClass,
      titleCurReward,
      countReward,
      cardIcon,
      titleAmount,
      countCurReward,
      countAmount,
      titleReward,
      btnTitle,
      round,
      token,
      countAvailable,
      countNet,
      countCpu,
    } = this.props;
    return (
      <div className={classNames('card-staking-wrapper', cardClass)}>
        <div className='header-card'>
          <I18n tKey={titleCard} className='title-card' />
          <I18n tKey={subtitleCard} className='subtitle-card' />
          {cardIcon && <img alt='' src={cardIcon} className='card-icon' />}
        </div>
        {titleReward && (
          <div className='reward-card'>
            <I18n tKey={titleReward} className='subtitle-card' />
            <p className='count-reward'>
              <span>{countReward}</span>
            </p>
          </div>
        )}
        <div className='staked-amount-card'>
          <div className='left-col col-amount'>
            <I18n tKey={titleAmount || 'wallet_staking.card.staked_amount'} className='subtitle-card' />
            {countNet ? <span>{countNet}</span> : <CountWithZero dividedView round={round} count={countAmount} />}
          </div>
          {countCurReward !== undefined && (
            <div className='right-col col-amount'>
              <I18n tKey={titleCurReward || 'wallet_staking.card.cur_reward'} className='subtitle-card' />
              {countCpu ? <span>{countCpu}</span> : <CountWithZero dividedView round={round} count={countCurReward} />}
            </div>
          )}
        </div>
        <Button title={btnTitle} btnClass='card-btn' ArrowIcon={StakeIcon} onClick={this.goToStakePage.bind(this, token)} />
        <div className='available-row'>
          <CountWithZero dividedView round={4} count={countAvailable} />
          <span className='tokne-name'>{token === 'cru-for-cru' ? TOKENS.CRU : token}</span>
          <I18n tKey='wallet_staking.card.available' />
        </div>
      </div>
    );
  }
}
