import {syncHistoryWithStore} from 'mobx-react-router';
import {createBrowserHistory} from 'history';
import {Provider} from 'mobx-react';
import {Router} from 'react-router';
import React, {Component} from 'react';

import 'swiper/css/swiper.css';
import './App.scss';

import MainLayout from './components/mainPages/MainLayout';
import ScrollToTop from './components/helpers/scrollToTop/ScrollToTop';

const history = createBrowserHistory();

export class App extends Component {
  render() {
    return (
      <Provider {...this.props.store}>
        <Router history={syncHistoryWithStore(history, this.props.store.routing)}>
          <ScrollToTop>
            <MainLayout />
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
