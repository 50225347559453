import React, {Component} from 'react';
import {I18n} from '../../helpers/i18n/I18n';

export class AppError extends Component {
  render() {
    return (
      <div className='app-error'>
        <I18n tKey='app_error.error' />
        <I18n tKey='app_error.wrong' />
        <I18n tKey='app_error.problem' />
      </div>
    );
  }
}
