import {withRouter} from 'react-router';
import React, {Component} from 'react';

@withRouter
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const isSendPage = /\/send/.test(prevProps.location.pathname);
    if (this.props.location.pathname !== prevProps.location.pathname && !isSendPage) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default ScrollToTop;
