import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {inject, observer} from 'mobx-react';

import {RecentTrxBlock} from '../helpers/recentTrxBlock/RecentTrxBlock';
import {WalletDividends} from './walletDividends/WalletDividends';
import {WalletDividendsTable} from './walletDividendsTable/WalletDividendsTable';
import {WalletDashboard} from './walletDashboard/WalletDashboard';
import {WalletHistory} from './walletHistory/WalletHistory';
import {WalletHeader} from './walletHeader/WalletHeader';
import {WalletUntb} from './walletUntb/WalletUntb';
import {WalletSend} from './walletSend/WalletSend';
import {WalletAuth} from './walletAuth/WalletAuth';
import {WalletVote} from './walletVote/WalletVote';
import {WalletCru} from './walletCru/WalletCru';
import {WalletRam} from './walletRam/WalletRam';
import {NoMatch} from './noMatch/NoMatch';
import {WalletConvert} from './walletConvert/walletConvert';
import {TOKENS} from '../../config/const';
import {WalletStaking} from './walletStaking/WalletStaking';
import {CruForCruStaking} from './cruForCruStaking/CruForCruStaking';
import {WalletConvertResult} from './walletConvertResult/WalletConvertResult';
import {WalletConvertRates} from './walletConvertRates/WalletConvertRates';
import {WalletBusinesAcc} from './walletBusinesAcc/WalletBusinesAcc';
import {WalletProducer} from './walletProducer/walletProducer';
import {WalletLimits} from './walletLimits/WalletLimits';
import {IssuePlan} from './issuePlan/IssuePlan';

// in main router  {path: "/wallet", component: WalletRouter}, can be used inside switch

@inject('userStore')
@observer
export class WalletRouter extends Component {
  render() {
    const {
      userStore,
      match: {path},
      history,
    } = this.props;
    const {user} = userStore;

    const regExp =
      /\/wallet\/history|\/wallet\/issue-plan|\/wallet\/limits|\/wallet\/vote|\/wallet\/business-account|\/wallet\/producer|\/wallet\/auth|\/wallet\/convert|\/wallet\/staking(?!\/)/;

    const hideTrx = regExp.test(history.location.pathname);
    const validName =
      typeof GLOBAL_ENV !== 'undefined' ? window.GLOBAL_ENV.REACT_APP_VALID_NAME : process.env.REACT_APP_VALID_NAME;

    return (
      <div className='wallet-router'>
        <WalletHeader userName={user.name} />
        <div className='container'>
          <Switch>
            {[
              {path: `${path}`, exact: true, component: WalletDashboard},
              {path: `${path}/auth`, component: WalletAuth},
              {path: `${path}/send/:token?`, component: WalletSend},
              {path: `${path}/staking`, exact: true, component: WalletStaking},
              {path: `${path}/staking/cru`, component: () => <WalletCru symbol={TOKENS.CRU} />},
              {path: `${path}/staking/wcru`, component: () => <WalletCru symbol={TOKENS.WCRU} />},
              {path: `${path}/staking/untb`, component: WalletUntb},
              {path: `${path}/staking/cru-for-cru`, component: CruForCruStaking},
              {path: `${path}/recent-trx-blockstaking/untb`, component: WalletUntb},
              {path: `${path}/ram`, component: WalletRam},
              {path: `${path}/history`, component: WalletHistory},
              {path: `${path}/vote`, component: WalletVote},
              {path: `${path}/convert`, component: WalletConvert},
              {path: `${path}/convert-rates`, component: WalletConvertRates},
              {path: `${path}/business-account`, component: WalletBusinesAcc},
              {path: `${path}/limits`, component: WalletLimits},
              // {path: `${path}/issue-plan`, component: IssuePlan},
              {
                path: `${path}/producer`,
                component: () => (userStore?.isProducerAccount || true ? <WalletProducer /> : <Redirect to={`${path}`} />),
              },
              // {path: `${this.props.match.path}/converted-ok`, component: WalletConvertResult},
              // {path: `${this.props.match.path}/converted-err`, component: props => <WalletConvertResult {...props} isError />},
              {
                path: `${path}/dividends`,
                component: WalletDividendsTable,
              },
              {
                path: `${path}/dividends-usdu`,
                component: () => (user.name === validName ? <WalletDividends /> : <Redirect to={`${path}`} />),
              },
              {path: `${path}/*`, component: NoMatch},
            ].map(route => (
              <Route key={route.path} {...route} />
            ))}
          </Switch>
        </div>
        {!hideTrx && <RecentTrxBlock isCruForCru={history.location.pathname === '/wallet/staking/cru-for-cru'} />}
      </div>
    );
  }
}
