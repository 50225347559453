import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {inject} from 'mobx-react';

import {I18n} from '../i18n/I18n';
import {changeFormat} from '../../../utils/utils';

@inject('blockListStore')
export class VerticalGraph extends Component {
  calcWidth = num => {
    const initFigure = 100;
    const width = Math.round((num / initFigure) * 100);
    return width > 0 ? width + 20 : width;
  };

  selectBlock = index => {
    this.props.blockListStore.setBlockIndex(index);
  };

  render() {
    const {blocks, activeIndex} = this.props;
    return (
      <ul className='vertical-graph'>
        {blocks.map((block, i) => (
          <li
            key={i}
            onClick={this.selectBlock.bind(this, i)}
            className={classNames({active: i === activeIndex})}
            style={{width: `${this.calcWidth(block.transaction_count)}%`}}
          >
            <div className='info-block'>
              <div className='content-wrapper'>
                <Link to={`/blocks/${block.block_num}`} className='info-number'>
                  #{changeFormat(block.block_num, 0)}
                </Link>
                <div className='info-tx'>
                  {block.transaction_count}
                  <I18n tKey="common.tx"/>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
