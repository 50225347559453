import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import HDKey from 'hdkey';
import * as bip39 from 'bip39';
import wif from 'wif';

import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';
import {Input} from '../../helpers/input/Input';
import {LOGGER_TYPES} from '../../../stores/LoggerStore';
import {ReactComponent as ArrowDown} from '../../../img/arrow_down.svg';
import {ReactComponent as CopySvg} from '../../../img/icon-copy.svg';
import './index.scss';

@inject('loggerStore')
@observer
export class SeedPhraseToKey extends Component {
  state = {
    seedPhase: '',
    privKey: '',
    isValidSeed: true,
  };

  onChange = (propName, value) => {
    this.setState({[propName]: value, privKey: '', isValidSeed: true});
  };

  tranformSeedToKey = () => {
    const {loggerStore} = this.props;
    const {seedPhase} = this.state;
    const isSeed = bip39.validateMnemonic(seedPhase);
    const newState = {privKey: ''};

    try {
      if (isSeed) {
        const seed = bip39.mnemonicToSeedSync(seedPhase).toString('hex');
        const master = HDKey.fromMasterSeed(Buffer(seed, 'hex'));
        const node = master.derive("m/44'/194'/0'/0/0");
        newState.privKey = wif.encode(128, node._privateKey, false);
      } else {
        throw new Error('notSeed');
      }
    } catch {
      newState.isValidSeed = false;
      loggerStore.addItem({type: LOGGER_TYPES[1], msgTxt: 'errors.invalid-seed'});
    } finally {
      this.setState(newState);
    }
  };

  copyKey = async () => {
    const {privKey} = this.state;
    await navigator.clipboard.writeText(privKey);
  };

  render() {
    const {seedPhase, privKey, isValidSeed} = this.state;

    return (
      <div className='seed-phrase-to-key-page'>
        <I18n tKey='seed_to_key.title' className='title-page' />

        <div className='row'>
          <I18n tKey='seed_to_key.seed-phrase-label' className='input-label' />
          <Input
            containerClass='password-field'
            placeholder='seed_to_key.seed-phrase-placeholder'
            statusBorder
            error={!isValidSeed}
            onChange={this.onChange}
            name='seedPhase'
            value={seedPhase}
          />
        </div>
        <Button
          btnClass='with-bg blue tranform-btn'
          title='seed_to_key.transform-btn'
          disabled={!seedPhase || !isValidSeed}
          onClick={this.tranformSeedToKey}
        />
        <ArrowDown className='arrow-down' />

        <div className='row'>
          <I18n tKey='seed_to_key.key-label' className='input-label' />
          <Input containerClass='password-field' placeholder='seed_to_key.key-placeholder' readOnly value={privKey} />

          {privKey && <CopySvg onClick={this.copyKey} className='copy-icon' />}
        </div>
      </div>
    );
  }
}
