/* eslint-disable no-nested-ternary */
import React, {Component} from 'react';
import Modal from 'react-modal';

import {inject, observer} from 'mobx-react';

import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';
import './index.scss';

Modal.setAppElement('#root');

@inject('userStore')
@observer
export class ConfirmModal extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
  }

  render() {
    const {isOpen, closeModal, onConfirm, message, title, btnTitle, btnTitleBack} = this.props;
    const {children} = this.props;

    return (
      <Modal
        overlayClassName='mobile-modal-overlay'
        className='confirm-modal'
        isOpen={isOpen}
        shouldCloseOnEsc
        onRequestClose={closeModal}
      >
        <div className='content'>
          <h1>
            <I18n className='sorry-txt' tKey={title || 'common.confirm_title'} />
          </h1>
          {children}
          {!children && (
            <div className='result-txt'>
              <p className='subtitle'>
                <I18n tKey={message} />
              </p>
            </div>
          )}
          <div className='btn-wrapper'>
            <Button btnClass='with-bg blue' onClick={onConfirm} title={btnTitle || 'common.confirm'} />
            <p onClick={closeModal}>
              <I18n tKey={btnTitleBack || 'common.back'} />
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
